export const componentsSteps = {
    navArrowColor: 'transparent',
    colorTextDescription: '#FFFFFF',
    colorText: '#FFFFFF',
};
export const componentsSelect = {
    optionActiveBg: 'transparent',
    activeBorderColor: '#475766',
    colorBorder: '#475766',
    colorTextPlaceholder: '#BFDFFF',
    selectorBg: '#12161A',
    colorTextQuaternary: '#FFFFFF',
    optionSelectedColor: '#FFFFFF',
    colorText: '#FFFFFF',
    colorTextDisabled: '#7F7F7F',
    borderRadius: 4,
};
export const componentsTypography = {
    colorText: '#FFFFFF',
};
export const componentsModal = {
    contentBg: '#1F2226',
    colorIcon: '#FFFFFF',
};
export const componentsCard = {
    padding: 0,
};
export const componentsButton = {
    colorBgContainerDisabled: 'transparent',
    colorTextDisabled: '#7F7F7F',
    borderColorDisabled: '#7F7F7F',
    paddingBlock: 8,
    defaultColor: '#D9D9D9',
    defaultBg: 'transparent',
    defaultBorderColor: '#D9D9D9',
    defaultHoverBg: 'transparent',
    defaultHoverBorderColor: '#D9D9D9',
    defaultHoverColor: '#D9D9D9',
    borderRadius: 2,
    onlyIconSize: 8,
    controlHeight: 12,
    colorError: '#FF3938',
};
export const componentsInputNumber = {
    addonBg: '#303940',
    colorBorder: '#303940',
    colorBgContainer: '#12161A',
    colorText: '#FFFFFF',
    controlHeightLG: 38,
    borderRadiusLG: 5,
};
export const componentsForm = {
    colorTextDescription: '#FFFFFF',
    labelColor: '#FFFFFF',
};
export const componentsCheckbox = {
    colorText: '#FFFFFF',
    colorTextDisabled: '#7F7F7F',
};
export const componentsRadio = {
    colorTextDisabled: '#7F7F7F',
};
export const componentsSpin = {
    colorPrimary: '#DCA342',
};
