import { Row, Col } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import dayjs from 'dayjs';

import { useCare1AppDispatch } from '../../apps/care1-hooks';
import '../../../static/css/components/od-dashboard.scss';

interface TableItem {
    month: string,
    value: number,
}

type ComponentProps = {
    title: string,
    data: TableItem[],
}

interface CustomizedAwsCostServiceGraphAxisTickProps {
    x: number;
    y: number;
    stroke: string;
    payload: { value: string };
}

const AwsCostServiceGraph = ({ title, data }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const getSixMonthIntervals = (data: TableItem[]) => {
        const result = [];
        // Iterate through the data and add every 6th month
        let j = 0;
        for (let i = data.length-1; i >= 0; i--) {
            if (j % 6 === 0) {
                result.push(data[i]);
            }
            j++;
        }
        return result;
    }

    // Function to check if a month is in the result array
    const isMonthInResult = (month: string, result: TableItem[]) => {
        // Convert the input month to a Day.js object
        const targetMonth = dayjs(month).format("MMM YYYY");

        // Check if any item in the result array has the same month
        return result.some(item => dayjs(item.month).format("MMM YYYY") === targetMonth);
    }

    const CustomizedAwsCostServiceGraphAxisTick = ({ x, y, stroke, payload }: CustomizedAwsCostServiceGraphAxisTickProps) => {

        const validMonths = getSixMonthIntervals(data);
        const fill = isMonthInResult(payload.value, validMonths) ? '#666' : 'rgba(31, 34, 38, 0)';
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={fill} transform="rotate(0)">
                    {payload.value}
                </text>
            </g>
        );
    };

    return (
        <div className="aws-cost-graph">
            <Row>
                <Col span={24}>
                    <div className='title'>
                        {title}
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <div className='graph-container'>
                        <ResponsiveContainer height={170} width="100%">
                            <LineChart
                                data={data}
                                margin={{
                                    right: 20,
                                }}>
                                <Tooltip />
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" height={60} tick={CustomizedAwsCostServiceGraphAxisTick} />
                                <YAxis />
                                <Line type="monotone" dataKey="value" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default AwsCostServiceGraph