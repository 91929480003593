import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-latency-metrics-list-table.scss';
import { compareStrings } from '../../helpers/sorting';
import { localizedText } from '../../localizedText';
import slugify from 'react-slugify';
import AdminLatencyMetricsDetailModal from './admin-latency-metrics-detail-modal';


export interface ILatencyMetricListTable {
    key: React.Key;
    id: string;
    'metric_name': string,
    [x: string]: {
        max: string,
        average: string,
    } | string | React.Key,
}

type AppProps = {
    tableList: ILatencyMetricListTable[],
    days: string[],
    title: string,
    color_green_threshold: number | null,
    color_yellow_threshold: number | null,
    rowKeyMap: { [k: string]: string },
    onClickOpenDetail: boolean,
}

const AdminLatencyMetricsTable = ({ tableList, days, title, color_green_threshold, color_yellow_threshold, rowKeyMap, onClickOpenDetail }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedMetricsName, setSelectedMetricsName] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleServiceSelection = (record: ILatencyMetricListTable) => {
        if (!onClickOpenDetail) {
            return;
        }
        
        setSelectedMetricsName(rowKeyMap[record.metric_name]);
        setOpenDetailModal(true);
    }

    const renderMs = (symbol: string, max: string) => {
        if (max === '')
            return '';

        if (color_green_threshold === null || color_yellow_threshold === null) {
            return <span className='status-white'>{symbol} {Number(max).toFixed(2)}</span>;
        }

        if (Number(max) <= color_green_threshold)
            return <span className='status-green'>{symbol} {Number(max).toFixed(2)}</span>;
        else if (Number(max) < color_yellow_threshold)
            return <span className='status-yellow'>{symbol} {Number(max).toFixed(2)}</span>;
        else
            return <span className='status-red'>{symbol} {Number(max).toFixed(2)}</span>;
    }

    const nameColumns: ColumnsType<ILatencyMetricListTable> = [
        {
            className: 'metric_name-column',
            title: 'Metric Name',
            dataIndex: 'metric_name',
            sorter: (a, b) => compareStrings(a['metric_name'], b['metric_name']),
            render: (text, record) => <div data-testid={`'metric_name'-${record.key}`}><a onClick={() => handleServiceSelection(record)}>{text}</a></div>
        }
    ];

    days.forEach((d: string) => {
        nameColumns.push({
            className: `d${slugify(d)}-column`,
            title: `${d}`,
            dataIndex: `${d}`,
            sorter: (a, b) => {
                const va: {
                    max: string,
                    average: string
                } = a[`${d}`] as {
                    max: string,
                    average: string
                };

                const vb: {
                    max: string,
                    average: string
                } = b[`${d}`] as {
                    max: string,
                    average: string
                };

                return Number(va.average) - Number(vb.average);
            },
            render: (text, record) => {
                const v: {
                    max: string,
                    average: string
                } = record[d] as {
                    max: string,
                    average: string
                };

                return (<div className='ms-cell'>
                    <div>{renderMs("↑", v.max)}</div>
                    <div>{renderMs("~", v.average)}</div>
                </div>);
            }
        })
    });


    return (
        <div>
            <div className='metrics-title'>{title}</div>
            <div className={'latency-metrics-list-table'}>
                <div className={scrollClass}>
                    <Table
                        columns={nameColumns}
                        dataSource={tableList}
                        pagination={false}
                    />
                </div>
                <div>
                    <AdminLatencyMetricsDetailModal
                        title={title}
                        isModalOpen={openDetailModal}
                        closeModal={() => setOpenDetailModal(false)}
                        metricsName={selectedMetricsName}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdminLatencyMetricsTable;