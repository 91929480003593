import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IGptOmdHistoryPrompt {
    gpt_chat_omd_history_api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_omd_history_prompts: string,
}

const initialState: IGptOmdHistoryPrompt = {
    gpt_chat_omd_history_api_status: 'idle',
    gpt_chat_omd_history_prompts: '',
};


export const fetchGptChatOmdHistoryResponse = createAsyncThunk(
    'gptOmdHistory/fetchGptChatOmdHistoryResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_omd_history_chat_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatOmdHistoryPrompt = createAsyncThunk(
    'gptOmdHistory/fetchGptChatOmdHistoryPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_omd_history_chat_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const gptOmdHistorySlice = createSlice({
    name: 'gptOmdHistorySlice',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGptChatOmdHistoryPrompt.pending, (state) => {
                state.gpt_chat_omd_history_api_status = 'loading';
                state.gpt_chat_omd_history_prompts = '';
            })
            .addCase(fetchGptChatOmdHistoryPrompt.fulfilled, (state, action) => {
                state.gpt_chat_omd_history_api_status = 'succeeded';
                state.gpt_chat_omd_history_prompts = action.payload.gpt_omd_history_chat_prompts;
            })
            .addCase(fetchGptChatOmdHistoryPrompt.rejected, (state) => {
                state.gpt_chat_omd_history_api_status = 'failed';
                state.gpt_chat_omd_history_prompts = '';
            })
    },
});


export default gptOmdHistorySlice.reducer;
