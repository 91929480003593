// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import '../../../../static/css/components/admin-gpt-vision-oct-rnfl.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { fetchGptVisionOctRnflPrompt, fetchAutoGptVisionOctRnflResponse } from '../../../reducers/gpt-vision-slice';
import GptUI from '../gpt/gpt-ui';
import GptOctRnflAverage from './gpt-oct-rnfl-average';
import Care1LightBox from '../../retina/care1-light-box';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';

interface Message {
    input: string;
    output: string;
    meta: string;
}

type ComponentProps = {
    side: string,
    photo: string,
    octavSup: string,
    setOctavSup: (val: string) => void,
    octav: string,
    setOctav: (val: string) => void,
    octavInf: string,
    setOctavInf: (val: string) => void,
}

const GptVisionOctRfnl = ({ side, photo, octavSup, setOctavSup, octav, setOctav, octavInf, setOctavInf }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const [lightboxOn, setLightboxOn] = useState(false);

    const examId = useCare1AppSelector(store => store.examData.id);

    const extractColor = (value: string) => {
        if (!value) {
            return 'na';
        }

        const valueStr = `${value}`;
        if (valueStr.toLowerCase() === 'green') {
            return 'green';
        }
        else if (valueStr.toLowerCase() === 'red') {
            return 'red';
        }
        else if (valueStr.toLowerCase() === 'yellow') {
            return 'yellow';
        }

        return 'na';
    }

    const setExamDataValues = (results: { [x: string]: string; }) => {

        // # {
        // #   "Whole": "Green",
        // #   "Superior": "Green",
        // #   "Nasal": "Green",
        // #   "Inferior": "Green",
        // #   "Temporal": "Yellow"
        // # }


        if (results.hasOwnProperty('Whole')) {
            setOctav(extractColor(results['Whole']));
        }
        else if (results.hasOwnProperty('whole')) {
            setOctav(extractColor(results['whole']));
        }
        else if (results.hasOwnProperty('Average')) {
            setOctav(extractColor(results['Average']));
        }
        else if (results.hasOwnProperty('average')) {
            setOctav(extractColor(results['average']));
        }
        else {
            setOctav('na');
        }

        if (results.hasOwnProperty('Superior')) {
            setOctavSup(extractColor(results['Superior']));
        }
        else if (results.hasOwnProperty('superior')) {
            setOctavSup(extractColor(results['superior']));
        }
        else {
            setOctavSup('na');
        }

        if (results.hasOwnProperty('Inferior')) {
            setOctavInf(extractColor(results['Inferior']));
        }
        else if (results.hasOwnProperty('inferior')) {
            setOctavInf(extractColor(results['inferior']));
        }
        else {
            setOctavInf('na');
        }
    }

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = `${obj['message']['meta']}`;

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Vision output',
            });
        };

        if (outputStr !== '') {
            try {
                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);
                    setExamDataValues(outputObj);

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
            catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Errors parsing GPT Vision output',
                });
            };
        }
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/vision/oct_rnfl/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // // Get Gpt response
        getAutoGptResponse();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'input': inputMessage,
                    'image_url': `${getFilepathFromBackendMediaUrl(photo)}`,
                    'history': []
                }
            }));
            setMessageHistory(h => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
        }
    }

    const divStyle = {
        height: `${windowHeight - 100}px`,
    };

    const getAutoGptResponse = async () => {

        if (examId === null) {
            return
        }
        
        try {
            const result = await dispatch(fetchAutoGptVisionOctRnflResponse()).unwrap();
            if (result && result.success) {
                if (side === 'right' || side === 'od') {
                    if (result.od_gpt_oct_rnfl_avg_response !== '') {
                        handleSocketOnMessage(result.od_gpt_oct_rnfl_avg_response);
                    }
                }
                else if (side === 'left' || side === 'os') {
                    if (result.os_gpt_oct_rnfl_avg_response !== '') {
                        handleSocketOnMessage(result.os_gpt_oct_rnfl_avg_response);
                    }
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {

        }
    }

    const handleReset = async () => {

        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchGptVisionOctRnflPrompt(side)).unwrap();
            if (result && result.success) {
                setInputMessage(result.gpt_vision_oct_rnfl_prompts);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Vision Oct Rnfl prompts',
            });
        }
    }


    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={false}
        >
            <div className="chat-container gpt-vision-oct-rnfl">
                <Row>
                    <Col span={24}>
                        <img src={`${getFilepathFromBackendMediaUrl(photo)}`} onClick={() => setLightboxOn(true)} />
                        <Care1LightBox
                            field={'gpt-vision-oct-rnfl'}
                            isOpen={lightboxOn}
                            mainSrc={`${getFilepathFromBackendMediaUrl(photo)}`}
                            mainSrcThumbnail={`${getFilepathFromBackendMediaUrl(photo)}`}
                            onCloseRequest={() => setLightboxOn(false)}
                        />
                    </Col>
                </Row>
                <Row>
                    {(side === 'left' || side === 'os') &&
                        <Col span={12}>
                            <GptOctRnflAverage
                                octavSup={octavSup}
                                setOctavSup={setOctavSup}
                                octav={octav}
                                setOctav={setOctav}
                                octavInf={octavInf}
                                setOctavInf={setOctavInf}
                            />
                        </Col>
                    }
                    <Col
                        span={12}>
                        <GptUI
                            messageHistory={messageHistory}
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            sendMessage={sendMessage}
                            formatMessageContent={formatMessageContent}
                            isAssistantTyping={isAssistantTyping}
                            messagesEndRef={messagesEndRef}
                        />
                        <Row>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleReset}
                                    type="primary"
                                    size="large"
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    {(side === 'right' || side === 'od') &&
                        <Col span={12}>
                            <GptOctRnflAverage
                                octavSup={octavSup}
                                setOctavSup={setOctavSup}
                                octav={octav}
                                setOctav={setOctav}
                                octavInf={octavInf}
                                setOctavInf={setOctavInf}
                            />
                        </Col>
                    }
                </Row>
            </div>
        </Spin>)
}

export default GptVisionOctRfnl;
