import {
    CloseCircleOutlined,
    CloudSyncOutlined,
    GlobalOutlined,
    InfoCircleOutlined,
    ProfileOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Flex, Row, Select, Space, Tooltip, Typography } from 'antd';

import styles from '../../../../static/css/modules/smart-upload-table-control-bar.module.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { localizedText } from '../../../localizedText';
import { getSmartUploadList, setSmartUploadDataValues, setSmartUploadSliceDataValue, setSortedInfo, setSortOrder } from '../../../reducers/smart-upload-slice';
import { goToExamInNewTab } from '../../../reducers/patient-exam-slice';



const SmartUploadTableControlBar = () => {
    const {
        ADMIN_REFRESH_TABLES,
        ADMIN_RESET_SORT,
        ADMIN_COLUMN_SORT_TOOLTIP,
        OPEN_SELECTED_PATIENTS,
        GET_FILTERED_LIST,
        CLEAR_FILTERS,
    } = localizedText;

    const odGroupFilter = useCare1AppSelector(
        (store) => store.smartUpload.odGroupFilter
    );
    const readyForValidationFilter = useCare1AppSelector(
        (store) => store.smartUpload.readyForValidationFilter
    );
    const miscFilter = useCare1AppSelector(
        (store) => store.smartUpload.miscFilter
    );
    const selectedRowKeys = useCare1AppSelector(
        (store) => store.smartUpload.selectedRowKeys
    );
    const sortOrder = useCare1AppSelector(
        (store) => store.smartUpload.sortOrder
    );
    const odGroupPracticOptions = useCare1AppSelector(
        (store) => store.options.odgrouppractices
    );

    const dispatch = useCare1AppDispatch();

    const readyForValidationFilterOptions = [
        {
            label: 'All uploads',
            value: 'all_uploads',
        },
        {
            label: 'High Priority',
            value: 'high_priority',
        },
        {
            label: 'Other',
            value: 'other',
        },
        {
            label: 'Hold < 3 days',
            value: 'hold_lt_3',
        },
        {
            label: 'Hold > 3 days',
            value: 'hold_gt_3',
        },
    ];

    const miscFilterOptions = [
        {
            label: 'Alert',
            value: 'alert',
        },
        {
            label: 'Deleted',
            value: 'deleted',
        },
    ];

    const getOpenSelectedTooltipText = () => {
        return (
            <div className='admin-load-patients-tooltip'>
                In order to open the exam(s) in a separate tab and remain on the
                current page, please install extension
                <a
                    href='https://chrome.google.com/webstore/detail/force-background-tab/gidlfommnbibbmegmgajdbikelkdcmcl'
                    target='_blank'
                    rel='noreferrer'
                    onClick={(event) => event.stopPropagation()}
                >
                    Force Background Tab
                </a>
            </div>
        );
    };

    const handleResetPriority = () => {
        dispatch(setSortOrder([]));
        dispatch(setSortedInfo({}));
    };

    const handleFilterSmartUploads = () => {
        if (miscFilter === 'alert' && readyForValidationFilter === null) {
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'readyForValidationFilter',
                    value: 'all_uploads',
                })
            );
        }
        if (miscFilter === 'deleted' && readyForValidationFilter !== null) {
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'readyForValidationFilter',
                    value: null,
                })
            );
        }

        if (odGroupFilter && readyForValidationFilter === null) {
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'readyForValidationFilter',
                    value: 'all_uploads',
                })
            );
        }

        dispatch(getSmartUploadList());
    };

    const handleClearSmartUploadsFilters = () => {
        dispatch(
            setSmartUploadDataValues({
                readyForValidationFilter: null,
                miscFilter: null,
                odGroupFilter: null,
            })
        );
    };

    const handleOpenSelectedUploads = () => {
        selectedRowKeys.forEach((key) => {
            const url = `upload-reconciler/${key}`;
            goToExamInNewTab(url);
        });
    };

    return (
        <Row align='middle' className={styles.controlBar}>
            <Col span={24}>
                <Flex gap='large' className={styles.filterOptionRow}>
                    <Select
                        placeholder={
                            <Space>
                                <ProfileOutlined />
                                <Typography.Text>
                                    Ready for Validation
                                </Typography.Text>
                            </Space>
                        }
                        value={readyForValidationFilter}
                        allowClear={true}
                        optionFilterProp='label'
                        options={readyForValidationFilterOptions}
                        size='large'
                        className={styles.filterOption}
                        showSearch
                        onChange={(value) =>
                            dispatch(
                                setSmartUploadSliceDataValue({
                                    key: 'readyForValidationFilter',
                                    value,
                                })
                            )
                        }
                    />

                    <Select
                        placeholder={
                            <Space>
                                <GlobalOutlined />
                                <Typography.Text>OD Group</Typography.Text>
                            </Space>
                        }
                        value={odGroupFilter}
                        allowClear={true}
                        optionFilterProp='label'
                        options={odGroupPracticOptions}
                        size='large'
                        className={styles.filterOption}
                        showSearch
                        onChange={(value) =>
                            dispatch(
                                setSmartUploadSliceDataValue({
                                    key: 'odGroupFilter',
                                    value,
                                })
                            )
                        }
                    />
                    <Select
                        placeholder={
                            <Space>
                                <ProfileOutlined />
                                <Typography.Text>Miscellaneous</Typography.Text>
                            </Space>
                        }
                        value={miscFilter}
                        allowClear={true}
                        optionFilterProp='label'
                        options={miscFilterOptions}
                        size='large'
                        className={styles.filterOption}
                        showSearch
                        onChange={(value) =>
                            dispatch(
                                setSmartUploadSliceDataValue({
                                    key: 'miscFilter',
                                    value,
                                })
                            )
                        }
                    />
                </Flex>
            </Col>

            <Divider
                type='horizontal'
                className='patient-list-horizontal-divider action-buttons'
            />

            <Col span={24} className={styles.filterButtonsRow}>
                <Flex gap='small' justify='space-between'>
                    <Space>
                        <Button
                            type='primary'
                            onClick={handleFilterSmartUploads}
                        >
                            {ADMIN_REFRESH_TABLES}
                        </Button>
                        <Button
                            className={styles.button}
                            type='primary'
                            disabled={selectedRowKeys.length === 0}
                            onClick={handleOpenSelectedUploads}
                        >
                            {OPEN_SELECTED_PATIENTS}
                            <Tooltip
                                className='col-sort-order-tooltip'
                                placement='topLeft'
                                title={getOpenSelectedTooltipText}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Button>

                        <Button
                            className='clear-priority-indexes-button'
                            type='primary'
                            disabled={sortOrder.length === 0}
                            onClick={handleResetPriority}
                        >
                            {ADMIN_RESET_SORT}
                            <Tooltip
                                className='col-sort-order-tooltip'
                                placement='topLeft'
                                title={ADMIN_COLUMN_SORT_TOOLTIP}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type='primary'
                            className={styles.getFilteredListButton}
                            onClick={handleFilterSmartUploads}
                        >
                            <CloudSyncOutlined /> {GET_FILTERED_LIST}
                        </Button>

                        <Button
                            className={styles.clearFilterButton}
                            type='primary'
                            onClick={handleClearSmartUploadsFilters}
                        >
                            <CloseCircleOutlined /> {CLEAR_FILTERS}
                        </Button>
                    </Space>
                </Flex>
            </Col>
        </Row>
    );
};

export default SmartUploadTableControlBar;