import { Modal, Button, Form } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IClinicLocationDetails, useAddClinicLocationMutation } from '../../services/od-group-practice-api';
import { toggleClinicLocationAddModal } from '../../reducers/doctor-slice';
import ClinicLocationForm from './clinic-location-form';

const ClinicLocationAddModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showClinicLocationAddModal);
    const currentOdGroupPracticeId = useCare1AppSelector(store => store.doctorSlice.currentOdGroupPractice);
    const currentOdGroupPracticeName = useCare1AppSelector(store => store.doctorSlice.currentGroupPracticeName);

    const [addClinicLocation] = useAddClinicLocationMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(toggleClinicLocationAddModal());
    };

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const saveButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const onFinish = async (values: IClinicLocationDetails) => {
        const submitData = {
            ...values,
            integrated_only_default: !!values.integrated_only_default,
            essentials_only_default: !!values.essentials_only_default,
            reimburse_default: !!values.reimburse_default,
            is_migrated_default: !!values.is_migrated_default,
            uses_referral_letter_pei_default: !!values.uses_referral_letter_pei_default,
            has_pei_toggle_default: !!values.has_pei_toggle_default,
            has_smart_upload_default: !!values.has_smart_upload_default,
            has_glaucoma_program_default: !!values.has_glaucoma_program_default,
            gets_pdf_reviews_default: !!values.gets_pdf_reviews_default,
            send_gp_letters_default: !!values.send_gp_letters_default,
            od_group_practice_id: currentOdGroupPracticeId!
        }
        try {
            const result = await addClinicLocation(submitData).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Location successfully added.',
                    onOk: () => {
                        dispatch(toggleClinicLocationAddModal());
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='od-group-practice-modal clinic-location-modal'
            open={isVisible}
            closable={false}
            title={`Add New Location for ${currentOdGroupPracticeName}`}
            footer={[closeButton, saveButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <ClinicLocationForm onFinish={onFinish} form={form} edit={false} />
        </Modal>
    );
}

export default ClinicLocationAddModal;
