import { Modal, Button, Row, Col, Table, Spin } from 'antd';
import { OPERATING_TEXT } from '../../constants';

// Action imports
import { CopyOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useLazyGetLatencyMetricsListByMetricsNameQuery, ILatencyMetricsListTableItem } from '../../services/latency-metrics-api';
import { ColumnsType } from 'antd/es/table';
import { compareStrings } from '../../helpers/sorting';


type ComponentProps = {
    title: string,
    isModalOpen: boolean,
    closeModal: () => void,
    metricsName: string,
}

const AdminLatencyMetricsDetailModal = ({ title, isModalOpen, closeModal, metricsName }: ComponentProps) => {

    const [getLatencyMetricsListByMetricsName, { data: data, isFetching: isFetching }] = useLazyGetLatencyMetricsListByMetricsNameQuery();

    useEffect(() => {
        getLatencyMetricsListByMetricsName({ metrics_name: metricsName });
    }, [metricsName]);

    const closeButton = (
        <Button
            className='close-button'
            key='close-button'
            onClick={() => {

                closeModal();
            }}
        >
            Close
        </Button>
    );

    const handleCopySelection = (record: ILatencyMetricsListTableItem) => {
        const url = `${process.env.REACT_APP_FRONTENDURL}/patient-exam/${record.exam_id}`;
        navigator.clipboard.writeText(url);
    }

    const nameColumns: ColumnsType<ILatencyMetricsListTableItem> = [
        {
            className: 'exam_date-column',
            title: 'Exam date',
            dataIndex: 'exam_date',
            sorter: (a, b) => compareStrings(`${a['exam_date']}`, `${b['exam_date']}`),
            render: (text, record) => <div data-testid={`'exam_date'-${record.id}`}>{text}</div>
        },
        {
            className: 'latency_ms-column',
            title: 'Load Time (Seconds)',
            dataIndex: 'latency_ms',
            sorter: (a, b) => compareStrings(`${a['latency_ms']}`, `${b['latency_ms']}`),
            render: (text, record) => <div data-testid={`'latency_ms'-${record.id}`}>{(text / 1000).toFixed(2)}</div>
        },
        {
            className: 'exam_id-column',
            title: 'Exam URL',
            dataIndex: 'exam_id',
            sorter: (a, b) => compareStrings(`${a['exam_id']}`, `${b['exam_id']}`),
            render: (text, record) => <div data-testid={`'exam_id'-${record.id}`} onClick={() => handleCopySelection(record)}><CopyOutlined /> {`${process.env.REACT_APP_FRONTENDURL}/patient-exam/${text}`}</div>
        }
    ];

    return (
        <Modal
            className='admin-latency-metrics-list-modal'
            open={isModalOpen}
            closable={false}
            footer={[closeButton]}
            keyboard={true}
            zIndex={998}
        >
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row>
                    <Col span={24}>
                        <div className='metrics-title'>Top Ten Load Times - {title}</div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            className='latency-metrics-list-table'
                            columns={nameColumns}
                            dataSource={data?.latency_metrics_list.rows}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default AdminLatencyMetricsDetailModal;