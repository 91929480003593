import { useMemo, useEffect, useState } from 'react';
import { Form,  Input, Select, FormInstance, Row, Col, Checkbox } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { useCare1AppSelector } from '../../apps/care1-hooks';
import InputMask from 'react-input-mask';
import { ERROR_INVALID_PHONE_FORMAT, PHONE_MASK_FORMAT } from '../../constants';
import { clinicLocationDefaultFormData, IClinicLocationDetails, useGetClinicLocationDetailQuery, useGetOdGroupPracticeDetailQuery } from '../../services/od-group-practice-api';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type AppProps = {
    onFinish: (values: IClinicLocationDetails) => Promise<void>,
    form: FormInstance,
    edit: boolean,
}

const ClinicLocationForm = ({onFinish, form, edit}: AppProps) => {

    const showClinicLocationEditModal = useCare1AppSelector(store => store.doctorSlice.showClinicLocationEditModal);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const omds = useCare1AppSelector(store => store.options.omds);
    const omdNetworks = useCare1AppSelector(store => store.options.omd_networks);
    const currentClinicLocationId = useCare1AppSelector(store => store.doctorSlice.currentClinicLocation);
    const currentOdGroupPracticeId = useCare1AppSelector(store => store.doctorSlice.currentOdGroupPractice);
    const clinicLocationHeaders = useCare1AppSelector(store => store.options.clinicLocationHeaders);
    const billingRegionOptions = useCare1AppSelector(store => store.options.billingRegionOptions);

    const skipQuery = currentClinicLocationId && showClinicLocationEditModal ? false : true;
    const {data} = useGetClinicLocationDetailQuery(currentClinicLocationId!, {skip: skipQuery});

    const skipOdGroupPracticeDetailQuery = currentOdGroupPracticeId && !edit ? false : true;
    const odGroupPracticeDetailResult = useGetOdGroupPracticeDetailQuery(currentOdGroupPracticeId!, {skip: skipOdGroupPracticeDetailQuery});
    const odGroupPracticeDetailData = odGroupPracticeDetailResult?.data;

    const provinceOptions = useMemo(() =>
        provinces.map(({code, name }) => ({value: code, label: name, key: code }))
    , [provinces])

    const omdOptions = useMemo(() => 
        omds.map(({ id, name }) => ({ value: id, label: name, key: id }))
    , [omds]);

    const omdNetworkOptions = useMemo(() =>
        omdNetworks.map(({ id, name }) => ({ value: id, label: name, key: id }))
    , [omdNetworks]);

    const clinicLocationHeaderOptions = useMemo(() =>
        clinicLocationHeaders.map(({image_file_name, image_file_path}) => ({value: image_file_path, label: image_file_name, key: image_file_path}))
        , [clinicLocationHeaders])

    const getFullUrlPath = (url: string | undefined) => {
        return url ? getBackendMediaUrl() + url : '';
    }

    const [clinicLocationFullUrlPath, setClinicLocationFullUrlPath] = useState('');

    // make the column span the whole column width
    const fullColWidth = { span: 24 };

    useEffect(() => {
        if (data) {
            const {key, od_group_practice_id, ...formData} = data;
            form.setFieldsValue({
                ...formData,
            });
        }
        
    }, [data, form])

    useEffect(() => {
        if (odGroupPracticeDetailData) {
            form.setFieldsValue({
                province: odGroupPracticeDetailData.province,
            });
        }
    }, [odGroupPracticeDetailData, form]);

    useEffect(() => {
        if (data?.logo_url) {
            setClinicLocationFullUrlPath(getFullUrlPath(data.logo_url));
        }
    }, [data?.logo_url])

    const onIntegratedEssentialsChange = (field: string, e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        if (checked) {
            const allFields = ['integrated_only_default', 'essentials_only_default'];
            const disableFields = allFields.filter(item => item !== field);
            disableFields.forEach(item => {
                form.setFieldsValue({
                    [item]: false
                });
            });
        }
    };

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'logo_url':
                        setClinicLocationFullUrlPath(getFullUrlPath(field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    const onDefaultCheck = (e: CheckboxChangeEvent) => {
        if (e.target.checked && odGroupPracticeDetailData) {
            form.setFieldsValue({
                full_name: odGroupPracticeDetailData.name,
                address: odGroupPracticeDetailData.address,
                city: odGroupPracticeDetailData.city,
                province: odGroupPracticeDetailData.province,
                postal_code: odGroupPracticeDetailData.postal_code,
                phone_number: odGroupPracticeDetailData.phone_number,
                fax: odGroupPracticeDetailData.fax,
            });
        }
    };

    return (
        <Form
            form={form}
            labelCol={{span:5}}
            wrapperCol={{span: 18}}
            className='od-group-practice-form'
            colon={false}
            requiredMark={false}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            initialValues={clinicLocationDefaultFormData}
        >
            { edit ?
                null
                :
                <Form.Item
                    label='Primary'
                    name='is_default'
                    valuePropName=''
                >
                    <Checkbox data-testid='default-checkbox' onChange={onDefaultCheck} />
                </Form.Item>
            }
            { edit ?
                <Row>
                    <Col span={2}/>
                    <Col span={15}>
                        <Form.Item
                            label='Name'
                            name='name'
                            >
                            <Input data-testid='name-input' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label='ID'
                            name='location_id'
                            initialValue={currentClinicLocationId}>
                            <Input data-testid='id-input' disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                :
                <Form.Item
                    label='Name'
                    name='name'
                >
                    <Input data-testid='name-input' />
                </Form.Item>
            }
            <Form.Item
                label='Full Name'
                name='full_name'
            >
                <Input />
            </Form.Item>
            <Form.Item label='Address' name='address'>
                <Input />
            </Form.Item>
            <Form.Item label='City' name='city'>
                <Input />
            </Form.Item>
            <Form.Item label='Province' name='province' >
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={provinceOptions}
                />
            </Form.Item>
            <Form.Item label='Postal Code' name='postal_code'>
                <Input />
            </Form.Item>
            <Form.Item
                    label={'Phone Number'}
                    hasFeedback
                    name={'phone_number'}
                    validateTrigger={'onBlur'}
                    rules={[{
                        pattern: /^[^_]+$/,
                        message: ERROR_INVALID_PHONE_FORMAT,
                    }]}
                >
                    <InputMask
                        data-testid='phone-input'
                        className={'phone-number-input ant-input'}
                        mask={PHONE_MASK_FORMAT}
                    />
                </Form.Item>
            
            <Form.Item
                label={'Fax'}
                hasFeedback
                name={'fax'}
                validateTrigger={'onBlur'}
                rules={[{
                    pattern: /^[^_]+$/,
                    message: ERROR_INVALID_PHONE_FORMAT,
                }]}
            >
                <InputMask
                    data-testid='fax-input'
                    className={'fax-number-input ant-input'}
                    mask={PHONE_MASK_FORMAT}
                />
            </Form.Item>

            <Form.Item label='Billing Region' name='billing_region' initialValue={null}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={billingRegionOptions}
                />
            </Form.Item>

            <Row gutter={16} className='checkbox-row'>
                <Col span={5} className='checkbox-row-label'>
                    Program
                </Col>
                <Col span={6}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'integrated_only_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='integrated-input'
                            className='checkbox-row-input'
                            onChange={(e) => onIntegratedEssentialsChange('integrated_only_default', e)}
                        >
                            Integrated
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'essentials_only_default'}
                        valuePropName='checked'
                        >
                            <Checkbox
                                data-testid='essentials-input'
                                className='checkbox-row-input'
                                onChange={(e) => onIntegratedEssentialsChange('essentials_only_default', e)}
                            >
                                Essentials Only
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'reimburse_default'}
                            valuePropName='checked'
                        >
                            <Checkbox
                                data-testid='reimburse-input'
                                className='checkbox-row-input'
                            >
                            Reimburse?
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} className='checkbox-row'>
                <Col span={5} className='checkbox-row-label'>
                    Interface
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'is_migrated_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='migrated-input'
                            className='checkbox-row-input'
                        >
                            Is migrated to React?
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'has_smart_upload_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='smart-upload-input'
                            className='checkbox-row-input'
                        >
                            Smart Upload
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={5}></Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'uses_referral_letter_pei_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='referral-letter-input'
                            className='checkbox-row-input'
                        >
                            Uses Referral Letter PEI
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'has_pei_toggle_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='pei-toggle-input'
                            className='checkbox-row-input'
                        >
                            PEI Toggle
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} className='checkbox-row'>
                <Col span={5} className='checkbox-row-label'>
                    Features
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'has_glaucoma_program_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='glaucoma-input'
                            className='checkbox-row-input'
                        >
                            Glaucoma Dashboard
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'send_gp_letters_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='gp-letters-input'
                            className='checkbox-row-input'
                        >
                            Send GP Letters?
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={5}></Col>
                <Col span={19}>
                    <Form.Item
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'gets_pdf_reviews_default'}
                        valuePropName='checked'
                    >
                        <Checkbox
                            data-testid='pdf-reviews-input'
                            className='checkbox-row-input'
                        >
                            Gets PDF Reviews?
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            
            <Form.Item label={'Default OMD'} name='default_omd_default'>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={omdOptions}
                />
            </Form.Item>
            
            <Form.Item label={'OMD Network'} name='default_omd_network'>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={omdNetworkOptions}
                />
            </Form.Item>

            { edit &&
                <div>
                    <Form.Item label='Clinic Logo' name='logo_url'>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp='label'
                            options={clinicLocationHeaderOptions}
                        />
                    </Form.Item>
                    { clinicLocationFullUrlPath &&
                        <div className='clinic-logo'>
                            <img
                                data-testid='clinic-logo-img'
                                alt='logo preview'
                                className='clinic-logo-img'
                                src={clinicLocationFullUrlPath}
                            />
                        </div>
                    }
                </div>
            }
        </Form>
    );
}

export default ClinicLocationForm;
