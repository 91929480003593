import { Input, Space, Typography } from 'antd';

import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';

const ValidatorInitials = () => {
    const adminReconciler = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciler
    );

    const dispatch = useCare1AppDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(
            setUploadReconcilerSliceDataValue({
                key: 'admin_reconciler',
                value,
            })
        );
    };

    return (
        <Space>
            <Typography.Text>Validator Initials</Typography.Text>
            <Input
                className='initial-input'
                data-testid='initial-input'
                value={adminReconciler}
                onChange={handleChange}
            />
        </Space>
    );
};

export default ValidatorInitials;
