import { baseApi, IBaseResponse } from './base-api'
import { IDoctorResponse } from './doctor-api';

export interface IOdGroupPracticeResponse {
    success: boolean;
    id?: number | null;
    error? : string;
}

export interface IClinicLocationOption {
    key: number;
    value: number;
    label: string;
}
export interface IClinicLocationOptionResponse {
    success: boolean;
    data: IClinicLocationOption[];
    error? : string;
}

export interface IOdGroupPracticeOdOption {
    key: number;
    value: number;
    label: string;
}
export interface IOdGroupPracticeOdOptionResponse {
    success: boolean;
    data: IOdGroupPracticeOdOption[];
    error? : string;
}

export interface IGroupPracticeListResponse {
    od_group_practice_list: IOdGroupPracticeTableItems[];
    success: boolean;
    error?: string;
}

export interface IOdGroupPracticeDetailResponse {
    success: boolean;
    data: IOdGroupPracticeDetails;
}
export interface IClinicLocationDetailResponse {
    success: boolean;
    data: IClinicLocationDetails;
    error?: string;
}

export interface IClinicLocationListResponse {
    success: boolean;
    data: IClinicLocationTableItem[];
}

export interface IClinicLocationTableItem {
    key: number;
    location_name: string;
    location_full_name: string;
}

export interface IOdGroupPracticeTableItems {
    key: number;
    od_group_practice_name: string;
    province: string;
    locations: IClinicLocationTableItem[];
}

export interface ITechnicianTableItem {
    key: number;
    od_group_practice_name: string;
    first_name: string;
    last_name: string;
    email: string;
    status: string;
}

export interface ITechnicianDetail {
    key: number;
    od_group_practice: number | null;
    first_name: string;
    last_name: string;
    email: string;
    status: string;
}

export interface IStaffFormItems {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    user_status: string[];
}


export interface IOdGroupPracticeDetails {
    key: number | null;
    name: string;
    province: string;
    address: string;
    postal_code: string;
    fax: string;
    phone_number: string;
    city: string;
    region: number | null;
    od_group_practice_status: string;
    study_funding_all_visits: string;
    study_funding_cat_ref: string;
    is_test_group: boolean;
}

export interface IClinicLocationDetails {
    key: number | null;
    name: string;
    province: string | null;
    address: string;
    postal_code: string;
    fax: string;
    phone_number: string;
    city: string;
    od_group_practice_id: number;
    full_name: string;
    logo_url: string;
    integrated_only_default: boolean;
    essentials_only_default: boolean;
    reimburse_default: boolean;
    is_migrated_default: boolean;
    uses_referral_letter_pei_default: boolean;
    has_pei_toggle_default: boolean;
    has_smart_upload_default: boolean;
    has_glaucoma_program_default: boolean;
    gets_pdf_reviews_default: boolean;
    send_gp_letters_default: boolean;
    default_omd_default: number | null;
    default_omd_network: number | null;
}


export const clinicLocationDefaultFormData: Omit<IClinicLocationDetails, 'od_group_practice_id' | 'key'> = {
    name: '',
    province: null,
    address: '',
    postal_code: '',
    fax: '',
    phone_number: '',
    city: '',
    full_name: '',
    logo_url: '',
    integrated_only_default: false,
    essentials_only_default: false,
    reimburse_default: false,
    is_migrated_default: false,
    uses_referral_letter_pei_default: false,
    has_pei_toggle_default: false,
    has_smart_upload_default: false,
    has_glaucoma_program_default: false,
    gets_pdf_reviews_default: false,
    send_gp_letters_default: false,
    default_omd_default: null,
    default_omd_network: null,
}

export const odGroupPracticeApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getOdGroupPracticeList: builder.query<IOdGroupPracticeTableItems[], void>({
            query: () => 'od_group_practice/list/',
            transformResponse: (response: IGroupPracticeListResponse, meta, arg) => response.od_group_practice_list,

            // Give a tag to ODGroupPractice so that other API actions can invalidate OdGroupPracticeList
            providesTags: (result = [], _error, _arg) => result.length ? [
                ...result.map(({key}) => ({type: 'OdGroupPractice' as const, id: key})), 'OdGroupPractice'
            ] : ['OdGroupPractice'],
        }),

        getOdGroupPracticeDetail: builder.query<IOdGroupPracticeDetails, number>({
            query: (id) => `od_group_practice/${id}`,
            // give a tag to a specific OD so that when this OD is updated by other API calls, its
            // tag(OD Details) is also updated (by refetching), note that _result and _error are not
            // used here, only id is used
            transformResponse: (response: IOdGroupPracticeDetailResponse, meta, arg) => response.data,
            providesTags: (_result, _error, id) => [{type: 'OdGroupPractice', id}],
        }),

        addOdGroupPractice: builder.mutation<IDoctorResponse, IOdGroupPracticeDetails >({
            query(requestData) {
                return {
                    url: 'od_group_practice/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: ['OdGroupPractice']
        }),

        editOdGroupPractice: builder.mutation<IOdGroupPracticeResponse, IOdGroupPracticeDetails>({
            query(requestData) {
                return {
                    url: 'od_group_practice/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific OMD by its id so that the OMD tag is also updated, also invalidate
            // the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: (result) => [{type: 'OdGroupPractice', id: result?.id as number}],
        }),

        getClinicLocationDetail: builder.query<IClinicLocationDetails, number>({
            query: (id) => `clinic_location/${id}`,
            // give a tag to a specific OD so that when this OD is updated by other API calls, its
            // tag(OD Details) is also updated (by refetching), note that _result and _error are not
            // used here, only id is used
            transformResponse: (response: IClinicLocationDetailResponse, meta, arg) => response.data,
            providesTags: (result, error, id) => [{type: 'Location', id}],
        }),
        
        addClinicLocation: builder.mutation<IDoctorResponse, IClinicLocationDetails >({
            query(requestData) {
                return {
                    url: 'clinic_location/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: (result, _error, arg) =>
                result?.success ? [{type: 'OdGroupPractice', id: arg.od_group_practice_id},
                                   {type: 'LocationOptions', id: 'LIST'} ] :
                [],
        }),

        editClinicLocation: builder.mutation<IDoctorResponse, IClinicLocationDetails >({
            query(requestData) {
                return {
                    url: 'clinic_location/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: (result, _error, arg) => {
                return result?.success ? [
                    {type: 'Location', id: arg.key!}, 
                    {type: 'LocationOptions', id: 'LIST'},
                    {type: 'OdGroupPractice', id: arg.od_group_practice_id ?? 'LIST'},
                    'DoctorList'] :
                []
            },
        }),
        deleteClinicLocation: builder.mutation<IDoctorResponse, number >({
            query(clinicLocationId) {
                return {
                    url: `clinic_location/delete/${clinicLocationId}`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: [{type: 'LocationOptions', id: 'LIST'},'OdGroupPractice', 'DoctorList'],
        }),
        getClinicLocationOptions: builder.query<IClinicLocationOption[], number>({
            query: (odGroupPracticeId) => `od_clinic_location_options/${odGroupPracticeId}`,
            transformResponse: (response: IClinicLocationOptionResponse, meta, arg) => response.data,

            // Give a tag to LocationOptions so that other API actions can invalidate OdGroupPracticeList
            providesTags: [{type: 'LocationOptions', id: 'LIST'}],
        }),
        getOdGroupPracticeOdOptions: builder.query<IOdGroupPracticeOdOption[], number>({
            query: (odGroupPracticeId) => `od_group_practice_od_options/${odGroupPracticeId}`,
            transformResponse: (response: IOdGroupPracticeOdOptionResponse, meta, arg) => response.data,

            // Give a tag to ODGroupPracticeODOptions so that other API actions can invalidate OdGroupPracticeList
            providesTags: [{type: 'ODGroupPracticeODOptions', id: 'LIST'}],
        }),
        getTechnicianList: builder.query<ITechnicianTableItem[], void>({
            query: () => 'technicians',
            transformResponse: (response: IBaseResponse & {data: ITechnicianTableItem[]}, meta, arg) => response.data,

            // Give a tag to Technician so that other API actions can invalidate TechnicianList
            providesTags: (result = [], _error, _arg) => result.length ? [
                ...result.map(({key}) => ({type: 'Technicians' as const, id: key})), {type: 'Technicians', id: 'LIST'}
            ] : [{type: 'Technicians', id: 'LIST'}],
        }),
        getTechnicianDetail: builder.query<ITechnicianDetail, number>({
            query: (id) => `technicians/${id}`,
            // give a tag to a specific OD so that when this OD is updated by other API calls, its
            // tag(OD Details) is also updated (by refetching), note that _result and _error are not
            // used here, only id is used
            transformResponse: (response: IBaseResponse & {data: ITechnicianDetail }, meta, arg) => response.data,
            providesTags: (result, error, id) => [{type: 'Technicians', id}],
        }),
        
        addTechnician: builder.mutation<IDoctorResponse, ITechnicianDetail >({
            query(requestData) {
                return {
                    url: 'technicians/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: (_result, _error, arg ) => [
                {type: 'Technicians', id: 'LIST'}, {type: 'TechnicianOptions', id: arg.od_group_practice ?? 'LIST'} ]
        }),

        editTechnician: builder.mutation<IDoctorResponse, ITechnicianDetail >({
            query(requestData) {
                return {
                    url: 'technicians/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: (result, _error, arg) => {
                return result?.success ? [{type: 'Technicians', id: arg.key}, {type: 'TechnicianOptions', id: arg.od_group_practice ?? 'LIST'}, 'OD'] :
                []
            },
        }),
        deleteTechnician: builder.mutation<IDoctorResponse, number >({
            query(id) {
                return {
                    url: `technicians/delete/${id}`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: (result, _error, arg) => [{type: 'Technicians', id: 'LIST'}, {type: 'TechnicianOptions', id: result?.od_group_practice_id ?? 'LIST'}],
        }),
        getClinicTechnicianOptions: builder.query<IClinicLocationOption[], number>({
            query: (id) => `clinic_technician_options/${id}`,
            transformResponse: (response: IBaseResponse & {data: IClinicLocationOption[]}, meta, arg) => response.data,
            providesTags: (result, error, id) => [{type: 'TechnicianOptions', id}],
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useAddOdGroupPracticeMutation, useGetOdGroupPracticeListQuery,
    useGetOdGroupPracticeDetailQuery, useEditOdGroupPracticeMutation,
    useAddClinicLocationMutation, useEditClinicLocationMutation, useGetClinicLocationDetailQuery,
    useDeleteClinicLocationMutation, useGetClinicLocationOptionsQuery, useGetOdGroupPracticeOdOptionsQuery,
    useAddTechnicianMutation, useDeleteTechnicianMutation, useEditTechnicianMutation,
    useGetTechnicianDetailQuery, useGetTechnicianListQuery, usePrefetch, useGetClinicTechnicianOptionsQuery,
} = odGroupPracticeApi;