import { useMemo, useEffect } from 'react';
import { Checkbox, Form,  Input, Select, Row, Col, FormInstance } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IOdGroupPracticeDetails, useGetOdGroupPracticeDetailQuery } from '../../services/od-group-practice-api';
import InputMask from 'react-input-mask';
import { ERROR_INVALID_PHONE_FORMAT, PHONE_MASK_FORMAT } from '../../constants';
import { PlusOutlined } from '@ant-design/icons';
import { setShowNewRegionModal } from '../../reducers/doctor-slice';

type AppProps = {
    onFinish: (values: IOdGroupPracticeDetails) => Promise<void>,
    form: FormInstance,
}


const ODGroupPracticeForm = ({onFinish, form}: AppProps) => {

    const provinces = useCare1AppSelector(store => store.options.provinces);
    const regions = useCare1AppSelector(store => store.options.regions);
    const odGroupPracticeStatuses = useCare1AppSelector(store => store.options.odStatuses);
    const currentOdGroupPractice = useCare1AppSelector(store => store.doctorSlice.currentOdGroupPractice);
    const showGroupPracticeEditModal = useCare1AppSelector(store => store.doctorSlice.showGroupPracticeEditModal);

    const skipQuery = currentOdGroupPractice && showGroupPracticeEditModal ? false : true;

    const {data} = useGetOdGroupPracticeDetailQuery(currentOdGroupPractice!, {skip: skipQuery});

    const dispatch = useCare1AppDispatch();

    const provinceOptions = useMemo(() =>
        provinces.map(({code, name }) => ({value: code, label: name, key: code }))
    , [provinces])

    const regionOptions = useMemo(() =>
        regions.map(({id, name }) => ({value: id, label: name, key: id }))
    , [regions])

    useEffect(() => {
        if (data) {
            const {key, ...formData} = data;
            form.setFieldsValue({
                ...formData,
            });
        }
        
    }, [data, form])

    return (
        <Form
            form={form}
            labelCol={{span:24}}
            wrapperCol={{span: 24}}
            className='od-group-practice-form'
            colon={false}
            requiredMark={false}
            onFinish={onFinish}
        >
            <Form.Item
                label={`Group Name`}
                name='name'
                rules={[
                    {required: true,
                        message: buildRequiredErrorMessage('Group Name') }
                ]}
            >
                <Input />
            </Form.Item>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item label='Address' name='address' initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='City' name='city' initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
                
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item label='Province' name='province' initialValue={null}>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp='label'
                            options={provinceOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                        <Form.Item label='Postal Code' name='postal_code' initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                    label={
                        <Row justify={'space-between'} className='region-label-row'>
                            <Col>Region</Col>
                            <Col className='add-icon-col'>
                                <PlusOutlined
                                    className='add-icon'
                                    onClick={() => dispatch(setShowNewRegionModal(true))}
                                />
                            </Col>
                        </Row>
                    }
                    name='region'
                    initialValue={null}
                >
                    <Select
                        showSearch
                        allowClear
                        options={regionOptions}
                        optionFilterProp='label'
                        data-testid='group-practice-region'
                    />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                <Form.Item
                    label={'Fax'}
                    hasFeedback
                    name={'fax'}
                    validateTrigger={'onBlur'}
                    rules={[{
                        pattern: /^[^_]+$/,
                        message: ERROR_INVALID_PHONE_FORMAT,
                    }]}
                >
                    <InputMask
                        data-testid='fax-input'
                        className={'fax-number-input ant-input'}
                        mask={PHONE_MASK_FORMAT}
                    />
                </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Phone Number'}
                        hasFeedback
                        name={'phone_number'}
                        validateTrigger={'onBlur'}
                        rules={[{
                            pattern: /^[^_]+$/,
                            message: ERROR_INVALID_PHONE_FORMAT,
                        }]}
                    >
                        <InputMask
                            data-testid='fax-input'
                            className={'phone-number-input ant-input'}
                            mask={PHONE_MASK_FORMAT}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label={'OD Group Practice Status'}
                hasFeedback
                colon={false}
                name={'od_group_practice_status'}
                rules={[{
                    required: true,
                    message: buildRequiredErrorMessage('OD Group Practice Status')
                }]}
                initialValue={'active'}
            >
                <Select
                    placeholder={'Select OD Group Practice Status'}
                    className="od-group-practice-status-input"
                    data-testid='od-group-practice-status-select'
                    allowClear
                >
                    {odGroupPracticeStatuses.map(odGroupPracticeStatus => {
                        return (
                            <Select.Option key={odGroupPracticeStatus.value} value={odGroupPracticeStatus.value}>
                                {odGroupPracticeStatus.label}
                            </Select.Option>
                        );
                    })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={'Study Funding All Visits'}
                name='study_funding_all_visits'
                initialValue={''}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item
                label={'Study Funding Cat Ref'}
                name='study_funding_cat_ref'
                initialValue={''}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item
                name={'is_test_group'}
                valuePropName="checked"
            >
                <Checkbox>
                    <span className="is_test_group">Is test group?</span>
                </Checkbox>
            </Form.Item>
        </Form>
    );
}

export default ODGroupPracticeForm;
