import { Col, Layout, message, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';

// Components
import GptImageIdentifierDNDExtraImages from '../components/admin/image-identifier/gpt-image-identifier-dnd-extra-images';
import GptImageIdentifierDNDImages from '../components/admin/image-identifier/gpt-image-identifier-dnd-images';
import PatientReconciliation from '../components/admin/upload-reconciler/patient-reconciliation';
import PatientValidation from '../components/admin/upload-reconciler/patient-validation';
import SelectNewExamDateModal from '../components/integrated/select-new-exam-date-modal';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PDFViewer from '../components/retina/pdf-viewer';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/_patient-exam-extra-images.scss';
import '../../static/css/components/admin-gpt-image-identifier.scss';
import '../../static/css/components/admin-gpt.scss';
import '../../static/css/components/patient-exam-re-bcva.scss';
import '../../static/css/pages/upload-reconciler-page.scss';

// Actions
import { OPERATING_TEXT } from '../constants';
import { getSmartUploadData } from '../reducers/smart-upload-slice';
import { getUploadReconcilerData, setAdminReconcilerData } from '../reducers/upload-reconciler-slice';
import SmartUploadRow from '../components/admin/upload-reconciler/smart-upload-row';
import AdminManagementRow from '../components/admin/upload-reconciler/admin-management-row';
import { getUserInitials } from '../helpers/patient-exam-convert';

const { Header, Content } = Layout;

const UploadReconcilerPage = () => {
    const smartUploadLoading = useCare1AppSelector(
        (store) => store.smartUpload.loading
    );
    const uploadReconcilerLoading = useCare1AppSelector(
        (store) => store.uploadReconciler.loading
    );

    const user = useCare1AppSelector(
        (store) => store.user
    );

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const { smart_upload_id } = useParams();

    const referralLetter = useCare1AppSelector(
        (store) => store.smartUpload.referralLetterUrl
    );

    // send edit exam request when exam page first renders

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (smart_upload_id) {
                    await Promise.all([
                        dispatch(getSmartUploadData(Number(smart_upload_id))),
                        dispatch(
                            getUploadReconcilerData(Number(smart_upload_id))
                        ),
                    ]);
                    
                        const newInitials = getUserInitials(user);
                        dispatch(
                            setAdminReconcilerData(newInitials)
                        );
                   
                    
                }
            } catch (error) {
                const errorMessage =
                    error instanceof Error ? error?.message : (error as string);
                message.error(errorMessage);
            }
        };

        fetchData();
    }, [dispatch, smart_upload_id, user]);

    const onPDFLoadSuccess = (numPages: number) => {};

    const onPDFLoadError = () => {};

    const onClose = () => {};

    return (
        <Layout>
            <Spin
                className='loading-spinner'
                spinning={smartUploadLoading || uploadReconcilerLoading}
                size='large'
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className='navigation-top-bar' />
                    <NavigationControlBar
                        className='admin-pei-navigation-control-bar'
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='admin-patient-exam-page upload-reconciler-page'>
                        <Row gutter={16}>
                            <Col
                                span={12}
                                className={
                                    'pdfViewerModal no-overflow padding-col'
                                }
                            >
                                <PDFViewer
                                    file={referralLetter}
                                    onPDFLoadSuccess={onPDFLoadSuccess}
                                    onPDFLoadError={onPDFLoadError}
                                    onClose={onClose}
                                    isGPTTab={true}
                                />
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <PatientValidation />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <PatientReconciliation />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className='mt-5'></div>
                            </Col>
                        </Row>
                        <SmartUploadRow />
                        <Row className='admin-gpt-image-identifier' gutter={16}>
                            <Col span={24}>
                                <DndProvider
                                    backend={HTML5Backend}
                                    context={window}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <GptImageIdentifierDNDImages />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <GptImageIdentifierDNDExtraImages />
                                        </Col>
                                    </Row>
                                </DndProvider>
                            </Col>
                        </Row>
                        <AdminManagementRow />
                        <SelectNewExamDateModal />
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
};

export default UploadReconcilerPage;
