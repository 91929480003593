import { Modal, Button, Row, Col, Input } from 'antd';
import ReactDOMServer from 'react-dom/server';

// Action imports
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import GpPatientEmailTags from './gp-patient-email-tags';
import { useEffect, useState } from 'react';
import { useSendGPEmailRemoveMutation } from '../../../services/patient-management-api';


type ComponentProps = {
    bulkOperation: string | null,
    isPreviewModalOpen: boolean,
    closePreviewModal: () => void,
    refreshTable: () => void,
    emailSubject: string,
    emailBody: JSX.Element,
    selectedIds: Set<number>,
}

const GpPatientPreviewModal = ({ bulkOperation, isPreviewModalOpen, closePreviewModal, refreshTable, emailSubject, emailBody, selectedIds }: ComponentProps) => {

    const [toEmails, setToEmails] = useState<string[]>([]);
    const [fromEmails, setFromEmails] = useState<string[]>(['consultgroup@care1.ca']);
    const [bccEmails, setBccEmails] = useState<string[]>(['consultgroup@care1.ca']);
    const [subject, setSubject] = useState<string>('');

    const [sendGPEmailRemove] = useSendGPEmailRemoveMutation();

    useEffect(() => {
        setSubject(emailSubject);
      }, [emailSubject]); 
      
    const onSendButtonClick = () => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            content: 'Are you sure you want to remove the GP from the patient’s file?',
            icon: <InfoCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: async () => {
                try {
                    let response = null;
                    if (bulkOperation !== null && (bulkOperation === 'inactive_gp_email_remove' || bulkOperation === 'incorrect_gp_info_email_remove' || bulkOperation === 'not_a_patient_email_remove')) {
                        response = await sendGPEmailRemove({
                            to: toEmails,
                            from: fromEmails[0],
                            bcc: bccEmails,
                            subject: subject,
                            body: ReactDOMServer.renderToString(emailBody),
                            patient_ids: Array.from(selectedIds)
                        }).unwrap();
                    }

                    if (response !== null && response.success) {
                        setToEmails([]);
                        closePreviewModal();
                        refreshTable();
                    } else {
                        throw new Error(response?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ? error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
            }
        });
    }

    const closeButton = (
        <Button
            className='close-button'
            key='close-button'
            onClick={() => {
                setToEmails([]);
                closePreviewModal();
            }}
        >
            Close
        </Button>
    );

    const sendEmailButton = (
        <Button
            className='send-button'
            key='send-button'
            icon={<MailOutlined />}
            type='primary'
            style={{ height: '2rem' }}
            onClick={onSendButtonClick}
        >
            Send Email
        </Button>
    );

    return (
        <Modal
            className='patient-management-share-modal'
            open={isPreviewModalOpen}
            closable={false}
            footer={[closeButton, sendEmailButton]}
            keyboard={true}
            width={960}
            zIndex={998}
        >
            <Row>
                <Col span={4}>
                    To:
                </Col>
                <Col span={20}>
                    <GpPatientEmailTags
                        shareEmails={toEmails}
                        setShareEmails={setToEmails}
                        oneEmailOnly={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    From:
                </Col>
                <Col span={20}>
                    <GpPatientEmailTags
                        shareEmails={fromEmails}
                        setShareEmails={setFromEmails}
                        oneEmailOnly={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    Bcc:
                </Col>
                <Col span={20}>
                    <GpPatientEmailTags
                        shareEmails={bccEmails}
                        setShareEmails={setBccEmails}
                        oneEmailOnly={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    Subject:
                </Col>
                <Col span={20}>
                    <Input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        {emailBody}
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}

export default GpPatientPreviewModal;
