import { useEffect, useState } from 'react';
import { useCare1AppDispatch } from '../../../apps/care1-hooks';
import { Layout, Row, Col, Modal, Button, Input, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router';
import { localizedText } from '../../../localizedText';
import {
    SearchOutlined
} from '@ant-design/icons';

// Components
import AdminGpListTable from './gp-matching-list-table';
import {
    useMatchGpsMutation,
    useDeleteGpsMutation,
    useLazyGetPatientManagementAdminGpListQuery,
    IPatientManagementMatchGpsRequestItem,
    IPatientManagementMatchGpsRequest
} from '../../../services/patient-management-api';
import * as Constants from '../../../constants';

// Helpers
import '../../../../static/css/pages/_patient-list-page.scss';
import '../../../../static/css/pages/admin-patient-exam-page.scss';
import '../../../../static/css/pages/upload-reconciler-page.scss';
import '../../../../static/css/shared/patient-list-control-bar.scss';


// Actions

const { Header, Content } = Layout;

const AdminGpMergeTab = () => {

    const { MONTHS } = localizedText;

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const [getAdminGpListQuery, { data: gpMatchingList = [], isFetching: isAdminGpListFetching }] = useLazyGetPatientManagementAdminGpListQuery();

    const [matchGps, { isLoading: isGpMatchLoading }] = useMatchGpsMutation();
    const [deleteGps, { isLoading: isGpDeleteLoading }] = useDeleteGpsMutation();

    const [gpMatchingOps, setGpMatchingOps] = useState<{ [v: number]: string }>({});

    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        setGpMatchingOps({});
        setSelectedIds(new Set());
    }, [])

    const sendSearchGpListRequest = async (value: string) => {
        if (value) {
            await getAdminGpListQuery({
                name: value
            });
        }
    }

    const onDeleteGps = async () => {
        try {
            const result = await deleteGps({ items: Array.from(selectedIds) }).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Gp is successfully deleted.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        } finally {
            setDeleteModalOpen(false);
        }
    }

    const mergeGps = async () => {

        const items: IPatientManagementMatchGpsRequestItem[] = [];
        for (const [key, value] of Object.entries(gpMatchingOps)) {
            items.push({
                id: Number(key),
                op: value,
            })
        }

        const gpData: IPatientManagementMatchGpsRequest = {
            items: items
        }

        try {

            const response = await matchGps(gpData).unwrap();
            if (response.success) {
                setGpMatchingOps({});
                // await reMatch();
            } else {
                throw new Error(response?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const shouldDisableMergingButton = () => {

        let targetCount = 0;
        let deleteCount = 0;
        for (const [key, value] of Object.entries(gpMatchingOps)) {
            if (value === 'target') {
                targetCount++;
            }
            if (value === 'delete') {
                deleteCount++;
            }
        }

        if (targetCount === 1 && deleteCount >= 1) {
            return false;
        }
        return true;
    }

    return (
        <Spin
            className="loading-spinner"
            spinning={isAdminGpListFetching}
            size="large"
            tip={Constants.OPERATING_TEXT}
        >
            <div className='patient-list-page user-is-admin upload-reconciler-page'>
                <div className='patient-list-control-bar'>
                    <div className='control-bar-elements '>
                        <Row>
                            <Col span={20}>
                                <Input.Search
                                    className="patient-list-search-input"
                                    allowClear
                                    placeholder={localizedText['GP_LIST_SEARCH_PLACEHOLDER']}
                                    prefix={<SearchOutlined />}
                                    onSearch={sendSearchGpListRequest}
                                />
                            </Col>
                            <Col span={2}>
                                <div className='patient-reconciliation'>
                                    <div className='button-container'>
                                        <Button
                                            disabled={shouldDisableMergingButton()}
                                            className='merge-patients-button'
                                            onClick={mergeGps}
                                        >Merge Gps</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2}>
                                <div className='patient-reconciliation'>
                                    <div className='button-container'>
                                        <Button
                                            disabled={selectedIds?.size === 0}
                                            className='merge-patients-button'
                                            onClick={() => setDeleteModalOpen(true)}
                                        >Delete Gps</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='gp-reconciliation'>
                    <Row>
                        <Col span={24}>
                            <AdminGpListTable
                                tableList={gpMatchingList}
                                gpMatchingOps={gpMatchingOps}
                                setGpMatchingOps={setGpMatchingOps}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                            />
                        </Col>
                    </Row>
                </div>
                <Modal
                    className="confirm-delete-gp-modal"
                    open={deleteModalOpen}
                    title="Are you sure you want to delete?"
                    onCancel={() => setDeleteModalOpen(false)}
                    footer={[
                        <Button key="back" onClick={() => setDeleteModalOpen(false)}>
                            No Please Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={onDeleteGps}>
                            Yes I Want to Delete
                        </Button>
                    ]}
                >
                </Modal>
            </div>
        </Spin>
    );

}

export default AdminGpMergeTab;