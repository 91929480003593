import { RefObject, useRef } from 'react';
import ContentEditable from 'react-contenteditable';


const ReviewProcessingPreviewEntry = ({ entry, setRef, update }: { entry: any, setRef: any, update: any}) => {
    const ref = useRef<Function | RefObject<HTMLElement> | undefined>();
    setRef(ref);

    return (
        <ContentEditable
            data-testid='content-editable'
            className='content-editable'
            innerRef={ref.current}
            html={entry.text}
            onChange={(e: { target: { value: any; }; }) => {
                update(e.target.value);
            }}
        />
    );
}

export default ReviewProcessingPreviewEntry;
