/* eslint-disable no-multi-str */
import globalColors from '../static/css/shared/global-colors.scss';

// API URLs. For calling the backend service.
export const API_LOGIN_URL = '/auth/login/';
export const API_LOGOUT_URL = '/auth/logout/';

// Site navigation URLs. For the React UI.
export const ROOT_URL = '/';
export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const PATIENTS_LIST_URL = '/patients';
export const PATIENT_EXAM_URL = '/patient-exam';
export const CARE1_HOMEPAGE = 'https://care1.ca';

// API URLs. For calling the backend service.
export const API_CSRF_COOKIE_URL = '/csrf_cookie/';
export const API_PASSWORD_RESET_URL = '/account/password_reset/';
export const API_PATIENT_URL = '/patient/';
export const API_FILTERED_PATIENT_LIST_URL = '/data/patients/';
export const API_ADMIN_PATIENTS_LIST_WITH_NO_OMDR_ASSIGNED_URL = '/data/admin/patients/with_no_omdr_assigned/';
export const API_ADMIN_PATIENTS_LIST_WITH_OMDC_STATUS_BUT_NO_OMDC_ASSIGNED_URL = '/data/admin/patients/with_omdc_status_but_no_omdc_assigned/';
export const API_ADMIN_PATIENTS_LIST_WITH_MULTIPLE_EXAMS_HAVING_AN_OMDC_STATUS_URL = '/data/admin/patients/with_multiple_exams_having_an_omdc_status/';
export const API_ADMIN_PATIENTS_LIST_WITH_IPC_STATUS_URL = '/data/admin/patients/with_ipc_status/';
export const API_ADMIN_PATIENTS_LIST_READY_OVER_2WEEKS_URL = '/data/admin/patients/ready_over_2weeks/';
export const API_ADMIN_PATIENTS_LIST_FLAGGED_FOR_URGENT_ATTENTION_BY_THE_OD_URL = '/data/admin/patients/flagged_for_urgent_attention_by_the_od/';
export const API_ADMIN_PATIENTS_LIST_READY_OVER_3WEEKS_URL = '/data/admin/patients/ready_over_3weeks/';
export const API_ADMIN_PATIENTS_LIST_PREREVIEW_OVER_2WEEKS_URL = '/data/admin/patients/prereview_over_2weeks/';
export const API_ADMIN_PATIENTS_LIST_PREREVIEW_OVER_3WEEKS_URL = '/data/admin/patients/prereview_over_3weeks/';
export const API_ADMIN_PATIENTS_LIST_OMDC_6MONTHS_URL = '/data/admin/patients/omdc_6months/';
export const API_ADMIN_PATIENTS_LIST_BILLING_URL = '/data/admin/patients/billing/';
export const API_ADMIN_PATIENTS_LIST_ALERT_URL = '/data/admin/patients/alert/';
export const API_ADMIN_PATIENTS_LIST_CONFLICTING_NOTES_LIST_URL = '/data/admin/patients/conflicting_notes_list/';
export const API_ADMIN_PATIENTS_LIST_OMDR_REVIEWED_NO_TIMESTAMP_LIST_URL = '/data/admin/patients/omdr_reviewed_no_timestamp_list/';
export const API_DELETE_EXAM_URL = '/exam/delete/';
export const API_EXAM_DETAILS_URL = '/exam/';
export const API_NEW_EXAM_DETAILS_URL = '/data/exam/';
export const API_EXAM_NUM_IMAGES = 24;
export const API_OPTIONS_URL = '/data/options/';
export const API_GP_LIST_URL = '/data/gp/list/province_and_practice/';
export const API_GP_FAX_URL = '/data/gp/fax/';
export const API_ADD_GP_URL = '/data/gp/add/';
export const API_UPDATE_GP_URL = '/data/gp/update/';
export const API_SAVE_EXAM_URL = '/data/exam/save/';
export const API_PATIENT_HAS_EXAM_EXTENSION = '/data/patient/exam_date_exists/';
export const API_REMOVE_EXTRA_IMG_URL = '/data/exam/image/delete/';
export const API_REMOVE_DROPZONE_IMG_URL = '/data/exam/image/remove/';
export const API_UPLOAD_IMG_URL = '/data/exam/image/upload/';
export const API_OMD_REVIEW_TIMESTAMP_URL_EXTENSION = '/review_timestamp/';
export const API_PHN_EXISTS_URL = '/data/patient/phn_exists/';
export const API_DEACTIVATE_EXAM_URL = '/data/exam/deactivate/';
export const API_GP_CONSULT_LETTER_URL = '/data/exam/consult_letter/gp/';
export const API_EDIT_PATIENT_URL = '/data/patient/edit/';
export const API_OMD_REVIEW_TIMESTAMP_URL = '/data/exam/review_timestamp/';
export const API_SET_INTERNAL_GP_STATUS_URL = '/data/exam/set_internal_gp_status/';
export const API_SET_INTERNAL_GP_AND_STATUS_URL = '/data/exam/set_internal_gp_and_status/';
export const API_IGP_LIST_URL = '/data/igp/list/';


// API Request Names
export const BACKEND_FILTERED_PATIENT_LIST_TEXT = 'Get Filtered Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_WITH_NO_OMDR_ASSIGNED_TEXT = 'Get with no OMDR assigned Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_WITH_OMDC_STATUS_BUT_NO_OMDC_ASSIGNED_TEXT = 'Get with OMDC status but no OMDC assigned Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_WITH_MULTIPLE_EXAMS_HAVING_AN_OMDC_STATUS_TEXT = 'Get with multiple exams having an OMDC status Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_WITH_IPC_STATUS_TEXT = 'Get with IPC status Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_READY_OVER_2WEEKS_TEXT = 'Get Urgent to Review Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_FLAGGED_FOR_URGENT_ATTENTION_BY_THE_OD_TEXT = 'Get Flagged as high priority (urgent) attention by the OD Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_READY_OVER_3WEEKS_TEXT = 'Get Critical to Review Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_PREREVIEW_OVER_2WEEKS_TEXT = 'Get Urgent to Pre-review Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_PREREVIEW_OVER_3WEEKS_TEXT = 'Get Critical to pre-review Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_OMDC_6MONTHS_TEXT = 'Get Assigned to OMDC last visit 6 months ago Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_BILLING_TEXT = 'Get Outstanding Billing Notes Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_ALERT_TEXT = 'Get IOP greater than 35 Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_CONFLICTING_NOTES_LIST_TEXT = 'Get patients with conflicting OMD notes Patient List';
export const BACKEND_ADMIN_PATIENTS_LIST_OMDR_REVIEWED_NO_TIMESTAMP_LIST_TEXT = 'Get patients who are OMD Reviewed with no Timestamp Patient List';
export const BACKEND_PATIENT_DETAILS_TEXT = 'Get Patient Details';
export const BACKEND_DEACTIVATE_EXAM_TEXT = 'Deactivate Exam';
export const BACKEND_EXAM_DETAILS_TEXT = 'Get Exam Details';
export const BACKEND_GET_NEW_EXAM_TEXT = 'Get New Exam';
export const BACKEND_OPTIONS_TEXT = 'Get Options';
export const BACKEND_GP_LIST_TEXT = 'Get GP List';
export const BACKEND_GP_LIST_BY_PROVINCE_TEXT = 'Get GP List by Province';
export const BACKEND_IGP_LIST_TEXT = 'Get IGP List';
export const BACKEND_GP_FAX_TEXT = 'Get GP Fax Number';
export const BACKEND_API_ADD_TEXT = 'Add New Patient';
export const BACKEND_SAVE_EXAM_TEXT = 'Save Exam Details';
export const BACKEND_GET_PATIENT_TEXT = 'Get Patient';
export const BACKEND_DEACTIVATE_PATIENT_TEXT = 'Deactivate Patient';
export const BACKEND_EDIT_PATIENT_TEXT = 'Save Edit Patient';
export const BACKEND_ADD_GP_TEXT = 'Add GP';
export const BACKEND_UPDATE_GP_TEXT = 'Update GP';
export const BACKEND_PATIENT_HAS_EXAM = 'Patient has Exam';
export const BACKEND_OMD_REVIEW_TIMESTAMP = 'Set OMD Review Timestamp';
export const BACKEND_PHN_EXISTS_TEXT = 'Check PHN Exists';
export const PATIENT_HAS_EXAM_ERROR_TEXT = 'A patient encounter for this date already exists';
export const BACKEND_GP_CONSULT_LETTER_TEXT = 'Get Consult Letter';
export const BACKEND_ADD_PATIENT_TEXT = 'Add Patient';

// Response types
export const RESPONSE_TYPE_JSON = 'application/json';
export const RESPONSE_TYPE_OTHER = 'other';

// Login messages.
export const LOGIN_MIGRATION_ERROR_MESSAGE = 'User is not enabled for this service.';

// Login text.
export const LOGIN_INVITATION_EMAIL = 'hello@care1.ca';

// Error message areas
export const USER_ERROR_MESSAGE_AREA = 'User Authentication Error';
export const DOB_ERROR_MESSAGE = 'Invalid DOB. Please double check entries and try again.';

// Top navigation static links
export const NAVIGATION_URLS = [
    { title: 'Avocado Lenses', url: 'https://avocadolenses.com' },
    { title: 'Retina Enabled Clinics', url: '/' },
    { title: 'Dry Eye Enabled Clinics', url: '#' },
    { title: 'Integrated Clinics', url: '#' },
];

// Navigation Bar Platform Title
export const PLATFORM_RETINA_ENABLED_TITLE = 'Retina Enabled';
export const PLATFORM_INTEGRATED_TITLE = 'Integrated Clinic';
export const PLATFORM_OMDC_TITLE = 'OMD Consultant';
export const PLATFORM_OMDR_RE_TITLE = 'OMD Retina Enabled';
export const PLATFORM_OMDR_IC_TITLE = 'OMD Integrated Clinic';
export const PLATFORM_ADMIN_TITLE = 'Administrator';

// Headers
export const RETINA_CLINICS_HEADING = 'Retina Enabled Clinics';

// Patient List text
export const PATIENT_LIST_TEXT = 'Patient List';

// Patient list control bar
export const PATIENT_LIST_TITLE_TEXT = 'Patient List';
export const PATIENT_LIST_SEARCH_PLACEHOLDER = 'Search by name or health number…';
export const NEW_PATIENT_TEXT = 'New Patient';
export const STATUS_FILTER_BUTTON_TEXT = 'Status';
export const LATEST_VISIT_TEXT = 'Latest Visit';
export const ALL_TIME_KEY = 'all_time';
export const ALL_STATUSES_KEY = 'all_statuses';
export const CLINIC_PATIENTS_KEY = 'clinic';

export const EXAM_PERIOD_OPTIONS = [
    { key: 'all_time', value: 'All time' },
    { key: '1_week', value: 'Last 1 week'},
    { key: '1_month', value: 'Last 1 month' },
    { key: '3_months', value: 'Last 3 months' },
    { key: '6_months', value: 'Last 6 months' },
    { key: '12_months', value: 'Last 12 months' },
    { key: '12_18_months', value: 'Last 12~18 months' },
    { key: '18_24_months', value: 'Last 18~24 months' },
];

export const EXAM_STATUS_TYPES = [
    { key: 'all_statuses', value: 'All Statuses' },
    {
        key: 'not_ready_od_review', value: 'Not Ready/OD to Review', color: globalColors['redTagColor']
    },
    {
        key: 'not_ready', value: 'Not Ready', color: globalColors['redTagColor']
    },
    {
        key: 'od_review', value: 'OD to Review', color: globalColors['yellowTagColor']
    },
    {
        key: 'ready', value: 'Ready for OMD', color: globalColors['blueTagColor']
    },
    {
        key: 'reviewed', value: 'OMD Reviewed', color: globalColors['greenTagColor']
    },
];

export const SMART_UPLOAD_STATUS = [
    {
        key: 'draft',
        value: 'Not Submitted',
        color: globalColors['redTagColor'],
    },
    {
        key: 'submitted',
        value: 'Submitted',
        color: globalColors['yellowTagColor'],
    },
];

export const OMDC_STATUS_TYPES = [
    { key: 'all_statuses', value: 'All Statuses' },
    { key: 'ready', value: 'Ready for Consultant' },
    { key: 'reviewed', value: 'Consultant Reviewed' },
    { key: 'processed', value: 'Consultation Processed' },
];

export const OMDR_RE_STATUS_TYPES = [
    { key: 'ready', value: 'Ready for OMD' },
    { key: 'reviewed', value: 'OMD Reviewed' },
    { key: 'ready_or_reviewed', value: 'Ready or Reviewed' },
];

export const OMDR_IC_STATUS_TYPES = [
    { key: 'none', value: 'No Filter Selected' },
    { key: 'ready', value: 'Ready for OMD' },
    { key: 'special_attention', value: 'Special Attention' },
    { key: 'retina1', value: 'Retina (DM, AMD, ERM)' },
    { key: 'retina2', value: 'Other' },
    { key: 'glc_p', value: 'Glc F/U - Parameters Set (P)' },
    { key: 'glc_bo', value: 'Glc F/U - Borderline Probably Observe (BO)' },
    { key: 'glc_bt', value: 'Glc F/U - Borderline Probably Treat (BT)' },
    { key: 'gdgvf', value: 'Glc New - Good Disc Good VF' },
    { key: 'bdgvf', value: 'Glc New - Bad Disc Good VF' },
    { key: 'gdbvf', value: 'Glc New - Good Disc Bad VF' },
    { key: 'bdbvf', value: 'Glc New - Bad Disc Bad VF' },
];

export const PATIENT_GROUPING_OPTIONS = [
    { key: 'doctor', value: 'My Pts' },
    { key: 'clinic', value: 'Clinic Pts' },
];

export const OMDR_EXAM_REVIEWED_PERIOD_OPTIONS = [
    { key: 'none', value: 'No Period Filter Selected'},
    { key: '1_hour', value: 'Reviewed Last 1 hr' },
    { key: '6_hours', value: 'Reviewed Last 6 hrs' },
    { key: '12_hours', value: 'Reviewed Last 12 hrs' },
    { key: '24_hours', value: 'Reviewed Last 24 hrs' },
    { key: '24_48_hours', value: 'Reviewed Last 24~48 hrs' },
    { key: '48_72_hours', value: 'Reviewed Last 48~72 hrs' },
    { key: '72_hours_1_week', value: 'Reviewed Last 72hrs~1wk' },
    { key: '30_days', value: 'Reviewed Last 30 days' },
];

export const OMDR_EXAM_PRE_REVIEWED_PERIOD_OPTIONS = [
    { key: 'over_4_wks', value: 'Pre-Rvwd > 4 wks ago' },
    { key: 'over_3_wks', value: 'Pre-Rvwd > 3 wks ago' },
    { key: 'over_2_wks', value: 'Pre-Rvwd > 2 wks ago' },
    { key: 'over_1_wk', value: 'Pre-Rvwd > 1 wk ago' },
    { key: 'any_time', value: 'Pre-Rvwd any time' },
];

export const ADMIN_OD_PROGRAM_OPTIONS = [
    { key: 'canada_ic', value: 'CA IC'},
    { key: 'canada_re', value: 'CA RE'},
    { key: 'canada_es', value: 'CA ES'},
    { key: 'us_ic', value: 'US IC'},
];

export const ADMIN_GPT_NEAR_MISS_HISTORICAL_PERIOD_OPTIONS = [
    { key: 'last_30_days', value: 'Last 30 days'},
    { key: 'last_60_days', value: 'Last 60 days'},
    { key: 'last_90_days', value: 'Last 90 days'},
    { key: 'last_120_days', value: 'Last 120 days'},
    { key: 'last_180_days', value: 'Last 180 days'},
];

export const ADMIN_UNFLAGGED_OPTIONS = [
    { key: 'all_ic_unflagged', value: 'All IC Exams'},
    { key: 'retina', value: 'Retina'},
    { key: 'glaucoma', value: 'Glaucoma' },
    { key: 'other', value: 'Other' },
    { key: 'all_ic_unflagged_ref_ltr', value: 'All Exams (Ref Ltr + Submit)'},
    { key: 'ipc_yes_ref_ltr', value: 'IPC (Ref Ltr + Submit)'},
    { key: 'high_priority_ref_ltr', value: 'High priority (Ref Ltr + Submit)'},
    { key: 'od_messaging_ref_ltr', value: 'OD Messaging'},
    { key: 'outbound_referral_unflagged', value: 'Outbound Referral'},
    { key: 'other_ref_ltr', value: 'Other(Ref Ltr + Submit)'},
];

export const ADMIN_FLAGGED_OPTIONS = [
    { key: 'all_flagged', value: 'All patients' },
    { key: 'special_attention', value: 'Special Attention' },
    { key: 'assign_first', value: 'Assign First' },
    { key: 'outbound_referral_flagged', value: 'Outbound Referral' },
    { key: 'retina1', value: 'Retina' },
    { key: 'retina2', value: 'Other' },
    { key: 'glc_p', value: 'GLC-P' },
    { key: 'glc_bo', value: 'GLC-BO' },
    { key: 'glc_bt', value: 'GLC-BT' },
    { key: 'gdgvf', value: 'GDGVF' },
    { key: 'bdgvf', value: 'BDGVF' },
    { key: 'gdbvf', value: 'GDBVF' },
    { key: 'bdbvf', value: 'BDBVF' },
];

export const SCIENCE_FLAGGED_OPTIONS = [
    { key: 'retina1', value: 'Retina' },
    { key: 'retina2', value: 'Other' },
    { key: 'glc_p', value: 'GLC-P' },
    { key: 'glc_bo', value: 'GLC-BO' },
    { key: 'glc_bt', value: 'GLC-BT' },
    { key: 'gdgvf', value: 'GDGVF' },
    { key: 'bdgvf', value: 'BDGVF' },
    { key: 'gdbvf', value: 'GDBVF' },
    { key: 'bdbvf', value: 'BDBVF' },
];

export const ADMIN_MISC_OPTIONS = [
    { key: 'not_ready_od_review', value: 'Not Ready/OD to Review' },
    { key: 'omdc_ready', value: 'Ready for OMDC' },
    { key: 'omdc_reviewed', value: 'OMDC Reviewed' },
    { key: 'need_igp', value: 'Need IGP' },
    { key: 'need_igp_ottawa', value: 'Need IGP (Ottawa)' },
    { key: 'igp_ready', value: 'Ready for IGP' },
    { key: 'igp_reviewed', value: 'IGP Reviewed' },
    { key: 'no_od_assigned', value: 'No OD Assigned'},
    { key: 'standard_rlu', value: 'Standard RLU'},
    { key: 'od_messaging', value: 'OD Messaging'},
    { key: 'waiting_for_omdc', value: 'Waiting For OMDC'},
];

export const ADMIN_AUDIT_RECORD_OPTIONS = [
    { key: 'rlu_ic_gp_letter_to_send', value: 'RLU OMDR-GP letters to send'},
    { key: 'rlu_omdc_gp_letter_to_send', value: 'RLU OMDC-GP letters to send'},
    { key: 'ic_gp_letter_to_send', value: 'OMDR-GP IC letters to send' },
    { key: 're_gp_letter_to_send', value: 'OMDR-GP RE letters to send' },
    { key: 'omdc_gp_letter_to_send', value: 'OMDC-GP letters to send' },
    { key: 'omdr_reviewed_audit', value: 'OMDR reviewed date' },
    { key: 'omdc_processed_audit', value: 'OMDC processed date' },
    { key: 'rlu_igp_referred_audit', value: 'RLU IGP referred date'},
    { key: 'igp_referred_audit', value: 'IGP referred date' },
];

export const ADMIN_BULK_OPERATIONS_OPTIONS = [
    { key: 'fax_rlu_omdr_gp', value: 'Fax RLU OMDR-GP IC letters' },
    { key: 'fax_rlu_omdc_gp', value: 'Fax RLU OMDC-GP letters' },
    { key: 'fax_omdr_gp', value: 'Fax OMDR-GP IC letters' },
    { key: 'fax_omdr_gp_re', value: 'Fax OMDR-GP RE letters' },
    { key: 'fax_rlu_omdr_igp', value: 'Fax RLU OMDR-IGP letters' },
    { key: 'fax_omdr_igp', value: 'Fax OMDR-IGP letters' },
    { key: 'fax_omdc_gp', value: 'Fax OMDC-GP letters ' },
    { key: 'set_omdc_ready', value: 'Set OMDC status to Ready + set OMDC' },
    { key: 'set_omdc_processed', value: 'Set OMDC status to Processed' },
    { key: 'set_igp', value: 'Set IGP status to Ready + set IGP' },
    { key: 'set_omdr', value: 'Set OMDR' },
    { key: 'send_od_to_review_reminder_email', value: 'Send OD to Review Reminder Email' },
];

export const ADMIN_GP_PATIENT_MANAGEMENT_BULK_OPERATIONS_OPTIONS = [
    { key: 'inactive_gp_email_remove', value: 'Inactive GP (email + remove)' },
    { key: 'incorrect_gp_info_email_remove', value: 'Incorrect GP Info (email + remove)' },
    { key: 'not_a_patient_email_remove', value: 'Not a Patient (email + remove)' },
    { key: 'remove_gp_no_email', value: 'Remove GP (no email)' },
];

// This maps the backend IPC status to front-end referral status display strings.
export const REFERRAL_STATUS_TYPES = [
    {
        key: 'requested', value: 'NOT SENT', color: globalColors['yellowTagColor']
    },
    {
        key: 'addressed', value: 'SENT', color: globalColors['greenTagColor']
    },
    {
        key: 'ipc_only', value: 'SENDING', color: globalColors['yellowTagColor']
    },
];

// Person fields
export const FIRST_NAME_FIELD = 'first_name';
export const LAST_NAME_FIELD = 'last_name';
export const DOB_FIELD = 'dob';
export const AGE_FIELD = 'age';
export const DOB_DAY_FIELD = 'dobDay';
export const DOB_MONTH_FIELD = 'dobMonth';
export const DOB_YEAR_FIELD = 'dobYear';
export const GENDER_FIELD = 'gender';
export const GENDER_MALE_FIELD = 'M';
export const GENDER_FEMALE_FIELD = 'F';
export const PHONE_FIELD = 'primary_phone';
export const EMAIL_FIELD = 'email';
export const PROVINCE_FIELD = 'province';
export const STREET_FIELD = 'address';
export const CITY_FIELD = 'city';
export const POSTAL_CODE_FIELD = 'postal_code';

// Patient details fields
export const BACKEND_PATIENT_ID_FIELD = 'id';
export const PATIENT_ID_FIELD = 'patient_id';
export const PATIENT_SEARCH_VALUE_FIELD = 'name_or_health_number';
export const PHN_FIELD = 'phn';
export const PHN_NAME_FIELD = 'phn_name';
export const PHN_VALIDATION_FORMAT_FIELD = 'health_id_format';
export const SECONDARY_PHN_REQUIRED_FIELD = 'secondary_phn_required';
export const SECONDARY_PHN_FIELD = 'secondary_phn';
export const SECONDARY_PHN_NAME_FIELD = 'secondary_phn_name';
export const SECONDARY_PHN_VALIDATION_FORMAT_FIELD = 'health_billing_id_format';
export const IS_CAF_PERSONNEL_FIELD = 'caf_personnel';
export const CAF_SERVICE_NUMBER_FIELD = 'service_number';
export const PLEASE_CONFIRM_FIELD = 'please_confirm';
export const HAS_NO_PHN_FIELD = 'has_no_phn';
export const IS_ON_OLD_OHIP_FIELD = 'is_on_old_ohip';

// Doctor fields
export const GP_ID_FIELD = 'id';
export const DOCTOR_NAME_FIELD = 'od';
export const GP_FIELD = 'gp';
export const GP_CURRENT_FIELD = 'current_gp';
export const FAX_NUMBER_FIELD = 'fax_number';
export const GP_FAX_NUMBER_FIELD = 'fax_number', // used in gp table
    GP2_CURRENT_FIELD = 'current_gp2';
export const GP2_FAX_NUMBER_FIELD = 'gp2_fax_number', // used in gp table
    GP_OD_PRACTICE_FIELD = 'practice', // used in gp table
    GP_SEARCH_FIELD = 'gp_search', // used to persist user search entry
    GP_NEW_TEMP_ID = 'Dr.', // temporary ID for new GP
    DR_PREFIX_TEXT = 'Dr.';

// Optometrist fields
export const OPTOMETRIST = 'optometrist';
export const OPTOMETRIST_OPTIONS = 'optometrist_options';

// Clinic treatment fields
export const CHIEF_COMPLAINT_FIELD = 'chief_complaint';
export const NUMBER_VISITS_FIELD = 'visits';
export const LAST_VISIT_DATE_FIELD = 'last_visit';
export const LAST_REVIEWED_DATE_FIELD = 'last_reviewed';
export const LATEST_EXAM_STATUS_FIELD = 'latest_exam_status';
export const LATEST_EXAM_ID_FIELD = 'latest_exam_id';
export const BACKEND_EXAM_PERIOD_FIELD = 'exam_date';
export const BACKEND_EXAM_STATUS_FIELD = 'exam_status';
export const BACKEND_PATIENT_GROUPING_FIELD = 'clinic_patients';

// Patient list table column headings text.
export const FIRST_NAME_HEADING = 'First Name';
export const LAST_NAME_HEADING = 'Last Name';
export const DOB_HEADING = 'DOB';
export const DOCTOR_HEADING = 'OD';
export const CHIEF_COMPLAINT_HEADING = 'Chief Complaint';
export const NUMBER_VISITS_HEADING = 'Visits';
export const LAST_VISIT_HEADING = 'Last Visit';
export const LAST_REVIEWED_HEADING = 'Last Rev\'d';
export const STATUS_HEADING = 'Status';
export const SELECT_HEADING = 'Select';

// Patient list table fields.
export const IS_SELECTED = 'is_selected';

// Patient list state
export const PATIENT_LIST_EXAM_PERIOD_FIELD = 'examPeriod';
export const PATIENT_LIST_EXAM_STATUS_FIELD = 'examStatus';
export const PATIENT_LIST_PATIENT_GROUPING_FIELD = 'patientGrouping';

// Patient Details Labels
export const FIRST_NAME_LABEL_TEXT = 'First Name';
export const LAST_NAME_LABEL_TEXT = 'Last Name';
export const GENDER_LABEL_TEXT = 'Gender';
export const GENDER_MALE_TEXT = 'Male';
export const GENDER_FEMALE_TEXT = 'Female';
export const PHONE_LABEL_TEXT = 'Phone';
export const EMAIL_LABEL_TEXT = 'Email';
export const DOB_LABEL_TEXT = 'Date of Birth';
export const PROVINCE_LABEL_TEXT = 'Province';
export const PHN_DEFAULT_LABEL_TEXT = 'PHIN';
export const STREET_LABEL_TEXT = 'Street';
export const CITY_LABEL_TEXT = 'City';
export const POSTAL_CODE_LABEL_TEXT = 'Postal Code';
export const GP_LABEL_TEXT = 'GP';
export const GP_FAX_NUMBER_LABEL_TEXT = 'GP Fax No.';
export const GP2_LABEL_TEXT = 'MD#2';
export const GP2_FAX_NUMBER_LABEL_TEXT = 'MD#2 Fax No.';
export const ARMED_PERSONNEL_LABEL_TEXT = 'Service Number';
export const PLEASE_CONFIRM_LABEL_TEXT = 'Please confirm';

// Modal buttons
export const EDIT_BUTTON_TEXT = 'Edit';
export const NEW_VISIT_BUTTON_TEXT = 'New Visit';
export const CANCEL_BUTTON_TEXT = 'Cancel';
export const CLOSE_BUTTON_TEXT = 'Close';
export const SAVE_BUTTON_TEXT = 'Save';
export const DELETE_BUTTON_TEXT = 'Delete';
export const SAVE_AND_CLOSE_BUTTON_TEXT = 'Save & Close';
export const GP_CONSULT_LETTER_TEXT = 'GP Consult';
export const GP2_CONSULT_LETTER_TEXT = 'MD#2 Consult';
export const VIEW_VISIT_TEXT = 'View';
export const SUBMIT_BUTTON_TEXT = 'Submit';

// Edit patient modal
export const EDIT_PATIENT_TEXT = 'Edit Patient';

// Delete Confirmation Modal
export const DELETE_CONFIRMATION_MODAL_TITLE = 'Are you sure you want to delete this?';
export const DELETE_CONFIRMATION_MODAL_TEXT = 'You cannot revert this action afterwards.';
export const DELETE_CONFIRMATION_OK_TEXT = 'Yes';
export const DELETE_CONFIRMATION_CANCEL_TEXT = 'No';
export const DELETE_CONFIRMATION_TITLE = 'Delete Image';

// Deactivate Confirmation Modal
export const DEACTIVATE_CONFIRMATION_MODAL_TITLE = 'Are you sure you want to deactivate this exam?';
export const DEACTIVATE_CONFIRMATION_MODAL_TEXT = 'You cannot revert this action afterwards.';
export const DEACTIVATE_CONFIRMATION_OK_TEXT = 'Yes';
export const DEACTIVATE_CONFIRMATION_CANCEL_TEXT = 'No';
export const DEACTIVATE_PATIENT_CONFIRMATION_MODAL_TITLE = 'Are you sure you want to deactivate this patient?';

// Unauthorized Exam Modal
export const UNAUTHORIZED_MODAL_TITLE = 'The requested resource is not available.';
export const UNAUTHORIZED_MODAL_CONTENT = 'Please contact hello@care1.ca if you continue to have issues.';

// Patient edit form
export const FNAME_PLACEHOLDER_TEXT = 'Enter first name';
export const LNAME_PLACEHOLDER_TEXT = 'Enter last name';
export const PHONE_PLACEHOLDER_TEXT = 'Enter phone number';
export const EMAIL_PLACEHOLDER_TEXT = 'Enter email address';
export const DOB_PLACEHOLDER_TEXT = 'Select date of birth';
export const PROVINCE_PLACEHOLDER_TEXT = 'Select province';
export const STREET_PLACEHOLDER_TEXT = 'Enter street address';
export const CITY_PLACEHOLDER_TEXT = 'Select city';
export const POSTAL_CODE_PLACEHOLDER_TEXT = 'XXX XXX';
export const GP_PLACEHOLDER_TEXT = 'Search or enter GP';
export const GP_FAX_PLACEHOLDER_TEXT = 'Enter GP fax no.';
export const GP2_PLACEHOLDER_TEXT = 'Search or enter GP';
export const GP2_FAX_PLACEHOLDER_TEXT = 'Enter GP fax no.';
export const FORCES_PLACEHOLDER_TEXT = 'Enter service number';
export const GP_FAX_INFO_TEXT = `Entering GP's fax number allows Care1 to send updates to patient's GP, promoting your office as an access point for high-level medical care.`;
export const PLEASE_CONFIRM_LIST_TEXT = [
    { key: 1, value: 'Patient requests their GP be informed of their case.' },
    { key: 2, value: 'Patient requests collaboration with an ophthalmologist.' },
    { key: 3, value: 'Patient consents to digital communication to discuss their chart, including the use of email.' },
    {
        key: 4,
        value: 'Patient consents to the use of their chart for research, medical presentations, program\n'
            + 'improvement and medical advancement, both by Care1 or individuals/programs/organizations external to Care1.\n'
            + 'Data used will be stripped of all identifying data.'
    },
];
export const PLEASE_ENSURE_HEADING_TEXT = 'Please ensure that patient healthcare numbers are input accurately! ';
export const PLEASE_ENSURE_TEXT = 'Our software automatically charges OD Clinics $100 per upload for patients without valid\n'
    + 'Canadian healthcare coverage (these costs should then be passed on to the patient).';
export const PHONE_MASK_FORMAT = '(999) 999-9999';
export const POSTAL_CODE_MASK = 'a9a 9a9';
export const ZIP_CODE_MASK = '99999';
export const CAF_MASK_FORMAT = 'a99999999';
export const ONTARIO_OLD_OHIP_LENGTH = 12;
export const ONTARIO_PROVINCE_ID = 9;
export const ADD_PATIENT_CONFIRMATION_TEXT = 'New patient successfully added.';
export const EDIT_PATIENT_CONFIRMATION_TEXT = 'New edits successfully added.';
export const DEACTIVATE_PATIENT_CONFIRMATION_TEXT = 'Patient successfully deactivated.';
export const ERROR_INVALID_PHONE_FORMAT = 'The phone number format is invalid';
export const ERROR_INVALID_EMAIL_FORMAT = 'The email format is invalid';
export const ERROR_INVALID_HN_FORMAT = 'The health number is invalid';
export const ERROR_INVALID_POSTAL_CODE_FORMAT = 'The postal code is invalid';
export const ERROR_PHN_ALREADY_EXISTS = 'The health number already exists';

// Options
export const PROVINCE_OPTIONS_FIELD = 'province_options';
export const APPLANATION_OPTIONS_FIELD = 'applanation_options';
export const RX_OPTIONS_FIELD = 'rx_options';
export const GP_REFERRAL_FIELD = 'gp_refed';
export const GP_REFERRAL_UPLOAD_LABEL = 'gpReferralUploadLabel';
export const GP_REFERRAL_LETTER = 'referral_letter';
export const SURGERY_TYPES_LIST = 'surgery_types_list';
export const EYES_LIST = 'eyes_list';
export const DROPS_LIST = 'drops_list';
export const COMPLIANCE_LIST = 'compliance_list';
export const PROCEDURE_LIST = 'procedure_list';
export const MACHINES_LIST = 'machines_list';
export const FOLLOW_UP_OPTIONS = 'omdc_fu_options';
export const LRS_TYPE_OPTIONS = [
    {value: 'lasik', label: 'LASIK'},
    {value: 'prk', label: 'PRK'},
    {value: 'slt', label: 'SLT'},
    {value: 'alt', label: 'ALT'},
    {value: 'trab', label: 'Trab'},
    {value: 'tube', label: 'Tube'},
    {value: 'istent', label: 'iStent'},
    {value: 'cataract_sx', label: 'Cataract Sx'},
    {value: 'ret_detachment', label: 'Ret. Detachment'},
]


// Diagnosis fields
export const DIAGNOSIS_GLC = 'rr_glc';
export const DIAGNOSIS_GLC_S = 'rr_glc_suspect';
export const DIAGNOSIS_DM = 'rr_dm';
export const DIAGNOSIS_AMD = 'rr_amd';
export const DIAGNOSIS_ERM = 'rr_erm';
export const DIAGNOSIS_OTHERS = 'rr_others';
export const DIAGNOSIS_OPTIC_NERVE = 'rr_optic_nerve';
export const DIAGNOSIS_NARROW_ANGLES = 'rr_narrow_angles';
export const DIAGNOSIS_CATARACT = 'rr_cataract';
export const DIAGNOSIS_CATARACT_POST_OP = 'rr_cat_post_op';
export const DIAGNOSIS_PED = 'ped';
export const DIAGNOSIS_MAC_HOLE = 'macular_hole';
export const DIAGNOSIS_NEVUS = 'nevus';
export const DIAGNOSIS_PLAQUENIL = 'plaquenil';
export const DIAGNOSIS_RAO = 'rao';
export const DIAGNOSIS_VMT = 'vmt';
export const DIAGNOSTICS_VALUES = 'diagnostics_values';

// Diagnosis types
export const DIAGNOSIS_TEXT_TYPE = 'text';
export const DIAGNOSIS_BOOLEAN_TYPE = 'boolean';
export const DIAGNOSIS_EYE_SELECT_TYPE = 'boolean_eye_select';
export const DIAGNOSIS_DOSAGE_DATE_TYPE = 'dosage_date';

// Eye select fields
export const EYE_SELECT_OPTIONS = 'eye_select_options';
export const EYE_SELECT_SUFFIX = '_eye_select';

// Diagnosis dosage fields
export const DIAGNOSIS_DOSAGE = 'dosageText';
export const DIAGNOSIS_DOSAGE_SUFFIX = '_dose';
export const DIAGNOSIS_DOSAGE_DATE_SUFFIX = '_date';

// Diagnosis text
export const DIAGNOSIS_CATEGORY_TEXT = 'categorized as ';

// Diagnosis fields
export const DIAGNOSIS_LIST = 'diagnosis_list';
export const DIAGNOSIS_TYPE = 'diagnosisType';

// Visual Acuity fields
export const BCVA_PLACEHOLDER = '20 / Select';
export const BCVA_OPTIONS = 'bcva_options';
export const OD_BCVA = 'od_bcva';
export const OS_BCVA = 'os_bcva';
export const BCVA_TEXT = 'BCVA';
export const OD_SPHERE = 'od_sphere';
export const OD_CYLINDER = 'od_cylinder';
export const OD_AXIS = 'od_axis';
export const OS_SPHERE = 'os_sphere';
export const OS_CYLINDER = 'os_cylinder';
export const OS_AXIS = 'os_axis';
export const RX_TEXT = 'RX';

// Axis field mask
export const AXIS_MASK = '000';

// Exam History text
export const EXAM_HISTORY_TITLE = 'History & Exam';
export const EXAM_OMD_HISTORY_TITLE = 'OMD History';

export const EXAM_HISTORY_SHORT_TEXT = '- The shorter the better! 1 sentence is just fine.';
export const EXAM_HISTORY_COPY_TEXT = '- Copy (Ctrl-C) and paste (Ctrl-V) from your EMR.';
export const EXAM_HISTORY_CONSULT_TEXT = '- A consult letter will also be sent to the patient\'s GP. The letter will promote your practice as an access point for a retinal specialist.';

// Exam History fields
export const EXAM_HISTORY_FIELD = 'pmh_m';
export const EXAM_HISTORY_PLACEHOLDER = 'Something short, even only 1-3 sentences long, is perfectly fine.';
export const EXAM_HISTORY_DEFAULT_TEXT = 'no significant past medical history.';
export const EXAM_OMD_HISTORY_FIELD = 'omd_h';

// Exam Status text
export const EXAM_STATUS_TITLE_TEXT = 'CHART STATUS IS=';
export const OCT_MACULA_TEXT = 'OCT Macula';
export const OCT_RNFL_TEXT = 'OCT RNFL';
export const OCT_VF_TEXT = 'VF';
export const FUNDUS_HISTORY_TEXT = 'Fundus Photo';
export const CONVERSATION_HISTORY_TEXT = 'OMD & OD Conversation';
export const OD_NOTES_TEXT = 'Show OD Notes';
export const SHOW_ADMIN_VIEW = 'Show Admin View';
export const EXTRA_IMAGES_TEXT = 'Extra Images';
export const IOP_GRAPH_TEXT = 'IOP vs. Age';
export const BCVA_GRAPH_TEXT = 'BCVA vs. Age';
export const NKDA_TEXT = 'NKDA';
export const SULFA_TEXT = 'Sulfa';
export const NONE_TEXT = 'None';
export const NO_GLC_MEDS = 'No Glc Meds';
export const SAME_MEDS = 'The Same Meds';
export const BASELINE_TESTING_IMAGE_LABEL = '[Baseline testing images are displayed in the first row]';

// Exam Status fields
export const EXAM_STATUS = 'exam_status';
export const EXAM_STATUS_OPTIONS = 'exam_status_options';
export const EXAM_STATUS_OD_ALLOWED = 'exam_status_od_allowed';
export const EXAM_STATUS_OD_CAN_SET = 'exam_status_od_can_set';
export const SAVED_EXAM_STATUS = 'saved_exam_status';

// Exam ID field in the state
export const EXAM_ID = 'id';

// Exam ID field in the save exam JSON
export const EXAM_ID_SAVE = 'exam';

// Exam Fields in the exam JSON, except exam_date, which is under patient info
export const OMDC_NOTE_STRING = 'omdc_note_string';
export const OMD_NOTE_STRING = 'omd_note_string';
export const OMDC_NOTE_STR_ADMIN = 'omdc_note_str_admin';
export const OMD_NOTE_STR_ADMIN = 'omd_note_str_admin';
export const OD_NAME = 'od_name';
export const OMDR_NAME = 'omdr_name';
export const OD_OMD = 'od_omd';
export const OD_PROPOSED_PLAN = 'od_proposed_plan';

// Other exams fields
export const EXAMS = 'exams';
export const EXAMS_WITH_LEFT_OCT = 'exams_with_left_oct'; // previous exams with left oct
export const EXAMS_WITH_RIGHT_OCT = 'exams_with_right_oct'; // previous exams with right oct
export const EXAMS_WITH_LEFT_OCT_RNFL = 'exams_with_left_oct_rnfl'; // previous exams with left oct rnfl
export const EXAMS_WITH_RIGHT_OCT_RNFL = 'exams_with_right_oct_rnfl'; // previous exams with right oct rnfl
export const EXAMS_WITH_LEFT_FUNDUS = 'exams_with_left_fundus'; // previous exams with left fundus
export const EXAMS_WITH_RIGHT_FUNDUS = 'exams_with_right_fundus'; // previous exams with right fundus
export const EXAMS_WITH_LEFT_VF = 'exams_with_left_vf'; // previous exams with left vf
export const EXAMS_WITH_RIGHT_VF = 'exams_with_right_vf'; // previous exams with right vf
export const EXTRA_IMAGES = 'extra_images'; // All extra exam images
export const EXTRA_IMAGES_VIEW_ALL_LIMIT = 6;
export const DELETE_EXTRA_IMAGE_ERROR = 'Delete extra image error';
export const SWITCH_IMAGE_ERROR = 'Swap images error';
export const SWITCH_IMAGE_SUCCEED = 'Images are swapped';
export const GRAPH_IOP = 'graph_iop';
export const GRAPH_BCVA = 'graph_bcva';
export const OMD_HISTORY = 'omd_history';
export const OMD_HISTORY_VALUES = 'omd_history_values';
export const OD_GROUP_PRACTICE = 'od_group_practice';
export const OD_GROUP_PRACTICE_PROVINCE = 'od_group_practice_province';
export const IOP_HISTORY = 'iop_history';
export const IOP_HISTORY_VALUES = 'iop_history_values';
export const UNTIL_YESTERDAY = 'until_yesterday';
export const UNTIL_YESTERDAY_VALUES = 'glc_past_drops_values';
export const ONGOING = 'ongoing';
export const ONGOING_VALUES = 'glc_current_drops_values';

// Div Tag Empty Value
export const UNTIL_YESTERDAY_EMPTY_VALUE = 'undefined \xa0 \xa0undefined';
export const ONGOING_EMPTY_VALUE = 'undefined \xa0';

// React Dropdown option titles
export const REACT_DROPDOWN_LABEL = 'label';
export const REACT_DROPDOWN_VALUE = 'value';

// OMD Consult fields
export const OMD_CONSULT_NOTES = 'omd_od';
export const OMD_CONSULT_BUTTONS = 'retina_only_omd_note_templates';
export const OMD_CONSULT_BUTTONS_OPTIONS = 'retina_only_omd_note_templates_options';
export const OMD_CONSULT_BUTTONS_ID = 'id';
export const OMD_CONSULT_BUTTONS_NAME = 'name';

// IOP fields
export const OS_IOP = 'os_iop';
export const OD_IOP = 'od_iop';
export const APPLANATION_FIELD = 'applanation';

// Billing Fields
export const FU_LETTER_FIELD = 'fu_letter';
export const FU_NUMBER_FIELD = 'fu_number';
export const OTHER_OMD_FIELD = 'other_omd';
export const OMD_APT_FIELD = 'refer_in_person_omd_appt';
export const CANT_BILL_OCT_FIELD = 'cant_bill_oct';
export const CANT_BILL_VF_FIELD = 'cant_bill_vf';

// Billing Text
export const FU_LETTER_TEXT = 'Pt is returning for follow-up in';
export const OTHER_OMD_TEXT = 'Pt currently also sees an OMD';
export const OMD_APT_TEXT = 'Care1 to refer pt to in-person OMD';
export const CANT_BILL_OCT_TEXT = 'We couldn\'t bill the gov\'t for the OCT';
export const CANT_BILL_VF_TEXT = 'We couldn\'t bill the gov\'t for the VF';

// Exam user fields
export const USER_IS_OPTOMETRIST = 'is_optometrist';
export const USER_IS_PERC = 'is_perc';
export const USER_IS_OPHTHALMOLOGIST = 'is_ophthalmologist';
export const USER_IS_PRE_REVIEWER = 'is_pre_reviewer';

// Exam type fields
export const EXAM_IS_RETINA_ONLY = 'exam_is_retina_only';
export const EXAM_IS_PERC = 'exam_is_perc';

// Patient information
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const DOB = 'dob';
export const PHN = 'phn';
export const AGE = 'age';
export const SEX = 'sex';
export const FIRST_PHONE = 'first_phone';
export const SECOND_PHONE = 'second_phone';
export const GP = 'gp';
export const GP2 = 'gp2';
export const OMDR = 'omdr';
export const OMDC = 'omdc';
export const OMD_OPTIONS = 'omd options';
export const EXAM_DATE = 'exam_date';
export const EXAM_HIGH_PRIORITY = 'is_urgent';
export const VISIT_NUMBER = 'visit_number';
export const TOTAL_VISIT_NUMBER = 'exam_count';
export const PATIENT_ID = 'patient';
export const PATIENT_URL = 'patient_url';
export const EXAM_DATE_UTC = 'exam_date_utc';

export const RIGHT_SIDE_TEXT = 'right';
export const LEFT_SIDE_TEXT = 'left';

// Dropzone fields
export const RIGHT_FUNDUS_FIELD = 'right_fundus_photo';
export const RIGHT_STEREO_FIELD = 'right_stereo_photo';
export const RIGHT_VF_FIELD = 'right_vf_photo';
export const RIGHT_OCT_MACULA_FIELD = 'right_oct_photo';
export const RIGHT_OCT_RNFL_FIELD = 'right_oct_rnfl_photo';
export const RIGHT_VF_10_2_FIELD = 'right_vf_10_2_photo';
export const RIGHT_OCT_ANGLE_FIELD = 'right_oct_angle_photo';
export const RIGHT_GCC_FIELD = 'right_gcc_photo';
export const LEFT_FUNDUS_FIELD = 'left_fundus_photo';
export const LEFT_OCT_MACULA_FIELD = 'left_oct_photo';
export const LEFT_OCT_RNFL_FIELD = 'left_oct_rnfl_photo';
export const LEFT_STEREO_FIELD = 'left_stereo_photo';
export const LEFT_VF_FIELD = 'left_vf_photo';
export const LEFT_VF_10_2_FIELD = 'left_vf_10_2_photo';
export const LEFT_OCT_ANGLE_FIELD = 'left_oct_angle_photo';
export const LEFT_GCC_FIELD = 'left_gcc_photo';
export const FILENAME = 'filename';
export const RIGHT_CAROUSEL_FIELDS = ['right_fundus_photo', 'right_stereo_photo', 'right_oct_photo', 'right_oct_rnfl_photo', 'right_vf_photo'];
export const LEFT_CAROUSEL_FIELDS = ['left_fundus_photo', 'left_stereo_photo', 'left_oct_photo', 'left_oct_rnfl_photo', 'left_vf_photo'];

// Past image fields left
export const PAST_LEFT_FUNDUS_PHOTO = 'past_left_fundus_photo';
export const PAST_LEFT_FUNDUS_PHOTO_DATE = 'past_left_fundus_photo_date';
export const PAST_LEFT_STEREO_PHOTO = 'past_left_stereo_photo';
export const PAST_LEFT_STEREO_PHOTO_DATE = 'past_left_stereo_photo_date';
export const PAST_LEFT_OCT_PHOTO = 'past_left_oct_photo';
export const PAST_LEFT_OCT_PHOTO_DATE = 'past_left_oct_photo_date';
export const PAST_LEFT_OCT_RNFL_PHOTO = 'past_left_oct_rnfl_photo';
export const PAST_LEFT_OCT_RNFL_PHOTO_DATE = 'past_left_oct_rnfl_photo_date';
export const PAST_LEFT_VF_PHOTO = 'past_left_vf_photo';
export const PAST_LEFT_VF_PHOTO_DATE = 'past_left_vf_photo_date';
export const PAST_LEFT_VF_10_2_PHOTO = 'past_left_vf_10_2_photo';
export const PAST_LEFT_VF_10_2_PHOTO_DATE = 'past_left_vf_10_2_photo_date';
export const PAST_LEFT_OCT_ANGLE_PHOTO = 'past_left_oct_angle_photo';
export const PAST_LEFT_OCT_ANGLE_PHOTO_DATE = 'past_left_oct_angle_photo_date';
export const PAST_LEFT_GCC_PHOTO = 'past_left_gcc_photo';
export const PAST_LEFT_GCC_PHOTO_DATE = 'past_left_gcc_photo_date';

// Past image fields right
export const PAST_RIGHT_FUNDUS_PHOTO = 'past_right_fundus_photo';
export const PAST_RIGHT_FUNDUS_PHOTO_DATE = 'past_right_fundus_photo_date';
export const PAST_RIGHT_STEREO_PHOTO = 'past_right_stereo_photo';
export const PAST_RIGHT_STEREO_PHOTO_DATE = 'past_right_stereo_photo_date';
export const PAST_RIGHT_OCT_PHOTO = 'past_right_oct_photo';
export const PAST_RIGHT_OCT_PHOTO_DATE = 'past_right_oct_photo_date';
export const PAST_RIGHT_OCT_RNFL_PHOTO = 'past_right_oct_rnfl_photo';
export const PAST_RIGHT_OCT_RNFL_PHOTO_DATE = 'past_right_oct_rnfl_photo_date';
export const PAST_RIGHT_VF_PHOTO = 'past_right_vf_photo';
export const PAST_RIGHT_VF_PHOTO_DATE = 'past_right_vf_photo_date';
export const PAST_RIGHT_VF_10_2_PHOTO = 'past_right_vf_10_2_photo';
export const PAST_RIGHT_VF_10_2_PHOTO_DATE = 'past_right_vf_10_2_photo_date';
export const PAST_RIGHT_OCT_ANGLE_PHOTO = 'past_right_oct_angle_photo';
export const PAST_RIGHT_OCT_ANGLE_PHOTO_DATE = 'past_right_oct_angle_photo_date';
export const PAST_RIGHT_GCC_PHOTO = 'past_right_gcc_photo';
export const PAST_RIGHT_GCC_PHOTO_DATE = 'past_right_gcc_photo_date';

// Image History fields
export const FUNDUS_HISTORY = 'fundus_history';
export const OCT_MACULA_HISTORY = 'oct_macula_history';
export const OCT_RNFL_HISTORY = 'oct_rnfl_history';
export const OCT_VF_HISTORY = 'oct_vf_history';
export const NO_IMAGE_UPLOADED = 'No image\r\nuploaded.';

// Dropzone text
export const DRAG_DROP_TEXT = 'Drag & Drop to upload.';
export const EXTRA_DRAG_DROP_TEXT = 'Drag & Drop\r\nsingle or multiple files\r\nto upload.';
export const LEFT_FUNDUS_PHOTO_TITLE_TEXT = 'Left Fundus Photo';
export const RIGHT_FUNDUS_PHOTO_TITLE_TEXT = 'Right Fundus Photo';
export const LEFT_STEREOPAIR_TITLE_TEXT = 'Left Stereopair Photo';
export const RIGHT_STEREOPAIR_TITLE_TEXT = 'Right Stereopair Photo';
export const OCT_MACULA_PHOTO_DROP_TEXT = 'Drag & Drop to upload.\r\nUpload a new OCT Macula at every visit.\r\nIf you have multiple OCT slices, please upload\r\nthe single file that looks the most pathological.';
export const PHOTO_DROP_TEXT_SHORT = 'Drag & Drop to upload.';
export const SUPPORTED_FORMAT_TEXT = 'Supported Formats= jpg, png, tiff, pdf';
export const LEFT_OCT_MACULA_PHOTO_TITLE_TEXT = 'Left OCT Macula';
export const LEFT_OCT_RNFL_PHOTO_TITLE_TEXT = 'Left OCT RNFL';
export const LEFT_VF_PHOTO_TITLE_TEXT = 'Left VF';
export const RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT = 'Right OCT Macula';
export const RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT = 'Right OCT RNFL';
export const RIGHT_VF_PHOTO_TITLE_TEXT = 'Right VF';
export const FILE_UPLOAD_FAILED_TEXT = 'Files must\r\n be smaller\r\nthan 1MB.';

// Dropzone thumbnail size
export const THUMBNAIL_WIDTH = 372;
export const THUMBNAIL_HEIGHT = 372;

// Warning messages text
export const ERROR_MESSAGE_TITLE = 'Error';
export const INFO_MESSAGE_TITLE = 'Info';

// Patient Visits Text
export const PATIENT_VISIT_NUMBER_TEXT = 'Patient Visit';
export const OF_TEXT = 'of';
export const DOB_TEXT = 'DOB=';
export const TELEPHONE_TEXT = 'TEL=';
export const GP_TEXT = 'GP=';
export const GP2_TEXT = 'MD#2=';
export const OMD_TEXT = 'OMD=';
export const OD_TEXT = 'OD=';
export const EXAM_DATE_TEXT = 'Exam Date=';
export const HIGH_PRIORITY_TEXT = 'High Priority';
export const CHART_STATUS_TEXT = 'Chart Status=';
export const OD_SELECT_PLACEHOLDER_TEXT = 'Select OD';
// The long date format has full month names. (e.g. January)
export const FRONTEND_LONG_DATE_FORMAT = 'MMMM DD, YYYY';
// The short date format has abbreviated month names. (e.g. Jan)
export const FRONTEND_SHORT_DATE_FORMAT = 'MMM DD, YYYY';
export const MOMENT_PST_FOR_BACKEND_REQUEST_FORMAT = 'YYYY-MM-DD-hh:mm:ss';
export const CHIEF_COMPLAINT_PLACEHOLDER_TEXT = 'Search or enter other complaints';
export const OMD_SELECT_PLACEHOLDER_TEXT = 'Select OMDR';

// Patient Visit Chief Complaint Text
export const DIABETES_TEXT = 'Diabetes';
export const AMD_TEXT = 'AMD';
export const ERM_TEXT = 'ERM';
export const GLC_TEXT = 'Glc';
export const GLC_S_TEXT = 'Glc Susp';
export const OPTIC_NERVE_TEXT = 'Optic Nerve';
export const CATARACT_TEXT = 'Cataract Referral (synonym: Cat Referral)';
export const CATARACT_POST_UP_TEXT = 'Cataract Post-Op (synonym: Cat Post-Op)';
export const NARROW_ANGLES_TEXT = 'Narrow Angles';


// Patient Visit Exit Confirmation Modal
export const EXIT_CONFIRMATION_TITLE = 'Are you sure you want to leave this visit?';
export const EXIT_CONTENT_TEXT = 'You have unsaved data.';
export const CONFIRMATION_OK_TEXT = 'Yes';
export const CONFIRMATION_CANCEL_TEXT = 'No';

// Patient Visit Status Change Confirmation Modal
export const READY_FOR_OMD_CONFIRMATION_TITLE = 'Please confirm if visit is ready for OMD?';
export const READY_FOR_OMD_CONTENT_TEXT = 'You cannot revert this action or change patient visit information afterwards.';

// Entry Edit Row Incomplete Modal
export const INCOMPLETE_ROW_CONFIRMATION_TITLE = 'There are unfilled fields in your entry.';
export const INCOMPLETE_ROW_CONTENT_TEXT = 'Incomplete row will not be saved.';
export const INCOMPLETE_ROW_OK_TEXT = 'Close Anyway';
export const INCOMPLETE_ROW_CANCEL_TEXT = 'Continue Editing';

// Feedback text
export const OPERATING_TEXT = 'Operating...';

// PERC PEI fields
export const HISTORY_SYMPTOMS_FIELD = 'history_symptoms';
export const HISTORY_MEDICAL_FIELD = 'history_medical';
export const HISTORY_FAMILY_FIELD = 'history_family';
export const MEDICATION_EYE_FIELD = 'medication_eye';
export const MEDICATION_OTHER_FIELD = 'medication_other';

// Label Text
export const LEFT_EYE_LABEL = 'Left Eye=';
export const RIGHT_EYE_LABEL = 'Right Eye=';
export const ALLERGIES_FIELD = 'allergies';
export const ALLERGIES_SULFA_FIELD = 'allergies_sulfa';
export const ALLERGIES_NONE_FIELD = 'allergies_none';
export const EXAM_NOTES_FIELD = 'e_notes';
export const OD_NOTES_PLAN_FIELD = 'od_omd';
export const DTC_HOUR = 'dtc_hour';
export const DTC_VALUES = 'dtc_values';

// DTC time strings.
export const DTC_AM = 'AM';
export const DTC_PM = 'PM';
export const DTC_HS = 'HS';
export const DTC_LABEL = ' DTC= ';

// Side strings.
export const OS = 'os';
export const OD = 'od';

// DTC required number of entries.
export const REQUIRED_DTC_NUMBER = 3;

// Max and min times for DTC start time.
export const DTC_HOUR_SUFFIX = ':00h';

// Vert, Pach and Ishihara fields
export const OD_CD_FIELD = 'od_cd';
export const OD_PACHS_FIELD = 'od_pachs';
export const OD_ISHIHARA_FIELD = 'od_ishihara';
export const OD_ISHIHARA2_FIELD = 'od_ishihara2';
export const OS_CD_FIELD = 'os_cd';
export const OS_PACHS_FIELD = 'os_pachs';
export const OS_ISHIHARA_FIELD = 'os_ishihara';
export const OS_ISHIHARA2_FIELD = 'os_ishihara2';
export const PAST_OD_PACHS = 'past_od_pachs';
export const PAST_OS_PACHS = 'past_os_pachs';
export const PAST_OD_IOP = 'past_od_iop';
export const PAST_OS_IOP = 'past_os_iop';
export const PAST_OD_ISHIHARA = 'past_od_ishihara';
export const PAST_OD_ISHIHARA2 = 'past_od_ishihara2';
export const PAST_OS_ISHIHARA = 'past_os_ishihara';
export const PAST_OS_ISHIHARA2 = 'past_os_ishihara2';
export const PAST_OD_CD = 'past_od_cd';
export const PAST_OS_CD = 'past_os_cd';

// PERC PEI component titles
export const HISTORY_SYMPTOMS_TITLE = 'History';
export const HISTORY_MEDICAL_TITLE = 'Past Medical History';
export const HISTORY_FAMILY_TITLE = 'Family History (Eye-Related)';
export const MEDICATION_EYE_TITLE = 'Medications (Eye-Related)';
export const MEDICATION_OTHER_TITLE = 'Medications (Other)';
export const ALLERGIES_TITLE = 'Allergies';
export const EXAM_NOTES_TITLE = 'Exam';
export const EXAM_IMPRESSION_TITLE = 'Impression';
export const OD_NOTES_PLAN_TITLE = 'OD Notes + Proposed Plan';
export const IOP_TITLE = 'IOP';
export const IOP_HISTORY_TITLE = 'IOP History';
export const UNTIL_YESTERDAY_TITLE = 'Until Yesterday';
export const EXAM_ONGOING_TITLE = 'Ongoing';
export const COMORBIDITIES_TITLE = 'Comorbidities';

// OMDG PEI component titles
export const NOTES_FROM_MOA_TITLE = 'Notes from Care1 MOA';
export const OMD_REVIEW_NOTES_TITLE = 'OMD\'s Review';
export const NOTES_TO_MOA_TITLE = 'Notes to Care1 MOA';

// OMDG PEI component placeholders
export const OMD_REVIEW_NOTES_PLACEHOLDER = 'Enter full sentences using your keyboard or dictation software. Spell carefully! Your notes will be copied directly into your consult letter.';
export const NOTES_TO_MOA_PLACEHOLDER = 'Your private instructions to the MOA, not viewable by the optometrist.';

// OMDG PEI component fields
export const NOTES_FROM_MOA_FIELD = 'notes_from_moa';
export const OMDC_REVIEW_NOTES_FIELD = 'omdc_proposed_plan';
export const NOTES_TO_MOA_FIELD = 'billing_note';
export const NOTES_TO_MOA_FIELD_CACHE = 'billing_note_cache';
export const NOTES_TO_MOA_FIELD_HELP = 'You may type instructions here to the Care1 MOA.'

// PERC PEI component text
export const MM_OF_MERCURY_TEXT = 'mmHg';
export const HISTORY_SYMPTOMS_PLACEHOLDER = 'Can copy and paste from EHR';
export const HISTORY_MEDICAL_PLACEHOLDER = 'Can copy and paste from EHR';
export const HISTORY_FAMILY_PLACEHOLDER = 'Family history of ocular conditions';
export const MEDICATION_EYE_PLACEHOLDER = 'Medications related to ocular conditions';
export const MEDICATION_OTHER_PLACEHOLDER = 'All other medications';
export const ALLERGIES_PLACEHOLDER = 'Medication allergies';
export const EXAM_NOTE_DEFAULT_TEXT = 'clear corneas OU, open angles OU, clear lens OU, and normal retinas.';
export const EXAM_NOTE_PLACEHOLDER = 'Ocular adnexa, anterior segment, posterior segment, etc';
export const EXAM_IMPRESSION_PLACEHOLDER = 'exam impression placeholder text';
export const OD_NOTES_PLAN_PLACEHOLDER = 'Additional Questions/Notes';
export const APPLANATION_PLACEHOLDER = 'Applanation';

// PDF Viewer Scale related fields
export const PDF_VIEWER_DEFAULT_SCALE = 1.2, // Default scale of the PDF document, 1 is the original size
    PDF_VIEWER_MAX_SCALE = 3, // Cannot zoom in more if the current scale is over this max scale
    PDF_VIEWER_MIN_SCALE = 1.2, // Cannot zoom out more if the current scale is under this min scale
    PDF_VIEWER_SCALE_MULTIPLIER = 1.2, // Current scale is multiplied/divided by this multiplier value when zooming in/out.
    PDF_WORKER_VERSION = '2.9.359';

// Dates
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Regex
export const EMPTY_SPACES_REGEX = /\s+/g;

// Exam Metrics Validation Messages
export const VERT_CD_VALIDATION_MSG = 'Vert C/D value is outside our accepted range of 0.1-0.9';
export const PACH_VALIDATION_MSG = 'Pachs value is outside our accepted range of 200 to 750';
export const IOP_VALIDATION_MSG = 'IOP value is outside our accepted range of 0 to 70';
export const ISHIHARA_VALIDATION_MSG = 'Ishihara value is outside our accepted range of 0 to 30';
export const ISHIHARA2_VALIDATION_MSG = 'Ishihara2 value is outside our accepted range of 0 to 30';
export const SPHERE_VALIDATION_MSG = 'Sphere value is outside our accepted range of -50.00 to 50.00';
export const CYLINDER_VALIDATION_MSG = 'Cylinder value is outside our accepted range of -9.5 to 0.00';
export const AXIS_VALIDATION_MSG = 'Axis value is outside our accepted range of 001 to 180';
export const DTC_HOUR_VALIDATION_MSG = 'DTC hour value is outside our accepted range of 7 to 18';

// Keyboard codes
export const ENTER_KEY = 13;

// OMDC PEI component titles
export const PATIENT_EXAM_HISTORY_MEDS_EXAMS_TITLE = 'History, Exam and Medications';

// OMDC Follow Up Options Component
export const OMDC_NOTE_TEMPLATES = 'omdc_note_templates';
export const FOLLOW_UP_OPTIONS_HEADING = 'OMDC Follow-Up Options';

// OMD Reviewer Notes Component
export const OMDR_REVIEWER_NOTES_HEADING = 'Private Notes to OD';
export const OMDR_REVIEWER_NOTES_HEADING_FOR_OMDC_PEI = 'OMD Reviewer Notes';

// OMD Notes to OD and GP
export const OMDR_NOTES_TO_OD_AND_GP_HEADING = 'Notes to OD and GP';

// OMD Consultant Notes
export const OMDC_NOTES_HEADING = 'OMD Consultant Notes';

export const PLAQUENIL_DOSE = 'plaquenil_dose';
export const PLAQUENIL_DATE = 'plaquenil_date';

export const SAME_REGION_OPTIONAL_TEXT = "The patient may benefit from an in-person appointment with an Ophthalmologist. The optometrist can make this decision with the patient, and contact Care1 to coordinate this if they agree.";
export const DIFF_REGION_OPTIONAL_TEXT = "The patient may benefit from an in-person appointment with an Ophthalmologist. The optometrist can make this decision with the patient, and send an in person referral to a local Ophthalmologist if they agree.";
export const SAME_REGION_NEEDED_TEXT = "This patient needs further evaluation and treatment in-person. My office will arrange an appointment.";
export const DIFF_REGION_NEEDED_TEXT = "This patient needs further evaluation and treatment in-person. Please send a referral to a local Ophthalmologist for an in-person consultation.";
export const NA_TEXT = "The patient does not need to see a retina specialist.";
export const RETINA_REFERRAL_PLACEHOLDER_TEXT = "Please enter retina referral related instructions.";

export const OUTBOUND_OMDR_ID = 459;

// Special rr_ diagnosis fields that have to be added individually into the diagnostics values entries on the front-end.
export const RR_BOOLEAN_DIAGNOSIS_FIELDS = [
    {
        value: DIAGNOSIS_DM,
        label: DIABETES_TEXT,
    },
    {
        value: DIAGNOSIS_AMD,
        label: AMD_TEXT,
    },
    {
        value: DIAGNOSIS_GLC,
        label: GLC_TEXT,
    },
    {
        value: DIAGNOSIS_GLC_S,
        label: GLC_S_TEXT,
    },
    {
        value: DIAGNOSIS_CATARACT,
        label: CATARACT_TEXT,
    },
    {
        value: DIAGNOSIS_CATARACT_POST_OP,
        label: CATARACT_POST_UP_TEXT,
    },
    {
        value: DIAGNOSIS_ERM,
        label: ERM_TEXT,
    },
    {
        value: DIAGNOSIS_NARROW_ANGLES,
        label: NARROW_ANGLES_TEXT,
    }
];

// Special rr_ diagnosis fields key array
export const RR_BOOLEAN_DIAGNOSIS_FIELDS_KEY_ARRAY = [DIAGNOSIS_DM, DIAGNOSIS_AMD, DIAGNOSIS_GLC,
    DIAGNOSIS_GLC_S, DIAGNOSIS_CATARACT, DIAGNOSIS_CATARACT_POST_OP, DIAGNOSIS_NARROW_ANGLES, DIAGNOSIS_ERM];

// The six diagnostic value fields
export const SIX_DIAGNOSTIC_VALUES_BOOLEAN_FIELDS = [
    {
        value: DIAGNOSIS_PED,
    },
    {
        value: DIAGNOSIS_MAC_HOLE,
    },
    {
        value: DIAGNOSIS_NEVUS,
    },
    {
        value: DIAGNOSIS_PLAQUENIL,
    },
    {
        value: DIAGNOSIS_RAO,
    },
    {
        value: DIAGNOSIS_VMT,
    },
];

export const EXAM_BEFORE_AUG2017 = 'exam_before_aug2017';
export const OMDC_REVIEW_DATE = 'omdc_review_date';

// Initiate Treatment Dialog selectable values.
export const TREATMENT_DROP_OPTIONS = [
    {
        value: 'prostaglandin',
        label: 'PG',
        is_default: true,
    },
    {
        value: 'betablocker',
        label: 'BB',
    },
    {
        value: 'pg_bb',
        label: 'PG-BB',
    },
];

export const TREATMENT_FOLLOW_UP_OPTIONS = [
    {
        value: '1-2d',
        label: '1-2d',
    },
    {
        value: '1-2w',
        label: '1-2w',
    },
    {
        value: '1m',
        label: '1m',
        is_default: true,
    },
    {
        value: '6m',
        label: '6m',
    },
];

export const TREATMENT_IOP_OPTIONS = [
    {
        value: '14',
        label: '14',
    },
    {
        value: '17',
        label: '17',
    },
    {
        value: '21',
        label: '21',
    },
    {
        value: '24',
        label: '24',
    },
    {
        value: 'custom',
        label: 'Custom',
    },
];

// Alternative drops for side effecs and not enough effects are restricted by the original drop selection.
// The key "requires_drop" indicates the original drop that had to be selected for this drop to be enabled.
export const SIDE_EFFECT_DROP_OPTIONS = [
    {
        value: 'different_pg',
        label: 'Different PG',
        required_drops: ['prostaglandin', 'betablocker'],
        is_default: true,
    },
    {
        value: 'pg_bb',
        label: 'PG-BB',
        required_drops: ['prostaglandin', 'betablocker'],
    },
    {
        value: 'different_pg_bb',
        label: 'Different PG-BB',
        required_drops: ['pg_bb'],
        is_default: true,
    },
    {
        value: 'pg_slt',
        label: 'PG + SLT',
        required_drops: ['pg_bb'],
    },
    {
        value: 'bb_cai',
        label: 'BB-CAI',
        required_drops: ['pg_bb'],
    },
];

export const ALTERNATE_TREATMENT_DROP_OPTIONS = [
    {
        value: 'pg_bb',
        label: 'PG-BB',
        required_drops: ['prostaglandin', 'betablocker'],
        is_default: true,
    },
    {
        value: 'add_aa',
        label: 'Add AA',
        required_drops: ['prostaglandin', 'betablocker'],
    },
    {
        value: 'add_cai',
        label: 'Add CAI',
        required_drops: ['prostaglandin', 'betablocker'],
    },
    {
        value: 'add_slt',
        label: 'Add SLT',
        required_drops: ['pg_bb'],
    },
    {
        value: 'switch_to_bb_cai_pg',
        label: 'Switch to BB-CAI + PG',
        required_drops: ['pg_bb'],
    },
    {
        value: 'add_sim',
        label: 'Add Simbrinza',
        required_drops: ['pg_bb'],
    },
    {
        value: 'offer_all',
        label: 'Offer All',
        required_drops: ['pg_bb'],
        is_default: true,
    },
];

export const ALTERNATE_TREATMENT_FOLLOW_UP_OPTIONS = [
    {
        value: '1-2d',
        label: '1-2d',
    },
    {
        value: '1-2w',
        label: '1-2w',
    },
    {
        value: '1m',
        label: '1m',
        is_default: true,
    },
    {
        value: 'custom',
        label: 'Custom',
    },
];

export const TREATMENT_SUCCESS_DROP_OPTIONS = [
    {
        value: 'same_gtts',
        label: 'Same Gtts',
        is_default: true,
    },
    {
        value: '1-2x/Wk',
        label: '1-2x/Wk',
    },
    {
        value: 'no_gtts',
        label: 'No Gtts',
    },
];

export const TREATMENT_SUCCESS_FOLLOW_UP_OPTIONS = [
    {
        value: '4m',
        label: '4m',
    },
    {
        value: '4-6m',
        label: '4-6m',
        is_default: true,
    },
    {
        value: '6m',
        label: '6m',
    },
    {
        value: '6-12m',
        label: '6-12m',
    },
    {
        value: '12m',
        label: '12m',
    },
    {
        value: 'custom',
        label: 'Custom',
    },
];

// The list of random sentence names to get from the backend.
export const TREATMENT_RANDOM_SENTENCES_LIST = [
    'start_drop_sentence', 'start_iop_zone_sentence', 'start_fu_sentence', 'side_effects_drop_sentence',
    'not_effective_drop_sentence', 'not_effective_fu_sentence', 'effective_drop_sentence',
    'effective_fu_sentence', 'refrigeration_or_artificial_tears_sentence', 'prostaglandin', 'betablocker',
    '1-2d', '1-2w', '1m', '6m', 'different_pg', 'pg_bb', 'different_pg_bb', 'pg_slt', 'bb_cai', 'add_aa',
    'add_cai', 'add_slt', 'switch_to_bb_cai_pg', 'offer_all', 'add_sim', 'same_gtts', '1-2x/Wk', 'no_gtts',
    '4m', '4-6m', '6-12m', '12m', 'slt_date_sentence', 'topical_date_sentence'
];

// Stereoviewer display width in pixels.
export const CSV_STEREO_VIEWER_WIDTH = 328;

// Helper text to tell the user to add an US insurance policy if there are none.
export const ADD_US_INSURANCE_POLICY_HELP_TEXT = 'Please add a new insurance policy. There are currently none.';

export const IOP_AIM_VALUES = ['XXX', '14', '17', '21', '24'];
export const PATIENT_STATUS = ['XXX', 'P', 'BO', 'BT'];
export const FOLLOW_UP_AIM = [{
    key: 'XXX',
    display: 'XXX',
},{
    key: '0.03-0.07',
    display: '1-2d',
}, {
    key: '0.25-0.5',
    display: '1-2w',
}, {
    key: '1',
    display: '1',
}, {
    key: '4',
    display: '4',
}, {
    key: '4-6',
    display: '4-6',
}, {
    key: '6',
    display: '6',
}, {
    key: '6-12',
    display: '6-12',
}, {
    key: '12',
    display: '12',
}];

export const VERBOSE_APPLANATION_OPTIONS = [
    {
        "value": "applanation",
        "label": "Applanation"
    },
    {
        "value": "tonopen",
        "label": "Tono-Pen"
    },
    {
        "value": "noncontact",
        "label": "Non-Contact"
    },
    {
        "value": "icare",
        "label": "iCare"
    },
    {
        "value": "other",
        "label": "Unknown"
    }
];

export const TERSE_APPLANATION_OPTIONS = [
    {
        "value": "applanation",
        "label": "App"
    },
    {
        "value": "tonopen",
        "label": "Tono"
    },
    {
        "value": "noncontact",
        "label": "NC"
    },
    {
        "value": "icare",
        "label": "Icare"
    },
    {
        "value": "other",
        "label": "Unknown"
    }
];

export const OMD_NOTE_TEMPLATE_DICT = {
    teleFuId: 15,
    gpLetterId: 11,
    gpInfoId: 28,
    omdFuId: 16,
    octId: 17,
    octVfId: 19,
    noSurgId: 5,
    issuesId: 1,
    catTmId: 6,
    glcBaseId: 20,
    alertId: 26,
    dmId: 12,
    agreeId: 4,
    sltId: 37,
    ohtsId: 9,
    reassuringId: 50,
    odReplyId: 61,
};

// The ID for the Alert OMD note.
export const ALERT_ID = 26;

export const OPTIONAL_ID = 59;
export const NEEDED_ID = 60;
export const ERM_SURGICAL_ID = 58;
export const CUSTOM_ID = 62;

const { teleFuId, gpLetterId, gpInfoId, omdFuId, octId, octVfId, noSurgId, catTmId, glcBaseId,
    alertId, dmId, agreeId, issuesId, sltId, ohtsId, reassuringId } = OMD_NOTE_TEMPLATE_DICT;

export const PRE_REVIEW_VALUE_ID_LIST = [alertId, agreeId, glcBaseId, catTmId, issuesId, sltId, ohtsId, gpLetterId,
    gpInfoId, noSurgId, dmId, octId, octVfId, teleFuId, omdFuId, reassuringId];

export const PRE_REVIEW_VALUE_BOOLEAN_LIST = ['od_question', 'od_req_omd', 'billing_addressed',
    'od_start_drops', 'cu_omd', 'past_omd', 'od_baseline','fhx', 'dhx_od', 'dhx_os', 'pxf_od',
    'pxf_os', 'pds_od', 'pds_os' ];

// Photo Swap Confirmation Modal
export const SWITCH_IMAGE_CONFIRMATION_MODAL_TITLE = 'Swap Image';
export const SWITCH_IMAGE_CONFIRMATION_MODAL_TEXT = 'Are you sure you want to swap the two selected images?';
export const SWITCH_IMAGE_CONFIRMATION_OK_TEXT = 'Yes';
export const SWITCH_IMAGE_CONFIRMATION_CANCEL_TEXT = 'No';
export const SWITCH_IMAGE_CONFIRMATION_TITLE = 'Swap Image';

export const PATIENTS_REQUIRING_REFERRALS_TEXT = 'PATIENTS REQUIRING REFERRALS';
export const PREVIOUSLY_REFERRED_PATIENTS_TEXT = 'PREVIOUSLY REFERRED PATIENTS';
export const REFER_BUTTON_TEXT = 'Refer Selected Patients';
export const REFERRAL_SUCCESS_TEXT = 'Selected patients have been successfully referred.';

export const GP_LETTERS_LABEL = {
    'omdrgp': 'OMDR-GP',
    'omdrgpenabled': 'OMDR-GP Retina Enabled',
    'omdcgp': 'OMDC-GP',
    'rluomdrgp': 'RLU OMDR-GP',
    'rluomdcgp': 'RLU OMDC-GP',
};

export const IGP_LETTERS_LABEL = {
    'omdrintgp': 'OMDR-Internal GP',
    'rluomdrintgp': 'RLU OMDR-Internal GP'
}

export const GLAUCOMA_SCORE_NUMBER_DECIMAL = 1;

// DeepMD Recommendation Text For User Types.
export type deepMDRecommendationTextByUser = {[key: string] : { [key: string]: any }};

export const DEEPMD_RECOMMENDATION_TEXT_BY_USER = {
    'OD': {
        'DISCUSS_PXF': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_PDS': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_CD_ASYM': {
            text: 'C/D asymmetry',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_CD_HIGHASYM': {
            text: 'C/D HIGH asymmetry',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_MIN_RNFL_THIN': {
            text: 'High risk (?structure-function damage correlation between OCT RNFL and VF)',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_SIG_RNFL_THIN': {
            text: 'High risk (?structure-function damage correlation between OCT RNFL and VF)',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_ASYM_VF_DISC': {
            text: 'High risk (?correlation between VF asymmetry and optic nerve asymmetry)',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_ASYM_VF_IOP': {
            text: 'High risk (?correlation between VF asymmetry and IOP asymmetry)',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_ASYM_DISC_IOP': {
            text: 'High risk (?correlation between optic nerve asymmetry and IOP asymmetry)',
            category: 'Analysis',
            color: 'recommendation-text-red',
        },
        'DISCUSS_HXDHX': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_LARGECD': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_VLARGECD': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_LARGECD_THICKRNFL': {
            text: 'Mismatch (large C/D and thick OCT)',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_SMALLCD_THINRNFL': {
            text: 'Mismatch (small C/D and thin OCT)',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_ADVANCEAGE': {
            text: 'Reassuring (very advanced age, without extreme glaucoma features)',
            category: 'Analysis',
            color: 'recommendation-text-green'
        },
        'DISCUSS_OLD_AGE': {
            text: 'Reassuring (advanced age, without extreme glaucoma features)',
            category: 'Analysis',
            color: 'recommendation-text-green'
        },
        'DISCUSS_YOUNG_AGE': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_FUNDUS': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_OMDR_OD_CD_DIFF': {
            text: 'C/D asessed differently by external consultant',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_HIGHIOP_FU': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND': {
            text: 'Extra caution (monocular patient)',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_LARGECD_GLCBASE': {
            text: 'Baseline testing (3 visits with IOP, OCT, VF) recommended',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_BLURRY_VA': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_WORSENING_VA': {
            text: 'Extra caution (?vision is worsening)',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_IOP_ABV_COMFORT': {
            text: 'IOP slightly too high',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_IOP_SIG_ABV_COMFORT': {
            text: 'IOP significantly too high',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_MISS_FU': {
            text: 'Patient may have missed follow-up',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_SIG_MISS_FU': {
            text: 'Patient may have missed follow-up',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_OBSERVE': {
            text: 'Observation is recommended.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_OBSERVE_BO': {
            text: 'No treatment recommended',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_OPTION_BO': {
            text: 'Patient can decide on treatment vs. observation. Concerning feature is IOP:disc ratio.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_BT': {
            text: 'Potentially treat. Concerning feature is IOP:disc ratio.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_OPTION_REC_TX_BT': {
            text: 'Treatment probably recommended, patient can decide. Concerning feature is IOP:disc ratio.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_2WKS_BO': {
            text: 'Fast baseline testing (IOP, OCT and VF) q2wks x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_OPTION_REC_TX_BT_LOW_AGE': {
            text: 'Treatment probably recommended, patient can decide',
            category: 'Plan',
            color: 'recommendation-text-red'
        },
        'DISCUSS_FAST_BASELINE_2WKS_BT': {
            text: 'Fast baseline testing (IOP, OCT and VF) q2wks x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_TREAT_BT': {
            text: 'Treatment recommended',
            category: 'Plan',
            color: 'recommendation-text-red'
        },
        'DISCUSS_BASELINE_1D_BT': {
            text: 'Immediate baseline testing (IOP, OCT and VF) q1day x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-red'
        },
        'DISCUSS_PT_OPTION': {
            text: 'Patient can decide on treatment vs. observation.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_OBSERVE_BO_LOW_AGE': {
            text: 'Observation is recommended. Concerning feature is IOP:disc ratio and younger age.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_1MO_BO_LOW_AGE': {
            text: 'Fast baseline testing (IOP, OCT and VF) q1month x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_1MO_BT': {
            text: 'Fast baseline testing (IOP, OCT and VF) q1month x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_MANUAL_REVIEW_BO': {
            text: 'Unable to decide treatment vs observation',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_MANUAL_REVIEW_PT_OPTION_BO': {
            text: 'Unable to decide treatment vs observation. Patient can decide on treatment vs. observation. Concerning feature is IOP:disc ratio.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_BO_HIGH_AGE': {
            text: 'Borderline clinical features, follow closely',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_2WKS_BO_HIGH_AGE': {
            text: 'Fast baseline testing (IOP, OCT and VF) q2wks x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_PT_OPTION_REC_TX_BO_HIGH_AGE': {
            text: 'Treatment probably recommended, patient can decide',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_1WK_BT': {
            text: 'Fast baseline testing (IOP, OCT and VF) q1wk x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_BO': {
            text: 'Concerning feature is IOP:disc ratio.',
            category: 'Analysis',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_MANUAL_REVIEW_BT': {
            text: 'Unable to decide treatment vs. observation. Treatment probably recommended. Concerning feature is IOP:disc ratio.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_FAST_BASELINE_1D_BT': {
            text: 'Fast baseline testing (IOP, OCT and VF) q1day x 3, if not done already',
            category: 'Baseline',
            color: 'recommendation-text-blue'
        },
        'DISCUSS_OD_BASELINE': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_PT_ON_GTTS': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_PHOTO_OS': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_PHOTO_OD': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OD': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OS': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OU': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_MONOCULAR_EYE': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_RECOMMEND_APPLICATION': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_BASELINE': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_IOP': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_HIGH_IOP': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_BO_BT': {
            text: '',
            category: '',
            color: '',
        },
        'DISCUSS_RECOMMEND_APPLICATION_DISCUSSION': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_BASELINE': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_IOP': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_HIGH_IOP': {
            text: '',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_BO_BT': {
            text: '',
            category: '',
            color: '',
        },
        'OHT': {
            text: '',
            category: '',
            color: '',
        },

        // The following are from the various layers of OD/MD/OMD comparisons.
        'COMPARISON_WARNING_OD_PT_NOT_SOON': {
            text: 'Consider closer follow-ups.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'COMPARISON_WARNING_OD_PT_SIGNIFICANT_NOT_SOON': {
            text: 'Consider closer follow-ups.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'COMPARISON_DISCUSS_OD_PT_BIT_SOONER': {
            text: 'Consider closer follow-ups.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'COMPARISON_DISCUSS_OD_PT_LOT_SOONER': {
            text: 'Consider closer follow-ups.',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'COMPARISON_PT_BASELINE_NOT_RECOMMENDED': {
            text: '',
            category: '',
            color: '',
        },

        // The following are values are already keys. The OMD will just display them directly,
        // so the OMD section won't have these.
        '?PP GLC': {
            text: '?Pre-perimetric glaucoma',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
        'PP GLC': {
            text: 'Pre-perimetric glaucoma',
            category: 'Plan',
            color: 'recommendation-text-blue'
        },
    },
    'OMD': {
        'DISCUSS_PXF': {
            text: 'PXF',
            category: '',
            color: '',
        },
        'DISCUSS_PDS': {
            text: 'PDS',
            category: '',
            color: '',
        },
        'DISCUSS_CD_ASYM': {
            text: 'C/D Asymmetry',
            category: '',
            color: '',
        },
        'DISCUSS_CD_HIGHASYM': {
            text: 'C/D High Asymmetry',
            category: '',
            color: '',
        },
        'DISCUSS_MIN_RNFL_THIN': {
            text: 'Asymmetry Correlation (minor, hemi-OCT and hemi-VF)',
            category: '',
            color: '',
        },
        'DISCUSS_SIG_RNFL_THIN': {
            text: 'Asymmetry Correlation (significant, hemi-OCT and hemi-VF)',
            category: '',
            color: '',
        },
        'DISCUSS_ASYM_VF_DISC': {
            text: 'Asymmetry Correlation (VF and Disc)',
            category: '',
            color: '',
        },
        'DISCUSS_ASYM_VF_IOP': {
            text: 'Asymmetry Correlation (VF and IOP)',
            category: '',
            color: '',
        },
        'DISCUSS_ASYM_DISC_IOP': {
            text: 'Asymmetry Correlation (Disc and IOP)',
            category: '',
            color: '',
        },
        'DISCUSS_HXDHX': {
            text: 'Hx Drance Heme',
            category: '',
            color: '',
        },
        'DISCUSS_LARGECD': {
            text: 'Large C/D (0.8)',
            category: '',
            color: '',
        },
        'DISCUSS_VLARGECD': {
            text: 'Very Large C/D (0.9)',
            category: '',
            color: '',
        },
        'DISCUSS_LARGECD_THICKRNFL': {
            text: 'Mismatch: Large C/D, Thick OCT',
            category: '',
            color: '',
        },
        'DISCUSS_SMALLCD_THINRNFL': {
            text: 'Mismatch: Small C/D, Thin OCT',
            category: '',
            color: '',
        },
        'DISCUSS_ADVANCEAGE': {
            text: 'Age >=90 w/o Extreme Suspicion',
            category: '',
            color: '',
        },
        'DISCUSS_OLD_AGE': {
            text: 'Age >=75 w/o Extreme Suspicion',
            category: '',
            color: '',
        },
        'DISCUSS_YOUNG_AGE': {
            text: 'Age <10 w/o Extreme Suspicion',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_FUNDUS': {
            text: 'Retake photos.',
            category: '',
            color: '',
        },
        'DISCUSS_OMDR_OD_CD_DIFF': {
            text: 'OMDR-OD C/D 0.3 Difference',
            category: '',
            color: '',
        },
        'DISCUSS_HIGHIOP_FU': {
            text: 'High IOP',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND': {
            text: 'Blind',
            category: '',
            color: '',
        },
        'DISCUSS_LARGECD_GLCBASE': {
            text: 'Rec Baseline (C/D>=0.8)',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_VA': {
            text: 'Blurry VA',
            category: '',
            color: '',
        },
        'DISCUSS_WORSENING_VA': {
            text: 'Worsening VA',
            category: '',
            color: '',
        },
        'DISCUSS_IOP_ABV_COMFORT': {
            text: 'IOP slightly above Comfort Zone',
            category: '',
            color: '',
        },
        'DISCUSS_IOP_SIG_ABV_COMFORT': {
            text: 'Patient SIGNIFICANTLY above IOP comfort zone',
            category: '',
            color: '',
        },
        'DISCUSS_PT_MISS_FU': {
            text: 'Pt Missed F/U',
            category: '',
            color: '',
        },
        'DISCUSS_PT_SIG_MISS_FU': {
            text: 'Pt SIGNIFICANTLY Missed F/U',
            category: '',
            color: '',
        },
        'DISCUSS_OBSERVE': {
            text: 'Observe',
            category: '',
            color: '',
        },
        'DISCUSS_OBSERVE_BO': {
            text: 'Observe, BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_PT_OPTION_BO': {
            text: 'PT OPTION, BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_BT': {
            text: 'BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_PT_OPTION_REC_TX_BT': {
            text: 'PT OPTION, REC TX, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_2WKS_BO': {
            text: 'FAST Baseline Q2wks, BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_PT_OPTION_REC_TX_BT_LOW_AGE': {
            text: 'PT OPTION, REC TX, BT (IOP:disc and Age <= 65)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_2WKS_BT': {
            text: 'FAST Baseline Q2wks, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_TREAT_BT': {
            text: 'TREAT, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_BASELINE_1D_BT': {
            text: 'IMMEDIATE Baseline Q1d, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_PT_OPTION': {
            text: 'PT OPTION',
            category: '',
            color: '',
        },
        'DISCUSS_OBSERVE_BO_LOW_AGE': {
            text: 'Observe, BO (IOP:disc and Age <= 65)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_1MO_BO_LOW_AGE': {
            text: 'FAST Baseline Q1mo, BO (IOP:disc and Age <= 65)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_1MO_BT': {
            text: 'FAST Baseline Q1mo, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_MANUAL_REVIEW_BO': {
            text: 'MANUAL REVIEW, BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_MANUAL_REVIEW_PT_OPTION_BO': {
            text: 'MANUAL REVIEW, PT OPTION, BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_BO_HIGH_AGE': {
            text: 'BO (IOP:disc and Age >= 85)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_2WKS_BO_HIGH_AGE': {
            text: 'FAST Baseline Q2wks, BO (IOP:disc and Age >= 85)',
            category: '',
            color: '',
        },
        'DISCUSS_PT_OPTION_REC_TX_BO_HIGH_AGE': {
            text: 'PT OPTION, REC TX, BO (IOP:disc and Age >= 85)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_1WK_BT': {
            text: 'FAST Baseline Q1wk, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_BO': {
            text: 'BO (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_MANUAL_REVIEW_BT': {
            text: 'MANUAL REVIEW, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_FAST_BASELINE_1D_BT': {
            text: 'FAST Baseline Q1d, BT (IOP:disc)',
            category: '',
            color: '',
        },
        'DISCUSS_OD_BASELINE': {
            text: 'OD Wants Baseline',
            category: '',
            color: '',
        },
        'DISCUSS_PT_ON_GTTS': {
            text: 'Pt Already On Gtts',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_PHOTO_OS': {
            text: 'Blurry Photo OS',
            category: '',
            color: '',
        },
        'DISCUSS_BLURRY_PHOTO_OD': {
            text: 'Blurry Photo OD',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OD': {
            text: 'Blind OD.',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OS': {
            text: 'Blind OS.',
            category: '',
            color: '',
        },
        'DISCUSS_BLIND_OU': {
            text: 'Blind OU',
            category: '',
            color: '',
        },
        'DISCUSS_MONOCULAR_EYE': {
            text: 'Extra Caution for Monocular Eye',
            category: '',
            color: '',
        },
        'DISCUSS_RECOMMEND_APPLICATION': {
            text: 'Recommend Applanation bc ',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_BASELINE': {
            text: 'we\'re doing a baseline',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_IOP': {
            text: 'IOP>=24',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_HIGH_IOP': {
            text: 'IOP is above comfort zone',
            category: '',
            color: '',
        },
        'APPLANATION_RECOMMEND_REASON_BO_BT': {
            text: 'DeepMD Class is BO or BT',
            category: '',
            color: '',
        },
        'DISCUSS_RECOMMEND_APPLICATION_DISCUSSION': {
            text: 'Recommend Applanation',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_BASELINE': {
            text: '(for baseline testing)',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_IOP': {
            text: '(IOP>=24)',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_HIGH_IOP': {
            text: '(IOP > comfort zone)',
            category: '',
            color: '',
        },
        'APPLANATION_DISCUSS_REASON_BO_BT': {
            text: '(Class BO and BT)',
            category: '',
            color: '',
        },
        'OHT': {
            text: 'OHT',
            category: '',
            color: '',
        },

        // The following are from the various layers of OD/MD/OMD comparisons.
        'COMPARISON_WARNING_OD_PT_NOT_SOON': {
            text: 'OD not seeing patient soon enough.',
            category: '',
            color: '',
        },
        'COMPARISON_WARNING_OD_PT_SIGNIFICANT_NOT_SOON': {
            text: 'OD SIGNIFICANTLY not seeing patient soon enough.',
            category: '',
            color: '',
        },
        'COMPARISON_DISCUSS_OD_PT_BIT_SOONER': {
            text: 'OD should see Pt a bit sooner',
            category: '',
            color: '',
        },
        'COMPARISON_DISCUSS_OD_PT_LOT_SOONER': {
            text: 'OD should see Pt a lot sooner',
            category: '',
            color: '',
        },
        'COMPARISON_PT_BASELINE_NOT_RECOMMENDED': {
            text: 'Patient should get baseline, but OD did not recommend',
            category: '',
            color: '',
        },
    }
} as deepMDRecommendationTextByUser;

// Admin referral letter viewer Scale related fields
export const REFERRAL_LETTER_VIEWER_DEFAULT_SCALE = 1.0, // Default scale of the PDF document, 1 is the original size
    REFERRAL_LETTER_VIEWER_MAX_SCALE = 3.0, // Cannot zoom in more if the current scale is over this max scale
    REFERRAL_LETTER_VIEWER_MIN_SCALE = 1.0, // Cannot zoom out more if the current scale is under this min scale
    REFERRAL_LETTER_VIEWER_SCALE_MULTIPLIER = 1.2; // Current scale is multiplied/divided by this multiplier value when zooming in/out.

    export const CARE1_UPLOAD_TEXT = 'A Care1 upload will keep patients in your clinic. If an outbound referral to an OMD is recommended, we will assist in coordinating an in-person visit.';
    export const OUTBOUNT_REFERRAL_TEXT = 'The uploaded information will be e-faxed to the specified local OMD’s office. The local OMD will contact your patient or your office directly about an appointment.';

export const GLAUCOMA_PROGRAM_NEXT_SUGGESTED_VISIT_OPTIONS = [
    {
        key: 'past_due',
        label: 'Past Due',
    },
    {
        key: 'within_2months',
        label: 'Within 2 Months',
    },
    {
        key: 'within_6months',
        label: 'Within 6 Months',
    },
    {
        key: 'within_12months',
        label: 'Within 12 Months',
    }
];
