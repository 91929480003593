import { useCallback, useEffect, useState, useRef } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Flex, Layout, Row, Spin, TabPaneProps, Tabs, Tag } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';
import * as Constants from '../constants';

// Components
import { FormValidationProvider } from '../context/useFormValidationContext';
import ExamTabs from '../components/exam-tabs';
import HelpDeskModal from '../components/retina/help-desk-modal';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PatientDetailsModal from '../components/retina/patient-details-modal';
import PatientEditModal from '../components/retina/patient-edit-modal';

import PatientExamPreviousVisits from '../components/patient-exam-previous-visits';

import AdminAIInsights from '../components/admin/admin-ai-insights';
import AdminMaculaAbnormality from '../components/admin/admin-macula-abnormality';
import AdminPrereviewStopwatch from '../components/admin/admin-prereview-stopwatch';

import LatencyMetrics, { LatencyMetricsHandle } from '../latency-metrics';

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/patient-exam-re-bcva.scss';

// Actions
import AdminLetterModal from '../components/admin/admin-letter-modal';
import PlaquenilModal from '../components/integrated/plaquenil-modal';
import PatientInsuranceModal from '../components/integrated/us/patient-insurance-modal';
import ExamDateSelectModal from '../components/retina/exam-date-select-modal';
import { editExamRequest } from '../reducers/patient-exam-slice';
import AdminExamMetricsInfoModal from '../components/admin/admin-exam-metrics-info-modal';
import ADMINLegacyPatientExamPage from './admin-legacy-exam-page';
import ADMINReferralLetterExamPage from './admin-referral-letter-exam-page';
import ADMINStandardReferralLetterExamPage from './admin-standard-referral-letter-exam-page';
import GlaucomaWorksheet from '../components/glaucoma-worksheet';
import GptTabs from '../components/admin/gpt/gpt-tabs';
import type { TabsProps } from 'antd';

const { Header, Content } = Layout;

const ADMINPatientExamPage = () => {

    const latencyMetricsRef = useRef<LatencyMetricsHandle>(null);
    if (latencyMetricsRef.current) {
        latencyMetricsRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "ADMINPatientExamPage::trigger");
    }

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const isAdminLetterModalVisible = useCare1AppSelector(store => store.letter.letterModalOpen);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const smartUploadId = useCare1AppSelector(store => store.examData.smart_upload_id);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab');

    const defaultTab = tabParam !== null && tabParam === 'gpt-vision' ? 'item-gpt' : 'item-pei';
    const [activeKey, setActiveKey] = useState(defaultTab);

    const examId = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
    
    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        if (examId !== null) {
            if (latencyMetricsRef.current) {
                latencyMetricsRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "ADMINPatientExamPage::editExam");            
            }
            dispatch(editExamRequest(examId, history));
        }
    }, [examId, history, dispatch])

    useEffect(() => {
        editExam();
    }, [editExam])

    useEffect(() => {
        if (latencyMetricsRef.current) {
            latencyMetricsRef.current.startTimerCheck(!examOperating && !patientDetailsOperating);
        }
    }, [examOperating, patientDetailsOperating])

    const getLatenctMetricsName = () => {
        return isReferralLetterUploadPEI ? (rluExamPremium ? 'admin_rlu_premium_pei_load' : 'admin_rlu_standard_pei_load') : 'admin_legacy_pei_load';
    }

    const getTabOrginal = () => {
        return isReferralLetterUploadPEI ?
            (rluExamPremium ? <ADMINReferralLetterExamPage /> :
                <ADMINStandardReferralLetterExamPage />) :
            <ADMINLegacyPatientExamPage />
    }

    const getTabAI = () => {

        return <>
            <AdminMaculaAbnormality />
        </>;
    }

    const getTabAIInsights = () => {

        return <>
            <AdminAIInsights />
        </>;
    }

    const getTabGlaucomaWorksheet = () => {

        return <>
            <GlaucomaWorksheet />
        </>;
    }

    const getTabGPT = () => {
        return <><GptTabs /></>
    }

    const tabs: TabsProps['items'] = [{
        label: 'PEI', key: 'item-pei',
        children: getTabOrginal()
    }];

    tabs.push({
        label: 'Retina Worksheet', key: 'item-ai-insights',
        children: getTabAIInsights()
    });

    if ((isReferralLetterUploadPEI && examStatus !== 'not_ready') || (examStatus !== 'od_review' && examStatus !== 'not_ready'))
        tabs.push({
            label: 'Glaucoma Worksheet', key: 'item-glaucoma-worksheet',
            children: getTabGlaucomaWorksheet(),
        });

    tabs.push({
        label: 'AI Analytics', key: 'item-ai-analytics',
        children: getTabAI()
    });
    tabs.push({
        label: 'GPT', key: 'item-gpt',
        children: getTabGPT()
    });

    tabs.push({
        label: 'Upload Reconciler',
        key: 'item-upload-reconciler',
        disabled: !Boolean(smartUploadId),
        children: <></>,
    })

    return (
        <Layout>
            {getLatenctMetricsName() === 'admin_rlu_premium_pei_load' && 
                <LatencyMetrics key={getLatenctMetricsName()} 
                    ref={latencyMetricsRef} 
                    examId={`${examId}`} 
                    metricsName={getLatenctMetricsName()} 
                    timerMs={5000} 
                    consloeLog={true} 
                />
            }
            {getLatenctMetricsName() === 'admin_rlu_standard_pei_load' && 
                <LatencyMetrics key={getLatenctMetricsName()} 
                    ref={latencyMetricsRef} 
                    examId={`${examId}`} 
                    metricsName={getLatenctMetricsName()} 
                    timerMs={5000} 
                    consloeLog={true} 
                />
            }
            {showDetails && <PatientDetailsModal />}
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar
                        className="admin-pei-navigation-control-bar"
                        breadcrumbItems={[{ key: 1, component: <PatientExamPreviousVisits /> }]}
                    />
                </Header>
                <Content>
                    <PatientEditModal />
                    <AdminExamMetricsInfoModal />
                    <HelpDeskModal />
                    <ExamTabs />
                    {isAdminLetterModalVisible && <AdminLetterModal />}
                    <PlaquenilModal />
                    <PatientInsuranceModal />
                    <ExamDateSelectModal />
                    <FormValidationProvider>
                        <div className={`admin-patient-exam-page${examIsRE ? ' exam-is-re' : ' exam-is-ic'}${isReferralLetterUploadPEI ? rluExamPremium ? ' exam-is-rlu' : ' exam-is-rlu standard-referral-letter-pei' : ' exam-is-legacy'}`}>
                            {!(examOperating || patientDetailsOperating) &&
                                <Tabs
                                    defaultActiveKey={defaultTab}
                                    activeKey={activeKey}
                                    items={tabs}
                                    tabBarExtraContent={{
                                        right: <Flex gap='small'>
                                            {smartUploadId && <Tag color="#FFFF00" style={{color: 'black'}}>Smart Upload</Tag>}
                                            {isOutboundReferral && <Tag color="#FFFF00" style={{color: 'black'}}>Outbound Referral</Tag>}
                                            <span className='exam-is-od-messaging-label'>{examIsODMessaging ? 'OD Messaging' : ''}</span>
                                            <AdminPrereviewStopwatch />
                                        </Flex>
                                    }} 
                                    onTabClick={(key: string, e: React.KeyboardEvent | React.MouseEvent) => {
                                        if (key === 'item-upload-reconciler') {
                                            window.open(`/upload-reconciler/${smartUploadId ?? ''}`, '_blank')?.focus();
                                        }
                                        else {
                                            setActiveKey(key);
                                        }
                                    }}
                                />
                            }
                            <Row>
                                <br />
                                <br />
                            </Row>
                        </div>
                    </FormValidationProvider>
                </Content>
            </Spin>
        </Layout>
    );
}

export default ADMINPatientExamPage;