import { Button } from 'antd';

import dayjs from 'dayjs';
import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector, } from '../../../apps/care1-hooks';
import { setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import { setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';

const DeleteButton = () => {
    const status = useCare1AppSelector((store) => store.smartUpload.status);
    const adminReconciliationNotes = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciliation_notes
    );

    const adminReconciler = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciler
    );

    const dispatch = useCare1AppDispatch();

    const handleDeleteButtonClick = () => {
        console.log(dayjs('2024-11-14'));
        const currentDate = dayjs().format('YYYY/MM/DD');
        const deleteMessage = `${adminReconciliationNotes} ${currentDate} ${adminReconciler} - Upload`;
        if (status === 'deleted') {
            // Button Text is 'Undelete'
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'status',
                    value: 'submitted',
                })
            );

            dispatch(
                setUploadReconcilerSliceDataValue({
                    key: 'admin_reconciliation_notes',
                    value: `${deleteMessage} undeleted`,
                })
            );
        } else {
            // Button Text is 'Delete'
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'status',
                    value: 'deleted',
                })
            );

            dispatch(
                setUploadReconcilerSliceDataValue({
                    key: 'admin_reconciliation_notes',
                    value: `${deleteMessage} deleted`,
                })
            );
        }
    };

    return (
        <Button type='primary' danger onClick={handleDeleteButtonClick}>
            {status === 'deleted' ? 'Undelete' : 'Delete'}
        </Button>
    );
};

export default DeleteButton;
