import React, { Fragment, useEffect, useState } from 'react';
import { Card, Checkbox, Row, Form, Modal, Col } from 'antd';
import GptPatientExamUntilYesterdayEntry from './gpt-patient-exam-until-yesterday-entry';
import { getUntilYesterdayValues, getUntilYesterdayValuesRlu, isUntilYesterdayValueAllEmpty } from '../../../helpers/patient-exam-convert';
import DivTagInput from '../../integrated/div-tag-input';
import * as Constants from '../../../constants';
import { useFormValidation } from '../../../context/useFormValidationContext';

import '../../../../static/css/components/patient-exam-until-yesterday.scss';
import { localizedText } from '../../../localizedText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IUntilYesterdayItem } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import GptPatientExamUntilYesterdayEntryReferralLetter from './gpt-patient-exam-until-yesterday-entry-referral-letter';
import { setGptExamDataValues } from '../../../reducers/gpt-patient-exam-slice';

const FormItem = Form.Item;

type ComponentProps = {
    gptResultState: { [x: string]: string };
}

const GptPatientExamUntilYesterday = ({ gptResultState }: ComponentProps) => {

    const placeholder = '';
    const disabled = false;

    const { PATIENT_EXAM_UNTIL_YESTERDAY, UNTIL_YESTERDAY_TITLE, NO_GLC_MEDS, ENTRY_REQUIRED } = localizedText;
    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const untilYesterday = useCare1AppSelector(store => store.gptExamData.until_yesterday);
    const [showUntilYesterday, setShowUntilYesterday] = useState(false);
    const [untilYesterdayChecked, setUntilYesterdayChecked] = useState(false);

    const glcDropsList = useCare1AppSelector(store => store.options.glcDropsList);
    const glcComplianceList = useCare1AppSelector(store => store.options.glcComplianceList);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const inReferralLetterPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamIsPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    const setUntilYesterday = (untilYesterdayLocal: { values: IUntilYesterdayItem[] }) => {
        dispatch(setGptExamDataValues({ until_yesterday: { show: showUntilYesterday, values: untilYesterdayLocal.values } }));
    }

    const getGlcDropOptions = () => {
        let options = glcDropsList;
        if (rluExamIsPremium) {
            options = options.concat([['brimonidine_tim', 'brimonidine/timolol'], ['zimed', 'Zimed']]);
        }
        return options;
    }

    const glcDropOptions = getGlcDropOptions();

    const toggleUntilYesterdayEntries = () => {
        setShowUntilYesterday(!showUntilYesterday);
    }

    const deleteUntilYesterdayEntry = (idx: number) => {
        const untilYesterdayLocal = { ...untilYesterday };
        untilYesterdayLocal.values = untilYesterdayLocal.values.filter((_, index) => index !== idx);
        setUntilYesterday(untilYesterdayLocal);
    }

    const closeUntilYesterdayEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const untilYesterdayValues = untilYesterday.values ? untilYesterday.values : [];

        let isRowCompleted = true;
        let selectedRow: null | number = null;

        if (inReferralLetterPei) {
            untilYesterdayValues.forEach((entry, index) => {
                if (entry.glc_past_drops_select === '' && entry.glc_past_drops_eye_select !== '') {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        }
        else {
            // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
            // a row are empty, it is considered a complete row
            untilYesterdayValues.forEach((entry, index) => {
                if (entry.glc_past_drops_select === '' &&
                    (entry.glc_past_drops_eye_select !== '' || entry.glc_past_drops_compliance_select !== '')) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });

        }

        if (!isRowCompleted) {
            Modal.confirm({
                className: 'div-tag-row-incomplete-modal',
                title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                content: INCOMPLETE_ROW_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: INCOMPLETE_ROW_OK_TEXT,
                cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => {
                    deleteUntilYesterdayEntry(selectedRow!);
                    toggleUntilYesterdayEntries();
                },
            });
        } else {
            toggleUntilYesterdayEntries();
        }
    }

    const isUntilYesterdayRequired = (v: { values: IUntilYesterdayItem[] }) => {
        // At least one entry is required if same no GLC meds is not selected.
        // Having only 1 Entry and that entry is empty means there is not one complete entry selected..
        return v.values.length === 1 &&
            (Object.values(v.values[0]).some(str => str === ''));
    }

    const editUntilYesterdayEntry = (params: any) => {
        const { editIndex, field, value } = params;
        if (editIndex >= 0 && field) {
            const untilYesterdayLocal = untilYesterday.values.map(
                (untilYesterdayItem, index) =>
                    index === editIndex ? {
                        ...untilYesterdayItem,
                        [field]: value,
                        disabled: false,
                    } : untilYesterdayItem
            )

            const untilYesterdayFixed = untilYesterdayLocal.filter(entry => !isUntilYesterdayValueAllEmpty(entry));
            if (inReferralLetterPei) {
                untilYesterdayFixed.push({
                    glc_past_drops_eye_select: '',
                    glc_past_drops_select: '',
                })
            } else {
                untilYesterdayFixed.push({
                    glc_past_drops_eye_select: '',
                    glc_past_drops_select: '',
                    glc_past_drops_compliance_select: '',
                })
            }
            setUntilYesterday({ values: untilYesterdayFixed });
        }
    }

    const getValuesForDivTagInput = () => {
        const valuesLocal = inReferralLetterPei ? getUntilYesterdayValuesRlu(untilYesterday.values, glcDropOptions)
            : getUntilYesterdayValues(untilYesterday.values, glcDropOptions, glcComplianceList);

        return valuesLocal;
    }

    const getValues = () => {
        return untilYesterday.values;
    }

    const getEntries = () => {
        return getValues().map((entry, index) => {

            return inReferralLetterPei ? (
                <GptPatientExamUntilYesterdayEntryReferralLetter
                    eyeTypes={eyeTypes}
                    index={index}
                    data={entry}
                    glcDropsList={glcDropOptions}
                    size={"small"}
                    key={index}
                    editUntilYesterdayEntry={editUntilYesterdayEntry}
                    deleteUntilYesterdayEntry={deleteUntilYesterdayEntry}
                />
            ) : (
                <GptPatientExamUntilYesterdayEntry
                    eyeTypes={eyeTypes}
                    index={index}
                    data={entry}
                    glcDropsList={glcDropOptions}
                    glcComplianceList={glcComplianceList}
                    size={"small"}
                    key={index}
                    editUntilYesterdayEntry={editUntilYesterdayEntry}
                    deleteUntilYesterdayEntry={deleteUntilYesterdayEntry}
                />
            );
        });
    }

    const required = isUntilYesterdayRequired(untilYesterday);

    // Do not display the subtitle if there is a validation error. This provides space for the error message.
    const label =
        <span data-testid='heading' className={'heading'}>
            {UNTIL_YESTERDAY_TITLE}
            {!required && <span className='sub-title'>Pt Was Taking</span>}
        </span>

    return (
        <Card bordered={false} className={'patient-exam-until-yesterday gpt-patient-exam-until-yesterday'}>
            {/* UNTIL-YEST 001 */}
            <Fragment>
                <Row className={'heading-row'}>
                    <Col span={16}>
                        <div className="heading">{label}</div>
                    </Col>
                    <Col span={8}>
                        <Checkbox
                            checked={untilYesterdayChecked}
                            onChange={(e) => setUntilYesterdayChecked(e.target.checked)}
                            disabled={disabled}
                            data-testid='no-glc-meds'
                        >
                            {NO_GLC_MEDS}
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={disabled ? 'div-tag-disabled' : ''}>
                    <div className={`${gptResultState["omdHistoryValues"]}`}>
                        {/* UNTIL-YEST 002, UNTIL-YEST 003, UNTIL-YEST 004 */}
                        <DivTagInput
                            disabled={untilYesterdayChecked || disabled}
                            placeholder={placeholder}
                            onClick={() => {
                                toggleUntilYesterdayEntries();
                            }}
                            values={getValuesForDivTagInput()}
                            drawer={!untilYesterdayChecked && showUntilYesterday}
                            onClose={() => {
                                closeUntilYesterdayEntries();
                            }}
                            entries={getEntries()}
                            emptyValue={Constants.UNTIL_YESTERDAY_EMPTY_VALUE}
                            required={required}
                        />
                    </div>
                </Row>
            </Fragment>
        </Card>
    );
}

export default GptPatientExamUntilYesterday;
