import React from 'react';
import { Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const items = [
    { label: 'Reviewed', key: 'omdr_reviewed' },
    { label: 'Pre-reviewed', key: 'pre-reviewed' },
    { label: 'OMD Ready', key: 'omd_ready' },
    { label: 'OD To Review', key: 'od_to_review' },
    { label: 'Submitted Date', key: 'submitted_date' },
    { label: 'Exam Visit Date', key: 'exam_date' },
];

interface DropdownProps {
    onSelect: (value: string) => void;
    defaultSelectedKey: string;
    selectedKey: string;
}

const SalesIntegrationMetricsFilter: React.FC<DropdownProps> = ({ onSelect, defaultSelectedKey, selectedKey }) => {

    const handleMenuClick = (value: string): void => {
        onSelect(value);
    }

    return (
        <div>
            <Select
                className="dropdown-filter dropdown-filter-exam-metrics-filter"
                popupClassName="patient-list-dropdown"
                defaultValue={defaultSelectedKey}
                onSelect={handleMenuClick}
                value={selectedKey}
            >
                {items && items.map((option) => (
                    <Select.Option key={option.key} value={option.key}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip
                className='sales-report-filter-tooltip'
                placement='right'
                overlayInnerStyle={{width: "530px", maxHeight: '60vh', overflowY: 'scroll', marginTop: '32px'}}
                title={
                    <div className='sales-page-tooltip-div'>
                        Reviewed:
                        <ul>
                            <li>This will count the number of exams uploaded by each OD that has been <b>reviewed by an OMDR</b> each week.</li>
                            <li>This number should be stable over time.</li>
                        </ul>
                        Pre-reviewed:
                        <ul>
                            <li>This will count the number of exams uploaded by each OD that has been <b>pre-reviewed</b> each week.</li>
                            <li>This number should be stable over time.</li>
                            <li>This represents the number of <b>uploads processed by admin</b> for all optometrists each week.</li>
                            <li>This number will likely be the same or very similar to the <b>OMD Ready</b> filter for optometrists using the <b>Referral Letter Upload Interface</b>. There may be some variation due to the end point that is being used to calculate the two different filters. If in doubt, this number should be used over the OMD Ready filter <b>if the goal is to know when the admin has processed an upload</b>.</li>
                        </ul>
                        OMD Ready:
                        <ul>
                            <li>This will count the number of exams uploaded by each OD that has been set to Ready for OMD each week.</li>
                            <li>This number should be stable over time.</li>
                            <li>This represents the number of exams <b>uploaded to Care1 by optometrists</b> using the <b>Original Upload Interface</b> each week.</li>
                            <li>This represents the number of <b>uploads processed by admin</b> for optometrists using the <b>Referral Letter Upload Interface</b> each week.</li>
                        </ul>
                        OD to review:
                        <ul>
                            <li>This will count the number of exams uploaded by each OD that has been set to OD to review each week</li>
                            <li>This number <b>is not stable over time</b> and can change, especially for optometrists using the <b>Original Upload Interface</b>.</li>
                            <li>This represents the number of exams <b>Submitted to Care1</b> by the optometrists <b>using the Manual Uploads Interface plus the exams Submitted to Care1 by admin on behalf of the OD (Smart Uploads)</b> each week.</li>
                            <li>The instability is due to the OD name being added or changed on the exams over time. Exams with no OD added yet will not be counted until an OD name has been added.</li>
                        </ul>
                        Submitted Date:
                        <ul>
                            <li>This will count the number of uploads/exams submitted to Care1 by each OD each week.</li>
                            <li>Submitted to Care1” means:</li>
                            <li>
                                <ul>
                                    <li>Smart Uploads - the date an upload is submitted to Care1</li>
                                    <li>Non-Smart RLU exams (Manual Uploads) - the date an exam’s status is changed to OD to Review</li>
                                    <li>Legacy exams - the date an exam’s status is changed to Ready for OMD</li>
                                </ul>
                            </li>
                            <li>This number is not stable over time and can change, especially for optometrists using the RLU and Smart Upload because the optometrist’s name may be modified from the time an upload is submitted to the time an exam is Ready for OMD.</li>
                        </ul>
                        Exam Visit Date:
                        <ul>
                            <li>This will count the number of exams that have an exam visit date broken down by OD for each week.</li>
                            <li>This number <b>is not stable over time</b>, and can change for optometrists using both the <b>Original Upload Interface</b> and the <b>Referral Letter Upload Interface</b>.</li>
                            <li>The instability is due to exam visit date being editable.</li>
                        </ul>
                    </div>
                }
            >
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    );
};

export default SalesIntegrationMetricsFilter;
