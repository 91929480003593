import { useEffect } from 'react';
import { CalendarOutlined, AuditOutlined, FileSearchOutlined, SearchOutlined, DownOutlined
    } from '@ant-design/icons';
import { Row, Divider, Select } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import { GLAUCOMA_PROGRAM_NEXT_SUGGESTED_VISIT_OPTIONS } from '../../constants';
import '../../../static/css/components/patient-list-control-bar-glaucoma-program.scss';
import { useGetGlaucomaProgramODListQuery } from '../../services/glaucoma-program-api';
import GlaucomaProgramValueSelect from './glaucoma-program-value-select';
import { setGlaucomaProgramFieldData } from '../../reducers/glaucoma-program-slice';

const GlaucomaProgramPatientListControlBar = () => {
    const { GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES } = localizedText;
    const {data, isFetching} = useGetGlaucomaProgramODListQuery();

    const dispatch = useCare1AppDispatch();

    const ghtOptions = useCare1AppSelector(store => store.options.ghtOptions);
    const iopCompareFilter = useCare1AppSelector(store => store.glaucomaProgram.iopCompareFilter);
    const iopCompareNumberFilter = useCare1AppSelector(store => store.glaucomaProgram.iopCompareNumberFilter);
    const cdCompareFilter = useCare1AppSelector(store => store.glaucomaProgram.cdCompareFilter);
    const cdCompareNumberFilter = useCare1AppSelector(store => store.glaucomaProgram.cdCompareNumberFilter);
    const nextVisitFilter = useCare1AppSelector(store => store.glaucomaProgram.nextVisitFilter);

    const onODSelect = (value: number | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'odFilter', value: value || null}));
    }

    const onNextVisitSelect = (value: string | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'nextVisitFilter', value: value || null}));
    }

    const onBookingStatusSelect = (value: string | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'bookingStatusFilter', value: value || null}));
    }

    const onVFSelect = (value: string | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'vfFilter', value: value || null}));
    }

    const onIOPSelect = (compareType: string | null, compareNumber: number | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'iopCompareFilter', value: compareType || null}));
        dispatch(setGlaucomaProgramFieldData({key: 'iopCompareNumberFilter', value: compareNumber || null}));
    }

    // Format IOP input to only allow # or ##.
    const formatIOPValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([0-9]?)([0-9]?).*$/;
        const matches = String(value).match(regex);

        if (matches && matches[1] && matches[2]) {
            return String(Number(`${matches[1]}${matches[2]}`));
        } else if (matches && matches[1]) {
            return String(Number(matches[1]));
        } else {
            return '';
        }
    }

    const onCDSelect = (compareType: string | null, compareNumber: number | null) => {
        dispatch(setGlaucomaProgramFieldData({key: 'cdCompareFilter', value: compareType || null}));
        dispatch(setGlaucomaProgramFieldData({key: 'cdCompareNumberFilter', value: compareNumber || null}));
    }

    // Format C/D input to only allow 0.# or 0.##.
    const formatCDValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^(0?)(\.?)(([0-9][1-9])|([1-9])).*$/;
        const matches = String(value).match(regex);

        let vertCD = '';

        if (matches) {
            let match = matches[4] ? Number(`0.${matches[4]}`)
                : matches[5] ? Number(`0.${matches[5]}`)
                    : Number(`${matches[1]}${matches[2]}`);

            vertCD = match.toString();
        }

        return vertCD;
    }

    // only run when the component first mounts
    useEffect(() => {
        // Set default filters.
        dispatch(setGlaucomaProgramFieldData({key: 'nextVisitFilter', value: 'within_2months'}));
    }, [dispatch])

    return (
        <Row className={'glaucoma-program-patient-list-control-bar patient-list-control-bar-od'}>
            <Row align="middle" className="control-bar-elements">
                {/* PT-LIST-CTRL-BAR 001 */}
                <div className="patient-list-title">
                    Glaucoma Program Patient List
                </div>

                <Select
                    className="dropdown-filter glaucoma-program-vf"
                    allowClear
                    placeholder={<div><FileSearchOutlined /> VF</div>}
                    onSelect={onVFSelect}
                    onClear={() => onVFSelect(null)}
                    data-testid='vf-select'
                >
                    {ghtOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {/* We don't want the "GHT" text in front of each option since it's redundant. */}
                            {option.label.replace('GHT ', '')}
                        </Select.Option>
                    ))}
                </Select>
                <GlaucomaProgramValueSelect className='dropdown-filter glaucoma-program-cd'
                    compareTypeValue={cdCompareFilter}
                    compareNumberValue={cdCompareNumberFilter}
                    placeholder={<div className='glaucoma-program-placeholder'><FileSearchOutlined /> C/D <DownOutlined className='glaucoma-program-dropdown-icon' /></div>}
                    onSelect={onCDSelect}
                    formatValue={formatCDValue}/>
                <GlaucomaProgramValueSelect className='dropdown-filter glaucoma-program-iop'
                    compareTypeValue={iopCompareFilter}
                    compareNumberValue={iopCompareNumberFilter}
                    placeholder={<div className='glaucoma-program-placeholder'><FileSearchOutlined /> IOP <DownOutlined className='glaucoma-program-dropdown-icon' /></div>}
                    onSelect={onIOPSelect}
                    formatValue={formatIOPValue}/>
                <Divider type="vertical" className="patient-list-vertical-divider" />
                <Select
                    className="dropdown-filter glaucoma-program-status"
                    allowClear
                    placeholder={<div><AuditOutlined /> Status</div>}
                    onSelect={onBookingStatusSelect}
                    onClear={() => onBookingStatusSelect(null)}
                    data-testid='status-select'
                >
                    {GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            {option.key.charAt(0).toUpperCase() + option.key.slice(1)}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    className="dropdown-filter glaucoma-program-suggested-next-visit"
                    allowClear
                    placeholder={<div><CalendarOutlined /> Next Visit</div>}
                    onSelect={onNextVisitSelect}
                    onClear={() => onNextVisitSelect(null)}
                    value={nextVisitFilter}
                    data-testid='suggested-next-visit-select'
                >
                    {GLAUCOMA_PROGRAM_NEXT_SUGGESTED_VISIT_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
                <Divider type="vertical" className="patient-list-vertical-divider" />
                <Select
                    className="dropdown-filter glaucoma-program-od-select"
                    showSearch
                    allowClear
                    placeholder={<div><SearchOutlined /> OD Name</div>}
                    filterOption={(input, option) => option?.od_name.toLowerCase().includes(input.toLowerCase()) >= 0}
                    onSelect={onODSelect}
                    onClear={() => onODSelect(null)}
                    data-testid='od-select'
                >
                    {
                        data && data.map((optometrist) => (
                            <Select.Option
                                key={optometrist.id}
                                value={optometrist.id}
                            >
                                {`Dr. ${optometrist.od_name}`}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Row>
        </Row>
    );
}

export default GlaucomaProgramPatientListControlBar;
