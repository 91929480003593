import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import '../../../../static/css/components/admin-doctor-list-table.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { compareStrings, sortDates } from '../../../helpers/sorting';
import { goToExamInNewTab } from '../../../reducers/patient-exam-slice';
import { ISmartUploadTableItem, setSelectedRowKeys, setSortedInfo, setSortOrder } from '../../../reducers/smart-upload-slice';


const SmartUploadTable = () => {

    const selectedRowKeys = useCare1AppSelector(store => store.smartUpload.selectedRowKeys);
    const sortOrder = useCare1AppSelector(store => store.smartUpload.sortOrder);
    const sortedInfo = useCare1AppSelector(store => store.smartUpload.sortedInfo);
    const smartUploadList = useCare1AppSelector(store => store.smartUpload.smartUploadList);
    const isLoading = useCare1AppSelector(store => store.smartUpload.smartUploadTableLoading);
    
    const dispatch = useCare1AppDispatch();

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleChange = (sorter: SorterResult<ISmartUploadTableItem> | SorterResult<ISmartUploadTableItem>[]) => {
        dispatch(setSortedInfo(sorter));

        const lastSorterItem = Array.isArray(sorter) ? [...sorter].pop() : sorter;
        if (!lastSorterItem) return;
        if (lastSorterItem.order === 'ascend' || lastSorterItem.order === 'descend') {
            if (!sortOrder.includes(lastSorterItem.columnKey as string)) {
                // append the key to the front of list so that it has smaller priority
                dispatch(setSortOrder([lastSorterItem.columnKey as string, ...sortOrder]))
            }
        } else if (lastSorterItem.order === undefined) {
            dispatch(setSortOrder(sortOrder.filter(key => key !== lastSorterItem.columnKey)));
        }
    }

    // Each column needs to get the current state of its sortOrder
    const getSortOrder = (key: string) => {
        // If only one column is sorted, sortedInfo is an object.
        // If two or more columns are sorted, sortedInfo is an array.
        if(Array.isArray(sortedInfo)){
            const sortOrderItem = sortedInfo.find(so => so.columnKey === key);
            return sortOrderItem ? sortOrderItem.order : null;
        } else {
            return sortedInfo?.columnKey === key ? sortedInfo.order : null
        }
    }

    // Open the exam in a new tab and stay on the patient list page.
    // This achieved by using the Force Background Chrome extension
    const onTableRowClick = (record: ISmartUploadTableItem) => {
        const examUrl = `upload-reconciler/${record.smart_upload_id}`;
        goToExamInNewTab(examUrl);
    }

    
    const columns: ColumnsType<ISmartUploadTableItem> = [
        {
            key: 'admin_reconciler',
            className: 'review-processing-column',
            title: 'Reconciler',
            dataIndex: 'admin_reconciler',
            sorter: {
                compare: (a,b) => compareStrings(a['admin_reconciler'], b['admin_reconciler']),
                multiple: sortOrder.indexOf('admin_reconciler'),
            },
            sortOrder: getSortOrder('admin_reconciler'),
        }, {
            key: 'smart_upload_id',
            className: 'review-processing-column',
            title: 'Reference ID',
            dataIndex: 'smart_upload_id',
            sorter: {
                compare: (a,b) => compareStrings(a['smart_upload_id'].toString(), b['smart_upload_id'].toString()),
                multiple: sortOrder.indexOf('smart_upload_id'),
            },
            sortOrder: getSortOrder('smart_upload_id'),
        }, {
            key: 'submission_date',
            className: 'review-processing-fname-column',
            title: 'Submission Date',
            dataIndex: 'submission_date',
            sorter: {
                compare: (a,b) => compareStrings(a['submission_date'], b['submission_date']),
                multiple: sortOrder.indexOf('submission_date'),
            },
            sortOrder: getSortOrder('submission_date'),
        },
        {
            key: 'uploading_od_name',
            className: 'review-processing-lname-column',
            title: 'OD Name',
            dataIndex: 'uploading_od_name',
            sorter: {
                compare: (a,b) => compareStrings(a['uploading_od_name'], b['uploading_od_name']),
                multiple: sortOrder.indexOf('uploading_od_name'),
            },
            sortOrder: getSortOrder('uploading_od_name'),
        }, {
            key: 'uploading_odgrouppractice',
            className: 'review-processing-column',
            title: 'OD Group Practice',
            dataIndex: 'uploading_odgrouppractice',
            sorter: {
                compare: (a,b) => sortDates(a['uploading_odgrouppractice'], b['uploading_odgrouppractice']),
                multiple: sortOrder.indexOf('uploading_odgrouppractice'),
            },
            sortOrder: getSortOrder('uploading_odgrouppractice'),
        }, {
            key: 'status',
            className: 'review-processing-column',
            title: 'Status',
            dataIndex: 'status',
            sorter: {
                compare: (a,b) => compareStrings(a['status'], b['status']),
                multiple: sortOrder.indexOf('status'),
            },
            sortOrder: getSortOrder('status'),
        }
    ];
    
    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    loading={isLoading}
                    columns={columns}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: (selectedRowKeys) => {
                            dispatch(setSelectedRowKeys(selectedRowKeys));
                        }
                    }}
                    pagination={false}
                    dataSource={smartUploadList}
                    onChange={(pagination, filters, sorter) => handleChange(sorter)}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onTableRowClick(record);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default SmartUploadTable;