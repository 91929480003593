import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';


// Components
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';

// Helpers
import '../../static/css/pages/_patient-list-page.scss';
import '../../static/css/pages/admin-patient-exam-page.scss';
import '../../static/css/pages/upload-reconciler-page.scss';
import '../../static/css/shared/patient-list-control-bar.scss';
import '../../static/css/pages/admin-patient-management-page.scss';

import AdminPatientMergeTab from '../components/admin/patient-management/admin-patient-merge-tab';
import AdminGpMergeTab from '../components/admin/patient-management/admin-gp-merge-tab';
import AdminGpPatientListTab from '../components/admin/patient-management/admin-gp-patient-list-tab';

const { Header, Content } = Layout;

const AdminPatientManagementPage: React.FC = () => {

    const defaultTab = 'item-gppatientlisttab';

    const [activeKey, setActiveKey] = useState(defaultTab);

    const getAdminGpMergeTab = () => {
        return <AdminGpMergeTab />
    }

    const getAdminPatientMergeTab = () => {
        return <AdminPatientMergeTab />
    }

    const getAdminGpPatientListTab = () => {
        return <AdminGpPatientListTab />
    }

    const tabs = [{
        label: 'Patient', key: 'item-gppatientlisttab',
        children: getAdminGpPatientListTab()
    }, {
        label: 'Patient Merge', key: 'item-patientmergetab',
        children: getAdminPatientMergeTab()
    }, {
        label: 'Gp Merge', key: 'item-gpmergetab',
        children: getAdminGpMergeTab()
    }];

    return (
        <Layout>
            <Header>
                <NavigationTopBar className="navigation-top-bar" />
                <NavigationControlBar className="patient-list-navigation-control-bar" />
            </Header>
            <Content>
                <div className="admin-patient-management-page">
                    <Tabs
                        defaultActiveKey={defaultTab}
                        activeKey={activeKey}
                        items={tabs}
                        onTabClick={(key: string, e: React.KeyboardEvent | React.MouseEvent) => {
                            setActiveKey(key);
                        }}
                    />
                </div>
            </Content>
        </Layout>
    );
}

export default AdminPatientManagementPage;