import { ConfigProvider, Layout, Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import NavigationControlBar from '../components/navigation-control-bar';

import type { TabsProps } from 'antd';
import '../../static/css/pages/review-processing-page.scss';
import { OPERATING_TEXT } from '../constants';
import ManuallySentReviewsTab from '../components/admin/ReviewProcessing/manually-sent-reivews-tab';

const { Header, Content } = Layout;

const ReviewProcessingPage: React.FC = () => {

    const [activeKey, setActiveKey] = useState('1');

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Review Emails in Queue',
          children: 'Review Emails in Queue',
        },
        {
          key: '2',
          label: 'Review Emails Sent Log',
          children: 'Review Emails Sent Log',
        },
        {
          key: '3',
          label: 'Manually Sent Reviews',
          children: <ManuallySentReviewsTab />,
        },
      ];
    
    const theme = {
        components: {
            Tabs: {
                colorText: '#FFFFFF',
            }
           
        },
    };

    return (
        <ConfigProvider theme={theme}>
            <Layout>
                <Spin
                    className='loading-spinner'
                    spinning={false}
                    size='large'
                    tip={OPERATING_TEXT}
                >
                    <Header>
                        <NavigationControlBar
                            className='patient-list-navigation-control-bar'
                            breadcrumbItems={[]}
                        />
                    </Header>
                    <Content className='review-processing-page'>
                        <Tabs
                            items={items}
                            onChange={(key) => setActiveKey(key)}
                            activeKey={activeKey}
                        />
                    </Content>
                </Spin>
            </Layout>
        </ConfigProvider>
    );
}

export default ReviewProcessingPage;