import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../stores/retina-enabled-store';

export const createCare1AsyncThunk = createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
}>();

export type APIResponse<T> =
    | {
          success: true;
          error: never;
          data: T;
      }
    | {
          success: false;
          error: string;
      };
