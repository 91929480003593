import { Table, Row, Divider } from 'antd';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/patients-to-review-table.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { IAdminPatientsToReviewTableItem } from '../../reducers/admin-review-lists-slice';

const PatientsToReviewTableAdmin = () => {
    const { REVIEW_TABLE_CATEGORY } = localizedText
    const patientsToReviewData = useCare1AppSelector(store => store.adminReviewLists.patientsToReviewAdmin);
    const patientsToReviewAdminLoading = useCare1AppSelector(store => store.adminReviewLists.patientsToReviewAdminLoading);

    const columns : ColumnsType<IAdminPatientsToReviewTableItem> = 
        [
            {
                className: 'category',
                title: REVIEW_TABLE_CATEGORY,
                dataIndex: 'category',
                render: (text) => <span>{text}</span>
            }, {
                className: 'exams_in_queue_all',
                title: 'All',
                dataIndex: 'exams_in_queue_all',
            }, {
                className: 'exams_in_queue_orientating',
                title: 'Orientating',
                dataIndex: 'exams_in_queue_orientating',
            }, {
                className: 'exams_in_queue_special_attention',
                title: 'Special Attention',
                dataIndex: 'exams_in_queue_special_attention',
            },
        ]
    type NoOdAssignedTableItem = Omit<IAdminPatientsToReviewTableItem, 'exams_in_queue_orientating' | 'exams_in_queue_special_attention'>
    const noOdAssignedColumns : ColumnsType<NoOdAssignedTableItem> = 
        [
            {
                className: 'category',
                title: REVIEW_TABLE_CATEGORY,
                dataIndex: 'category',
                render: (text) => <span>{text}</span>
            }, {
                className: 'exams_in_queue_all',
                title: 'All',
                dataIndex: 'exams_in_queue_all',
            }, 
        ]
    
    const originalUploadList = [{
            key: 'all_ic_exams',
            exams_in_queue_all: patientsToReviewData['all_ic_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_ic_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_ic_exams_od_special_attention'],
            category: 'All IC Exams (Ready for OMD + Unflag)'
        }, {
            key: 'retina_exams',
            exams_in_queue_all: patientsToReviewData['retina_exams'],
            exams_in_queue_orientating: patientsToReviewData['retina_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['retina_exams_od_special_attention'],
            category: 'Retina (Ready for OMD + Unflag)'
        }, {
            key: 'glaucoma_exams',
            exams_in_queue_all: patientsToReviewData['glaucoma_exams'],
            exams_in_queue_orientating: patientsToReviewData['glaucoma_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['glaucoma_exams_od_special_attention'],
            category: 'Glaucoma (Ready for OMD + Unflag)'
        }, {
            key: 'other_exams',
            exams_in_queue_all: patientsToReviewData['other_exams'],
            exams_in_queue_orientating: patientsToReviewData['other_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['other_exams_od_special_attention'],
            category: 'Other (Ready for OMD + Unflag)'
        }
    ];

    const referralLetterUploadList = [{
            key: 'all_ic_ref_exams',
            exams_in_queue_all: patientsToReviewData['all_ic_ref_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_ic_ref_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_ic_ref_exams_od_special_attention'],
            category: 'All Exams (Ref Ltr + Sub)'
        }, {
            key: 'all_ipc_ref_exams',
            exams_in_queue_all: patientsToReviewData['all_ipc_ref_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_ipc_ref_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_ipc_ref_exams_od_special_attention'],
            category: 'IPC (Ref Ltr + Submit)'
        }, {
            key: 'all_high_priority_ref_exams',
            exams_in_queue_all: patientsToReviewData['all_high_priority_ref_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_high_priority_ref_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_high_priority_ref_exams_od_special_attention'],
            category: 'High Priority (Ref Ltr + Submit)'
        }, {
            key: 'all_od_messaging_exams',
            exams_in_queue_all: patientsToReviewData['all_od_messaging_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_od_messaging_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_od_messaging_exams_od_special_attention'],
            category: 'OD Messaging'
        }, {
            key: 'all_outbound_ref_exams',
            exams_in_queue_all: patientsToReviewData['all_outbound_ref_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_outbound_ref_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_outbound_ref_exams_od_special_attention'],
            category: 'Outbound Referral'
        }, {
            key: 'all_other_ref_exams',
            exams_in_queue_all: patientsToReviewData['all_other_ref_exams'],
            exams_in_queue_orientating: patientsToReviewData['all_other_ref_exams_od_orientating'],
            exams_in_queue_special_attention: patientsToReviewData['all_other_ref_exams_od_special_attention'],
            category: 'Other (Ref Ltr + Submit)'
        }
    ];
    const noOdAssignedUploadList = [{
        key: 'no_od_assigned_exams',
        exams_in_queue_all: patientsToReviewData['no_od_assigned_exams'],
        category: 'No OD assigned'
    }];
        

    return (
        <div className={`patient-list-table patients-to-review patient-list-table-omdr user-is-admin`}>
            <div className="patients-to-review-table">
                <div className="patient-list-title" data-testid='patient-list-title'>
                    Exams in queue
                </div>
                <Row>
                    <Table
                        size='small'
                        columns={columns}
                        dataSource={originalUploadList}
                        pagination={false}
                        title={() => 'Original Upload Interface'}
                        loading={patientsToReviewAdminLoading}
                        data-testid='original-upload-interface-table'
                    />
                </Row>
                <Divider className='patients-to-review-divider first' />
                <Row>
                    <Table
                        size='small'
                        columns={columns}
                        dataSource={referralLetterUploadList}
                        pagination={false}
                        title={() => 'Referral Letter Upload Interface'}
                        loading={patientsToReviewAdminLoading}
                        data-testid='referral-letter-upload-interface-table'
                    />
                </Row>
                <Divider className='patients-to-review-divider' />
                <Row>
                    <Table
                        size='small'
                        columns={noOdAssignedColumns}
                        dataSource={noOdAssignedUploadList}
                        pagination={false}
                        tableLayout='fixed'
                        loading={patientsToReviewAdminLoading}
                        data-testid='no-od-assigned-upload-list-table'
                    />
                </Row>
                <Divider className='patients-to-review-divider' />
            </div>
        </div>
    );
}

export default PatientsToReviewTableAdmin;