import { baseApi, IBaseResponse } from './base-api'

export interface IGpResponse {
    success: boolean;
    id: number;
    error?: string;
}
export interface IPatientManagementGpMatchingTableItem {
    key: number,
    id: number,
    gp_name: string,
    fax_number: string,
    province: string,
}

export interface IPatientManagementGpMatchingRequest {
    dob: string,
    first_name: string,
    last_name: string,
    insurance: string,
    od_group_practice_name: string,
    auto_referral: boolean,
}

export interface IPatientManagementMatchGpsRequestItem {
    id: number;
    op: string;
}

export interface IPatientManagementMatchGpsRequest {
    items: IPatientManagementMatchGpsRequestItem[];
}

export interface IPatientManagementDeleteGpsRequest {
    items: number[];
}

export interface IPatientManagementGpPatientTableItem {
    key: number,
    id: number,
    od_name: string,
    od_status: string,
    patient_first_name: string,
    patient_last_name: string,
    birthday: string,
    gp_name: string,
    gp_fax: string,
    od_grouppractice: string,
    od_province: string,
    latest_exam_id: string,
}

export interface IPatientManagementGpEmailRequest {
    to: string[],
    from: string,
    bcc: string[],
    subject: string,
    body: string,
    patient_ids: number[],
}

export interface IPatientManagementGpRemoveRequest {
    patient_ids: number[],
}

export const patientManagementApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        matchGps: builder.mutation<IGpResponse, IPatientManagementMatchGpsRequest>({
            query(requestData) {
                return {
                    url: 'patient_management/gp_match/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        deleteGps: builder.mutation<IGpResponse, IPatientManagementDeleteGpsRequest>({
            query(requestData) {
                return {
                    url: 'patient_management/gp_delete/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        getPatientManagementAdminGpList: builder.query<IPatientManagementGpMatchingTableItem[], { name: string }>({
            query: ({ name }) => {
                return {
                    url: `patient_management/admin_gp_list/`,
                    params: {
                        name
                    },
                }
            },
            transformResponse: (response: IBaseResponse & { data: IPatientManagementGpMatchingTableItem[] }, meta, arg) => response.data,
        }),
        getPatientManagementAdminGpPatientList: builder.query<IPatientManagementGpPatientTableItem[], { name: string | null, gp: number | null, od_group: number | null }>({
            query: ({ name, gp, od_group }) => {
                return {
                    url: `patient_management/admin_gp_patient_list/`,
                    params: {
                        name,
                        gp,
                        od_group
                    },
                }
            },
            transformResponse: (response: IBaseResponse & { data: IPatientManagementGpPatientTableItem[] }, meta, arg) => response.data,
        }),
        sendGPEmailRemove: builder.mutation<IGpResponse, IPatientManagementGpEmailRequest>({
            query(requestData) {
                return {
                    url: 'patient_management/send_gp_email_remove/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        gpRemove: builder.mutation<IGpResponse, IPatientManagementGpRemoveRequest>({
            query(requestData) {
                return {
                    url: 'patient_management/gp_remove/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useMatchGpsMutation,
    useDeleteGpsMutation,
    useLazyGetPatientManagementAdminGpListQuery,
    useLazyGetPatientManagementAdminGpPatientListQuery,
    useSendGPEmailRemoveMutation,
    useGpRemoveMutation
} = patientManagementApi;
