import { useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Layout, Row, Col, Modal, Button, Input, Spin, Divider, Select, Typography, Space, Flex } from 'antd';
import { useParams, useNavigate } from 'react-router';
import { localizedText } from '../../../localizedText';
import {
    ApartmentOutlined,
    CloseCircleOutlined,
    CloudSyncOutlined,
    InfoCircleOutlined,
    GlobalOutlined,
    SearchOutlined, UserOutlined
} from '@ant-design/icons';

// Components
import AdminGpPatientListTable from './gp-patient-list-table';
import {
    IPatientManagementGpPatientTableItem,
    useGpRemoveMutation,
    useLazyGetPatientManagementAdminGpPatientListQuery
} from '../../../services/patient-management-api';
import * as Constants from '../../../constants';
import { useGetAllGpsQuery } from '../../../services/doctor-api';
import GpPatientPreviewModal from './gp-patient-preview-modal';

// Helpers
import styles from '../../../../static/css/modules/smart-upload-table-control-bar.module.scss';
import '../../../../static/css/pages/_patient-list-page.scss';
import '../../../../static/css/pages/admin-patient-exam-page.scss';
import '../../../../static/css/pages/upload-reconciler-page.scss';
import '../../../../static/css/shared/patient-list-control-bar.scss';
import '../../../../static/css/components/patient-management.scss';


// Actions

const { Header, Content } = Layout;

const AdminGpPatientListTab = () => {

    const { SELECT_BULK_ACTION } = localizedText;

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const { data: gpList = [] } = useGetAllGpsQuery();

    const [getAdminGpPatientListQuery, { data: gpPatientList = [], isFetching: isAdminGpPatientListFetching }] = useLazyGetPatientManagementAdminGpPatientListQuery();
    const [gpRemove] = useGpRemoveMutation();

    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());
    const [selectedExamIds, setSelectedExamIds] = useState<Set<string>>(new Set());
    const [selectedTableItems, setSelectedTableItems] = useState<Set<IPatientManagementGpPatientTableItem>>(new Set());

    const [searchPatientName, setSearchPatientName] = useState<string | null>(null);
    const [searchGPFilter, setSearchGPFilter] = useState<number | null>(null);
    const [odGroupFilter, setOdGroupFilter] = useState<number | null>(null);

    const [bulkOperation, setBulkOperation] = useState<string | null>(null);

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const odGroupPracticOptions = useCare1AppSelector(
        (store) => store.options.odgrouppractices
    );

    useEffect(() => {
        setSelectedIds(new Set());
    }, [])

    const refreshTable = () => {
        setSelectedIds(new Set());
        setSelectedExamIds(new Set());
        setSelectedTableItems(new Set());
        sendSearchGpListPatientNameRequest(searchPatientName);
    }

    const sendSearchGpListPatientNameRequest = async (value: string | null) => {
        setSearchPatientName(value);
        await getAdminGpPatientListQuery({
            name: value,
            gp: searchGPFilter,
            od_group: odGroupFilter
        });
    }

    const sendSearchGpListGpRequest = async () => {
        refreshTable();
    }

    const clearFilterSelection = () => {
        setSearchGPFilter(null);
        setSearchPatientName(null);
        setOdGroupFilter(null);
    }

    const handleRemoveGpNoEmailSelection = async () => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            content: 'Are you sure you want to remove the GP from the patient’s file?',
            icon: <InfoCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: async () => {
                try {
                    let response = await gpRemove({
                        patient_ids: Array.from(selectedIds)
                    }).unwrap();

                    if (response !== null && response.success) {
                        refreshTable();
                    } else {
                        throw new Error(response?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ? error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
            }
        });
    }

    const handleBulkOperationSelection = async (key: string) => {
        if (key === 'remove_gp_no_email') {
            await handleRemoveGpNoEmailSelection();
            return;
        }

        // Confirm the bulk operation.
        if (key === 'incorrect_gp_info_email_remove' || key === 'not_a_patient_email_remove') {
            if (selectedTableItems.size > 1) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Can only select one patient',
                });
                return;
            }
        }
        setBulkOperation(key);
        setIsPreviewModalOpen(true);
    }

    const getEmailSubject = () => {
        if (bulkOperation === 'inactive_gp_email_remove') {
            return 'Retired or Inactive GP';
        }
        else if (bulkOperation === 'incorrect_gp_info_email_remove') {
            return 'Incorrect GP Fax Number';
        }
        else if (bulkOperation === 'not_a_patient_email_remove') {
            return 'Incorrect GP on File';
        }
        return '';
    }

    const getEmailBody = () => {
        if (selectedTableItems.size === 0) {
            return <div></div>;
        }

        if (bulkOperation === 'inactive_gp_email_remove') {
            const patients = Array.from(selectedTableItems);
            const firstPatient = Array.from(selectedTableItems)[0];
            const patientDetails = patients.map(element => {
                return <p>{element.patient_first_name} {element.patient_last_name} ({element.birthday})</p>;
            });
            return <div>
                <p>Hello,</p>

                <p>We have been informed that the following GP is retired or inactive/no longer practicing. Therefore this GP has been removed from the following patient profiles that have been uploaded to Care1:</p>

                <p>{firstPatient.gp_name} (Fax: {firstPatient.gp_fax})</p>
                <p>List of Patients:</p>
                {patientDetails}

                <p>If you have updated GP information for your patients above, please feel free to add that information to Care1 at your convenience.</p>

                <p>Best Regards,</p>
                <p>Care1 Team</p>
            </div>;
        }
        else if (bulkOperation === 'incorrect_gp_info_email_remove') {
            const firstPatient = Array.from(selectedTableItems)[0];
            return <div>
                <p>Hello,</p>

                <p>We have been informed that the following GP on file for one of your patients is not practicing at the location of the fax number provided. Please note, we have removed the GP from the patient’s profile.</p>

                <p>{firstPatient.gp_name} (Fax: {firstPatient.gp_fax})</p>
                <p>{firstPatient.patient_first_name} {firstPatient.patient_last_name} ({firstPatient.birthday})</p>

                <p>If you have updated GP information for the patient above, please feel free to add that information to Care1 at your convenience.</p>

                <p>Best Regards,</p>
                <p>Care1 Team</p>
            </div>;
        }
        else if (bulkOperation === 'not_a_patient_email_remove') {
            const firstPatient = Array.from(selectedTableItems)[0];
            return <div>
                <p>Hello,</p>

                <p>We have been informed that the following patient is not a patient of the GP listed in their patient profile. Please note, we have removed the GP from the patient’s profile. </p>

                <p>{firstPatient.gp_name} (Fax: {firstPatient.gp_fax})</p>
                <p>{firstPatient.patient_first_name} {firstPatient.patient_last_name} ({firstPatient.birthday})</p>

                <p>If you have updated GP information for the patient above, please feel free to add that information to Care1 at your convenience. </p>

                <p>Best Regards,</p>
                <p>Care1 Team</p>
            </div>;
        }
        return <div></div>;
    }

    const shouldDisableGetFilterButton = () => {
        const res = searchGPFilter === null && searchPatientName === null && odGroupFilter === null;
        return res;
    }

    const shouldDisableClearFilterButton = () => {
        const res = searchGPFilter === null && searchPatientName === null && odGroupFilter === null;
        return res;
    }

    return (
        <Spin
            className="loading-spinner"
            spinning={isAdminGpPatientListFetching}
            size="large"
            tip={Constants.OPERATING_TEXT}
        >
            <div className='patient-list-page user-is-admin upload-reconciler-page patient-management'>
                <div className=' patient-list-control-bar patient-list-control-bar-admin'>
                    <div className='control-bar-elements '>
                        <Row>
                            <Col span={24}>
                                <Flex gap='large' className={styles.filterOptionRow}>

                                    <Input.Search
                                        className="patient-list-search-input"
                                        allowClear
                                        placeholder={localizedText.PATIENT_LIST_SEARCH_PLACEHOLDER}
                                        prefix={<SearchOutlined />}
                                        onSearch={sendSearchGpListPatientNameRequest}
                                    />

                                    <Select
                                        data-testid='gp-select'
                                        className={`dropdown-filter gp-filter${searchGPFilter ? ' is-selected' : ''}`}
                                        placeholder={<div><UserOutlined /> {localizedText.SEARCH_FOR_GP_MD2}</div>}
                                        showSearch
                                        value={searchGPFilter}
                                        allowClear={true}
                                        onClear={clearFilterSelection}
                                        onSelect={(value) => setSearchGPFilter(value)}
                                        filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            gpList && gpList.map((gp) => {
                                                return (
                                                    <Select.Option key={gp.id} value={gp.id}>
                                                        {`Dr. ${gp.first_name} ${gp.last_name}`}
                                                    </Select.Option>
                                                );
                                            })
                                        }
                                    </Select>

                                    <Select
                                        placeholder={
                                            <Space>
                                                <GlobalOutlined />
                                                <Typography.Text>OD Group</Typography.Text>
                                            </Space>
                                        }
                                        value={odGroupFilter}
                                        allowClear={true}
                                        optionFilterProp='label'
                                        options={odGroupPracticOptions}
                                        size='large'
                                        className={`dropdown-filter gp-filter${searchGPFilter ? ' is-selected' : ''}`}
                                        showSearch
                                        onChange={(value) => setOdGroupFilter(value)}
                                    />

                                    <Divider type="vertical" className="patient-list-vertical-divider doctor-row" />

                                    {/* Bulk Action Selector. Should be disabled if no patients are selected. */}
                                    <Select
                                        data-testid='bulk-operation-selector'
                                        className={`dropdown-filter bulk-operation-selector`}
                                        placeholder={<div><ApartmentOutlined /> {SELECT_BULK_ACTION}</div>}
                                        popupClassName="patient-list-dropdown"
                                        value={bulkOperation}
                                        onSelect={handleBulkOperationSelection}
                                        disabled={selectedIds.size === 0}
                                    >
                                        {Constants.ADMIN_GP_PATIENT_MANAGEMENT_BULK_OPERATIONS_OPTIONS && Constants.ADMIN_GP_PATIENT_MANAGEMENT_BULK_OPERATIONS_OPTIONS.map((option) => {
                                            return <Select.Option key={option.key} value={option.key}>
                                                <ApartmentOutlined /> {option.value}
                                            </Select.Option>
                                        })}
                                    </Select>

                                </Flex>
                            </Col>
                            <Col span={24}>
                                <div className='gp-patient-list-second-rows'>
                                    <Button
                                        type='primary'
                                        className='get-filtered-list-button'
                                        onClick={sendSearchGpListGpRequest}
                                        disabled={shouldDisableGetFilterButton()}
                                    >
                                        <CloudSyncOutlined /> {localizedText.GET_FILTERED_LIST}
                                    </Button>

                                    <Button
                                        className='clear-all-filters-button'
                                        type='primary'
                                        onClick={clearFilterSelection}
                                        disabled={shouldDisableClearFilterButton()}
                                    >
                                        <CloseCircleOutlined /> {localizedText.CLEAR_FILTERS}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        { /* Row Five - Action Buttons */}
                        <Row className="control-bar-elements sixth-row">
                        </Row>
                    </div>
                </div>
                <div className='gp-reconciliation'>
                    <Row>
                        <Col span={24}>
                            <AdminGpPatientListTable
                                tableList={gpPatientList}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                                selectedExamIds={selectedExamIds}
                                setSelectedExamIds={setSelectedExamIds}
                                setSelectedTableItems={setSelectedTableItems}
                                refreshTable={refreshTable}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <GpPatientPreviewModal
                bulkOperation={bulkOperation}
                isPreviewModalOpen={isPreviewModalOpen}
                closePreviewModal={() => setIsPreviewModalOpen(false)}
                refreshTable={refreshTable}
                emailSubject={getEmailSubject()}
                emailBody={getEmailBody()}
                selectedIds={selectedIds}
            />
        </Spin>
    );

}

export default AdminGpPatientListTab;