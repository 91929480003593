import { Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import '../../../../static/css/components/patients-to-review-table.scss';
import '../../../../static/css/shared/patient-list-table.scss';
import { localizedText } from '../../../localizedText';
import { IAdminPatientsToReviewTableItem } from '../../../reducers/admin-review-lists-slice';
import { useGetUploadsInQueueListQuery } from '../../../services/upload-reconciler-api';

const SmartUploadPatientsToReviewTable = () => {
    const { REVIEW_TABLE_CATEGORY } = localizedText;

    const {data, isLoading} = useGetUploadsInQueueListQuery();

    const columns : ColumnsType<IAdminPatientsToReviewTableItem> = 
        [
            {
                className: 'category',
                title: REVIEW_TABLE_CATEGORY,
                dataIndex: 'category',
                render: (text) => <span>{text}</span>
            }, {
                className: 'exams_in_queue_all',
                title: 'All',
                dataIndex: 'exams_in_queue_all',
            }, {
                className: 'exams_in_queue_orientating',
                title: 'Orientating',
                dataIndex: 'exams_in_queue_orientating',
            }, {
                className: 'exams_in_queue_special_attention',
                title: 'Special Attention',
                dataIndex: 'exams_in_queue_special_attention',
            }, {
                className: 'exams_in_queue_test',
                title: 'Test',
                dataIndex: 'exams_in_queue_test',
            },
        ]

    return (
        <div className={`patient-list-table patients-to-r-+eview patient-list-table-omdr user-is-admin`}>
            <div className="patients-to-review-table">
                <div className="patient-list-title" data-testid='patient-list-title'>
                    Uploads in queue
                </div>
                <Row>
                    <Table
                        size='small'
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        loading={isLoading}
                    />
                </Row>
                
                
            </div>
        </div>
    );
}

export default SmartUploadPatientsToReviewTable;