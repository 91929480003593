import { IAdminPatientsToReviewTableItem } from '../reducers/admin-review-lists-slice';
import { baseApi, IBaseResponse } from './base-api'
import { IPatientResponse } from './patient-api';

export interface IUploadReconcilerPatientMatchingTableItem {
    key: number,
    id: number,
    patient_name: string,
    dob: string,
    insurance: string | null,
    exam_id: number | null,
}

export interface IUploadReconcilerPatientMatchingRequest {
    id: number | null,
    dob: string,
    first_name: string,
    last_name: string,
    insurance: string,
    od_group_practice_name: string,
}

export interface IUploadReconcilerAddPatientRequest {
    od_id: number;
    first_name: string;
    last_name: string;
    province: number | null;
    phn: string;
    dob: string;
    primary_phone: string | null;
    insurance_type: string;
    gp_id: number | null;
    gp_fax_number: string | null;
}

export interface IUploadReconcilerMatchPatientsRequestItem {
    id: number;
    op: string;
}

export interface IUploadReconcilerMatchPatientsRequest {
    items: IUploadReconcilerMatchPatientsRequestItem[];
}

export const uploadReconcilerApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        updateUploadReconcilerPatientMatchingList: builder.mutation<IBaseResponse & {data: IUploadReconcilerPatientMatchingTableItem[]}, IUploadReconcilerPatientMatchingRequest>({
            query: (requestData) => {
                return {
                    url: `upload_reconciler/patient_matching_list/`,
                    method: 'POST',
                    body: requestData,
                }
            } ,
        }),
        addReconcilerPatientPatient: builder.mutation<IPatientResponse, IUploadReconcilerAddPatientRequest>({
            query(requestData) {
                return {
                    url: 'upload_reconciler/patient_add/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        matchReconcilerPatientPatients: builder.mutation<IPatientResponse, IUploadReconcilerMatchPatientsRequest>({
            query(requestData) {
                return {
                    url: 'upload_reconciler/patient_match/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        getMatchingOdId: builder.query<IBaseResponse & {id?: number}, {name: string, location: string} >({
            query: ({name, location}) => {
                return {
                    url: 'upload_reconciler/matching_od',
                    params: {
                        name,
                        location
                    }
                }
            }
        }),
        getUploadReconcilerAdminPatientList: builder.query<IUploadReconcilerPatientMatchingTableItem[], {name: string}>({
            query: ({name}) => {
                return {
                    url: `upload_reconciler/admin_patient_list/`,
                    params: {
                        name
                    },
                }
            } ,
            transformResponse: (response: IBaseResponse & {data: IUploadReconcilerPatientMatchingTableItem[]}, meta, arg) => response.data,
        }),
        getUploadsInQueueList: builder.query<IAdminPatientsToReviewTableItem[], void>({
            query: () => {
                return {
                    url: `smart_upload/uploads_in_queue_list/`,
                   
                }
            } ,
            transformResponse: (response: IBaseResponse & {data: IAdminPatientsToReviewTableItem[]}, meta, arg) => response.data,
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useAddReconcilerPatientPatientMutation, 
    useMatchReconcilerPatientPatientsMutation,
    useLazyGetMatchingOdIdQuery,
    useLazyGetUploadReconcilerAdminPatientListQuery,
    useUpdateUploadReconcilerPatientMatchingListMutation,
    useGetUploadsInQueueListQuery,
} = uploadReconcilerApi;
