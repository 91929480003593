import { Fragment, useEffect, useState } from 'react';
import { Card, Checkbox, Row, Form, Modal, Col } from 'antd';
import GptPatientExamOngoingEntry from './gpt-patient-exam-ongoing-entry';
import { getOngoingValues, isOngoingValueAllEmpty } from '../../../helpers/patient-exam-convert';
import DivTagInput from '../../integrated/div-tag-input';
import * as Constants from '../../../constants';
import { useFormValidation } from '../../../context/useFormValidationContext';

import '../../../../static/css/components/patient-exam-ongoing.scss';
import { localizedText } from '../../../localizedText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IOngoingItem } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

const FormItem = Form.Item

type ComponentProps = {
    ongoingValues: IOngoingItem[],
    ongoingHasSameDrops: boolean,
    ongoingLetterIsNotClear: boolean,
    gptResultState: { [x: string]: string };
}

const GptPatientExamOngoing = ({ ongoingValues, ongoingHasSameDrops, ongoingLetterIsNotClear, gptResultState }: ComponentProps) => {

    const placeholder = "";
    const disabled = false;

    const { ASKED_PATIENT_TEXT, PATIENT_EXAM_DROPS_ONGOING, SAME_MEDS, EXAM_ONGOING_TITLE, ENTRY_REQUIRED } = localizedText;
    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();
    const { setOngoingForm } = useFormValidation();

    const [ongoing, setOngoing] = useState({ values: ongoingValues });
    const [showOngoing, setShowOngoing] = useState(false);
    const [sameMedsChecked, setSameMedsChecked] = useState(ongoingHasSameDrops);

    const examOngoing = useCare1AppSelector(store => store.examData.ongoing);
    const untilYesterday = useCare1AppSelector(store => store.gptExamData.until_yesterday);
    const glcDropsList = useCare1AppSelector(store => store.options.glcDropsList);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamIsPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    const getGlcDropOptions = () => {
        let options = glcDropsList;
        if (isReferralLetterUploadPEI) {
            options = options.concat([['letter_is_not_clear', 'Letter is not clear']]);
        }
        if (rluExamIsPremium) {
            options = options.concat([['brimonidine_tim', 'brimonidine/timolol'], ['zimed', 'Zimed']]);
        }
        return options;
    }

    const glcDropOptions = getGlcDropOptions();

    const toggleOngoingEntries = () => {
        setShowOngoing(!showOngoing);
    }

    const deleteOngoingEntry = (idx: number) => {
        const ongoingLocal = { ...ongoing };
        ongoingLocal.values = ongoingLocal.values.filter((_, index) => index !== idx);
        setOngoing(ongoingLocal);
    }

    const closeOngoingEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const ongoingValues = ongoing.values;

        let isRowCompleted = true;
        let selectedRow: null | number = null;
        // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
        // a row are empty, it is considered a complete row
        if (isReferralLetterUploadPEI) {
            ongoingValues.forEach((entry, index) => {
                if (entry.glc_current_drops_eye_select && !entry.glc_current_drops_select) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        } else {
            ongoingValues.forEach((entry, index) => {
                const valuesArray = Object.values(entry);
                if (valuesArray.some((value) => value === '') &&
                    !valuesArray.every((value) => value === '')) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        }


        if (!isRowCompleted) {
            Modal.confirm({
                className: 'div-tag-row-incomplete-modal',
                title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                content: INCOMPLETE_ROW_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: INCOMPLETE_ROW_OK_TEXT,
                cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => {
                    deleteOngoingEntry(selectedRow!);
                    toggleOngoingEntries();
                },
            });
        } else {
            toggleOngoingEntries();
        }
    }


    const isOngoingRequired = (ongoing: { values: IOngoingItem[] }) => {
        // At least one entry is required if same meds is not selected.
        // Having only 1 Entry and that entry is empty means there is not one complete entry selected..
        return ongoing.values.length === 1 &&
            (Object.values(ongoing.values[0]).some(str => str === ''));
    }

    const editOngoingEntry = (params: any) => {
        const { editIndex, field, value } = params;
        if (editIndex >= 0 && field) {
            const ongoingLocal = ongoing.values.map(
                (ongoingItem, index) =>
                    index === editIndex ? {
                        ...ongoingItem,
                        [field]: value,
                        disabled: false,
                    } : ongoingItem
            )

            const ongoingFixed = ongoingLocal.filter(entry => !isOngoingValueAllEmpty(entry));
            ongoingFixed.push({
                glc_current_drops_select: '',
                glc_current_drops_eye_select: ''
            })
            setOngoing({ values: ongoingFixed });
        }
    }

    useEffect(() => {
        if (sameMedsChecked) {
            const selectedVals = sameMedsChecked ? untilYesterday : examOngoing;            
            setOngoing(selectedVals);
        }
        else {
            setOngoing({ values: [{ disabled: true }] });
        }

    }, [examOngoing, untilYesterday, sameMedsChecked])


    const getValuesForDivTagInput = () => {
        return getOngoingValues(getValues(), glcDropOptions, sameMedsChecked);
    }

    const getValues = () => {
        return ongoing.values;
    }

    const getEntries = () => {
        return getValues().map((entry, index) => {
            return (
                <GptPatientExamOngoingEntry
                    eyeTypes={eyeTypes}
                    key={index}
                    index={index}
                    data={entry}
                    glcDropsList={glcDropOptions}
                    size={"small"}
                    editOngoingEntry={editOngoingEntry}
                    deleteOngoingEntry={deleteOngoingEntry}
                />
            );
        });
    }

    const required = isOngoingRequired(ongoing);

    // Do not display the subtitle if there is a validation error. This provides space for the error message.
    const label =
        <span data-testid='heading' className={'heading'}>
            {EXAM_ONGOING_TITLE}
            {!required && <span className='sub-title'>{ASKED_PATIENT_TEXT}</span>}
        </span>

    return (
        <Card bordered={false} className={'patient-exam-ongoing gpt-patient-exam-ongoing'}>
            {/* ONGOING 001, ONGOING 002 */}
            <Fragment>
                <Row className={'heading-row'}>
                    <Col span={14}>
                        <div className="heading">{label}</div>
                    </Col>
                    <Col span={10}>
                        <Checkbox
                            className={`${gptResultState["ongoingHasSameDrops"]}`}
                            checked={sameMedsChecked}
                            onChange={(e) => setSameMedsChecked(e.target.checked)}
                            disabled={disabled}
                        >
                            {SAME_MEDS}
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={disabled ? 'div-tag-disabled' : ''}>
                    <div className={`${gptResultState["ongoingValues"]}`}>
                        {/* ONGOING 003, ONGOING 004, ONGOING 005 */}
                        <DivTagInput
                            disabled={sameMedsChecked || disabled}
                            placeholder={placeholder}
                            onClick={() => {
                                toggleOngoingEntries();
                            }}
                            values={getValuesForDivTagInput()}
                            drawer={!sameMedsChecked && showOngoing}
                            onClose={() => {
                                closeOngoingEntries();
                            }}
                            entries={getEntries()}
                            emptyValue={Constants.ONGOING_EMPTY_VALUE}
                            required={required}
                        />
                    </div>
                </Row>
            </Fragment>
        </Card>
    );
}

export default GptPatientExamOngoing;
