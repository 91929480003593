import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { convertBackendApplanationToFrontend } from "../helpers/options-convert";
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";

// Interfaces for the options objects which can be consumed throughout the app to type-safe the options.
export interface IOmdLetterOption {
    key: number,
    name: string,
}
export interface IApplanationOption {
    label: string,
    value: string,
}
export interface IOdGroupPracticeOption {
    value: number;
    label: string;
    key: number;
}
export interface IOdOption {
    key: number;
    label: string;
    value: number;
}
export interface IOmdNetworkOption {
    address: string;
    id: number;
    name: string;
    telephone: string;
}
export interface IOmdcFuOption {
    name: string,
    id: number,
}
export interface IOmdNoteOption {
    note_to_od: string,
    note_to_gp: string,
    id: number,
    name: string,
}
export interface IOmdrFuOption {
    id: number,
    note_to_od: string,
}
export interface IOmdOption {
    agreed_to_review_provinces: string[];
    first_name: string;
    last_name: string;
    hidden_from_dropdown: boolean;
    id: number;
    is_generic: boolean;
    is_power_user: boolean;
    is_ret_specialist: boolean;
    is_reviewer: boolean;
    name: string;
    user_id: number;
}
export interface IPlannedBillingCodeOption {
    value: string,
    label: string,
}
export interface IPrereviewAlertOption {
    color: string,
    alert: string,
}
export interface IProvinceOption {
    can_bill_oct: boolean,
    can_bill_vf: boolean,
    code: string,
    country: string,
    diurnal_tension_curve: boolean,
    health_billing_id_code: string,
    health_billing_id_format: string,
    health_billing_id_name: string,
    health_id_code: string,
    health_id_format: string,
    health_id_name: string,
    id: number,
    name: string,
    require_billing_id: boolean,
}
export interface IRegionOption {
    id: number;
    name: string;
}
export interface IReviewSelectorsOption {
    note_to_od: string,
    note_to_gp: string,
    id: number,
    name: string,
}
export interface IRxRangeOption {
    max: number,
    min: number,
    step: number,
}

export interface IRxRange {
    sphere: IRxRangeOption,
    cylinder: IRxRangeOption,
    axis: IRxRangeOption,
}
export interface ITxalgo3Option {
    display_string: string,
    inline_color: string,
    keyword: string,
    negative_terms: string,
    popup_question: string,
    question_type: string,
    synonyms: string,
    textfield_allergies: boolean,
    textfield_exam_notes: boolean,
    textfield_impression: boolean,
    textfield_od_notes: boolean,
    textfield_od_proposed_plan: boolean,
    textfield_pmh: boolean,
    whole_word_search: boolean,
}

export interface IODStatusOption {
    label: string,
    value: string,
}

export interface IClinicLocationHeader {
    image_file_name: string,
    image_file_path: string,
}

export interface IInsuranceTypeOption {
    label: string,
    value: string,
}

export interface IGHTOption {
    label: string,
    value: string,
}

export interface IBillingRegionOption {
    value: number;
    label: string;
    key: number;
}

// Interfaces for handling the request and the store state
export interface IOptions {
    isLoading: boolean,
    applanations: IApplanationOption[],
    doNotFaxNumbers: string[],
    eyeTypes: string[][],
    fuPeriods: string[][],
    glcComplianceList: string[][],
    glcDropsList: string[][],
    machinesList: string[][],
    odgrouppractices: IOdGroupPracticeOption[],
    ods: IOdOption[],
    omd_networks: IOmdNetworkOption[],
    omdcFuOptions: IOmdcFuOption[],
    omdNoteOptions: IOmdNoteOption[],
    omdrFuOptions: IOmdrFuOption[],
    omds: IOmdOption[],
    plannedBillingCodeOptions: IPlannedBillingCodeOption[],
    prereviewAlerts: IPrereviewAlertOption[],
    procedureList: string[][],
    provinces: IProvinceOption[],
    regions: IRegionOption[],
    reviewSelectors: IReviewSelectorsOption[],
    rxRanges: IRxRange | null,
    surgeryTypes: string[][],
    txAlgoKeywords: ITxalgo3Option[],
    odStatuses: IODStatusOption[],
    clinicLocationHeaders: IClinicLocationHeader[],
    insuranceTypeOptions: IInsuranceTypeOption[],
    ghtOptions: IGHTOption[],
    billingRegionOptions: IBillingRegionOption[],
}
interface IOptionsResponseData {
    algo_keywords: ITxalgo3Option[],
    applanation_options: IApplanationOption[],
    compliance_list: string[][],
    do_not_fax_numbers: string[],
    drops_list: string[][],
    eyes_list:  string[][],
    fu_periods:  string[][],
    machines_list: string[][],
    odgrouppractices_list: IOdGroupPracticeOption[],
    ods: IOdOption[],
    omd_networks_list: IOmdNetworkOption[],
    omd_note_options: IOmdNoteOption[],
    omdc_fu_options: IOmdcFuOption[],
    omdr_fu_options: IOmdrFuOption[],
    omds: IOmdOption[],
    planned_billing_code_options: IPlannedBillingCodeOption[],
    prereview_alerts: IPrereviewAlertOption[],
    procedure_list: string[][],
    province_options: IProvinceOption[],
    regions_list: IRegionOption[],
    review_selectors: IReviewSelectorsOption[],
    rx_options: IRxRange,
    surgery_types_list: string[][],
    od_statuses_list: IODStatusOption[],
    clinic_location_headers_list: IClinicLocationHeader[],
    insurance_type_options: IInsuranceTypeOption[],
    ght_options: IGHTOption[],
    billing_region_options: IBillingRegionOption[],
}
const initialState : IOptions = {
    isLoading: false,
    applanations: [],
    doNotFaxNumbers: [],
    eyeTypes: [],
    fuPeriods: [],
    glcComplianceList: [],
    glcDropsList: [],
    machinesList: [],
    odgrouppractices: [],
    ods: [],
    omd_networks: [],
    omdcFuOptions: [],
    omdNoteOptions: [],
    omdrFuOptions: [],
    omds: [],
    plannedBillingCodeOptions: [],
    prereviewAlerts: [],
    procedureList: [],
    provinces: [],
    regions: [],
    reviewSelectors: [],
    rxRanges: null,
    surgeryTypes: [],
    txAlgoKeywords: [],
    odStatuses: [],
    clinicLocationHeaders: [],
    insuranceTypeOptions: [],
    ghtOptions: [],
    billingRegionOptions: [],
}

// GET: getOptionsRequest
export const getOptionsRequest = createAsyncThunk(
    'options/getOptions',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/options/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const optionsSlice = createSlice({
    name: 'options',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOptionsRequest.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOptionsRequest.fulfilled, (state, action: PayloadAction<IOptionsResponseData>) => {
            const options = action.payload;
            return {
                ...state,
                isLoading: false,
                // Convert applanation options to use front-end labels.
                applanations: convertBackendApplanationToFrontend(options.applanation_options),
                doNotFaxNumbers: options.do_not_fax_numbers,
                eyeTypes: options.eyes_list,
                fuPeriods: options.fu_periods,
                glcComplianceList: options.compliance_list,
                glcDropsList: options.drops_list,
                machinesList: options.machines_list,
                odgrouppractices: options.odgrouppractices_list,
                ods: options.ods,
                omd_networks: options.omd_networks_list,
                omdcFuOptions: options.omdc_fu_options,
                omdNoteOptions: options.omd_note_options,
                omdrFuOptions: options.omdr_fu_options,
                omds: options.omds,
                plannedBillingCodeOptions: options.planned_billing_code_options,
                prereviewAlerts: options.prereview_alerts,
                procedureList: options.procedure_list,
                provinces: options.province_options,
                regions: options.regions_list,
                reviewSelectors: options.review_selectors,
                rxRanges: options.rx_options,
                surgeryTypes: options.surgery_types_list,
                txAlgoKeywords: options.algo_keywords,
                odStatuses: options.od_statuses_list,
                clinicLocationHeaders: options.clinic_location_headers_list,
                insuranceTypeOptions: options.insurance_type_options,
                ghtOptions: options.ght_options,
                billingRegionOptions: options.billing_region_options,
            }
        });
        builder.addCase(getOptionsRequest.rejected, (state, action) => {
            state.isLoading = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting options data. ${action.payload}`,
            })
        });
    }
});

export default optionsSlice.reducer;