import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUntilYesterdayItem, IOngoingItem } from './patient-exam-slice';

export interface IGptExamData {
    until_yesterday: { show: boolean; values: IUntilYesterdayItem[] };
    ongoing: { show: boolean; values: IOngoingItem[] };
}

const initialState: IGptExamData = {
    until_yesterday: { show: false, values: [] },
    ongoing: { show: false, values: [] }
}

export const gptGptExamSlice = createSlice({
    name: 'gptGptExamSlice',
    initialState,
    reducers: {
        clearGptExamData: () => initialState,
        setGptExamDataValues: (state, action: PayloadAction<Partial<IGptExamData>>) => {
            Object.assign(state, action.payload);
        }
    }
});

export const { clearGptExamData, setGptExamDataValues } = gptGptExamSlice.actions;

export default gptGptExamSlice.reducer;
