import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Col, Divider, Row } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import '../../../static/css/components/macula-abnormality.scss';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import { IMaculaAbnormalityResultsItem } from '../../reducers/macula-abnormality-slice';

const MaculaSegmentationAnalysisForm = () => {

    const examId = useCare1AppSelector(store => store.maculaHistoricalAnalysis.selected_exam_id);
    const side = useCare1AppSelector(store => store.maculaHistoricalAnalysis.selected_side);
    const leftMaculaHistoricalAnalysis = useCare1AppSelector(store => store.maculaHistoricalAnalysis.left_macula_historical_analysis);
    const rightMaculaHistoricalAnalysis = useCare1AppSelector(store => store.maculaHistoricalAnalysis.right_macula_historical_analysis);

    // Find the exam that matches the selected exam ID.
    let currentExam = (side === 'left') ? leftMaculaHistoricalAnalysis.find(entry=>entry.exam_id === examId) : 
        rightMaculaHistoricalAnalysis.find(entry=>entry.exam_id===examId);

    const printableDate = currentExam ? new Date(currentExam.exam_date).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', year: 'numeric' }) : '';
    const heading = `Segmentation Analysis: ${printableDate}`;
    const allSegmentationImages = (side === 'left') ? currentExam?.macula_abnormality_results.left: 
        currentExam?.macula_abnormality_results.right;

    const COLUMN_WIDTH = 6;
    const IMAGES_PER_ROW = 4;

    const getSegmentationImageCards = (currentImages: IMaculaAbnormalityResultsItem[]) => {

        const imageCards = [];

        currentImages.forEach((image, index) => {
            let photoUrl = image.image;
            if (photoUrl === '') {
                photoUrl = side === 'right' ? currentExam?.right_fundus_photo || '' : currentExam?.left_fundus_photo || '';
            }

            const mainSrcUrl = getBackendMediaUrl() + photoUrl;

            if (image.point !== 0) {
                imageCards.push(
                    <Col key={index} className={'image-col'} span={COLUMN_WIDTH} data-testid={`image-col-${index}`}>
                        <Card key={index} bordered={false}>
                            <div id={index.toString()} className={`zoomed-image extra-image image`}>
                                <img
                                    role="img"
                                    alt=""
                                    className="img-thumbnail"
                                    data-testid="img-thumbnail"
                                    src={mainSrcUrl}
                                    onError={
                                        (e) => {
                                            // If the the thumbnail image can't be found, just use the full image.
                                            if (e.currentTarget.src !== mainSrcUrl) {
                                                e.currentTarget.src = mainSrcUrl;
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className='segmentation-analysis-data'>
                                <Row>
                                    <Col className='percentageLayer' span={12}>
                                        <span>{image.point}% Layer</span>
                                    </Col>
                                    <Col className='countNumber' span={12}>
                                        <span>Count: {image.num_blue_pixels}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                );
            }
        });

        const numImages = imageCards.length;
        for (let i = numImages; i < IMAGES_PER_ROW; i += 1) {
            imageCards.push(
                <Col key={i} span={COLUMN_WIDTH} data-testid={`blank-history-image-${i}`}>
                    <Card key={i} bordered={false}>
                        <div className='blank-history-image'>
                            <PictureOutlined />
                        </div>
                    </Card>
                </Col>
            );
        }

        return imageCards;
    }

    return (
        <div className="macula-segmentation-analysis patient-exam-fundus-history images-history-card">
            <Row className='segmentation-title'>
                <Col>
                <div className="heading segmentation-analysis-heading">
                    {heading}
                </div>
                </Col>
            </Row>
            <Divider />
            <Row className='image-row' data-testid='image-row-0'>
                { getSegmentationImageCards(allSegmentationImages?.slice(0, IMAGES_PER_ROW) || []) }
            </Row>
            <Row className='image-row' data-testid='image-row-1'>
                { getSegmentationImageCards(allSegmentationImages?.slice(IMAGES_PER_ROW) || []) }
            </Row>
        </div >
    );
}

export default MaculaSegmentationAnalysisForm;
