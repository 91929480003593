import { Col, ConfigProvider, Layout, Row } from 'antd';
import React, { useState } from 'react';
import NavigationControlBar from '../components/navigation-control-bar';

import SmartUploadPatientsToReviewTable from '../components/integrated/smartUpload/smart-upload-patients-to-review-table';
import SmartUploadTableControlBar from '../components/integrated/smartUpload/smart-upload-table-control-bar';
import { componentsSelect } from '../components/theme/theme';
import SmartUploadTable from '../components/integrated/smartUpload/smart-upload-table';
import styles from '../../static/css/modules/smart-upload-page.module.scss';



const theme = {
    components: {
        Select: {
            ...componentsSelect,
            borderRadiusLG: 4,
        },
        Typography: {
            colorText: '#FFFFFF',
        },
    },
};

const { Header, Content } = Layout;

const SmartUploadPage: React.FC = () => {

    return (
        <ConfigProvider theme={theme}>
            <Layout>
                <Header>
                    <NavigationControlBar
                        className='patient-list-navigation-control-bar'
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <Row gutter={[16, 24]} className={styles.smartUploadPage}>
                        <Col span={9}>
                            <SmartUploadPatientsToReviewTable />
                        </Col>
                        <Col span={15}>
                            <SmartUploadTableControlBar />
                        </Col>
                        <Col span={24}>
                            <SmartUploadTable />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </ConfigProvider>
    );
};

export default SmartUploadPage;