import { Key, useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { localizedText } from '../../../localizedText';
import { compareStrings } from '../../../helpers/sorting';
import { IPatientManagementGpMatchingTableItem } from '../../../services/patient-management-api';

import '../../../../static/css/components/patient-matching-list-table.scss';
import { RowSelectMethod } from 'antd/es/table/interface';

type AppProps = {
    tableList: IPatientManagementGpMatchingTableItem[],
    gpMatchingOps: { [k: number]: string },
    setGpMatchingOps: (v: { [k: number]: string }) => void,
    selectedIds: Set<number>,
    setSelectedIds: (s: Set<number>) => void,
}

const gpMatchingActionOptions = [
    {
        value: 'na',
        label: 'N/A',
    },
    {
        value: 'target',
        label: 'Target',
    },
    {
        value: 'delete',
        label: 'Delete',
    },
]

const GpMatchingListTable = ({ tableList, gpMatchingOps, setGpMatchingOps, selectedIds, setSelectedIds }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const getOp = (id: number) => {
        if (id in gpMatchingOps) {
            return gpMatchingOps[id];
        }
        return 'na';
    }

    const setOp = (id: number, v: string) => {
        const res = { ...gpMatchingOps };

        let targetSet = false;
        for (let i = 0; i < tableList.length; i++) {
            if (getOp(tableList[i].id) == 'target') {
                targetSet = true;
                break;
            }
        }

        if (targetSet === false) {
            res[id] = v;
        }
        else {
            if (v !== 'target') {
                res[id] = v;
            }
        }

        setGpMatchingOps(res);
    }

    const nameColumns: ColumnsType<IPatientManagementGpMatchingTableItem> = [
        {
            className: 'gp_name-column',
            title: 'Gp Name',
            dataIndex: 'gp_name',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'gp_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'fax_number-column',
            title: 'Fax',
            dataIndex: 'fax_number',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'fax_number'-${record.key}`}>{text}</div>
        },
        {
            className: 'province-column',
            title: 'Province',
            dataIndex: 'province',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'province'-${record.key}`}>{text}</div>
        },
        {
            className: 'auto_referral-column',
            title: 'Auto Referral',
            dataIndex: 'auto_referral',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'auto_referral'-${record.key}`}>{text ? 'YES' : 'NO'}</div>
        },
        {
            className: 'action-column',
            title: 'Merge Gps',
            width: '30px',
            render: (text, record) =>
                <Select
                    className="patient-matching-action-select"
                    optionFilterProp="label"
                    options={gpMatchingActionOptions}
                    defaultValue={'na'}
                    value={getOp(record.id)}
                    onChange={(v) => setOp(record.id, v)}
                />,
        }
    ];

    const getSelectedRowKeys = () => {
        return Array.from(selectedIds) as Key[];
    }

    const onChange = (selectedRowKeys: Key[], selectedRows: IPatientManagementGpMatchingTableItem[], info: {
        type: RowSelectMethod;
    }) => {
        const idSet = new Set(selectedRows.map(obj => obj.id));
        setSelectedIds(new Set(idSet));
    }

    return (
        <div className={'patient-matching-list-table'}>
            <div className={scrollClass}>
                <Table
                    rowSelection={{
                        type: 'radio',
                        onChange: onChange,
                        selectedRowKeys: getSelectedRowKeys(),
                    }}
                    columns={nameColumns}
                    dataSource={tableList}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 50,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                />
            </div>
        </div>
    );
}

export default GpMatchingListTable;