import { Col, Flex } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import classnames from 'classnames';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import { setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';
import checkableTagStyles from '../../../../static/css/components/checkable-tag.module.scss';
import textareaCardstyles from '../../../../static/css/components/component-textarea-card.module.scss';
import ComponentTextareaCard from '../../component-textarea-card';

const UploadReconcilerAdminReconciliationNotes = () => {
    const adminReconciliationNotesAddressed = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciliation_notes_addressed
    );
    const adminReconciliationNotes = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciliation_notes
    );

    const dispatch = useCare1AppDispatch();

    const checkableTagClassNames = classnames({
        [checkableTagStyles.checkableTag]: true,
        [checkableTagStyles.checkableTagChecked]:
            adminReconciliationNotesAddressed,
    });

    const HeadingComponent = () => (
        <>
            <Flex justify='space-between' align='center'>
                <Col span={15}>
                    <span className={textareaCardstyles.heading}>
                        Admin Reconciliation Notes
                    </span>
                </Col>
                <Col span={9}>
                    <CheckableTag
                        checked={adminReconciliationNotesAddressed}
                        onChange={(checked) =>
                            dispatch(
                                setUploadReconcilerSliceDataValue({
                                    key: 'admin_reconciliation_notes_addressed',
                                    value: checked,
                                })
                            )
                        }
                        className={checkableTagClassNames}
                    >
                        Addressed
                    </CheckableTag>
                </Col>
            </Flex>
        </>
    );

    return (
        <ComponentTextareaCard
            disabled={false}
            title={<HeadingComponent />}
            testId='admin-private-notes'
            value={adminReconciliationNotes}
            lightBlueBackground={true}
            handleChange={(e) =>
                dispatch(
                    setUploadReconcilerSliceDataValue({
                        key: 'admin_reconciliation_notes',
                        value: e.target.value,
                    })
                )
            }
        />
    );
};

export default UploadReconcilerAdminReconciliationNotes;
