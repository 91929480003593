import { CloseOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Card, Image, Progress, Space, Typography, Upload } from 'antd';
import { useState } from 'react';
import styles from '../../../../static/css/components/file-uploader.module.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../../helpers/media-image-convert';
import { deleteDiagnosisFile, isStepTwoComplete, setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import PDFViewer from '../../retina/pdf-viewer';


type PreviewType = {
    url: string,
    type: string,
}

const FileUploader = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const csrfToken = useCare1AppSelector((store) => store.user.csrfToken);
    const diagnosisFileList = useCare1AppSelector((store) => store.smartUpload.diagnosisFileList);
    const id = useCare1AppSelector((store) => store.smartUpload.id);
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);

    const [previewFile, setPreviewFile] = useState<PreviewType | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>(diagnosisFileList)

    const dispatch = useCare1AppDispatch();

    const handlePreview = async (file: UploadFile) => {

        if (file.type === 'application/pdf' || file.type === 'pdf') {
            setPreviewFile({ url: file.url ?? '', type: 'pdf' });
        } else {
            setPreviewFile({ url: file.url ?? '', type: 'image' });
        }

        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({file: currentFile,
        fileList: newFileList
    }) => {
        if (currentFile.status === 'done') {
            dispatch(setSmartUploadSliceDataValue({key: 'saving', value: false}));
        } else if (currentFile.status === 'uploading') {
            dispatch(setSmartUploadSliceDataValue({key: 'saving', value: true}));
        }
        setFileList([...newFileList]);

        if (newFileList.every((newFile) => newFile.status === 'done')){
            const updatedFileList = newFileList.map((file) => {
                if (
                    file.status === 'done' &&
                    file.response &&
                    file.response?.thumbUrl
                ) {
                    return {
                        ...file,
                        thumbUrl: getBackendMediaUrl() + file.response?.thumbUrl,
                        url: getBackendMediaUrl() + file.response?.url,
                        id: file.response?.id,
                    };
                } else {
                    return file;
                }
            });
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'diagnosisFileList',
                    value: updatedFileList,
                })
            );
            setFileList(updatedFileList);
            
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'stepTwoComplete',
                    value: dispatch(isStepTwoComplete()),
                })
            );
        }
    
    };

    const handleDeleteDiagnosis = async (file: UploadFile<any> ) => {
        try {
            const res = await dispatch(deleteDiagnosisFile((file as UploadFile & {id: number}).id)).unwrap();
            if (res?.error) throw new Error(res?.error)
        } catch (error) {
            console.error(error);
        }
    }

    const emptyContent = (
        <div className={styles.dropzoneEmpty}>
            <div className={styles.dropzoneEmptyImage}></div>
            <div className={styles.dropzoneEmptyText}>
                Drag & Drop to upload
            </div>
        </div>   
    );

    return (
        <>
            <Upload.Dragger
                action={`${process.env.REACT_APP_BACKENDURL}/data/smart_upload/file_upload/`}
                headers={{ 'X-CSRFToken': csrfToken }}
                data={(file) => ({ 
                    id,
                     })}
                withCredentials
                listType='text'
                multiple
                directory
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                openFileDialogOnClick={false}
                onRemove={handleDeleteDiagnosis}
                disabled={disabled}
                itemRender={(orignalNode, file, fileList, actions) => {
                    const url = file.thumbUrl
                        ? file.thumbUrl
                        : file.url
                        ? file.url
                        : '';

                    return file.status !== 'done' ? (
                        <Card className={styles.progressCard} size='small'>
                            <Typography.Text className={styles.uploadingText}>
                                Uploading...
                            </Typography.Text>
                            <Progress
                                percent={file.percent}
                                format={(percent) =>
                                    percent &&
                                    `${parseFloat(percent.toFixed(2))}%`
                                }
                                showInfo={false}
                            />
                        </Card>
                    ) : (
                        <Card
                            className={styles.imageCard}
                            hoverable
                            onClick={() => handlePreview(file)}
                        >
                            <div className={styles.cardCover}>
                                <img alt={file.fileName} src={url} />
                            </div>
                            <div className={styles.cardTitle}>
                                <Typography.Text
                                    ellipsis={{ tooltip: file.name }}
                                >
                                    {file.name.split('.')[0]}
                                </Typography.Text>
                            </div>
                            { !disabled &&
                            <CloseOutlined
                                className={styles.closeIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    actions.remove();
                                }}
                            />
                            }
                        </Card>
                    );
                }}
                className={`${styles.fileUploader} ${styles.uploadWrapper}`}
            > {diagnosisFileList.length > 0 ? <></> : emptyContent}</Upload.Dragger>

            {previewFile && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewFile(null),
                        imageRender: (originalNode) => {
                            if (previewFile.type === 'pdf') {
                                return (
                                    <PDFViewer
                                        file={previewFile.url}
                                        onPDFLoadSuccess={() => {}}
                                        onPDFLoadError={() => {}}
                                        onClose={() => {}}
                                    />
                                );
                            } else {
                                return originalNode;
                            }
                        },
                        toolbarRender: (
                            _,
                            {
                                transform: { scale },
                                actions: { onZoomOut, onZoomIn },
                            }
                        ) => {
                            if (previewFile.type === 'pdf') {
                                return null;
                            } else {
                                return (
                                    <Space
                                        size={12}
                                        className={styles.toolbarWrapper}
                                    >
                                        <ZoomOutOutlined
                                            disabled={scale === 1}
                                            onClick={onZoomOut}
                                        />
                                        <ZoomInOutlined
                                            disabled={scale === 50}
                                            onClick={onZoomIn}
                                        />
                                    </Space>
                                );
                            }
                        },
                    }}
                    src={previewFile.url}
                />
            )}
        </>
    );
};

export default FileUploader;
