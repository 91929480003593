import { useEffect, useState } from 'react';
import { Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { localizedText } from '../../localizedText';
import { IAwsCostSavingRecommendationItem } from '../../services/aws-cost-report-api';
import '../../../static/css/components/sales-integration-report-table.scss';


interface IAwsCostReportTableItemWithKey extends IAwsCostSavingRecommendationItem {
    key: number;
}

interface ComponentProps {
    awsCostSavingReport: IAwsCostSavingRecommendationItem[] | undefined;
}

const AwsCostSavingReportTable = ({ awsCostSavingReport }: ComponentProps) => {

    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const reports: IAwsCostReportTableItemWithKey[] | undefined = awsCostSavingReport?.map((v: IAwsCostSavingRecommendationItem, idx: number) => { return { ...v, key: idx } });

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const formatDateRange = (dateStr: string): string => {
        return dayjs(dateStr).format("MMM YYYY");
    };

    const columns: ColumnsType<IAwsCostReportTableItemWithKey> = [{
        className: 'report-current_resource_type-column',
        title: 'Action type',
        width: '50%',
        dataIndex: 'current_resource_type',
        // sorter: (a, b) => compareStrings(a['program'], b['program']),
        render: (text, record) => <div data-testid={`report-current_resource_type-${record.key}`}>{text}</div>
    }, {
        className: 'report-estimated_monthly_savings_after_discount-column',
        title: 'Estimated monthly savings',
        dataIndex: 'estimated_monthly_savings_after_discount',
        // sorter: (a, b) => compareStrings(a['program'], b['program']),
        render: (text, record) => <div data-testid={`report-estimated_monthly_savings_after_discount-${record.key}`}>{text}</div>
    }];


    return (
        <div className={'patient-list-table sales-integration-report-table tableContent'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    dataSource={reports}
                    pagination={false}
                />
            </div>
        </div>
    );

}

export default AwsCostSavingReportTable;
