import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
import { REQUIRED_DTC_NUMBER } from '../constants';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { IUploadReconcilerPatientMatchingTableItem, uploadReconcilerApi } from '../services/upload-reconciler-api';
import { APIResponse, createCare1AsyncThunk } from './care1-slice-types';
import { ISmartUpload } from './smart-upload-slice';
import { logout } from './user-slice';

type ValueOf<T> = T[keyof T];
type ValueOfUploadReconcilerData = ValueOf<IUploadReconciler>;
type DobFields = 'dob_day' | 'dob_month' | 'dob_year';

export type UploadReconcilerResponseData =
    | {
          success: true;
          error: never;
          data: Omit<IUploadReconciler, DobFields> | null;
      }
    | {
          success: false;
          error: string;
          data?: never;
      };

export type UploadReconcilerImageField =
    | 'left_fundus_photo'
    | 'left_stereo_photo'
    | 'left_oct_photo'
    | 'left_vf_photo'
    | 'left_oct_rnfl_photo'
    | 'right_fundus_photo'
    | 'right_stereo_photo'
    | 'right_oct_photo'
    | 'right_vf_photo'
    | 'right_oct_rnfl_photo'
    | 'extra_images';


export type DraggableImage = {
    url: string,
    base64Url: string,
}
export interface IUploadReconciler {
    id: number | null;
    optometrist_name: string;
    optometrist_location: string;
    od_account: number | null;
    exam_date: Dayjs | null;
    dob: string;
    first_name: string;
    last_name: string;
    primary_phone: string;
    province: number | null;
    insurance_type: string;
    phn: string;
    left_fundus_photo: DraggableImage | null;
    left_stereo_photo: DraggableImage | null;
    left_oct_photo: DraggableImage | null;
    left_vf_photo: DraggableImage | null;
    left_oct_rnfl_photo: DraggableImage | null;
    right_fundus_photo: DraggableImage | null;
    right_stereo_photo: DraggableImage | null;
    right_oct_photo: DraggableImage | null;
    right_vf_photo: DraggableImage | null;
    right_oct_rnfl_photo: DraggableImage | null;
    extra_images: DraggableImage[];
    loading: boolean;
    dob_month: string;
    dob_year: string;
    dob_day: string;
    admin_reconciliation_notes: string;
    admin_reconciliation_notes_addressed: boolean;
    admin_images_are_verified: boolean;
    admin_reconciler: string;
    matched_patients: IUploadReconcilerPatientMatchingTableItem[];
    submittingExam: boolean;
}

const initialState: IUploadReconciler = {
    id: null,
    optometrist_name: '',
    optometrist_location: '',
    od_account: null,
    exam_date: null,
    dob: '',
    first_name: '',
    last_name: '',
    primary_phone: '',
    province: null,
    insurance_type: '',
    phn: '',
    left_fundus_photo: null,
    left_stereo_photo: null,
    left_oct_photo: null,
    left_vf_photo: null,
    left_oct_rnfl_photo: null,
    right_fundus_photo: null,
    right_stereo_photo: null,
    right_oct_photo: null,
    right_vf_photo: null,
    right_oct_rnfl_photo: null,
    extra_images: [],
    loading: false,
    dob_month: '',
    dob_year: '',
    dob_day: '',
    admin_reconciliation_notes: '',
    admin_reconciliation_notes_addressed: false,
    admin_images_are_verified: false,
    admin_reconciler: '',
    matched_patients: [],
    submittingExam: false,

};

export const getUploadReconcilerData = createAsyncThunk(
    'smartUpload/getUploadReconcilerData',
    async (smartUploadId: number, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/upload_reconciler/${smartUploadId}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const updateAllUploadReconcilerData = createAsyncThunk(
    'uploadReconciler/updateAllUploadReconcilerData',
    async (
        requestData: Partial<IUploadReconciler>,
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const { id, exam_date, ...data } = requestData;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/upload_reconciler/update_all/${id}`;

        const body = JSON.stringify({
            ...data,
            exam_date: exam_date ? exam_date.toISOString() : '',
        });

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

const uploadReconcilerExamRequestData = ({
    smartUpload,
    uploadReconciler,
}: {
    smartUpload: ISmartUpload;
    uploadReconciler: IUploadReconciler;
}) => {
    const {
        submissionDate,
        referralLetterUrl,
        od_notes,
        high_priority,
        od_wants_omd_report,
        od_requested_omd,
        mini_dtc_time,
        id,
    } = smartUpload;
    const {
        exam_date,
        matched_patients,
        od_account,
        left_fundus_photo,
        left_oct_photo,
        left_oct_rnfl_photo,
        left_stereo_photo,
        left_vf_photo,
        right_fundus_photo,
        right_oct_photo,
        right_oct_rnfl_photo,
        right_stereo_photo,
        right_vf_photo,
        extra_images,
    } = uploadReconciler;

    const dtcValuesArray = [];
    type DctValueKeys =
        | 'od_iop_1'
        | 'od_iop_2'
        | 'od_iop_3'
        | 'os_iop_1'
        | 'os_iop_2'
        | 'os_iop_3';

    for (let i = 0; i < REQUIRED_DTC_NUMBER; i++) {
        const odValue = smartUpload[`od_iop_${i + 1}` as DctValueKeys];
        const osValue = smartUpload[`os_iop_${i + 1}` as DctValueKeys];
        const dtcObj = {} as { od?: number; os?: number };
        if (odValue) {
            dtcObj['od'] = odValue;
        }
        if (osValue) {
            dtcObj['os'] = osValue;
        }
        if (odValue || osValue) {
            dtcValuesArray.push(dtcObj);
        }
    }

    const extraImagesData = extra_images.map((img) => ({
        ...img,
        base64Url: img.url ? '' : img.base64Url,
    }));

    return {
        exam_date: getUploadReconcilerExamDate(exam_date, submissionDate),
        patient_id: matched_patients[0].id,
        optometrist: od_account,
        referral_letter: referralLetterUrl,
        od_omd: od_notes,
        is_urgent: high_priority,
        od_wants_omd_report,
        od_requested_omd,
        left_fundus_photo: left_fundus_photo,
        left_oct_photo: left_oct_photo,
        left_oct_rnfl_photo: left_oct_rnfl_photo,
        left_stereo_photo: left_stereo_photo,
        left_vf_photo: left_vf_photo,
        right_fundus_photo: right_fundus_photo,
        right_oct_photo: right_oct_photo,
        right_oct_rnfl_photo: right_oct_rnfl_photo,
        right_stereo_photo: right_stereo_photo,
        right_vf_photo: right_vf_photo,
           
        dtc_hour: mini_dtc_time,
        dtc_values: JSON.stringify(dtcValuesArray),
        extra_images: extraImagesData,
        smart_upload_id: id,
    };
};

export const getUploadReconcilerExamDate = (
    examDate: Dayjs | null,
    submissionDate: Dayjs | null
) => {
    return examDate === null
        ? submissionDate
            ? submissionDate.format()
            : ''
        : examDate.format();
};


export const createUploadReconcilerExam = createCare1AsyncThunk<
    APIResponse<number>,
    void
>(
    'uploadReconciler/updateAllUploadReconcilerData',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
        } = getState();
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const { smartUpload, uploadReconciler } = getState();

        const requestData = uploadReconcilerExamRequestData({
            smartUpload,
            uploadReconciler,
        });

        const body = JSON.stringify(requestData);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/upload_reconciler/create_exam/`;


        

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error as string);
            }
        }
    }
);

export const uploadReconcilerSlice = createSlice({
    name: 'uploadReconcilerSlice',
    initialState,
    reducers: {
        setUploadReconcilerSliceDataValue: (
            state,
            action: PayloadAction<{
                key: keyof IUploadReconciler;
                value: ValueOfUploadReconcilerData;
            }>
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        },
        setUploadReconcilerDataValues: (
            state,
            action: PayloadAction<Partial<IUploadReconciler>>
        ) => {
            Object.assign(state, action.payload);
        },
        setGptExtraImagesAtIndex: (
            state,
            action: PayloadAction<{ base64Image: DraggableImage; idx: number }>
        ) => {
            const { base64Image, idx } = action.payload;
            if (base64Image) {
                if (idx === -1) {
                    state.extra_images = [base64Image, ...state.extra_images];
                } else {
                    const res: DraggableImage[] = [...state.extra_images];
                    res[idx] = base64Image;
                    state.extra_images = res;
                }
            }
        },
        moveBase64Images: (
            state,
            action: PayloadAction<{
                fromField: UploadReconcilerImageField;
                toField: UploadReconcilerImageField;
            }>
        ) => {
            const { fromField, toField } = action.payload;
            if (toField === 'extra_images') {
                return;
            } else {
                if (fromField === 'extra_images') {
                    return {
                        ...state,
                        [toField]: state[fromField],
                    };
                }
                return {
                    ...state,
                    [fromField]: null,
                    [toField]: state[fromField],
                };
            }
        },
        moveBase64ExtraImages: (
            state,
            action: PayloadAction<{
                fromIndex: number;
                toField: UploadReconcilerImageField;
            }>
        ) => {
            const { fromIndex, toField } = action.payload;
            if (toField === 'extra_images') {
                return;
            } else {
                return {
                    ...state,
                    extra_images: state.extra_images.filter(
                        (_, index) => index !== fromIndex
                    ),
                    [toField]: state.extra_images[fromIndex],
                };
            }
        },
        deleteBase64Image: (
            state,
            action: PayloadAction<UploadReconcilerImageField>
        ) => {
            const imageToDelete = state[action.payload] as DraggableImage;
            return {
                ...state,
                [action.payload]: '',
                extra_images: [imageToDelete, ...state.extra_images],
            };
        },
        deleteBase64ExtraImage: (state, action: PayloadAction<number>) => {
            state.extra_images = state.extra_images.filter(
                (_, index) => index !== action.payload
            );
        },
        setAdminReconcilerData: (state, action: PayloadAction<string>) => {
            if (!state.admin_reconciler) {
                state.admin_reconciler = action.payload;
            }
        },
        setUploadReconcilerImageDataValue: (
            state,
            action: PayloadAction<{
                key: keyof IUploadReconciler;
                value: DraggableImage | null;
            }>
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        },
        resetUploadReconcilerData: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getUploadReconcilerData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getUploadReconcilerData.fulfilled,
            (state, action: PayloadAction<UploadReconcilerResponseData>) => {
                state.loading = false;
                if (action.payload.success && action.payload.data) {
                    const { data } = action.payload;

                    state.id = data.id;
                    state.optometrist_name = data.optometrist_name;
                    state.optometrist_location = data.optometrist_location;
                    state.od_account = data.od_account;
                    state.exam_date = dayjs(data.exam_date);
                    state.dob = data.dob;
                    const dayjsObj = dayjs(data.dob);
                    state.dob_year = dayjsObj.year().toString();
                    // Month is 0-indexed.
                    state.dob_month = (dayjsObj.month()+1).toString();
                    state.dob_day = dayjsObj.date().toString();

                    state.first_name = data.first_name;
                    state.last_name = data.last_name;
                    state.primary_phone = data.primary_phone;
                    state.province = data.province;
                    state.insurance_type = data.insurance_type;
                    state.phn = data.phn;
                    state.admin_images_are_verified = data.admin_images_are_verified;
                    state.admin_reconciler = data.admin_reconciler;
                    state.admin_reconciliation_notes = data.admin_reconciliation_notes;
                    state.admin_reconciliation_notes_addressed = data.admin_reconciliation_notes_addressed;

                    state.left_fundus_photo = data.left_fundus_photo;
                    state.left_oct_photo = data.left_oct_photo;
                    state.left_stereo_photo = data.left_stereo_photo
                    state.left_oct_rnfl_photo = data.left_oct_rnfl_photo;
                    state.left_vf_photo = data.left_vf_photo;
                  
                    state.right_fundus_photo = data.right_fundus_photo;
                    state.right_stereo_photo = data.right_stereo_photo;
                    state.right_oct_photo = data.right_oct_photo;
                    state.right_vf_photo = data.right_vf_photo;
                    state.right_oct_rnfl_photo = data.right_oct_rnfl_photo;
                    state.extra_images = data.extra_images;

                    state.matched_patients = data.matched_patients;
                }
            }
        );
        builder.addCase(getUploadReconcilerData.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateAllUploadReconcilerData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateAllUploadReconcilerData.fulfilled,
            (state, action: PayloadAction<UploadReconcilerResponseData>) => {
                state.loading = false;
                
            }
        );
        builder.addCase(updateAllUploadReconcilerData.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addMatcher(uploadReconcilerApi.endpoints.updateUploadReconcilerPatientMatchingList.matchFulfilled, (state, action) => {
            if (action.payload) {
                state.matched_patients = action.payload.data;
            }
           
        });
    },
});

export const {
    setUploadReconcilerSliceDataValue,
    setUploadReconcilerDataValues,
    setGptExtraImagesAtIndex,
    moveBase64Images,
    deleteBase64Image,
    deleteBase64ExtraImage,
    moveBase64ExtraImages,
    setAdminReconcilerData,
    setUploadReconcilerImageDataValue,
} = uploadReconcilerSlice.actions;

export default uploadReconcilerSlice.reducer;
