import React, { useEffect } from 'react';
import { Row, Col, Layout, Spin, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { useCare1AppDispatch } from '../../apps/care1-hooks';
import AwsCostReportTable from './admin-aws-cost-report-table';
import AwsCostServiceGraph from './admin-aws-cost-service-graph';
import AwsCostSavingReportTable from './admin-aws-cost-saving-report-table';
import {
    IAwsCostItem, useLazyGetAwsCostListQuery, useLazyGetAwsCostSavingRecommendationListQuery
} from '../../services/aws-cost-report-api';

import '../../../static/css/pages/sales-page.scss';
import '../../../static/css/shared/date-picker.scss';

import * as Constants from '../../constants';

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

const AdminAwsCostReportTab: React.FC = () => {

    const dispatch = useCare1AppDispatch();

    const [getAwsCostList, { data: data, isFetching: isFetching }] = useLazyGetAwsCostListQuery();
    const [getAwsCostSavingRecommendationList, { data: dataSaving, isFetching: isFetchingSaving }] = useLazyGetAwsCostSavingRecommendationListQuery();

    useEffect(() => {
        getAwsCostList();
        getAwsCostSavingRecommendationList();

    }, [])

    const transformData = (data: IAwsCostItem) => {
        const result = [];

        for (const key in data) {
            // Skip non-date keys
            if (key === "id" || key === "Service") continue;

            // Add the transformed object to the result array
            result.push({
                month: dayjs(key).format("MMM YYYY"),
                value: parseFloat(data[key])
            });
        }

        return result;
    }

    return (
        <div>
            <Row className='reportFilter'>
            </Row>
            <div className="awsCostSaving">
                <Row>
                    <Col className='titleWrapper' span={16}>
                        <span className="list-title">AWS Cost Analysis</span>
                    </Col>
                    <Col className='titleWrapper' span={8}>
                        <div>
                            <Spin
                                className="loading-spinner"
                                spinning={isFetchingSaving}
                                size="large"
                                tip={Constants.OPERATING_TEXT}
                            >
                                <AwsCostSavingReportTable
                                    awsCostSavingReport={dataSaving?.savings_opportunities}
                                />
                            </Spin>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={24}>
                    <Spin
                        className="loading-spinner"
                        spinning={isFetching}
                        size="large"
                        tip={Constants.OPERATING_TEXT}
                    >
                        <AwsCostReportTable
                            awsCostReport={data?.report}
                            awsCostMonthColumn={data?.month_column}
                            colName={data?.col_name}
                        />
                    </Spin>
                </Col>
            </Row>
            {data?.report.map((item) => (
                <Row key={item.id}>
                    <Col span={24}>
                        <AwsCostServiceGraph
                            title={item["Service"]}
                            data={transformData(item)}
                        />
                    </Col>
                </Row>
            ))}
            <Row className='reportFilter'>
            </Row>
        </div>);
}

export default AdminAwsCostReportTab;