import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';


import NavigationControlBar from '../components/navigation-control-bar';

import '../../static/css/pages/performance-metrics-page.scss';
import LatencyMetricsTab from '../components/admin/latency-metrics-tab';
import AdminAwsCostReportTab from '../components/admin/admin-aws-cost-report-tab';

const { Header, Content } = Layout;

const PerformanceMetricsPage: React.FC = () => {

    const defaultTab = 'item-latencymetricspage';

    const [activeKey, setActiveKey] = useState(defaultTab);

    const getLatencyTab = () => {
        return <LatencyMetricsTab />
    }

    const getAwsCostTab = () => {
        return <AdminAwsCostReportTab />
    }

    const tabs = [{
        label: 'Latency', key: 'item-latencymetricspage',
        children: getLatencyTab()
    }, {
        label: 'AWS Cost', key: 'item-awscosttab',
        children: getAwsCostTab()
    }];

    return (
        <Layout className='salesReportPage'>
            <Header>
                <NavigationControlBar
                    className="patient-list-navigation-control-bar"
                    breadcrumbItems={[]}
                />
            </Header>
            <Content>
                <div className='sales-page'>
                    <div className='title'>
                        Performance Metrics
                    </div>
                    <div>
                        <Tabs
                            defaultActiveKey={defaultTab}
                            activeKey={activeKey}
                            items={tabs}
                            onTabClick={(key: string, e: React.KeyboardEvent | React.MouseEvent) => {
                                setActiveKey(key);
                            }}
                        />
                    </div>
                </div>
            </Content>
        </Layout>
    );
}

export default PerformanceMetricsPage;