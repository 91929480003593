import { useState, useRef } from 'react';
import { Row, Tabs, Modal } from 'antd';
import { pdfjs, Page, Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { REFERRAL_LETTER_VIEWER_MAX_SCALE, REFERRAL_LETTER_VIEWER_MIN_SCALE, REFERRAL_LETTER_VIEWER_SCALE_MULTIPLIER
} from '../../constants';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { retrieveModifiedRLUPdfs, retrieveModifiedRLUPdfsForPreviousExam } from '../../reducers/patient-exam-slice';
import { PlusOutlined, MinusOutlined, EditOutlined, ReloadOutlined,  } from '@ant-design/icons';
import '../../../static/css/components/admin-referral-letter-viewer.scss';

// Helpers

// Components
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { useReferralLetterModifictionRequestMutation } from '../../services/exam-api';
import EditPdfModal from './edit-pdf-modal';

const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const AdminReferralLetterViewer = () => {
    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData.id);
    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter_pdf);
    const currentHighlightedPDFURL = useCare1AppSelector(store => store.examData.highlight_omd_pdf);
    const recipientRemovedPDFURL = useCare1AppSelector(store => store.examData.recipient_removed_pdf);

    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const pastPhotoURL = useCare1AppSelector(store => store.examData.past_od_referral_letter_pdf);
    const pastHighlightedPDFURL = useCare1AppSelector(store => store.examData.past_highlight_omd_pdf);
    const pastRecipientRemovedPDFURL = useCare1AppSelector(store => store.examData.past_recipient_removed_pdf);
    const usePastPhoto = examIsODMessaging && !currentPhotoURL && pastPhotoURL;
    const usePastHighlightedPDFURL = examIsODMessaging && !currentHighlightedPDFURL && pastHighlightedPDFURL;
    const usePastRecipientRemovedPDFURL = examIsODMessaging && !recipientRemovedPDFURL && pastRecipientRemovedPDFURL;

    const [referralLetterModifiction] = useReferralLetterModifictionRequestMutation();

    const currentReferralLetterUrl = (usePastPhoto || usePastHighlightedPDFURL) ? pastHighlightedPDFURL || pastPhotoURL 
        : currentHighlightedPDFURL || currentPhotoURL;
    const currentRecipientRemovedPDFURL = usePastRecipientRemovedPDFURL ? pastRecipientRemovedPDFURL : recipientRemovedPDFURL;
    // Get ID of exam where past recipient removed PDF is from.
    const pastRecipientRemovedPDFExamIds = pastRecipientRemovedPDFURL.match('(?<=/exam/)[0-9]*?(?=/)');
    const pastRecipientRemovedPDFExamId = (pastRecipientRemovedPDFExamIds && pastRecipientRemovedPDFExamIds!.length > 0) ? pastRecipientRemovedPDFExamIds![0] : '';
    // The editor will use ID of whichever exam's PDF is being used.
    const examIdForReload = usePastRecipientRemovedPDFURL ? (Number(pastRecipientRemovedPDFExamId) || examId) : examId;

    const [numPages, setNumPages] = useState(1);
    const [highlightedScale, setHighlightedScale] = useState(1);
    const [recipientRemovedScale, setRecipientRemovedScale] = useState(1);
    const [zoom, setZoom] = useState(1.0);

    const highlightedScaleRef = useRef(highlightedScale);
    const recipientRemovedScaleRef = useRef(recipientRemovedScale);

    const [editPdfModalOpen, setEditPdfModalOpen] = useState(false);

    const zoomIn = () => {
        setZoom(zoom * 1.1);
    }

    const zoomOut = () => {
        setZoom(zoom / 1.1);
    }

    const onPDFLoadSuccess = ({numPages}: {numPages: number}) => {
        setNumPages(numPages);
    }

    const onReferralLetterViewerHighlightedPageLoad = ({originalWidth}: {originalWidth: number}) => {
        const parentDiv = document.querySelector('#admin-referral-letter-container');
        if (parentDiv) {
            const pageScale = parentDiv.clientWidth / originalWidth;

            if (highlightedScaleRef.current !== pageScale) {
                highlightedScaleRef.current = pageScale;
                setHighlightedScale(pageScale);
            }
        }
      }
    
      const onReferralLetterViewerRecipientRemovedPageLoad = ({originalWidth}: {originalWidth: number}) => {
        const parentDiv = document.querySelector('#admin-referral-letter-container');
        if (parentDiv) {
            const pageScale = parentDiv.clientWidth / originalWidth;

            if (recipientRemovedScaleRef.current !== pageScale) {
                recipientRemovedScaleRef.current = pageScale;
                setRecipientRemovedScale(pageScale);
            }            
        }
      }
    
    const handleReloadClick = async () => {
        try {
            const result = await referralLetterModifiction({examId:examIdForReload!, confirmed: false}).unwrap();
            if (result?.exists) {
                Modal.confirm({
                    className: 'info-modal',
                    title: result.error ? result.error : 'Are you sure to re-run the referral letter modification task?',
                    onOk: async () => {
                        const res = await referralLetterModifiction({examId: examIdForReload!, confirmed: true}).unwrap();
                        if (res && res.success) {
                            Modal.info({
                                className: 'info-modal',
                                title: 'Referral letter task added successfully.',
                            });
                        }
                    },
                })
            } else if (result?.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Referral letter task added successfully.',
                });
            } else {
                throw new Error(result?.error)
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleEditClick = () => {
        setEditPdfModalOpen(true);
    }

    const highlighted = () => (
        <div className='admin-referral-letter-viewer'>
            { currentReferralLetterUrl &&
                <div className='referral-letter-controls'>
                    <PlusOutlined
                        className='control-icon referral-letter-zoom-in'
                        onClick={zoomIn} />
                    <MinusOutlined
                        className='control-icon referral-letter-zoom-out'
                        onClick={zoomOut} />
                </div>
            }
            <Document
                file={currentReferralLetterUrl}
                onLoadSuccess={onPDFLoadSuccess}
                noData={<div className='referral-letter-message'>
                            <div className='referral-letter-empty-text'>No Referral Letter</div>
                        </div>}
            >
                <Row className='referral-letter-display'>
                    {Array.from(new Array(numPages), (element, index) => (
                        <Page 
                            key={`page_${index + 1}`} 
                            pageNumber={index + 1} 
                            onLoadSuccess={onReferralLetterViewerHighlightedPageLoad}
                            scale={highlightedScale * zoom}
                        />
                    ))}
                </Row>
            </Document>
        </div>
    )

    const recipientRemoved = () => (
        <div className='admin-referral-letter-viewer'>
            { currentRecipientRemovedPDFURL ?
            <>
                <div className='referral-letter-controls'>
                    <PlusOutlined
                        className='control-icon referral-letter-zoom-in'
                        onClick={zoomIn} />
                    <MinusOutlined
                        className='control-icon referral-letter-zoom-out'
                        onClick={zoomOut} />
                </div>
                <div className='referral-letter-edit'>
                    <EditOutlined
                        className='referral-letter-edit-button'
                        onClick={handleEditClick}
                    />
                    <ReloadOutlined
                        onClick={handleReloadClick}
                        className='referral-letter-reload-button' />
                </div>
                </>
                :
                <div className='referral-letter-edit'>
                    <ReloadOutlined
                        onClick={handleReloadClick}
                        className='referral-letter-reload-button' />
                </div>
            }
            <Document
                options={options} 
                file={currentRecipientRemovedPDFURL}
                onLoadSuccess={onPDFLoadSuccess}
                noData={<div className='referral-letter-message'>
                            <div className='referral-letter-empty-text'>No Recipient Removed Referral Letter</div>
                        </div>}
            >
                <Row className='referral-letter-display'>
                    {Array.from(new Array(numPages), (element, index) => (
                        <Page 
                            key={`page_${index + 1}`} 
                            pageNumber={index + 1} 
                            onLoadSuccess={onReferralLetterViewerRecipientRemovedPageLoad}
                            scale={recipientRemovedScale * zoom}
                        />
                    ))}
                </Row>
            </Document>
        </div>
    )

    const retrieveModifiedRLUPdfsRequest = (examId: number) => {
        if (usePastRecipientRemovedPDFURL) {
            dispatch(retrieveModifiedRLUPdfsForPreviousExam(examId));
        }
        else {
            dispatch(retrieveModifiedRLUPdfs(examId));
        }
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    return (
        <>
            {
                examId ?
                <div id="admin-referral-letter-container">
                    <Tabs type="card">
                        <Tabs.TabPane tab='Highlighted' forceRender key='highlighted'>
                            {highlighted()}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Recipient Removed' forceRender key='recipient_removed'>
                            {recipientRemoved()}
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                :
                null
            }
            <EditPdfModal
                examId={examIdForReload}
                recipientRemovedPDFURL={currentRecipientRemovedPDFURL}
                editPdfModalOpen={editPdfModalOpen}
                setEditPdfModalOpen={setEditPdfModalOpen}
                retrieveModifiedRLUPdfs={retrieveModifiedRLUPdfsRequest}
             />
        </>
    );
}

export default AdminReferralLetterViewer;
