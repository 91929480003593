import { useEffect, useState } from 'react';
import { Modal, Spin, Table, Typography } from 'antd';
import '../../../static/css/components/admin-exam-metrics-info-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IMetricsInfo, useGetExamMetricsInfoQuery } from '../../services/exam-api';
import type { ColumnsType } from 'antd/es/table';
import { compareStrings } from '../../helpers/sorting';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import dayjs from 'dayjs';



const AdminExamMetricsInfoModal = () => {

    const examMetricsInfoModalOpen = useCare1AppSelector(store => store.examData.examMetricsInfoModalOpen);
    const examId = useCare1AppSelector(store => store.examData.id);

    const skipQuery = examId && examMetricsInfoModalOpen ? false : true;

    const dispatch = useCare1AppDispatch();

    const {data, isFetching } = useGetExamMetricsInfoQuery(examId!, {skip: skipQuery});

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const columns : ColumnsType<IMetricsInfo> = [{
        className: 'metrics-type-column',
        title: 'Metrics Type',
        dataIndex: 'metrics_type',
        sorter: (a, b) => compareStrings(a['metrics_type'], b['metrics_type']),
    }, {
        className: 'datetime-column',
        title: 'Datetime',
        dataIndex: 'datetime',
        sorter: (a, b) => compareStrings(a['datetime'], b['datetime']),
        render: (value, _) => <span>{dayjs(value).format('MMM D, YYYY h:mm A')}</span>
    }, {
        className: 'user-column',
        title: 'User',
        dataIndex: 'user',
        sorter: (a, b) => compareStrings(a['user'], b['user']),
    }, {
        className: 'od-column',
        title: 'OD',
        dataIndex: 'od',
        sorter: (a, b) => compareStrings(a['od'], b['od']),
    }, {
        className: 'omdr-column',
        title: 'OMDR',
        dataIndex: 'omdr',
        sorter: (a, b) => compareStrings(a['omdr'], b['omdr']),
    },{
        className: 'omdc-column',
        title: 'OMDC',
        dataIndex: 'omdc',
        sorter: (a, b) => compareStrings(a['omdc'], b['omdc']),
    },{
        className: 'igp-column',
        title: 'IGP',
        dataIndex: 'igp',
        sorter: (a, b) => compareStrings(a['igp'], b['igp']),
    }];

    const handleModalClose = () => {
        dispatch(setExamDataValue('examMetricsInfoModalOpen', false));
    };

    return (
        <Modal
            className='admin-exam-metrics-info-modal'
            open={examMetricsInfoModalOpen}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={isFetching}
            >
                <div className={'doctor-list-table doctor-list-table-re'}>
                    <Typography.Title
                        level={3}
                        className='exam-metrics-modal-title'
                    >
                        Audit Trail (Exam Metrics) Info Log
                    </Typography.Title>
                    <div className={scrollClass}>
                        <Table
                            columns={columns}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10',
                                    '30',
                                    '50',
                                    '100',
                                    '200',
                                ],
                                defaultPageSize: 30,
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`,
                            }}
                            dataSource={data}
                            loading={isFetching}
                        />
                    </div>
                </div>
            </Spin>
        </Modal>
    );

}

export default AdminExamMetricsInfoModal;
