import { Fragment, useEffect, useState } from 'react';
import { Form, Card, Checkbox, Col, Row, Modal } from 'antd';
import GptPatientExamOMDHistoryEntry from './gpt-patient-exam-omd-history-entry';
import { getOMDHistoryValue, isOMDHistoryValueAllEmpty, checkEmptyOMDHistoryFields } from '../../../helpers/patient-exam-convert';
import DivTagInput from '../../integrated/div-tag-input';
import '../../../../static/css/components/patient-exam-omd-history.scss';
import { localizedText } from '../../../localizedText';
import * as Constants from '../../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IOmdHistoryItem } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';


type ComponentProps = {
    omdHistoryValues: IOmdHistoryItem[],
    gptResultState: {[x: string]: string};
}

const GptPatientExamOMDHistory = ({ omdHistoryValues, gptResultState }: ComponentProps) => {
    const disabled = false;

    const { EXAM_OMD_HISTORY_TITLE, NO_HISTORY_TEXT, NONE_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const [omdHistory, setOmdHistory] = useState({ values: omdHistoryValues });
    const [showOmdHistory, setShowOmdHistory] = useState(false);

    const user = useCare1AppSelector(store => store.user);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const inReferralLetterPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    const [values, setValues] = useState<(string | never[])[]>([]);

    const isNone = omdHistory.values[0] && omdHistory.values[0].disabled;

    const toggleOMDHistoryEntries = () => {
        setShowOmdHistory(!showOmdHistory);
    }

    const addEmptyOMDHistoryEntry = (omdHistoryValuesLocal: IOmdHistoryItem[]) => {
        const omdHistoryLocal = omdHistoryValuesLocal.filter(entry => !isOMDHistoryValueAllEmpty(entry));
        omdHistoryLocal.push({
            history_omd_name: '',
            history_diagnosis: '',
            eye_select: '',
            history_date: '',
            history_end_date: '',
        })
        setOmdHistory({ values: omdHistoryLocal });
    }

    const setOMDHistoryNone = (disabled: boolean) => {
        const omdHistoryLocal = { ...omdHistory };

        if(disabled) {
            setOmdHistory({ values: [{ disabled: true }] });
        } 
        else if (omdHistoryLocal.values.length && omdHistoryLocal.values[0].disabled) {
            omdHistoryLocal.values = [{
                history_omd_name: '',
                history_diagnosis: '',
                eye_select: '',
                history_date: '',
                history_end_date: '',
            }]
            setOmdHistory(omdHistoryLocal);
        }
        setShowOmdHistory(false);
    }

    const deleteOMDHistoryEntry = (idx: number) => {
        const omdHistoryLocal = { ...omdHistory };
        omdHistoryLocal.values = omdHistoryLocal.values.filter((_, index) => index !== idx);
        setOmdHistory(omdHistoryLocal);
    }
    
    const closeOMDHistoryEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const omdHistoryValues = omdHistory.values;

        let isRowCompleted = true;
        let selectedRow: null | number = null;
        // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
        // a row are empty, it is considered a complete row
        omdHistoryValues.forEach((entry, index) => {
            if (checkEmptyOMDHistoryFields(entry)) {
                isRowCompleted = false;
                selectedRow = index;
            }
        });

        if (inReferralLetterPei !== undefined && inReferralLetterPei) {
            toggleOMDHistoryEntries();
        }
        else {
            if (!isRowCompleted) {
                Modal.confirm({
                    className: 'div-tag-row-incomplete-modal',
                    title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                    content: INCOMPLETE_ROW_CONTENT_TEXT,
                    icon: <InfoCircleOutlined />,
                    okText: INCOMPLETE_ROW_OK_TEXT,
                    cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                    cancelButtonProps: { className: 'continue-editing' },
                    onOk: () => {
                        deleteOMDHistoryEntry(selectedRow!);
                        toggleOMDHistoryEntries();
                    },
                });
            } else {
                toggleOMDHistoryEntries();
            }
        }
    }

    const editOMDHistoryEntry = (params: any) => {
        const {editIndex, field, value} = params;
        if (editIndex >=0 && field) {
            const omdHistoryLocal = omdHistory.values.map(
                (omdHistoryItem, index) =>
                    index === editIndex ? {
                        ...omdHistoryItem,
                        [field]: value
                    } : omdHistoryItem
            )

            const omdHistoryFixed = omdHistoryLocal.filter(entry => !isOMDHistoryValueAllEmpty(entry));
            omdHistoryFixed.push({
                history_omd_name: '',
                history_diagnosis: '',
                eye_select: '',
                history_date: '',
                history_end_date: '',
            })
            setOmdHistory({ values: omdHistoryFixed });

        }
    }

    useEffect(() => {
        console.log(`GptPatientExamOmdHistory: useEffect`);
        addEmptyOMDHistoryEntry(omdHistoryValues);
    }, [omdHistoryValues])

    useEffect(() => {
        const valuesLocal = getOMDHistoryValue(user, omdHistory.values);

        setValues(valuesLocal);

    }, [omdHistory])

    const isNoEntries = values.length === 0;
    const entries = omdHistory.values.map((entry, index) => (
        <GptPatientExamOMDHistoryEntry
            eyeTypes={eyeTypes}
            key={index}
            index={index}
            data={entry}
            editOMDHistoryEntry={editOMDHistoryEntry}
            deleteOMDHistoryEntry={deleteOMDHistoryEntry}
        />
    ));

    // Remove empty value from array for the OMD display string
    const OmdDisplayValues = values.filter(value => {
        return value !== '';
    })

    return (
        <Card bordered={false} className="patient-exam-omd-history gpt-patient-exam-omd-history">
            <Fragment>
                <Row>
                    <Col span={18}>
                        <div className="heading">{EXAM_OMD_HISTORY_TITLE}</div>
                    </Col>
                    <Col span={6}>
                        {/* OMD-HIST 004 */}
                        <Checkbox
                            checked={isNone}
                            onChange={(e) => setOMDHistoryNone(e.target.checked)}
                            disabled={disabled}
                        >
                            {NONE_TEXT}
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={disabled ? 'div-tag-disabled' : ''}>
                    <div className={`${gptResultState["omdHistoryValues"]} w-100`}>
                    {/* OMD-HIST 001, OMD-HIST 002, OMD-HIST 003 */}
                    <DivTagInput
                        disabled={disabled || !!isNone}
                        onClick={() => {
                            if (omdHistory.values.length === 0) {
                                addEmptyOMDHistoryEntry(omdHistoryValues);
                            }
                            toggleOMDHistoryEntries();
                        }}
                        values={values}
                        drawer={!isNone && showOmdHistory}
                        onClose={() => { closeOMDHistoryEntries(); }}
                        entries={entries}
                        emptyValue=""
                    />
                    </div>
                </Row>
            </Fragment>
        </Card>
    );
}

export default GptPatientExamOMDHistory;
