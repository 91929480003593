import { Button, Checkbox, Col, Flex, message, Modal, Row } from 'antd';

import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { saveUploadReconcilerPageData } from '../../../reducers/smart-upload-slice';
import { createUploadReconcilerExam, getUploadReconcilerData, getUploadReconcilerExamDate, setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';
import DeleteButton from './delete-button';
import HoldButton from './hold-button';
import UploadReconcilerAdminReconciliationNotes from './upload-reconciler-admin-reconciliation-notes';
import ValidatorInitials from './validator-initials';
import { examApi } from '../../../services/exam-api';
import { editExamRequest, resetNewExamDefaults, saveExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useNavigate } from 'react-router';
import { PATIENT_HAS_EXAM_ERROR_TEXT } from '../../../constants';

const AdminManagementRow = () => {
    const imageVerified = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_images_are_verified
    );

    const matchedPatients = useCare1AppSelector(
        (store) => store.uploadReconciler.matched_patients
    );

    const smartUploadStatus = useCare1AppSelector(
        (store) => store.smartUpload.status
    );

    const holdDate = useCare1AppSelector(
        (store) => store.smartUpload.hold_date
    );

    const uploadReconcilerExamDate = useCare1AppSelector(
        (store) => store.uploadReconciler.exam_date
    );

    const smartUploadSubmissionDate = useCare1AppSelector(
        (store) => store.smartUpload.submissionDate
    );
    const smartUploadId = useCare1AppSelector(
        (store) => store.smartUpload.id
    );

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const handleSave = async () => {
        try {
            await dispatch(saveUploadReconcilerPageData());
        } catch (error) {
            console.error(error);
            message.error('Error saving upload reconciler data');
        }
    };

    const submitUploadReconcilerExam = async () => {
        dispatch(
            setUploadReconcilerSliceDataValue({
                key: 'submittingExam',
                value: true,
            })
        );
        dispatch(saveUploadReconcilerPageData())
            .then(() => {
                return dispatch(getUploadReconcilerData(smartUploadId!));
            })
            .then(() => {
                return dispatch(
                    examApi.endpoints.patientHasExam.initiate({
                        patientId: matchedPatients[0].id,
                        examDate: getUploadReconcilerExamDate(
                            uploadReconcilerExamDate,
                            smartUploadSubmissionDate
                        ),
                    })
                ).unwrap();
            })
            .then((res) => {
                if (res && res.result === 'true') {
                    throw new Error(PATIENT_HAS_EXAM_ERROR_TEXT);
                } else {
                    return dispatch(createUploadReconcilerExam()).unwrap();
                }
            })
            .then((res) => {
                if (res && res.success) {
                    dispatch(resetNewExamDefaults());
                    return dispatch(editExamRequest(res?.data, history));
                } else {
                    throw new Error(res.error);
                }
            })
            .then((res) => {
                dispatch(setExamDataValue('exam_status', 'od_review'));
                dispatch(setExamDataValue('is_smart_upload_submission', true));
                return dispatch(saveExamData());
            })
            .then((res) => {
                dispatch(
                    setUploadReconcilerSliceDataValue({
                        key: 'submittingExam',
                        value: false,
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    setUploadReconcilerSliceDataValue({
                        key: 'submittingExam',
                        value: false,
                    })
                );
                const message = error instanceof Error ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    title: 'Error',
                    content: message,
                });
            });
    };

    return (
        <Row gutter={4} className='admin-management-row'>
            <Col span={6}>
                <UploadReconcilerAdminReconciliationNotes />
            </Col>
            <Col>
                <Flex
                    align='end'
                    gap={'small'}
                    className='admin-management-row-buttons'
                >
                    <Checkbox
                        checked={imageVerified}
                        onChange={(e) =>
                            dispatch(
                                setUploadReconcilerSliceDataValue({
                                    key: 'admin_images_are_verified',
                                    value: e.target.checked,
                                })
                            )
                        }
                    >
                        Images Verified
                    </Checkbox>
                    <DeleteButton />
                    <HoldButton />
                    <ValidatorInitials />
                    <Button type='primary' onClick={handleSave}>
                        Save
                    </Button>
                    <Button
                        type='primary'
                        disabled={
                            !(
                                matchedPatients.length === 1 &&
                                imageVerified &&
                                smartUploadStatus === 'submitted' &&
                                holdDate === null
                            )
                        }
                        onClick={submitUploadReconcilerExam}
                    >
                        Submit Exam for the Matched Patient
                    </Button>
                </Flex>
            </Col>
        </Row>
    );
};

export default AdminManagementRow;
