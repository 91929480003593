import { CloseOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    ConfigProvider,
    Flex,
    Modal,
    Row,
    Steps,
    Typography,
} from 'antd';
import { StepProps } from 'antd/lib';
import classnames from 'classnames';
import { useState } from 'react';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import {
    isStepTwoComplete,
    resetSmartUploadData,
    submitSmartUpload,
    toggleSmartUploadConfirmationModal,
    toggleSmartUploadDeleteConfirmationModal,
    toggleSmartUploadModal,
} from '../../../reducers/smart-upload-slice';
import SmartUploadDeleteConfirmationModal from './smart-upload-delete-confirmation-modal';
import SmartUploadLoadingIndicator from './smart-upload-loading-indicator';
import SmartUploadStep1 from './smart-upload-step1';
import SmartUploadStep2 from './smart-upload-step2';
import SmartUploadStep3 from './smart-upload-step3';
import { clearSmartUploadDropzone } from '../../../reducers/dropzone-slice';
import {
    componentsButton,
    componentsInputNumber,
    componentsSelect,
    componentsSteps,
} from '../../theme/theme';
import { getODPatientListRequest } from '../../../reducers/patient-list-slice';

const theme = {
    components: {
        Steps: {
            ...componentsSteps,
        },
        Select: {
            ...componentsSelect,
        },
        Typography: {
            colorText: '#FFFFFF',
        },
        Modal: {
            contentBg: '#1F2226',
            colorIcon: '#FFFFFF',
        },
        Card: {
            padding: 0,
        },
        Button: {
            ...componentsButton,
        },
        InputNumber: {
            ...componentsInputNumber,
        },
        Form: {
            colorTextDescription: '#FFFFFF',
            labelColor: '#FFFFFF',
        },
        Checkbox: {
            colorText: '#FFFFFF',
            colorTextDisabled: '#7F7F7F',
        },
        Radio: {
            colorTextDisabled: '#7F7F7F',
        },
        Spin: {
            colorPrimary: '#DCA342',
        },
    },
};

interface ExtendedStepProps extends StepProps {
    content: React.ReactNode;
    buttons: React.ReactNode;
}

const SmartUploadModal = () => {
    const isVisible = useCare1AppSelector(
        (store) => store.smartUpload.smartUploadModalOpen
    );
    const odSendGpLetters = useCare1AppSelector(
        (store) => store.user.odSendGpLetters
    );
    const referralLetterUrl = useCare1AppSelector(
        (store) => store.smartUpload.referralLetterUrl
    );
    const smartUploadId = useCare1AppSelector((store) => store.smartUpload.id);
    const displayLoadingIndicator = useCare1AppSelector(
        (store) => store.smartUpload.displayLoadingIndicator
    );
    const confirmChecked = useCare1AppSelector(
        (store) => store.smartUpload.please_confirm
    );
    const isSliceStepTwoComplete = useCare1AppSelector(
        (store) => store.smartUpload.stepTwoComplete
    );

    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(toggleSmartUploadModal());
        dispatch(resetSmartUploadData());
        dispatch(clearSmartUploadDropzone());
        setCurrentStep(0);
    };

    const [currentStep, setCurrentStep] = useState(0);

    const submit = async () => {
        if (smartUploadId) {
            try {
                const res = await dispatch(
                    submitSmartUpload({
                        id: smartUploadId,
                    })
                ).unwrap();
                if (res?.error) throw new Error(res?.error);

                dispatch(getODPatientListRequest());
                dispatch(toggleSmartUploadConfirmationModal());
                setCurrentStep(0);
            } catch (error) {
                const message = error instanceof Error ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    title: `Errors`,
                    content: message as string,
                });
            }
        }
    };
    const stepTwoComplete =
        (currentStep === 1 && dispatch(isStepTwoComplete())) ||
        isSliceStepTwoComplete;

    const stepItems: ExtendedStepProps[] = [
        {
            status: 'wait',
            title: (
                <>
                    <Typography.Text className={styles.stepTitle}>
                        Step 1: Letter
                    </Typography.Text>
                    <span>&gt;</span>
                </>
            ),
            icon: <></>,
            content: <SmartUploadStep1 />,
            buttons: (
                <Button
                    type='primary'
                    className={styles.button}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={!referralLetterUrl}
                >
                    Next
                </Button>
            ),
        },
        {
            status: 'wait',
            disabled: !smartUploadId,
            title: (
                <>
                    <Typography.Text className={styles.stepTitle}>
                        Step 2: Diagnostics
                    </Typography.Text>
                    <span>&gt;</span>
                </>
            ),
            icon: <></>,
            content: <SmartUploadStep2 />,
            buttons: (
                <>
                    {stepTwoComplete ? (
                        <Button
                            type='primary'
                            className={styles.button}
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            type='primary'
                            className={styles.button}
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Skip
                        </Button>
                    )}
                </>
            ),
        },
        {
            status: 'wait',
            disabled: !smartUploadId,
            title: (
                <Typography.Text className={styles.stepTitle}>
                    Step 3: Confirmation
                </Typography.Text>
            ),
            icon: <></>,
            content: <SmartUploadStep3 />,
            buttons: (
                <Button
                    type='primary'
                    className={styles.button}
                    disabled={!confirmChecked || disabled}
                    onClick={() => submit()}
                >
                    Submit
                </Button>
            ),
        },
    ];
    const classNames = classnames(styles.smartUploadModal, {
        [styles.diagnosticStep]: currentStep === 1,
        [styles.confirmationStep]: currentStep === 2,
        [styles.includeGpSection]: odSendGpLetters,
    });

    return (
        <ConfigProvider theme={theme}>
            <Modal
                className={classNames}
                open={isVisible}
                closable={true}
                closeIcon={<CloseOutlined />}
                footer={null}
                keyboard={true}
                onCancel={handleModalClose}
                width={756}
                maskClosable={false}
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Typography.Title
                            level={5}
                            className={styles.modalTitle}
                        >
                            Smart Upload
                        </Typography.Title>
                    </Col>

                    <Col span={24}>
                        <Flex justify='center'>
                            <Col span={18}>
                                <Steps
                                    type='navigation'
                                    className='site-navigation-steps'
                                    current={currentStep}
                                    onChange={(clickedStep) =>
                                        setCurrentStep(clickedStep)
                                    }
                                    items={stepItems}
                                />
                            </Col>
                        </Flex>
                    </Col>
                    <Col span={24}>{stepItems[currentStep].content}</Col>
                    <Col span={24}>
                        <Flex justify='space-between'>
                            <Flex gap={8} align='center'>
                                {currentStep !== 0 && (
                                    <Button
                                        className={`${styles.button} ${styles.backButton}`}
                                        onClick={() =>
                                            setCurrentStep(currentStep - 1)
                                        }
                                    >
                                        Back
                                    </Button>
                                )}
                                {stepItems[currentStep].buttons}
                                {(smartUploadId || displayLoadingIndicator) && (
                                    <SmartUploadLoadingIndicator />
                                )}
                            </Flex>
                            <Button
                                type='primary'
                                disabled={!smartUploadId || disabled}
                                className={styles.button}
                                danger={Boolean(smartUploadId) && !disabled}
                                onClick={() =>
                                    dispatch(
                                        toggleSmartUploadDeleteConfirmationModal()
                                    )
                                }
                            >
                                Delete
                            </Button>
                        </Flex>
                    </Col>
                </Row>
                <SmartUploadDeleteConfirmationModal />
            </Modal>
        </ConfigProvider>
    );
};

export default SmartUploadModal;
