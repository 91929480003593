import { UserOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { useLocation } from 'react-router';
import '../../static/css/components/navigation-control-bar.scss';
import Logo from '../../static/images/logo-care1-whitetext.png';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { ROOT_URL } from '../constants';
import { getPlatformTitle } from '../helpers/user-type-check';
import { localizedText } from '../localizedText';
import { clearPatientExamRoomsData } from '../reducers/patient-exam-rooms-slice';
import { clearAllFilters } from '../reducers/patient-list-slice';
import { logout } from '../reducers/user-slice';
import ODDashboardButton from './admin/button-od-dashboard';
import ResourceLibraryButton from './admin/button-resource-library';
import Breadcrumbs, { IBreadcrumbItem } from './breadcrumbs';
import HelpDeskButton from './retina/button-help-desk';

type ComponentProps = {
    breadcrumbItems?: IBreadcrumbItem[]
    className: string;
}

const NavigationControlBar = ({ breadcrumbItems, className }: ComponentProps) => {
    const { LOG_OUT_TEXT, HELP_DESK_TEXT } = localizedText;
    const displayName = useCare1AppSelector(store => store.user.displayName);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const isOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const isOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isSALES = useCare1AppSelector(store => store.user.isSALES);
    const isSCIENCEUSER = useCare1AppSelector(store => store.user.isSCIENCEUSER);

    const dispatch = useCare1AppDispatch();
    const location = useLocation();

    const displayOdDashboardButton = (location.pathname === '/' || location.pathname === '/od-dashboard') && isOD;
    const displayResourcePageButton = (location.pathname === '/' || location.pathname === '/od-dashboard') && isOD;

    const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isSCIENCEUSER) {  
            dispatch(clearAllFilters());
    
            // Clear Patient Exam Room data
            dispatch(clearPatientExamRoomsData());
        }
        dispatch(logout());
    }

    // Get the platform title.
    const platformTitle = getPlatformTitle(isRE, isOD, isOMDC, isOMDR, isADMIN, isSCIENCEUSER);
    return (
        <Row className={`navigation-control-bar ${className}`}> {/* Test NavBar 001 NavBar 002 */}
            <Col span={6} className="nav-control-bar-title">
                <a className="logo" href={ROOT_URL}>
                    {/* NavBar 003 */}
                    <img
                        className={`care1-logo-img`}
                        data-testid='care1-logo'
                        alt="logo"
                        width="89"
                        height="32"
                        src={Logo}
                    />
                </a>
                <div className={`platform-title`} data-testid='platform-title'>
                    {/* NavBar 004, 011, 012, 013, 014 */}
                    {platformTitle}
                </div>
            </Col>
            {!isOMDC && !(isOMDR && isRE) &&
                <Col span={1} className="divider">
                    <Divider type="vertical" />
                </Col>
            }
            <Col span={isOD || isADMIN || (isOMDR && !isRE) || isSCIENCEUSER ?
                displayOdDashboardButton ? 6
                    : 9
                    : 8}
                className="breadcrumb">
                {/* NavBar 005-007 */}
                {!isOMDC && !(isOMDR && isRE) && !isSALES &&  
                    <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                }
            </Col>
            {displayOdDashboardButton && <Col span={3}>
            </Col>}
            <Col span={isOD || isADMIN || (isOMDR && !isRE) || isSCIENCEUSER ? 8 : 9}>
                <Row className="login-actions">
                    {/* NavBar 008 */}
                    <Col span={7}>
                        <div className="button-col">
                            <HelpDeskButton text={HELP_DESK_TEXT}/>
                            {displayResourcePageButton && <ResourceLibraryButton />}
                            {displayOdDashboardButton && <ODDashboardButton />}
                        </div>
                    </Col>
                    <Col span={13}>
                        <UserOutlined />
                        {/* NavBar 009 */}
                        <span className={`user-display-name`} data-testid='user-name'>
                            {!isADMIN ? `Dr. ${displayName}` : `${displayName}`}
                        </span>
                    </Col>
                    <Col span={4}>
                        {/* NavBar 010 */}
                        <button
                            className="logout"
                            data-testid='logout-button'
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleLogout(e)}
                        >
                            {LOG_OUT_TEXT}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default NavigationControlBar;