import React from 'react';
import { Layout, Spin } from 'antd';

import { OPERATING_TEXT } from '../../constants';

import AdminLatencyMetricsTable, { ILatencyMetricListTable } from '../../components/admin/admin-latency-metrics-table';
import { useGetLatencyMetricsSummaryQuery } from '../../services/latency-metrics-api';

import '../../../static/css/components/latency-metrics-tab.scss';

const { Header, Content } = Layout;

const LatencyMetricsTab: React.FC = () => {

    const { data: data = {
        latency_metrics_list: {
            summary: {
                rows: [],
                days: []
            }
        }
    }, isFetching: isFetching } = useGetLatencyMetricsSummaryQuery();

    const getTabLatencyMetricsSummary = (onClickOpenDetail: boolean, title: string, row_keys: any, color_green_threshold: number | null, color_yellow_threshold: number | null) => {
        const keys = Object.keys(row_keys);
        const values = Object.values(row_keys);

        const rows: ILatencyMetricListTable[] = data.latency_metrics_list.summary.rows;
        const collectedRows = [];
        for (let i = 0; i < rows.length; i++) {
            if (keys.includes(rows[i].metric_name)) {
                collectedRows.push({
                    ...rows[i],
                    metric_name: row_keys[rows[i].metric_name]
                })
            }
        }
        
        const sortedRows = collectedRows.sort((a, b) => {
            return values.indexOf(a.metric_name) - values.indexOf(b.metric_name);
        });

        const rowKeyMap: {[k: string]: string} = {}
        for (let i = 0; i < values.length; i++) {
            rowKeyMap[`${values[i]}`] = keys[i]
        }

        return (
            <div style={{ paddingTop: '1rem' }}>
                <div>
                    <AdminLatencyMetricsTable
                        tableList={sortedRows}
                        days={data.latency_metrics_list.summary.days}
                        title={title}
                        color_green_threshold={color_green_threshold}
                        color_yellow_threshold={color_yellow_threshold}
                        rowKeyMap={rowKeyMap}
                        onClickOpenDetail={onClickOpenDetail}
                    />
                </div>
            </div>);
    }

    return (
        <Layout className='latency-metrics-tab'>
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <div>
                    {getTabLatencyMetricsSummary(
                        true, 'OD Facing RLU Load Times', {
                        od_rlu_premium_pei_load: 'RLU Prem PEI',
                        od_rlu_standard_pei_load: 'RLU Stand',
                        od_glc_program_list_load: 'Glc Prog List',
                    }, 2000, 3999)}
                </div>
                <div>
                    {getTabLatencyMetricsSummary(
                        true, 'OMD Facing RLU Load Times', {
                        omdc_rlu_premium_pei_load: 'OMDC RLU Prem',
                        omdr_rlu_premium_pei_load: 'OMDR RLU Prem',
                        omdr_rlu_standard_pei_load: 'OMDR RLU Stand',
                    }, 2000, 3999)}
                </div>
                <div>
                    {getTabLatencyMetricsSummary(
                        true, 'Admin Facing RLU Load Times', {
                        admin_rlu_standard_pei_load: 'RLU Stand PEI',
                        admin_rlu_premium_pei_load: 'RLU Prem PEI',
                    }, 2000, 3999)}
                </div>
                <div>
                    {getTabLatencyMetricsSummary(
                        false, 'Backend Service Run Times', {
                        ml_disc_hemorrhage: 'ML Disc Heme',
                        ml_drusen_segmentation: 'ML Drusen Segm',
                        ml_hard_exudates: 'ML Hard Exud',
                        ml_large_heme_pigment: 'ML Lg Heme Pigm',
                        ml_micro_aneurysms: 'ML MicroAneur',
                        csv_position: 'CSV Position',
                        gpt_patient_demographics: 'GPT Pt Dem',
                        image_color_channel: 'Img Color Channel',
                    }, null, null)}
                </div>
            </Spin>
        </Layout>
    );
}

export default LatencyMetricsTab;