import React from 'react';
import ReviewProcessingControlBar from './review-processing-control-bar';
import ReviewProcessingTable from './review-processing-table';

const ManuallySentReviewsTab: React.FC = () => (
    <>
        <ReviewProcessingControlBar />
        <ReviewProcessingTable />
    </>
);

export default ManuallySentReviewsTab;
