import { baseApi, IBaseResponse } from './base-api'

export interface IAwsCostItem {
    id: string;
    [monthkey: string]: string;
}

export interface IAwsCostResponse {
    report: IAwsCostItem[];
    month_column: string[];
    col_name: string;
}

export interface IAwsCostSavingRecommendationItem {
    current_resource_type: string;
    estimated_monthly_savings_after_discount: number;
}

export interface IAwsCostSavingRecommendationResponse{
    savings_opportunities: IAwsCostSavingRecommendationItem[];
}

export const awsCostReportApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAwsCostList: builder.query<IAwsCostResponse, void>({
            query: () => {
                return {
                    url: `admin/aws_cost_report/`,
                }
            },
        }),
        getAwsCostSavingRecommendationList: builder.query<IAwsCostSavingRecommendationResponse, void>({
            query: () => {
                return {
                    url: `admin/aws_cost_saving_recommendation_report/`,
                }
            },
        })
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useLazyGetAwsCostListQuery, useLazyGetAwsCostSavingRecommendationListQuery
} = awsCostReportApi;
