/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import Icon, { WarningFilled, CheckCircleFilled, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import { Col, Row, Button, Tooltip } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import '../../../static/css/components/admin-retina-scan.scss';

import { getBackendMediaUrl, getFilepathFromBackendMediaUrl } from '../../helpers/media-image-convert';

type AppProps = {
    viewMode: string,
    fundusPhoto: string,
    useLargeHemePigmentPhoto: boolean;
    mlLargeHemePigmentOverlayPhoto: string;
    mlLargeHemePigmentValue: number;
    mlLargeHemePigmentSucceeded: boolean;
    useMicroAneurysmsPhoto: boolean;
    mlMicroAneurysmsOverlayPhoto: string;
    mlMicroAneurysmsValue: number;
    mlMicroAneurysmsSucceeded: boolean;
    useHardExudatesPhoto: boolean;
    mlHardExudatesOverlayPhoto: string;
    mlHardExudatesValue: number;
    mlHardExudatesSucceeded: boolean;
    title: string,
    titleTooltip: string,
    examDate: string,
}

const AdminRetinaScan = ({ viewMode, fundusPhoto, 
    useLargeHemePigmentPhoto, mlLargeHemePigmentOverlayPhoto, mlLargeHemePigmentValue, mlLargeHemePigmentSucceeded, 
    useMicroAneurysmsPhoto, mlMicroAneurysmsOverlayPhoto, mlMicroAneurysmsValue, mlMicroAneurysmsSucceeded,  
    useHardExudatesPhoto, mlHardExudatesOverlayPhoto, mlHardExudatesValue, mlHardExudatesSucceeded, 
    title, titleTooltip, examDate }: AppProps) => {

    const startWithAI = () => {
        if (useLargeHemePigmentPhoto || useMicroAneurysmsPhoto || useHardExudatesPhoto)
            return true;

        return false;
    }

    const [useAiPhoto, setUseAiPhoto] = useState(startWithAI());
    const [scale, setScale] = useState(1);

    const handleZoomIn = () => {
        setScale(prevScale => prevScale + 0.1); // Increase scale by 0.1
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(0.1, prevScale - 0.1)); // Decrease scale by 0.1, ensuring minimum scale of 0.1
    };

    const tooglePhoto = () => {
        if (startWithAI())
            setUseAiPhoto(!useAiPhoto);
    }

    const [panStart, setPanStart] = useState<{x: number | null, y: number | null}>({ x: 0, y: 0 });
    const [panOffset, setPanOffset] = useState<{x: number | null, y: number | null}>({ x: 0, y: 0 });
    const [mouseDown, setMouseDown] = useState(false);

    const handleMouseDown = (e: { clientX: any; clientY: any; }) => {
        setPanStart({ x: e.clientX, y: e.clientY });
        setMouseDown(true);
    };

    const handleMouseMove = (e: { clientX: number; clientY: number; }) => {
        if (mouseDown) {
            if (panStart.x !== null && panStart.y !== null) {
                const dx = e.clientX - panStart.x;
                const dy = e.clientY - panStart.y;
                const x = panOffset.x ? panOffset.x : 0;
                const y = panOffset.y ? panOffset.y : 0;
                setPanStart({ x: e.clientX, y: e.clientY });
                setPanOffset({ x: x + dx, y: y + dy });
            }
        }
    };

    const handleMouseUp = () => {
        setPanStart({ x: null, y: null });
        setMouseDown(false);
    };

    const getHeatmapDetectedTooltip = (model: string) => {
        if (model === 'micro-aneurysms')
            return <Tooltip className={'recommendations-tooltip'} placement='topLeft' title="The Microaneurysms and Pigment model identifies potential microaneurysms and pigments within the retina." ><InfoCircleOutlined /></Tooltip>;

        if (model === 'large-heme-pigment')
            return <Tooltip className={'recommendations-tooltip'} placement='topLeft' title="The Large Heme and Pigment model identifies potential hemorrhages and pigments within the retina." ><InfoCircleOutlined /></Tooltip>;
        
        if (model === 'hard-exudates')
            return <Tooltip className={'recommendations-tooltip'} placement='topLeft' title="The Hard Exudates and Drusen model identifies hard exudates and calcific drusen. It may also identify cotton wool spots and peripapillary atrophy." ><InfoCircleOutlined /></Tooltip>;
            
    }

    const getHeatmapDetected = () => {
        return <div className='heatmap-detected'><WarningFilled /> Heatmap Detected</div>
    }

    const getNoHeatmapDetected = () => {
        return <div className='no-heatmap-detected'><CheckCircleFilled /> No Heatmap Detected</div>
    }

    const getModelDidNotRun = () => {
        return <div className='model-did-not-run'><CloseCircleFilled /> Model did not run</div>
    }

    const getImageUrl = (img: string) => {
        return getBackendMediaUrl() + getFilepathFromBackendMediaUrl(img);
    }

    return (
        <Row className={`admin-retina-scan admin-retina-scan-${viewMode}`}>
            <Col span={24} className='text'>
                <Row>
                    <Col span={24} className='side-name'>
                        <div>
                            {title} 
                            {
                                titleTooltip !== '' && 
                                <Tooltip
                                    className={'recommendations-tooltip'}
                                    placement='topLeft'
                                    title={titleTooltip}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className='admin-retina-content'>
                            {!useAiPhoto &&
                                <div
                                    className="photo-container-border"
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    data-testid="photo-container-border-non-ai-photo"
                                >
                                    <div className="photo-container" style={{ transform: `scale(${scale}) translate(${panOffset.x}px, ${panOffset.y}px)` }}>
                                        {fundusPhoto &&
                                            <img
                                                className='photo'
                                                src={getImageUrl(fundusPhoto)}
                                                alt='Fundus Photo'
                                                data-testid='fundus-photo-non-ai-photo'
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {useAiPhoto && viewMode !== '4-view' &&
                                <div
                                    className="photo-container-border"
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    data-testid='photo-container-border-ai-photo'
                                >
                                    <div className="photo-container" style={{ transform: `scale(${scale}) translate(${panOffset.x}px, ${panOffset.y}px)` }}>
                                        {fundusPhoto &&
                                            <img
                                                className='photo'
                                                src={getImageUrl(fundusPhoto)}
                                                alt='Fundus Photo'
                                                data-testid='fundus-photo-ai-photo'
                                            />
                                        }
                                        {(mlLargeHemePigmentOverlayPhoto && useLargeHemePigmentPhoto) &&
                                            <img
                                                className='photo overlay'
                                                src={getImageUrl(mlLargeHemePigmentOverlayPhoto)}
                                                alt='ML Large Heme and Pigment Photo'
                                                data-testid='large-heme-pigment-photo'
                                            />
                                        }
                                        {(mlMicroAneurysmsOverlayPhoto && useMicroAneurysmsPhoto) &&
                                            <img
                                                className='photo overlay'
                                                src={getImageUrl(mlMicroAneurysmsOverlayPhoto)}
                                                alt='ML Micro Aneurysms Photo'
                                                data-testid='micro-aneurysms-photo'
                                            />
                                        }
                                        {(mlHardExudatesOverlayPhoto && useHardExudatesPhoto) &&
                                            <img
                                                className='photo overlay'
                                                src={getImageUrl(mlHardExudatesOverlayPhoto)}
                                                alt='ML Hard Exudates Photo'
                                                data-testid='hard-exudates-photo'
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            <div className='exam-date-offset'>{examDate}</div>
                            { viewMode !== '4-view' && 
                                <>
                                <Button
                                    className={!useAiPhoto ? 'original-button' : 'original-button-not-selected'}
                                    onClick={() => { tooglePhoto() }}
                                    data-testid='original-button'
                                >ORIGINAL</Button>
                                <Button
                                    type='primary'
                                    className={useAiPhoto ? 'ai-button' : 'ai-button-not-selected'}
                                    onClick={() => { tooglePhoto() }}
                                    data-testid='ai-button'
                                >AI</Button>
                                </>
                            }

                            {(useAiPhoto &&  useMicroAneurysmsPhoto && mlMicroAneurysmsSucceeded) && viewMode !== '4-view' && 
                                <span className="micro-aneurysms-value" data-testid="micro-aneurysms-value">{mlMicroAneurysmsValue}</span>
                            }
                            {(useAiPhoto && useLargeHemePigmentPhoto && mlLargeHemePigmentSucceeded) && viewMode !== '4-view' && 
                                <span className="large-heme-pigment-value" data-testid="large-heme-pigment-value">{mlLargeHemePigmentValue}</span>
                            }
                            {(useAiPhoto && useHardExudatesPhoto && mlHardExudatesSucceeded) && viewMode !== '4-view' && 
                                <span className="hard-exudates-value" data-testid="hard-exudates-value">{mlHardExudatesValue}</span>
                            }

                            <Button className='zoom-in-button' shape="circle" ghost icon={<ZoomInOutlined />} onClick={handleZoomIn} data-testid='zoom-in-button' />
                            <Button className='zoom-out-button' shape="circle" ghost icon={<ZoomOutOutlined />} onClick={handleZoomOut} data-testid='zoom-out-button' />
                        </div>
                    </Col>
                </Row>
                { viewMode !== '4-view' && 
                    <>
                    <Row className='dot-row'>
                        <Col span={14}><div className='micro-aneurysms-dot'></div><div>Microaneurysms and Pigment {getHeatmapDetectedTooltip('micro-aneurysms')}</div></Col>
                        <Col span={10}>
                            {mlMicroAneurysmsSucceeded ? (mlMicroAneurysmsValue === 0 ? getNoHeatmapDetected() : getHeatmapDetected()) : getModelDidNotRun() }
                        </Col>
                    </Row>
                    <Row className='dot-row'>
                        <Col span={14}><div className='large-heme-pigment-dot'></div><div>Large Heme and Pigment {getHeatmapDetectedTooltip('large-heme-pigment')}</div></Col>
                        <Col span={10}>
                            {mlLargeHemePigmentSucceeded ? (mlLargeHemePigmentValue === 0 ? getNoHeatmapDetected() : getHeatmapDetected()) : getModelDidNotRun() }
                        </Col>
                    </Row>
                    <Row className='dot-row'>
                        <Col span={14}><div className='hard-exudates-dot'></div><div>Hard Exudates and Drusen {getHeatmapDetectedTooltip('hard-exudates')}</div></Col>
                        <Col span={10}>
                            {mlHardExudatesSucceeded ? (mlHardExudatesValue === 0 ? getNoHeatmapDetected() : getHeatmapDetected()) : getModelDidNotRun() }
                        </Col>
                    </Row>
                    </>
                }
            </Col>
        </Row>
    );
}

export default AdminRetinaScan;
