import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { getBackendMediaUrl } from '../helpers/media-image-convert';
import { buildQueryParams, getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { AppDispatch, RootState } from '../stores/retina-enabled-store';
import { APIResponse, createCare1AsyncThunk } from './care1-slice-types';
import { updateAllUploadReconcilerData } from './upload-reconciler-slice';
import { logout } from './user-slice';



type ValueOf<T> = T[keyof T];
type ValueOfSmartUploadData = ValueOf<ISmartUpload>;
export type DtcIopField =
    | 'od_iop_1'
    | 'od_iop_2'
    | 'od_iop_3'
    | 'os_iop_1'
    | 'os_iop_2'
    | 'os_iop_3';

export type SmartUploadStatus = 'draft' | 'submitted' | 'processed' | 'deleted';

export type UploadFileResponse =
    | {
          success: true;
          error: never;
          thumbnail: string;
          url: string;
          id: number;
      }
    | {
          success: false;
          error: string;
          thumbnail?: never;
          url?: never;
          id?: never;
      };

export type SmartUploadResponseData = {
    id: number | null;
    od_notes: string;
    high_priority: boolean;
    od_wants_omd_report: boolean;
    od_requested_omd: number | null;
    mini_dtc_time: number | null;
    od_iop_1: string;
    od_iop_2: string;
    od_iop_3: string;
    os_iop_1: string;
    os_iop_2: string;
    os_iop_3: string;
    has_no_gp: boolean;
    gp: number | null;
    gp_fax: string;
    please_confirm: boolean;
    url: string;
    thumbnail: string;
    diagnosis_file_list: any[];
    status: SmartUploadStatus;
    odgrouppractice: string;
    od_full_name: string;
    submission_date: string;
    exam_id: number | null;
    od_id: number;
    od_province_code: string;
    hold_date: string;
};



export interface ISmartUploadTableItem {
    key: string;
    admin_reconciler: string;
    smart_upload_id: number;
    submission_date: string;
    uploading_od_name: string;
    uploading_od_orientating: boolean;
    uploading_od_special_attention: boolean;
    uploading_odgrouppractice: string;
    status: string;
}

export type SmartUploadResponse =
    | {
          success: true;
          error: never;
          data: SmartUploadResponseData | null;
      }
    | {
          success: false;
          error: string;
      };
export type DiagnosisFile = {
    uid: string,
    id?: number,
    thumbUrl?: string,
    url?: string,
    name: string,
    type?: string,
    status?: 'error' | 'done' | 'uploading' | 'removed',
}
export interface ISmartUpload {
    id: number | null;
    smartUploadModalOpen: boolean;
    stepTwoComplete: boolean;
    smartUploadConfirmationModalOpen: boolean;
    referralLetterUrl: string;
    referralLetterThumbnailUrl: string;
    loading: boolean;
    odId: number | null;
    od_notes: string;
    high_priority: boolean;
    od_wants_omd_report: boolean;
    od_requested_omd: number | null;
    mini_dtc_time: number | null;
    od_iop_1: number | null;
    od_iop_2: number | null;
    od_iop_3: number | null;
    os_iop_1: number | null;
    os_iop_2: number | null;
    os_iop_3: number | null;
    has_no_gp: boolean;
    gp: number | null;
    gp_fax: string;
    please_confirm: boolean;
    diagnosisFileList: DiagnosisFile[];
    saving: boolean;
    deleteConfirmationModalOpen: boolean;
    displayLoadingIndicator: boolean;
    status: SmartUploadStatus;
    disabled: boolean;
    odgrouppractice: string;
    odFullName: string;
    submissionDate: Dayjs | null;
    examId: number | null;
    odProvinceCode: string;
    hold_date: Dayjs | null;
    smartUploadList: ISmartUploadTableItem[];
    selectedRowKeys: React.Key[],
    sortOrder: string[];
    sortedInfo: SorterResult<ISmartUploadTableItem> | SorterResult<ISmartUploadTableItem>[];
    readyForValidationFilter: null | string,
    odGroupFilter: null | string,
    miscFilter: null | string,
    smartUploadTableLoading: boolean;
}

const initialState: ISmartUpload = {
    id: null,
    smartUploadModalOpen: false,
    stepTwoComplete: false,
    smartUploadConfirmationModalOpen: false,
    referralLetterUrl: '',
    referralLetterThumbnailUrl: '',
    loading: false,
    odId: null,
    od_notes: '',
    high_priority: false,
    od_wants_omd_report: false,
    od_requested_omd: null,
    mini_dtc_time: null,
    od_iop_1: null,
    od_iop_2: null,
    od_iop_3: null,
    os_iop_1: null,
    os_iop_2: null,
    os_iop_3: null,
    has_no_gp: false,
    gp: null,
    gp_fax: '',
    please_confirm: false,
    diagnosisFileList: [],
    saving: false,
    deleteConfirmationModalOpen: false,
    displayLoadingIndicator: false,
    status: 'draft',
    disabled: false,
    odgrouppractice: '',
    odFullName: '',
    submissionDate: null,
    examId: null,
    odProvinceCode: '',
    hold_date: null,
    smartUploadList: [],
    selectedRowKeys: [],
    sortOrder: [],
    sortedInfo: {},
    readyForValidationFilter: null,
    odGroupFilter: null,
    miscFilter: null,
    smartUploadTableLoading: false,
};

export const getSmartUploadList = createCare1AsyncThunk<
    APIResponse<ISmartUploadTableItem[]>,
    void
>(
    'smartUpload/getSmartUploadList',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
            smartUpload: {
                miscFilter,
                odGroupFilter,
                readyForValidationFilter,
            },
        } = getState();
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/list/`;

        const urlWithParams =
            URL +
            buildQueryParams({
                misc_filter: miscFilter,
                od_group_filter: odGroupFilter,
                ready_for_validation_filter: readyForValidationFilter,
            });

        try {
            const response = await apiRequest.get(urlWithParams, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error as string);
            }
        }
    }
);

export const deleteDiagnosisFile = createAsyncThunk(
    'smartUpload/deleteDiagnosisFile',
    async (
        smartUploadDiagnosisId: number,
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/diagnosis_file/delete/`;
        const body = JSON.stringify({ id: smartUploadDiagnosisId });

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const getSmartUploadData = createAsyncThunk(
    'smartUpload/getSmartUploadData',
    async (smartUploadId: number, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/${smartUploadId}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const isStepTwoComplete =
    () => (dispatch: AppDispatch, getState: () => RootState) => {
        const {
            smartUpload: {
                diagnosisFileList,
                mini_dtc_time,
                od_iop_1,
                od_iop_2,
                od_iop_3,
                os_iop_1,
                os_iop_2,
                os_iop_3,
            },
        } = getState();
        return Boolean(
            diagnosisFileList.length > 0 ||
                mini_dtc_time ||
                od_iop_1 ||
                od_iop_2 ||
                od_iop_3 ||
                os_iop_1 ||
                os_iop_2 ||
                os_iop_3
        );
    };

export const updateSmartUploadData = createAsyncThunk(
    'smartUpload/updateSmartUploadData',
    async (
        requestData: Partial<ISmartUpload>,
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const { id, ...data } = requestData;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/update/${id}`;

        const body = JSON.stringify(data);

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const updateSmartUploadGp = createAsyncThunk(
    'smartUpload/updateSmartUploadGp',
    async (
        { id, gp_id }: { id: number; gp_id: number | null },
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string; doctorID: number } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/update_gp/`;

        const body = JSON.stringify({ id, gp_id });

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const updateAllSmartUploadData = createAsyncThunk(
    'smartUpload/updateAllSmartUploadData',
    async (
        requestData: Partial<ISmartUpload>,
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const { id, hold_date, ...data } = requestData;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/update_all/${id}`;

        const body = JSON.stringify({
            ...data,
            hold_date: hold_date ? hold_date.toISOString() : null,
        });

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const saveUploadReconcilerPageData =
    () => (dispatch: AppDispatch, getState: () => RootState) => {
        const { smartUpload, uploadReconciler } = getState();
        const { please_confirm, gp_fax, ...smartUploadRequestData } =
            smartUpload;

        const {
            od_account,
            exam_date,
            dob_day,
            dob_year,
            dob_month,
            first_name,
            last_name,
            primary_phone,
            province,
            insurance_type,
            phn,
            admin_reconciler,
            admin_reconciliation_notes,
            admin_reconciliation_notes_addressed,
            id,
            left_fundus_photo,
            left_oct_photo,
            left_oct_rnfl_photo,
            left_stereo_photo,
            left_vf_photo,
            right_fundus_photo,
            right_oct_photo,
            right_oct_rnfl_photo,
            right_stereo_photo,
            right_vf_photo,
            extra_images,
            admin_images_are_verified,
        } = uploadReconciler;

        const uploadReconcilerRequestData = {
            od_account,
            exam_date,
            first_name,
            last_name,
            primary_phone,
            province,
            insurance_type,
            phn,
            admin_reconciler,
            admin_reconciliation_notes,
            admin_reconciliation_notes_addressed,
            admin_images_are_verified,
            id,
            dob: dayjs(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD'),
            left_fundus_photo: left_fundus_photo
                ? {
                    ...left_fundus_photo,
                    base64Url: left_fundus_photo.url
                        ? ''
                        : left_fundus_photo.base64Url,
                }
                : null,
            left_oct_photo: left_oct_photo
                ? {
                    ...left_oct_photo,
                    base64Url: left_oct_photo.url ? '' : left_oct_photo.base64Url,
                }
                : null,
            left_oct_rnfl_photo: left_oct_rnfl_photo
                ? {
                    ...left_oct_rnfl_photo,
                    base64Url: left_oct_rnfl_photo.url
                        ? ''
                        : left_oct_rnfl_photo.base64Url,
                }
                : null,
            left_stereo_photo: left_stereo_photo
                ? {
                    ...left_stereo_photo,
                    base64Url: left_stereo_photo.url
                        ? ''
                        : left_stereo_photo.base64Url,
                }
                : null,
            left_vf_photo: left_vf_photo
                ? {
                    ...left_vf_photo,
                    base64Url: left_vf_photo.url ? '' : left_vf_photo.base64Url,
                }
                : null,
            right_fundus_photo: right_fundus_photo
                ? {
                    ...right_fundus_photo,
                    base64Url: right_fundus_photo.url
                        ? ''
                        : right_fundus_photo.base64Url,
                }
                : null,
            right_oct_photo: right_oct_photo
                ? {
                    ...right_oct_photo,
                    base64Url: right_oct_photo.url
                        ? ''
                        : right_oct_photo.base64Url,
                }
                : null,
            right_oct_rnfl_photo: right_oct_rnfl_photo
                ? {
                    ...right_oct_rnfl_photo,
                    base64Url: right_oct_rnfl_photo.url
                        ? ''
                        : right_oct_rnfl_photo.base64Url,
                }
                : null,
            right_stereo_photo: right_stereo_photo
                ? {
                    ...right_stereo_photo,
                    base64Url: right_stereo_photo.url
                        ? ''
                        : right_stereo_photo.base64Url,
                }
                : null,
            right_vf_photo: right_vf_photo
                ? {
                    ...right_vf_photo,
                    base64Url: right_vf_photo.url ? '' : right_vf_photo.base64Url,
                }
                : null,
            extra_images,
        };

        return Promise.all([
            dispatch(updateAllSmartUploadData(smartUploadRequestData)).unwrap(),
            dispatch(updateAllUploadReconcilerData(uploadReconcilerRequestData)).unwrap(),
        ]);
    };

export const submitSmartUpload = createAsyncThunk(
    'smartUpload/submitSmartUpload',
    async (
        { id }: { id: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string; doctorID: number } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/submit/`;

        const body = JSON.stringify({ id });

        try {
            const response = await apiRequest.post(URL, csrfToken, body);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const deleteSmartUpload = createAsyncThunk(
    'smartUpload/deleteSmartUpload',
    async (id: number, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string; doctorID: number } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/delete/${id}`;

        try {
            const response = await apiRequest.post(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const smartUploadSlice = createSlice({
    name: 'smartUploadSlice',
    initialState,
    reducers: {
        toggleSmartUploadModal: (state) => {
            state.smartUploadModalOpen = !state.smartUploadModalOpen;
        },
        toggleSmartUploadConfirmationModal: (state) => {
            state.smartUploadConfirmationModalOpen =
                !state.smartUploadConfirmationModalOpen;
        },
        toggleSmartUploadDeleteConfirmationModal: (state) => {
            state.deleteConfirmationModalOpen =
                !state.deleteConfirmationModalOpen;
        },
        setSmartUploadSliceDataValue: (
            state,
            action: PayloadAction<{
                key: keyof ISmartUpload;
                value: ValueOfSmartUploadData;
            }>
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        },
        setSmartUploadDataValues: (
            state,
            action: PayloadAction<Partial<ISmartUpload>>
        ) => {
            Object.assign(state, action.payload);
        },
        createNewSmartUpload: (
            state,
            action: PayloadAction<UploadFileResponse>
        ) => {
            const res = action.payload;
            if (res.success) {
                const mediaUrl = getBackendMediaUrl();
                state.id = res.id;
                state.referralLetterUrl = mediaUrl + res.url;
                state.referralLetterThumbnailUrl = mediaUrl + res.thumbnail;
                state.saving = false;
            }
        },
        setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
            state.selectedRowKeys = action.payload;
        },
        setSortOrder: (state, action: PayloadAction<string[]>) => {
            state.sortOrder = action.payload;
        },
        setSortedInfo: (state, action: PayloadAction<SorterResult<ISmartUploadTableItem> | SorterResult<ISmartUploadTableItem>[]>) => {
            return {
                ...state,
                sortedInfo: action.payload,
            }
        },
        resetSmartUploadData: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getSmartUploadData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getSmartUploadData.fulfilled,
            (state, action: PayloadAction<SmartUploadResponse>) => {
                state.loading = false;
                if (action.payload.success && action.payload.data) {
                    const data = action.payload.data;
                    const mediaUrl = getBackendMediaUrl();

                    state.referralLetterUrl = data.url
                        ? mediaUrl + data.url
                        : '';
                    state.referralLetterThumbnailUrl = data.thumbnail
                        ? mediaUrl + data.thumbnail
                        : '';

                    state.id = data.id;
                    state.od_notes = data.od_notes;
                    state.high_priority = data.high_priority;
                    state.od_wants_omd_report = data.od_wants_omd_report;
                    state.od_requested_omd = data.od_requested_omd;
                    state.mini_dtc_time = data.mini_dtc_time;
                    state.od_iop_1 =
                        data.od_iop_1 !== '' ? Number(data.od_iop_1) : null;
                    state.od_iop_2 =
                        data.od_iop_2 !== '' ? Number(data.od_iop_2) : null;
                    state.od_iop_3 =
                        data.od_iop_3 !== '' ? Number(data.od_iop_3) : null;
                    state.os_iop_1 =
                        data.os_iop_1 !== '' ? Number(data.os_iop_1) : null;
                    state.os_iop_2 =
                        data.os_iop_2 !== '' ? Number(data.os_iop_2) : null;
                    state.os_iop_3 =
                        data.os_iop_3 !== '' ? Number(data.os_iop_3) : null;
                    state.has_no_gp = data.has_no_gp;
                    state.gp = data.gp;
                    state.gp_fax = data.gp_fax;
                    state.please_confirm = data.please_confirm;
                    state.diagnosisFileList = data.diagnosis_file_list.map(
                        (diagnosisFile) => ({
                            ...diagnosisFile,
                            url: mediaUrl + diagnosisFile.url,
                            thumbUrl: mediaUrl + diagnosisFile.thumbUrl,
                            status: 'done',
                        })
                    );
                    state.status = data.status;
                    state.disabled = data.status === 'submitted';
                    state.odgrouppractice = data.odgrouppractice;
                    state.odFullName = data.od_full_name;
                    state.submissionDate = data.submission_date ? dayjs(data.submission_date) : null;
                    state.examId = data.exam_id;
                    state.odId = data.od_id;
                    state.hold_date = data.hold_date ? dayjs(data.hold_date) : null;
                }
            }
        );
        builder.addCase(getSmartUploadData.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteDiagnosisFile.pending, (state) => {
            state.saving = true;
        });
        builder.addCase(
            deleteDiagnosisFile.fulfilled,
            (state, action: PayloadAction<{ id: number }>) => {
                state.saving = false;
            }
        );
        builder.addCase(deleteDiagnosisFile.rejected, (state, action) => {
            state.saving = false;
        });

        builder.addCase(updateSmartUploadData.pending, (state) => {
            state.saving = true;
        });
        builder.addCase(
            updateSmartUploadData.fulfilled,
            (state, action: PayloadAction<{ id: number }>) => {
                state.saving = false;
            }
        );
        builder.addCase(updateSmartUploadData.rejected, (state, action) => {
            state.saving = false;
        });

        builder.addCase(updateSmartUploadGp.pending, (state) => {
            state.saving = true;
        });
        builder.addCase(
            updateSmartUploadGp.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.saving = false;
                const data = action.payload;
                if (data.success) {
                    state.gp = data.gp_id;
                    state.gp_fax = data.fax_number;
                    state.has_no_gp = data.has_no_gp;
                }
            }
        );
        builder.addCase(updateSmartUploadGp.rejected, (state, action) => {
            state.saving = false;
        });

        builder.addCase(deleteSmartUpload.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            deleteSmartUpload.fulfilled,
            (state, action: PayloadAction<{ success: boolean }>) => {
                return {
                    ...initialState,
                    smartUploadModalOpen: true,
                    loading: false,
                };
            }
        );
        builder.addCase(deleteSmartUpload.rejected, (state, action) => {
            state.loading = false;
        });
        
        builder.addCase(submitSmartUpload.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            submitSmartUpload.fulfilled,
            (state, action: PayloadAction<{ success: boolean }>) => {
                return {
                    ...initialState,
                    smartUploadModalOpen: false,
                    loading: false,
                };
            }
        );
        builder.addCase(submitSmartUpload.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateAllSmartUploadData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateAllSmartUploadData.fulfilled,
            (state, action: PayloadAction<{ success: boolean }>) => {
                state.loading = false;
            }
        );
        builder.addCase(updateAllSmartUploadData.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getSmartUploadList.pending, (state) => {
            state.smartUploadTableLoading = true;
        });
        builder.addCase(
            getSmartUploadList.fulfilled,
            (state, action: PayloadAction<APIResponse<ISmartUploadTableItem[]>>) => {
                if (action.payload.success) {
                    state.smartUploadList = action.payload.data
                }
                state.smartUploadTableLoading = false;
            }
        );
        builder.addCase(getSmartUploadList.rejected, (state, action) => {
            state.smartUploadTableLoading = false;
        });
    },
});

export const {
    toggleSmartUploadModal,
    setSmartUploadSliceDataValue,
    toggleSmartUploadConfirmationModal,
    createNewSmartUpload,
    setSmartUploadDataValues,
    toggleSmartUploadDeleteConfirmationModal,
    resetSmartUploadData,
    setSelectedRowKeys,
    setSortOrder,
    setSortedInfo,
} = smartUploadSlice.actions;

export default smartUploadSlice.reducer;
