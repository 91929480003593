// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import GptUI from './gpt-ui';
import '../../../../static/css/components/admin-gpt.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import PDFViewer from "../../retina/pdf-viewer";
import { postReferralLetterAnalysisResults, 
    fetchAutoGptResponse } from '../../../reducers/referral-letter-analysis-slice';
import { IOmdHistoryItem } from '../../../reducers/patient-exam-slice';
import { editExamRequest } from '../../../reducers/patient-exam-slice';
import PatientExamOMDHistory from "../../integrated/patient-exam-omd-history";
import GptPatientExamOMDHistory from "./gpt-patient-exam-omd-history";
import { fetchGptChatOmdHistoryPrompt } from "../../../reducers/gpt-omd-history-slice";
import { getOMDHistoryValue, isOMDHistoryValueAllEmpty, checkEmptyOMDHistoryFields } from '../../../helpers/patient-exam-convert';

interface Message {
    input: string;
    output: string;
    meta: string;
}

const GptOmdHistory = () => {

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const examId = useCare1AppSelector(store => store.examData.id);

    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter_pdf);
    const apiStatus = useCare1AppSelector(store => store.referralLetterAnalysis.api_status);

    const [omdHistoryValues, setOmdHistoryValues] = useState<IOmdHistoryItem[]>([]);
    
    const omdHistory = useCare1AppSelector(store => store.examData.omd_history);

    const [gptResultState, setGptResultState] = useState<{[x: string]: string}>({
        omdHistoryValues: '',
    });

    
    const setExamDataValues = (results: { [x: string]: string; }) => {

        let omdHistoryValuesUpdated = false;
        if ('combined_ocular_surgeries' in results) {
            try {
                const omdHistoryValuesLocal : IOmdHistoryItem[] = results['combined_ocular_surgeries'] as unknown as IOmdHistoryItem[];
                const omdHistoryValuesExtracted = extractOmdHistoryValues(omdHistoryValuesLocal);

                // Combine lists and remove duplicates
                const omdHistoryValuesMerged = [...omdHistoryValuesExtracted, ...omdHistory.values].filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.history_omd_name === item.history_omd_name &&
                        t.history_diagnosis === item.history_diagnosis &&
                        t.eye_select === item.eye_select &&
                        t.history_date === item.history_date &&
                        t.history_end_date === item.history_end_date
                    ))
                );                
                setOmdHistoryValues(omdHistoryValuesMerged);
                omdHistoryValuesUpdated = true;
            }
            catch (e) {
                // do something
            }
        }
        else {
            setOmdHistoryValues(omdHistory.values);
        }
        
        setGptResultState({
            omdHistoryValues: omdHistoryValuesUpdated ? 'gpt-state-yellow' : '',
        })
    }

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = obj['message']['meta'];

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT output',
            });
        };

        if (outputStr !== '') {
            try {
                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    let jsonResponse = jsonMatches[0];

                    // Remove comments from the JSON string
                    jsonResponse = jsonResponse.replace(/\/\/.*$/gm, '');

                    const outputObj = JSON.parse(jsonResponse);

                    setExamDataValues(outputObj);

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
            catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Errors parsing GPT output',
                });
            };
        }
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/omd_history/chat/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'exam_id': examId,
                    'input': inputMessage,
                    'history': []
                }
            }));
            setMessageHistory(() => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
            setGptResultState({
                omdHistoryValues: '',
            })
        }
    }

    const onPDFLoadSuccess = () => {
    }

    const onPDFLoadError = () => {
    }

    const onClose = () => {
    }

    const divStyle = {
        height: `${windowHeight - 100}px`,
    };

    const getNonEmptyOmdHistoryValues = (values: IOmdHistoryItem[]) => {
        const result = [];
        for (let i=0; i<values.length; i++) {
            if (!isOMDHistoryValueAllEmpty(values[i])) {
                result.push(values[i]);
            }
        }
        return result;
    }

    const handleReset = async () => {

        try {
            const result = await dispatch(fetchGptChatOmdHistoryPrompt()).unwrap();
            if (result && result.success) {

                const omdHistoryNotEmptyList = getNonEmptyOmdHistoryValues(omdHistory.values);
    
                const resultWithOmdHistory = result.gpt_omd_history_chat_prompts + '\n' + JSON.stringify(omdHistoryNotEmptyList, null, 2);
    
                setInputMessage(resultWithOmdHistory);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting deidentified referral letter',
            });
        }
    }

    const handleAddGPTGeneratedValuesToExam = async () => {
        console.log('handleAddGPTGeneratedValuesToExam: messageHistory');
        console.log(messageHistory);
        if (messageHistory.length > 0) {
            if (messageHistory[0].output !== '') {
                try {
                    const result = await dispatch(postReferralLetterAnalysisResults({
                        value: {
                            omd_history_values: getNonEmptyOmdHistoryValues(omdHistoryValues)
                        }
                    })).unwrap();
                    if (result && result.success) {
                        Modal.info({
                            className: 'info-modal',
                            content: 'Setting GPT OMD histories results succeeded',
                            title: 'Info',
                        });
                        if (examId !== null) {

                            setGptResultState(s => {
                                const ns = {
                                    omdHistoryValues: s['omdHistoryValues'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['omdHistoryValues'],
                                }
                                return ns;
                            });
            
                            await dispatch(editExamRequest(examId, history));
                        }
                    }
                    else {
                        throw new Error(result?.error);
                    }

                } catch (error) {

                    setGptResultState(s => {
                        const ns = {
                            omdHistoryValues: s['omdHistoryValues'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['omdHistoryValues'],
                        }
                        return ns;
                    });

                    const message = (error instanceof Error) ? error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Errors setting GPT OMD histories results',
                    });
                }
            }
        }
    }

    const extractOmdHistoryValues = (input: IOmdHistoryItem[]) => {
        return [...input];
    }

    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={apiStatus === 'loading'}
        >
            <div className="chat-container" style={divStyle}>
                <Row>
                    <Col
                        span={12}
                        className={'pdfViewerModal no-overflow padding-col'}>
                        <PDFViewer
                            file={currentPhotoURL}
                            onPDFLoadSuccess={onPDFLoadSuccess}
                            onPDFLoadError={onPDFLoadError}
                            onClose={onClose}
                            isGPTTab={true}
                        />
                    </Col>
                    <Col
                        span={12}>
                        <GptUI
                            messageHistory={messageHistory}
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            sendMessage={sendMessage}
                            formatMessageContent={formatMessageContent}
                            isAssistantTyping={isAssistantTyping}
                            messagesEndRef={messagesEndRef}
                        />
                        <Row>
                            <Col span={12} className='padding-col'>
                                <div>PEI</div>
                                <PatientExamOMDHistory disabled={true} />
                            </Col>
                            <Col span={12} className='padding-col'>
                                <div>GPT</div>
                                <GptPatientExamOMDHistory 
                                    omdHistoryValues={omdHistoryValues}
                                    gptResultState={gptResultState}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleAddGPTGeneratedValuesToExam}
                                    type="primary"
                                    size="large"
                                >
                                    Add GPT Generated Values to Exam
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>)
}

export default GptOmdHistory;