import { Tabs } from 'antd';
import Gpt from './gpt';
import GptOmdHistory from './gpt-omd-history';
import GptVision from '../gpt-vision/gpt-vision';
import GptAISnapshot from './gpt-ai-snapshot';
import GptPatientDemographics from './gpt-patient-demographics';
import GptImageIdentifier from '../image-identifier/gpt-image-identifier';
import { useState } from 'react';
import { useLocation } from 'react-router';

const GptTabs = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab');

    const defaultTab = tabParam !== null && tabParam === 'gpt-vision' ? 'item-gpt-vision' : 'item-gpt-chat';
    const [activeKey, setActiveKey] = useState(defaultTab);

    return (
        <Tabs
            defaultActiveKey={'item-gpt-chat'}
            activeKey={activeKey}
            onTabClick={(key) => setActiveKey(key)}
            items={[
                {
                    label: 'GPT Chat',
                    key: 'item-gpt-chat',
                    children: <Gpt />,
                },
                {
                    label: 'OMD History',
                    key: 'item-gpt-omd-history',
                    children: <GptOmdHistory />,
                },
                {
                    label: 'GPT Vision',
                    key: 'item-gpt-vision',
                    children: <GptVision />,
                },
                {
                    label: 'AI Snapshot',
                    key: 'item-gpt-snapshot',
                    children: <GptAISnapshot />,
                },
                {
                    label: 'Demographics Testing',
                    key: 'item-gpt-demographics',
                    children: <GptPatientDemographics />,
                },
                {
                    label: 'Image Identifier',
                    key: 'item-gpt-image',
                    children: <GptImageIdentifier />,
                },
            ]}
        />
    );
};

export default GptTabs;
