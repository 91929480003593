import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-vf-near-miss-list-table.scss';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../constants';
import { localizedText } from '../../localizedText';

interface IVfNearMissListTable {
    key: React.Key;
    id: string;
    log_date: string,
    exam_date: string,
    exam_id: string,
    field_name: string,
    gpt_value: string,
    corrected_value: string,
}

type AppProps = {
    tableList: IVfNearMissListTable[],
}

const AdminVfNearMissTable = ({ tableList }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;


    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleExamSelection = (record: IVfNearMissListTable) => {
        window.open(`/patient-exam/${record.exam_id}?tab=gpt-vision`, '_blank')?.focus();
    }

    const nameColumns: ColumnsType<IVfNearMissListTable> = [
        {
            className: 'log_date-column',
            title: 'Log Date',
            dataIndex: 'log_date',
            sorter: (a, b) => compareStrings(a['log_date'], b['log_date']),
            render: (text, record) => <div data-testid={`'log_date'-${record.key}`}>{text}</div>
        }, {
            className: 'exam_date-column',
            title: 'Exam Date',
            dataIndex: 'exam_date',
            sorter: (a, b) => compareStrings(a['exam_date'], b['exam_date']),
            render: (text, record) => <div data-testid={`'exam_date'-${record.key}`}>{text}</div>
        }, {
            className: 'exam_id-column',
            title: 'Exam',
            dataIndex: 'exam_id',
            sorter: (a, b) => compareStrings(a['exam_id'], b['exam_id']),
            render: (text, record) => <div data-testid={`'exam_id'-${record.key}`}><a onClick={() => handleExamSelection(record)}>{text}</a></div>
        }, {
            className: 'field_name-column',
            title: 'Field name',
            dataIndex: 'field_name',
            sorter: (a, b) => compareStrings(a['field_name'], b['field_name']),
            render: (text, record) => <div data-testid={`'field_name'-${record.key}`}>{text}</div>
        }, {
            className: 'gpt_value-column',
            title: 'Gpt value',
            dataIndex: 'gpt_value',
            sorter: (a, b) => compareStrings(a['gpt_value'], b['gpt_value']),
            render: (text, record) => <div data-testid={`'gpt_value'-${record.key}`}>{text}</div>
        }, {
            className: 'corrected_value-column',
            title: 'Corrected value',
            dataIndex: 'corrected_value',
            sorter: (a, b) => compareStrings(a['corrected_value'], b['corrected_value']),
            render: (text, record) => <div data-testid={`'corrected_value'-${record.key}`}>{text}</div>
        }
    ];

    const renderTitle = () => {
        const title = 'VF Near Miss Log';
        return (
            <div>
            <span className='title'>{title} </span>
            <span className='title-tooltip'>
                <Tooltip
                    className={'recommendations-tooltip'}
                    placement='topLeft'
                    title="A near miss is logged when an Admin modifies and corrects the GPT Vision suggested value."
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </span>
            </div>);
    }

    return (
        <div className={'vf-near-miss-list-table'}>
        <div>{renderTitle()}</div>
            <div className={scrollClass}>
                <Table
                    columns={nameColumns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                />
            </div>
        </div>
    );
}

export default AdminVfNearMissTable;