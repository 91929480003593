import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { RootState, AppDispatch } from '../stores/retina-enabled-store';
import { ALERT_ID, BACKEND_EXAM_STATUS_FIELD, ERROR_MESSAGE_TITLE, EXAM_HISTORY_DEFAULT_TEXT, INFO_MESSAGE_TITLE, NOTES_TO_MOA_FIELD, OMD_NOTE_TEMPLATE_DICT, OUTBOUND_OMDR_ID, PATIENT_EXAM_URL, PATIENT_HAS_EXAM_ERROR_TEXT, PRE_REVIEW_VALUE_ID_LIST, ROOT_URL, TREATMENT_RANDOM_SENTENCES_LIST,
    OPTIONAL_ID, NEEDED_ID, ERM_SURGICAL_ID, CUSTOM_ID} from '../constants';
import { getDiagnosisValues, patientIsGlaucoma } from '../helpers/diagnosis-convert';
import { getOmdName, getUserInitials, isDiffOngoingDrops, isIopHistoryEntryEmpty, isOmdHistoryEntryEmpty, isOngoingEntryEmpty, isUntilYesterdayEntryEmpty } from '../helpers/patient-exam-convert';
import { getCsrfToken } from '../helpers/utilities';
import { IDiagnosis, IDiagnosisListItem, IDiagnosisValue, loadDiagnosisData, saveDiagnosisData } from './diagnosis-slice';
import { IOptions } from './options-slice';
import { IUser, logout, toggleNotAuthorizedForExam } from './user-slice';
import { NavigateFunction } from 'react-router';
import { getTxAlgo3Request, refreshTxAlgo3Request, resetTxAlgo3 } from './txalgo3-slice';
import { apiRequest } from '../services/api-request';
import { convertBackendExamData, IExamDataResponse } from '../helpers/exam-backend-data-convert';
import { clearDropzoneData } from './dropzone-slice';
import { getRandomSentencesRequest } from './patient-exam-initiate-treatment-slice';
import { closePatientDetailsModal, getPatientDetailsRequest } from './patient-details-slice';
import { toggleODNotes } from './conversation-history-slice';
import { getBackendMediaUrl } from '../helpers/media-image-convert';
import { getSaveDataFromState, IExamSubmitData } from '../helpers/exam-save-data-from-state';
import dayjs from 'dayjs';
import { clearPatientExamRoomsData, IPatientExamRooms, setPatientExamRoomsData, removeExamFromTab } from './patient-exam-rooms-slice';
import { examApi } from '../services/exam-api';
import { preReviewExam, setAutoPreReviewMessageRequest } from './pre-review-slice';
import { IStopWatchState, preReviewTimeTimeUpdate, stopwatchPause, setStopwatchResumeExamIdAfterSave } from './stopwatch-slice';
import { doctorApi } from '../services/doctor-api';
import { setGptSetValuesData } from './gpt-set-values-slice';


type ValueOf<T> = T[keyof T];
type ValueOfExamData = ValueOf<IExamData>;

export type HistoryDropzoneField = 'left_oct_photo' | 'right_oct_photo' | 'left_fundus_photo' |
    'right_fundus_photo' | 'left_oct_rnfl_photo' | 'right_oct_rnfl_photo' | 'left_vf_photo' |
    'right_vf_photo' | 'right_stereo_photo' | 'left_stereo_photo';

export type RxField = 'od_sphere' | 'os_sphere' | 'od_cylinder' | 'os_cylinder' | 'od_axis' | 'os_axis';

export interface IGraphProps {
    side: 'od' | 'os';
    page?: string;
}

export enum ReportType {
    LEGACY = 0,
    OMD_ONLY,
    AI_AND_OMD,
}

export interface IphotoUrls {
    right_fundus_photo: string;
    right_stereo_photo: string;
    right_oct_photo: string;
    right_oct_rnfl_photo: string;
    right_vf_photo: string;
    left_fundus_photo: string;
    left_stereo_photo: string;
    left_oct_photo: string;
    left_oct_rnfl_photo: string;
    left_vf_photo: string;
    right_vf_10_2_photo: string;
    left_vf_10_2_photo: string;
    right_oct_angle_photo: string;
    left_oct_angle_photo: string;
    right_gcc_photo: string;
    left_gcc_photo: string;
    right_ml_large_heme_pigment_overlay_photo: string;
    left_ml_large_heme_pigment_overlay_photo: string;
    right_ml_micro_aneurysms_overlay_photo: string;
    left_ml_micro_aneurysms_overlay_photo: string;
    right_ml_hard_exudates_overlay_photo: string;
    left_ml_hard_exudates_overlay_photo: string;
    right_ml_disc_hemorrhage_overlay_photo: string;
    left_ml_disc_hemorrhage_overlay_photo: string;
}

const initialPhotoUrls: IphotoUrls = {
    right_fundus_photo: '',
    right_stereo_photo: '',
    right_oct_photo: '',
    right_oct_rnfl_photo: '',
    right_vf_photo: '',
    left_fundus_photo: '',
    left_stereo_photo: '',
    left_oct_photo: '',
    left_oct_rnfl_photo: '',
    left_vf_photo: '',
    right_vf_10_2_photo: '',
    left_vf_10_2_photo: '',
    right_oct_angle_photo: '',
    left_oct_angle_photo: '',
    right_gcc_photo: '',
    left_gcc_photo: '',
    right_ml_large_heme_pigment_overlay_photo: '',
    left_ml_large_heme_pigment_overlay_photo: '',
    right_ml_micro_aneurysms_overlay_photo: '',
    left_ml_micro_aneurysms_overlay_photo: '',
    right_ml_hard_exudates_overlay_photo: '',
    left_ml_hard_exudates_overlay_photo: '',
    right_ml_disc_hemorrhage_overlay_photo: '',
    left_ml_disc_hemorrhage_overlay_photo: ''
}

export interface IpastPhotoUrls {
    past_left_fundus_photo: string;
    past_left_stereo_photo: string;
    past_left_oct_photo: string;
    past_left_oct_rnfl_photo: string;
    past_left_vf_photo: string;
    past_left_fundus_photo_date: string;
    past_left_stereo_photo_date: string;
    past_left_oct_photo_date: string;
    past_left_oct_rnfl_photo_date: string;
    past_left_vf_photo_date: string;
    past_left_vf_10_2_photo: string;
    past_left_vf_10_2_photo_date: string;
    past_left_oct_angle_photo: string;
    past_left_oct_angle_photo_date: string;
    past_left_gcc_photo: string;
    past_left_gcc_photo_date: string;
    past_right_fundus_photo: string;
    past_right_stereo_photo: string;
    past_right_oct_photo: string;
    past_right_oct_rnfl_photo: string;
    past_right_vf_photo: string;
    past_right_fundus_photo_date: string;
    past_right_stereo_photo_date: string;
    past_right_oct_photo_date: string;
    past_right_oct_rnfl_photo_date: string;
    past_right_vf_photo_date: string;
    past_right_vf_10_2_photo: string;
    past_right_vf_10_2_photo_date: string;
    past_right_oct_angle_photo: string;
    past_right_oct_angle_photo_date: string;
    past_right_gcc_photo: string;
    past_right_gcc_photo_date: string;
}

const initialPastPhotoUrls: IpastPhotoUrls = {
    past_left_fundus_photo: '',
    past_left_stereo_photo: '',
    past_left_oct_photo: '',
    past_left_oct_rnfl_photo: '',
    past_left_vf_photo: '',
    past_left_fundus_photo_date: '',
    past_left_stereo_photo_date: '',
    past_left_oct_photo_date: '',
    past_left_oct_rnfl_photo_date: '',
    past_left_vf_photo_date: '',
    past_left_vf_10_2_photo: '',
    past_left_vf_10_2_photo_date: '',
    past_left_oct_angle_photo: '',
    past_left_oct_angle_photo_date: '',
    past_left_gcc_photo: '',
    past_left_gcc_photo_date: '',
    past_right_fundus_photo: '',
    past_right_stereo_photo: '',
    past_right_oct_photo: '',
    past_right_oct_rnfl_photo: '',
    past_right_vf_photo: '',
    past_right_fundus_photo_date: '',
    past_right_stereo_photo_date: '',
    past_right_oct_photo_date: '',
    past_right_oct_rnfl_photo_date: '',
    past_right_vf_photo_date: '',
    past_right_vf_10_2_photo: '',
    past_right_vf_10_2_photo_date: '',
    past_right_oct_angle_photo: '',
    past_right_oct_angle_photo_date: '',
    past_right_gcc_photo: '',
    past_right_gcc_photo_date: '',
}

export interface ITxalgo3LocalStorageData {
    id: number;
    left_cd: string;
    right_cd: string;
    od_iop_aim: string;
    os_iop_aim: string;
    od_vf_override: string;
    os_vf_override: string;
}
export interface IOptionItem {
    value: string | number;
    label: string;
    key?: string | number;
}

export interface IIopHistoryItem {
    eye_select?: string;
    high_iop?: number | '';
    low_iop?: number | '';
    machine_select?: string;
    iop_history_procedure_prefix?: string;
    iop_history_drops_select?: string[];
    iop_history_procedure_select?: string[];
    disabled?: boolean;
}

export interface IOmdHistoryItem {
    history_omd_name?: string;
    history_diagnosis?: string;
    eye_select?: string;
    history_date?: string;
    history_end_date?: string;
    disabled?: boolean;
}

export interface IUntilYesterdayItem {
    glc_past_drops_compliance_select?: string;
    glc_past_drops_eye_select?: string;
    glc_past_drops_select?: string;
    disabled?: boolean;
}

export interface IOngoingItem {
    glc_current_drops_select?: string;
    glc_current_drops_eye_select?: string;
    disabled?: boolean;
}

export interface IExtraImage {
    date: number;
    filename: string;
    type: string;
    thumbnail: string;
}

export interface IAllExamImages {
    exam_date: string;
    exam_date_utc: string;
    exam_id: number | null;
    left_oct_photo?: string;
    right_oct_photo?: string;
    left_fundus_photo?: string;
    right_fundus_photo?: string;
    left_oct_rnfl_photo?: string;
    right_oct_rnfl_photo?: string;
    left_vf_photo?: string;
    right_vf_photo?: string;
    left_stereo_photo?: string;
    right_stereo_photo?: string;
}

export type CsvStatusType = 'did_not_run' | 'not_detected' | 'detected';

export interface ICSVPhoto {
    url: string;
    exam: number | null;
    overlay_url: string;
    top: number | string;
    left: number | string;
    width: number | string;
    height: number | string;
    status: CsvStatusType;
}

const initialCSVPhoto: ICSVPhoto = {
    url: '',
    exam: null,
    overlay_url: '',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    status: 'did_not_run',
}

export interface IExamDataDiagnosis {
    rr_glc: boolean;
    rr_glc_suspect: boolean;
    rr_dm: boolean;
    rr_amd: boolean;
    rr_erm: boolean;
    rr_optic_nerve: boolean;
    rr_narrow_angles: boolean;
    rr_cataract: boolean;
    rr_cat_post_op: boolean;
    ped: boolean;
    macular_hole: boolean;
    nevus: boolean;
    plaquenil: boolean;
    rao: boolean;
    vmt: boolean;
    rr_others: string;
    diagnostics_values: string;
    diagnosis_list: IDiagnosisListItem[];
}

export interface IGraphIop {
    ageTicks: number[];
    min_iop: number;
    max_iop: number;
    od_iop_points: Array<Array<number | null>>;
    od_series: [];
    od_grid_bg_color: string;
    os_iop_points: Array<Array<number | null>>;
    os_series: [];
    os_grid_bg_color: string;
}

export interface IGraphBcva {
    ageTicks: number[];
    od_bcvaTicks: number[];
    od_bcva_points: Array<Array<number | null>>;
    od_series: [];
    od_grid_bg_color: string;
    os_bcvaTicks: number[];
    os_bcva_points: Array<Array<number | null>>;
    os_series: [];
    os_grid_bg_color: string;

}

export interface IGlaucomaScoreGraphData {
    age: number;
    od_iop: string;
    os_iop: string;
    right_octav: string;
    left_octav: string;
    right_ght: string;
    left_ght: string;
    exam_id: number | null;
}

export interface IOmdcTravelTime {
    id: number;
    omdc_id: number;
    reachable_clinic_id: number;
    travel_time: string;
}

export interface ISharedExamData {
    exam_date_utc: string;
    exam_date: string;
    exam_status: string;
    omdc_note_string: string;
    omd_note_string: string;
    omdc_note_str_admin: [{
        content: string;
        color: string;
        html: string;
    }];
    omd_note_str_admin: [{
        content: string;
        color: string;
        html: string;
    }];
    od_name: string;
    omdr_name: string;
    omdc_name: string;
    od_omd: string;
    od_proposed_plan: string;
    pmh_m: string;
    id: number;
    version: number;
    ipc_notes: string;
    history_and_exam: string;
    omdc_is_outside_licensed_provinces: boolean;
    omdr_is_outside_licensed_provinces: boolean;
    is_inactive: boolean;
    omdc_review_date: null | string;
    omdr_review_date: string;
    label: string;
    exam_impression: string;
    od_sphere: string;
    os_sphere: string;
    od_cylinder: string;
    os_cylinder: string;
    od_axis: string;
    os_axis: string;
    is_outbound_referral: boolean;
    od_wants_omd_report: boolean;
    omd_report: string;
    ai_report: string;
    report_type: ReportType;
    exam_is_validation_review: boolean;
}

export interface IExamMetrics {
    bcva_options: IOptionItem[];
    od_bcva: string;
    os_bcva: string;
    od_sphere: string;
    os_sphere: string;
    od_cylinder: string;
    os_cylinder: string;
    od_axis: string;
    os_axis: string;
    dtc_hour: string | null;
    dtc_values: {od?: number | null, os?: number | null }[];
    od_iop: string;
    os_iop: string;
    od_cd: string;
    os_cd: string;
    od_pachs: string;
    os_pachs: string;
    od_ishihara: string;
    os_ishihara: string;
    od_ishihara2: string;
    os_ishihara2: string;
    applanation: string | null;
}

export interface IPastExamMetrics {
    past_od_pachs: string;
    past_os_pachs: string;
    past_od_iop: string;
    past_os_iop: string;
    past_od_ishihara: string;
    past_od_ishihara2: string;
    past_os_ishihara: string;
    past_os_ishihara2: string;
    past_od_cd: string;
    past_os_cd: string;
    past_od_bcva: string;
    past_os_bcva: string;
    past_od_sphere: string;
    past_os_sphere: string;
    past_od_cylinder: string;
    past_os_cylinder: string;
    past_od_axis: string;
    past_os_axis: string;
    past_applanation: string;
    past_dtc_hour: string | null;
    past_dtc_values: {od?: number | null, os?: number | null }[];
}

export interface IPastExamODMessagingFields {
    past_fhx: boolean;
    past_pxf_od: boolean;
    past_pxf_os: boolean;
    past_pds_od: boolean;
    past_pds_os: boolean;
    past_allergies: string;
    past_allergies_none: boolean;
    past_until_yesterday: {show: boolean; values: IUntilYesterdayItem[]};
    past_ongoing: {show: boolean; values: IOngoingItem[]};
    past_iop_history: {show: boolean; values: IIopHistoryItem[]};
}

export interface IOmdReiewerOption extends IOptionItem {
    hidden_from_dropdown: boolean,
}

export interface IExamHistories {
    omd_history: {show: boolean; values: IOmdHistoryItem[]};
    iop_history: {show: boolean; values: IIopHistoryItem[]};
    until_yesterday: {show: boolean; values: IUntilYesterdayItem[]};
    ongoing: {show: boolean; values: IOngoingItem[]};
    comorbidities_none: boolean;
    asthma: boolean;
    fhx: boolean;
    heart_block: boolean;
    uveitis_od: boolean;
    uveitis_os: boolean;
    hx_stroke: boolean;
    pxf_od: boolean;
    pxf_os: boolean;
    pds_od: boolean;
    pds_os: boolean;
}

export interface IExamsWithLeftVf extends IAllExamImages {
    left_ght: string;
}

export interface IExamsWithRightVf extends IAllExamImages {
    right_ght: string;
}

export interface IExamsWithRightOctRnfl extends IAllExamImages {
    right_octav: string;
    right_octav_sup: string;
    right_octav_inf: string;
}

export interface IExamsWithLeftOctRnfl extends IAllExamImages {
    left_octav: string;
    left_octav_sup: string;
    left_octav_inf: string;
}

export interface IExamImages {
    photoUrls: IphotoUrls;
    pastPhotos: IpastPhotoUrls;
    extra_images: IExtraImage[];
    exams_with_left_oct: IAllExamImages[];
    exams_with_right_oct: IAllExamImages[];
    exams_with_left_oct_rnfl: IExamsWithLeftOctRnfl[];
    exams_with_right_oct_rnfl: IExamsWithRightOctRnfl[];
    exams_with_left_vf: IExamsWithLeftVf[];
    exams_with_right_vf: IExamsWithRightVf[];
    exams_with_left_fundus: IAllExamImages[];
    exams_with_right_fundus: IAllExamImages[];
    csv_left_fundus: ICSVPhoto;
    csv_right_fundus: ICSVPhoto;
    csv_left_stereo: ICSVPhoto;
    csv_right_stereo: ICSVPhoto;
    csv_left_switched: boolean;
    csv_right_switched: boolean;
}

export interface RightMlLargeHemePigmentResult {
    right_ml_large_heme_pigment_fundus_photo: string;
    right_ml_large_heme_pigment_overlay_photo: string;
    right_ml_large_heme_pigment_value: number;
    right_ml_large_heme_pigment_succeeded: boolean;
    right_ml_large_heme_pigment_exam_date: string;
}

export interface LeftMlLargeHemePigmentResult {
    left_ml_large_heme_pigment_fundus_photo: string;
    left_ml_large_heme_pigment_overlay_photo: string;
    left_ml_large_heme_pigment_value: number;
    left_ml_large_heme_pigment_succeeded: boolean;
    left_ml_large_heme_pigment_exam_date: string;
}

export interface RightMlMicroAneurysmsResult {
    right_ml_micro_aneurysms_fundus_photo: string;
    right_ml_micro_aneurysms_overlay_photo: string;
    right_ml_micro_aneurysms_value: number;
    right_ml_micro_aneurysms_succeeded: boolean;
    right_ml_micro_aneurysms_exam_date: string;
}

export interface LeftMlMicroAneurysmsResult {
    left_ml_micro_aneurysms_fundus_photo: string;
    left_ml_micro_aneurysms_overlay_photo: string;
    left_ml_micro_aneurysms_value: number;
    left_ml_micro_aneurysms_succeeded: boolean;
    left_ml_micro_aneurysms_exam_date: string;
}

export interface RightMlHardExudatesResult {
    right_ml_hard_exudates_fundus_photo: string;
    right_ml_hard_exudates_overlay_photo: string;
    right_ml_hard_exudates_value: number;
    right_ml_hard_exudates_succeeded: boolean;
    right_ml_hard_exudates_exam_date: string;
}

export interface LeftMlHardExudatesResult {
    left_ml_hard_exudates_fundus_photo: string;
    left_ml_hard_exudates_overlay_photo: string;
    left_ml_hard_exudates_value: number;
    left_ml_hard_exudates_succeeded: boolean;
    left_ml_hard_exudates_exam_date: string;
}

export interface IExamData extends IExamMetrics, IPastExamMetrics, IExamHistories,IExamDataDiagnosis, IExamImages, IPastExamODMessagingFields  {
    id: number | null;
    version: number;
    readOnly: boolean;
    operating: boolean;
    lastOperationType: string;
    dirty: boolean;
    info: string;
    error: string;
    pmh_m: string;
    history_and_exam: string;
    exam_status_options: IOptionItem[];
    exam_status: string;
    exam_status_previous: string;
    omdc_status_previous: string;
    saved_exam_status: string;
    omdr: string;
    exam_date: string;
    is_urgent: boolean;
    visit_number: number;
    exam_count: number;
    optometrist: number | null;
    submission_date: string;
    optometrist_fullname: string;
    optometrist_odgrouppractice_name: string;
    optometrist_city: string;
    optometrist_province: string;
    optometrist_closest_omd_hours: string;
    optometrist_closest_omd_name: string;
    optometrist_options: IOmdReiewerOption[];
    exams: ISharedExamData[];
    graph_iop: IGraphIop | null;
    graph_bcva: IGraphBcva | null;
    glaucoma_score_graph_data: IGlaucomaScoreGraphData[];
    od_group_practice: number | null;
    od_group_practice_province: string;
    omd_od: string;
    retina_only_omd_note_templates_options: {id: number, name: string}[];
    retina_only_omd_note_templates: number[];
    omdc: number | null;
    patient: number | null;
    exam_status_od_allowed: string[];
    exam_status_od_can_set: string[];
    eye_select_options: IOptionItem[];
    allergies: string;
    allergies_none: boolean;
    gp_refed: boolean;
    gpReferralUploadLabel: string;
    referral_letter: string;
    fu_letter: 'month' | 'week';
    fu_number: string;
    other_omd: string;
    cant_bill_oct: boolean;
    cant_bill_vf: boolean;
    refer_in_person_omd_appt: string;
    e_notes: string;
    od_omd: string;
    omdc_proposed_plan: string;
    billing_note: string;
    billing_note_cache: string;
    exam_is_retina_only: boolean;
    exam_is_perc: boolean;
    omdc_note_templates: number[];
    omd_note_string: string;
    omd_gp: string;
    omdc_status: string;
    is_pre_reviewed: boolean;
    past_omd: boolean;
    cu_omd: boolean;
    ipc: string;
    od_req_omd: boolean;
    left_ght: string;
    right_ght: string;
    left_vf_md: string;
    right_vf_md: string;
    left_vf_psd: string;
    right_vf_psd: string;
    od_vf_analysis_vfi: string;
    os_vf_analysis_vfi: string;
    od_vf_analysis_vqi: string;
    os_vf_analysis_vqi: string;
    od_rnfl_analysis_avg: string;
    od_rnfl_analysis_sup: string;
    od_rnfl_analysis_inf: string;
    os_rnfl_analysis_avg: string;
    os_rnfl_analysis_sup: string;
    os_rnfl_analysis_inf: string;
    left_octav: string;
    left_octav_sup: string;
    left_octav_inf: string;
    left_oct_autoset: boolean;
    right_octav: string;
    right_octav_sup: string;
    right_octav_inf: string;
    right_oct_autoset: boolean;
    selected_omd_note_options: number[];
    second_omdr: null | number;
    omd_textbox0: string;
    retina_referral: string;
    is_same_region: boolean;
    omdctraveltime: IOmdcTravelTime[];
    right_cd: string;
    left_cd: string;
    past_right_cd: string;
    past_left_cd: string;
    gp_letter_sent_date: string;
    is_gp_letter_sent_date_older_than_11_months: boolean;
    is_glaucoma: boolean;
    susp_features: string[];
    reas_features: string[];
    od_vf_override: string;
    os_vf_override: string;
    od_iop_aim: string;
    patient_status: string;
    followup_aim: string;
    os_iop_aim: string;
    prereview_alert: string[];
    old_billing_note: string;
    billing_addressed: boolean;
    right_cd_display: string;
    od_cd_desc_display: string;
    right_omd_assessment_display: string;
    left_cd_display: string;
    os_cd_desc_display: string;
    left_omd_assessment_display: string;
    od_question: boolean;
    second_omdr_reviewed: boolean;
    omdrSetGlcSusDiagnosis: boolean;
    os_treatment_iop_zone: string | null;
    od_treatment_iop_zone: string | null;
    treatment_fu_zone: string;
    odDiagnosedGlaucoma: boolean;
    od_start_drops: boolean;
    auto_patient_status: boolean;
    last_exam_auto_patient_status: boolean;
    od_adb: string;
    os_adb: string;
    cpt: string;
    icd_10: string;
    external_gp_status: null | string;
    internal_gp: null | string;
    internal_gp_status: null | string;
    omdr_auto_planned_billing_code: string;
    omdr_manual_planned_billing_code: string;
    omdc_auto_planned_billing_code: string;
    omdc_manual_planned_billing_code: string;
    od_is_integrated: boolean;
    dhx_od: boolean;
    dhx_os: boolean;
    od_baseline: boolean;
    omd_reviewer: null | number;
    omd_reviewer_options: IOmdReiewerOption[];
    exam_boct: string;
    exam_bfield: string;
    glc_type: string;
    prev_exams_have_glc_base: boolean;
    last_omd_gp: string;
    last_exam_omd_textbox0: string;
    last_omd_od: string;
    gp_referral_date: string;
    manual_gp_referral_date: string;
    initials_select: string;
    pre_reviewer_values: string;
    last_prereview_date: string;
    exam_prereview_date_after_cutoff: boolean;
    is_inactive: boolean;
    exam_is_us_only: boolean;
    glcNotSelectedByOD: boolean;
    cu_omd_admin_override: undefined | boolean;
    past_od_iop_aim: string;
    past_os_iop_aim: string;
    past_patient_status: null | string;
    past_followup_aim: null | string;
    od_referral_letter: string;
    od_referral_letter_pdf: string;
    impression: string;
    exam_od_uses_referral_letter_pei: boolean;
    examMetricsInfoModalOpen: boolean;
    gpt_saved_values: string;
    gpt_near_miss_fields: string;
    gpt_response: string;
    od_gpt_vf_ght_saved_values: string;
    od_gpt_vf_ght_near_miss: boolean;
    os_gpt_vf_ght_saved_values: string;
    os_gpt_vf_ght_near_miss: boolean;
    od_gpt_rnfl_saved_values: string;
    od_gpt_rnfl_near_miss: boolean;
    os_gpt_rnfl_saved_values: string;
    os_gpt_rnfl_near_miss: boolean;
    is_referral_letter_upload_pei: boolean;
    is_oct_rnfl_od_category_near_miss: boolean;
    is_oct_rnfl_os_category_near_miss: boolean;
    is_vf_od_category_near_miss: boolean;
    is_vf_os_category_near_miss: boolean;
    highlight_omd_pdf: string;
    od_uses_rlu_pei: boolean;  // for OD UI only
    recipient_removed_pdf: string;
    assign_first: boolean;
    assign_first_admin_override: boolean | undefined;
    exam_od_orientating: boolean;
    exam_od_essentials_only: boolean;
    rlu_exam_is_premium: boolean;
    exam_od_has_pei_toggle: boolean;
    admin_private_notes: string;
    admin_private_notes_addressed: boolean;
    is_review_processed: boolean;
    gpt_ai_snapshot_band_impressions: string[];
    gpt_ai_snapshot_band_od_assessment: string;
    gpt_ai_snapshot_band_od_question: string;
    right_ml_large_heme_pigment_fundus_photo: string;
    left_ml_large_heme_pigment_fundus_photo: string;
    right_ml_large_heme_pigment_value: number;
    left_ml_large_heme_pigment_value: number;
    right_ml_large_heme_pigment_succeeded: boolean;
    left_ml_large_heme_pigment_succeeded: boolean;
    right_ml_large_heme_pigment_exam_date: string;
    left_ml_large_heme_pigment_exam_date: string;
    right_ml_large_heme_pigment_view_3: RightMlLargeHemePigmentResult[];
    left_ml_large_heme_pigment_view_3: LeftMlLargeHemePigmentResult[];
    right_ml_micro_aneurysms_fundus_photo: string;
    left_ml_micro_aneurysms_fundus_photo: string;
    right_ml_micro_aneurysms_value: number;
    left_ml_micro_aneurysms_value: number;
    right_ml_micro_aneurysms_succeeded: boolean;
    left_ml_micro_aneurysms_succeeded: boolean;
    right_ml_micro_aneurysms_exam_date: string;
    left_ml_micro_aneurysms_exam_date: string;
    right_ml_micro_aneurysms_view_3: RightMlMicroAneurysmsResult[];
    left_ml_micro_aneurysms_view_3: LeftMlMicroAneurysmsResult[];
    right_ml_hard_exudates_fundus_photo: string;
    left_ml_hard_exudates_fundus_photo: string;
    right_ml_hard_exudates_value: number;
    left_ml_hard_exudates_value: number;
    right_ml_hard_exudates_succeeded: boolean;
    left_ml_hard_exudates_succeeded: boolean;
    right_ml_hard_exudates_exam_date: string;
    left_ml_hard_exudates_exam_date: string;
    right_ml_hard_exudates_view_3: RightMlHardExudatesResult[];
    left_ml_hard_exudates_view_3: LeftMlHardExudatesResult[];
    right_fundus_red_channel_photo: string;
    right_fundus_green_channel_photo: string;
    right_fundus_blue_channel_photo: string;
    left_fundus_red_channel_photo: string;
    left_fundus_green_channel_photo: string;
    left_fundus_blue_channel_photo: string;
    hide_exam_for_od: boolean;
    is_od_messaging_submission: boolean;
    past_od_referral_letter: string;
    past_od_referral_letter_pdf: string;
    past_od_referral_letter_date: string;
    past_highlight_omd_pdf: string;
    past_recipient_removed_pdf: string;
    is_fu_unknown: boolean;
    is_outbound_referral: boolean;
    cat_refer: boolean | null;
    referral_omd: null | number;
    ai_recommends_omd_report: boolean;
    exam_omd_is_re: boolean;
    second_opinion_teleconsult: number | null;
    od_wants_omd_report: boolean;
    od_requested_omd: number | null;
    omd_referral_text: string;
    smart_upload_id: number | null;
    upload_reconciler_admin_reconciliation_notes: string;
    is_smart_upload_submission: boolean;
}


const initialState: IExamData = {
    id: null,
    version: 0,
    readOnly: false,
    operating: false,
    lastOperationType: '',
    dirty: false,
    info: '',
    error: '',
    pmh_m: '',
    history_and_exam: '',
    exam_status_options: [],
    exam_status: '',
    exam_status_previous: '',
    omdc_status_previous: '',
    saved_exam_status: '',
    omdr:'',
    exam_date: '',
    is_urgent: false,
    visit_number: 0,
    exam_count: 0,
    optometrist: null,
    submission_date: '',
    optometrist_fullname: '',
    optometrist_odgrouppractice_name: '',
    optometrist_city: '',
    optometrist_province: '',
    optometrist_closest_omd_hours: '',
    optometrist_closest_omd_name: '',
    optometrist_options: [],
    exams: [],
    graph_iop: null,
    graph_bcva: null,
    glaucoma_score_graph_data: [],
    od_group_practice: null,
    od_group_practice_province: '',
    omd_od: '',
    retina_only_omd_note_templates_options: [],
    retina_only_omd_note_templates: [],
    omdc: null,
    patient: null,
    exam_status_od_allowed: [],
    exam_status_od_can_set: [],
    eye_select_options: [],
    allergies: '',
    allergies_none: false,
    gp_refed: false,
    gpReferralUploadLabel: '',
    referral_letter: '',
    fu_letter: 'month',
    fu_number: '',
    other_omd: 'False',
    cant_bill_oct: false,
    cant_bill_vf: false,
    refer_in_person_omd_appt: 'False',
    e_notes: '',
    od_omd: '',
    applanation: null,
    omdc_proposed_plan: '',
    billing_note: '',
    billing_note_cache: '',
    exam_is_retina_only: false,
    exam_is_perc: false,
    omdc_note_templates: [],
    omd_note_string: '',
    omd_gp: '',
    omdc_status: '',
    is_pre_reviewed: false,
    past_omd: false,
    cu_omd: false,
    ipc: '',
    od_req_omd: false,
    left_ght: '',
    right_ght: '',
    left_vf_md: '',
    right_vf_md: '',
    left_vf_psd: '',
    right_vf_psd: '',
    od_vf_analysis_vfi: '',
    os_vf_analysis_vfi: '',
    od_vf_analysis_vqi: '',
    os_vf_analysis_vqi: '',
    od_rnfl_analysis_avg: '',
    od_rnfl_analysis_sup: '',
    od_rnfl_analysis_inf: '',
    os_rnfl_analysis_avg: '',
    os_rnfl_analysis_sup: '',
    os_rnfl_analysis_inf: '',
    left_octav: '',
    left_octav_sup: '',
    left_octav_inf: '',
    left_oct_autoset: false,
    right_octav: '',
    right_octav_sup: '',
    right_octav_inf: '',
    right_oct_autoset: false,
    selected_omd_note_options: [],
    second_omdr: null,
    omd_textbox0: '',
    retina_referral: '',
    is_same_region: false,
    omdctraveltime: [],
    right_cd: '',
    left_cd: '',
    past_right_cd: '',
    past_left_cd: '',
    gp_letter_sent_date: '',
    is_gp_letter_sent_date_older_than_11_months: false,
    is_glaucoma: false,
    susp_features: [],
    reas_features: [],
    od_vf_override: '',
    os_vf_override: '',
    od_iop_aim: '',
    patient_status: '',
    followup_aim: '',
    os_iop_aim: '',
    prereview_alert: [],
    old_billing_note: '',
    billing_addressed: false,
    right_cd_display: '',
    od_cd_desc_display: '',
    right_omd_assessment_display: '',
    left_cd_display: '',
    os_cd_desc_display: '',
    left_omd_assessment_display: '',
    od_question: false,
    second_omdr_reviewed: false,
    omdrSetGlcSusDiagnosis: false,
    os_treatment_iop_zone: '-',
    od_treatment_iop_zone: '-',
    treatment_fu_zone: '-',
    odDiagnosedGlaucoma: false,
    od_start_drops: false,
    auto_patient_status: false,
    last_exam_auto_patient_status: false,
    od_adb: '',
    os_adb: '',
    cpt: '',
    icd_10: '',
    external_gp_status: null,
    internal_gp: null,
    internal_gp_status: null,
    omdr_auto_planned_billing_code: 'no_value',
    omdr_manual_planned_billing_code: 'no_value',
    omdc_auto_planned_billing_code: 'no_value',
    omdc_manual_planned_billing_code: 'no_value',
    od_is_integrated: false,
    dhx_od: false,
    dhx_os: false,
    od_baseline: false,
    omd_reviewer: null,
    omd_reviewer_options: [],  // TODO
    exam_boct: '',
    exam_bfield: '',
    glc_type: '',
    prev_exams_have_glc_base: false,
    last_omd_gp: '',
    last_exam_omd_textbox0: '',
    last_omd_od: '',
    gp_referral_date: '',
    manual_gp_referral_date: '',
    initials_select: '',
    pre_reviewer_values: '',
    last_prereview_date: '',
    exam_prereview_date_after_cutoff: false,
    is_inactive: false,
    exam_is_us_only: false,
    glcNotSelectedByOD: false,
    cu_omd_admin_override: undefined,
    past_od_iop_aim: '',
    past_os_iop_aim: '',
    past_patient_status: null,
    past_followup_aim: null,
    od_referral_letter: '',
    od_referral_letter_pdf: '',
    impression: '',
    highlight_omd_pdf: '',
    recipient_removed_pdf: '',
    assign_first: false,
    assign_first_admin_override: undefined,
    exam_od_orientating: false,
    exam_od_essentials_only: false,

    /*  Exam Metrics  */
    bcva_options: [],
    od_bcva: 'ND',
    os_bcva: 'ND',
    od_sphere: '',
    os_sphere: '',
    od_cylinder: '',
    os_cylinder: '',
    od_axis: '',
    os_axis: '',
    dtc_hour: '',
    dtc_values: [],
    od_iop: '',
    os_iop: '',
    od_cd: '',
    os_cd: '',
    od_pachs: '',
    os_pachs: '',
    od_ishihara: '',
    os_ishihara: '',
    od_ishihara2: '',
    os_ishihara2: '',
    past_od_pachs: '',
    past_os_pachs: '',
    past_od_iop: '',
    past_os_iop: '',
    past_od_ishihara: '',
    past_od_ishihara2: '',
    past_os_ishihara: '',
    past_os_ishihara2: '',
    past_od_cd: '',
    past_os_cd: '',
    past_od_bcva: '',
    past_os_bcva: '',
    past_od_sphere: '',
    past_os_sphere: '',
    past_od_cylinder: '',
    past_os_cylinder: '',
    past_od_axis: '',
    past_os_axis: '',
    past_applanation: '',
    past_dtc_hour: '',
    past_dtc_values: [],
    past_fhx: false,
    past_pxf_od: false,
    past_pxf_os: false,
    past_pds_od: false,
    past_pds_os: false,
    past_allergies: '',
    past_allergies_none: false,
    past_until_yesterday: {show: false, values: []},
    past_ongoing: {show: false, values: []},
    past_iop_history: {show: false, values: []},

    /* Exam Histories  */
    omd_history: {show: false, values: []},
    iop_history: {show: false, values: []},
    until_yesterday: {show: false, values: []},
    ongoing: {show: false, values: []},
    comorbidities_none: false,
    asthma: false,
    fhx: false,
    heart_block: false,
    uveitis_od: false,
    uveitis_os: false,
    hx_stroke: false,
    pxf_od: false,
    pxf_os: false,
    pds_od: false,
    pds_os: false,

    /* Exam Images */
    photoUrls: initialPhotoUrls,
    pastPhotos: initialPastPhotoUrls,
    extra_images: [],
    exams_with_left_oct: [],
    exams_with_right_oct: [],
    exams_with_left_oct_rnfl: [],
    exams_with_right_oct_rnfl: [],
    exams_with_left_vf: [],
    exams_with_right_vf: [],
    exams_with_left_fundus: [],
    exams_with_right_fundus: [],
    csv_left_fundus: initialCSVPhoto,
    csv_right_fundus: initialCSVPhoto,
    csv_left_stereo: initialCSVPhoto,
    csv_right_stereo: initialCSVPhoto,
    csv_left_switched: false,
    csv_right_switched: false,

    /* Exam Diagnosis */
    rr_glc: false,
    rr_glc_suspect: false,
    rr_dm: false,
    rr_amd: false,
    rr_erm: false,
    rr_optic_nerve: false,
    rr_narrow_angles: false,
    rr_cataract: false,
    rr_cat_post_op: false,
    ped: false,
    macular_hole: false,
    nevus: false,
    plaquenil: false,
    rao: false,
    vmt: false,
    rr_others: '',
    diagnostics_values: '',
    diagnosis_list: [],
    exam_od_uses_referral_letter_pei: false,
    examMetricsInfoModalOpen: false,
    gpt_saved_values: '',
    od_gpt_vf_ght_saved_values: '',
    od_gpt_vf_ght_near_miss: false,
    os_gpt_vf_ght_saved_values: '',
    os_gpt_vf_ght_near_miss: false,
    od_gpt_rnfl_saved_values: '',
    od_gpt_rnfl_near_miss: false,
    os_gpt_rnfl_saved_values: '',
    os_gpt_rnfl_near_miss: false,
    gpt_near_miss_fields: '',
    gpt_response: '',
    is_referral_letter_upload_pei: false,
    is_oct_rnfl_od_category_near_miss: false,
    is_oct_rnfl_os_category_near_miss: false,
    is_vf_od_category_near_miss: false,
    is_vf_os_category_near_miss: false,
    od_uses_rlu_pei: false,
    rlu_exam_is_premium: false,
    exam_od_has_pei_toggle: false,
    admin_private_notes: '',
    admin_private_notes_addressed: false,
    is_review_processed: false,
    gpt_ai_snapshot_band_impressions: [],
    gpt_ai_snapshot_band_od_assessment: '',
    gpt_ai_snapshot_band_od_question: '',
    right_ml_large_heme_pigment_fundus_photo: '',
    left_ml_large_heme_pigment_fundus_photo: '',
    right_ml_large_heme_pigment_value: 0,
    left_ml_large_heme_pigment_value: 0,
    right_ml_large_heme_pigment_succeeded: false,
    left_ml_large_heme_pigment_succeeded: false,
    right_ml_large_heme_pigment_exam_date: '',
    left_ml_large_heme_pigment_exam_date: '',
    right_ml_large_heme_pigment_view_3: [],
    left_ml_large_heme_pigment_view_3: [],
    right_ml_micro_aneurysms_fundus_photo: '',
    left_ml_micro_aneurysms_fundus_photo: '',
    right_ml_micro_aneurysms_value: 0,
    left_ml_micro_aneurysms_value: 0,
    right_ml_micro_aneurysms_succeeded: false,
    left_ml_micro_aneurysms_succeeded: false,
    right_ml_micro_aneurysms_exam_date: '',
    left_ml_micro_aneurysms_exam_date: '',
    right_ml_micro_aneurysms_view_3: [],
    left_ml_micro_aneurysms_view_3: [],
    right_ml_hard_exudates_fundus_photo: '',
    left_ml_hard_exudates_fundus_photo: '',
    right_ml_hard_exudates_value: 0,
    left_ml_hard_exudates_value: 0,
    right_ml_hard_exudates_succeeded: false,
    left_ml_hard_exudates_succeeded: false,
    right_ml_hard_exudates_exam_date: '',
    left_ml_hard_exudates_exam_date: '',
    right_ml_hard_exudates_view_3: [],
    left_ml_hard_exudates_view_3: [],
    right_fundus_red_channel_photo: '',
    right_fundus_green_channel_photo: '',
    right_fundus_blue_channel_photo: '',
    left_fundus_red_channel_photo: '',
    left_fundus_green_channel_photo: '',
    left_fundus_blue_channel_photo: '',
    hide_exam_for_od: false,
    is_od_messaging_submission: false,
    past_od_referral_letter: '',
    past_od_referral_letter_pdf: '',
    past_od_referral_letter_date: '',
    past_highlight_omd_pdf: '',
    past_recipient_removed_pdf: '',
    is_fu_unknown: false,
    is_outbound_referral: false,
    cat_refer: null,
    referral_omd: null,
    ai_recommends_omd_report: false,
    exam_omd_is_re: false,
    second_opinion_teleconsult: null,
    od_wants_omd_report: true,
    od_requested_omd: null,
    omd_referral_text: '',
    smart_upload_id: null,
    upload_reconciler_admin_reconciliation_notes: '',
    is_smart_upload_submission: false,
}

const isOmdcStatusValid  = (omdc: number | null, omdc_status: string) => {
    if (!omdc && (omdc_status !=='' && omdc_status !=='processed')) {
        return false;
    }
    if (omdc && omdc_status === 'processed') {
        return false;
    }
    return true;
}
// Triggered by PEI Save Button click
export const saveExamData = (overwrite = false) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const { user, examData, diagnosis, stopwatch } = getState() as {user: IUser, examData: IExamData, diagnosis: IDiagnosis, stopwatch: IStopWatchState };
        const { history_and_exam, pmh_m, is_pre_reviewed, cu_omd, cu_omd_admin_override,
            selected_omd_note_options, assign_first, assign_first_admin_override,
            exam_od_uses_referral_letter_pei, rlu_exam_is_premium,
            is_od_messaging_submission, is_urgent, od_question,
            exam_status, exam_status_previous, ipc, omdc_status, omdc_status_previous
         } = examData;

        const userIsStandardRLU = exam_od_uses_referral_letter_pei && !rlu_exam_is_premium;

        const isOutboundPreReviewed = dispatch(isOutboundPreReviewedExam());

        // For outbound referral exams being submitted, make sure the correct settings are selected.
        if (user && user.isOD && examData.is_referral_letter_upload_pei) {
            if (exam_status === 'ready' || exam_status === 'od_review') {
                if (examData.is_outbound_referral === false) {
                    dispatch(setExamDataValue('cat_refer', false));
                    dispatch(setExamDataValue('referral_omd', null));
                    dispatch(setExamDataValue('rr_cataract', false));
                } else {
                    dispatch(setExamDataValue('is_urgent', false));
                    dispatch(setExamDataValue('od_requested_omd', null));
                    dispatch(setExamDataValue('od_wants_omd_report', true));
                }
            }
        }

        // First save the diagnosis data to the exam store.
        dispatch(saveDiagnosisData(diagnosis.entries));
        // Set the OD start drop data based on ongoing drops
        const {rr_glc : glc, rr_glc_suspect: glcS, rr_narrow_angles: narrowAngles} = examData;
        if (patientIsGlaucoma(diagnosis.entries, {glc, glcS, narrowAngles})) {
            dispatch(setODStartDropsField())
        }

        if (user && user.isRetinaEnabled && user.isOD){
            if(!pmh_m.includes(history_and_exam)){
                dispatch(setExamDataValue('pmh_m', `${history_and_exam}${pmh_m}`));
            }
        }
        // if the user is OMDR, add the retina referral option to selected OMD Note Templates
        if ((user && user.isOMDR) || isOutboundPreReviewed) {
            if (examData.retina_referral === 'optional') {
                // only one retina referral can be selected, make sure Needed and Custom are not in the selected options
                const new_selected_options = examData.selected_omd_note_options.filter(option => option !== NEEDED_ID && option !== CUSTOM_ID);
                new_selected_options.push(OPTIONAL_ID);
                dispatch(setExamDataValue('selected_omd_note_options', new_selected_options));
            }
            if (examData.retina_referral === 'needed') {
                // only one retina referral can be selected, make sure Optional and Custom are not in the selected options
                const new_selected_options = examData.selected_omd_note_options.filter(option => option !== OPTIONAL_ID && option !== CUSTOM_ID);
                new_selected_options.push(NEEDED_ID);
                dispatch(setExamDataValue('selected_omd_note_options', new_selected_options));
            }
            if (examData.retina_referral === '') {
                // when N/A is selected, make sure both Optional and Needed are not in selected_omd_notes_options
                const new_selected_options = examData.selected_omd_note_options.filter(
                    option => option !== OPTIONAL_ID && option !== NEEDED_ID && option !== CUSTOM_ID);
                dispatch(setExamDataValue('selected_omd_note_options', new_selected_options));
            }
            if (examData.retina_referral === 'custom') {
                // When no option is selected, make sure that only Custom is in selected options.
                const new_selected_options = examData.selected_omd_note_options.filter(
                    option => option !== OPTIONAL_ID && option !== NEEDED_ID);
                new_selected_options.push(CUSTOM_ID);
                dispatch(setExamDataValue('selected_omd_note_options', new_selected_options));
            }

            if(examData.old_billing_note !== examData.billing_note && examData.billing_addressed) {
                // if billing notes has been modified and billing addressed is true, set billing_addressed to false
                dispatch(setExamDataValue('billing_addressed', false));
            }

            if (user.isOMDR) {
                if ( examData.exam_status === 'reviewed' && examData.exam_status_previous === 'reviewed') {
                    // The post-submit edit made by doctors are now routed to the admin private notes section.
                    const oldAdminPrivateNotes = examData.admin_private_notes;
                    const newAdminPrivateNotes = `Dr. ${user.displayName} has made an edit on ${dayjs().format('YYYY-MM-DD')} `;
                    const combinedAdminPrivateNotes = `${oldAdminPrivateNotes.includes(newAdminPrivateNotes) ? '' : newAdminPrivateNotes + ' \n '}${oldAdminPrivateNotes}`;
                    dispatch(setExamDataValue('admin_private_notes', combinedAdminPrivateNotes.trim()));
                    // if the Addressed button was selected, it is now removed
                    if (examData.admin_private_notes_addressed === true){
                        dispatch(setExamDataValue('admin_private_notes_addressed', false));
                    }
                }
            }

            if ( exam_status === 'reviewed' && exam_status_previous !== 'reviewed') {
                if (examData.is_referral_letter_upload_pei && examData.rlu_exam_is_premium && !examData.is_outbound_referral) {
                    if (is_urgent || ipc === 'omd_requested' || od_question || selected_omd_note_options.includes(ALERT_ID) ) {
                        dispatch(setExamDataValue('ai_recommends_omd_report', true));
                    }
                }
            }

            if (examData.od_question) {
                if ((!examData.omd_gp && !examData.omd_od)|| !examData.selected_omd_note_options.includes(ALERT_ID)) {
                    Modal.error({
                        className: 'info-modal',
                        content: 'The optometrist asked a question this visit, but the alert button has not been checked on and/or you have not typed any notes to the optometrist. Please ensure that you do so for this patient. Your chart will still be saved.'
                    })
                }
            }

            if (examData.is_glaucoma) {
                const { left_cd, right_cd } = examData;
                if (left_cd === '' || left_cd === 'XXX' || right_cd === '' || right_cd === 'XXX') {
                    Modal.error({
                        className: 'info-modal',
                        content: 'C/D was not selected, this chart has still been saved.'
                    })
                }
            }

            if (user.isOMDR && examData.is_glaucoma){
                // reset the local storage when exam is saved
                try {
                    const txalgo3Data: ITxalgo3LocalStorageData[] = JSON.parse(localStorage.getItem('txalgo3') ?? '[]');
                    if (txalgo3Data && txalgo3Data.length && txalgo3Data.find(item => item.id===examData.id)){
                        const newTxalgo3Data = txalgo3Data.filter(item => item.id !== examData.id);
                        localStorage.setItem('txalgo3', JSON.stringify(newTxalgo3Data));
                    }
                } catch (error) {
                    console.log(`Errors converting txalgo3 data, ${error}`)
                }

            }

        }
        if (user && user.isADMIN) {
            const {preReview: { isDirty } } = getState() as {preReview: {isDirty: boolean}};
            if (isDirty && is_pre_reviewed ) {
                Modal.error({
                    className: 'info-modal',
                    title: 'Please Click the Pre-Review Button before Saving the Chart.',
                });
                return;
            }
            dispatch(setPreReviewData());
            // if admin manually selects CU OMD, use that value instead
            const cuOmdValue = cu_omd_admin_override ?? cu_omd;
            dispatch(setExamDataValue('cu_omd', cuOmdValue));
            // if admin manually selects Assign First, use that value instead
            const assignFirstValue = assign_first_admin_override ?? assign_first;
            dispatch(setExamDataValue('assign_first', assignFirstValue));
            // 1. When the CURT/UPCOMING OMD button is selected (either automatically by the software,
            // or manually by Admin), the GP LETTER button should always be deselected
            // Also, if the exam is an OD messaging submission, GP LETTER button should also be deselected.
            if (cuOmdValue === true || is_od_messaging_submission) {
                const { gpLetterId } = OMD_NOTE_TEMPLATE_DICT;
                const updatedOmdNoteOptions = selected_omd_note_options.filter(option => option !== gpLetterId);
                dispatch(setExamDataValue('selected_omd_note_options', updatedOmdNoteOptions));
            }
        }

        if (user && (user.isADMIN || user.isOMDR )) {
            if (!isOmdcStatusValid(examData.omdc, examData.omdc_status)) {
                Modal.error({
                    className: 'info-modal',
                    title: 'Assigned OMD consultant does not match status.',
                })
            }
        }

        if (user && user.isOD) {
            const {examData: {other_omd, refer_in_person_omd_appt} } = getState();
            const cuOmdValue = (refer_in_person_omd_appt === 'True') || (other_omd === 'True')
            dispatch(setExamDataValue('cu_omd', cuOmdValue));
        }

        // If the OMDR has selected the special option to add a GlcSus diagnosis to the patient than set the exam data
        // value since it could not be set in the omdr reviewer selectors component where the diagnosis form is not
        // available.
        if(examData.omdrSetGlcSusDiagnosis){
            dispatch(setExamDataValue('rr_glc_suspect', true));
        }

        // Trigger: When RE OD set to Ready for OMD
        // Action: The pre-review button gets set to true
        if (user && user.isOD && (user.isRetinaEnabled || userIsStandardRLU)) {
            if (examData.exam_status === 'ready') {
                dispatch(setExamDataValue('initials_select', 'auto'));
            }
        }
        // Trigger: When RE OD set to Ready for OMD
        // Action: The pre-review button gets set to true
        // Also, if user is OD and standard RLU, the same events happen.
        if (user && user.isOD && (user.isRetinaEnabled || userIsStandardRLU)) {
            if (getState().examData.exam_status === 'ready') {
                dispatch(preReviewExam());
                dispatch(setAutoPreReviewMessageRequest());
                dispatch(setExamDataValue('exam_prereview_date_after_cutoff', true));
            }
        }

        // For premium RLU exams being submitted by the OD, clear the default OMDR, since we want the admin to select it.
        if (user && user.isOD && (exam_od_uses_referral_letter_pei && rlu_exam_is_premium)) {
            if (getState().examData.exam_status === 'od_review') {
                dispatch(setExamDataValue('omd_reviewer', ''));
            }
        }

        // When the OMDR or Admin saves, make sure that certain selectors cancel out the NO SURG selector
        if (user && (user.isOMDR || user.isADMIN) && userIsStandardRLU) {
            const {examData: {selected_omd_note_options}} = getState();
            if (selected_omd_note_options.includes(OMD_NOTE_TEMPLATE_DICT.noSurgId) && 
                (selected_omd_note_options.includes(ERM_SURGICAL_ID) || selected_omd_note_options.includes(OPTIONAL_ID) ||
                selected_omd_note_options.includes(NEEDED_ID))) {
                // Always clear the No SURG selector if the above options are selected.
                const updatedOmdNoteOptions = selected_omd_note_options.filter(option => option !== OMD_NOTE_TEMPLATE_DICT.noSurgId);
                dispatch(setExamDataValue('selected_omd_note_options', updatedOmdNoteOptions));
            }
        }

        const currentExamData = dispatch(getSaveDataFromState());

        if (user && user.isADMIN) {
            if (stopwatch.stopwatchIsRunning) {
                dispatch(stopwatchPause());
                dispatch(preReviewTimeTimeUpdate());
                dispatch(setStopwatchResumeExamIdAfterSave(`${examData.id}`));
            }
        }

        if (overwrite) {
            currentExamData['overwrite'] = true;
        }
        const res = await dispatch(saveExamDataRequest(currentExamData)).unwrap();
        if (res?.success) {
            const currentState = getState();

            const { examData, user, uploadReconciler } = currentState;

             if (examData && examData.exam_status === 'reviewed' && examData.omdc_status === 'reviewed') {
                // Only for omdc, exam_status and omdc have been already set as 'reviewed'. use exam_status
                // and omdc_status to determine if submit button or save button is clicked
                Modal.info({
                    className: 'info-modal',
                    title: INFO_MESSAGE_TITLE,
                    content: 'Exam has been submitted successfully',
                });
            } else {
                // Show the success message in a dialog for non-OD IC users.
                if (user && !(user.isOD && !user.isRetinaEnabled)) {
                    Modal.info({
                        className: 'info-modal',
                        title: INFO_MESSAGE_TITLE,
                        content: res.success,
                    });
                }
            }

            const currentExamStatus = currentState.examData.exam_status;

            // Set the saved exam status to the current exam status.
            dispatch(setExamDataValue('exam_status', currentExamStatus));

            // Set exam status to ReadOnly if status is `Ready for OMD` Or `OMD Reviewed`
            if (currentExamStatus === 'ready' || currentExamStatus === 'reviewed') {
                dispatch(setExamReadOnly(true));
            }

            // Clear the exam dirty state.
            dispatch(setExamDirty(false));

            if (user && user.isOD && examData && examData.is_referral_letter_upload_pei && examData.is_outbound_referral) {
                if (examData.exam_status === 'od_review' && examData.exam_status_previous === 'not_ready') {
                    return new Promise((resolve) => {
                        return resolve('Success');
                    })
                }
            }

            if (user && user.isOMDC) {
                // handle OMDC submission at omd submit button component
                if ((omdc_status_previous === '' || omdc_status_previous === 'ready') && omdc_status === 'reviewed') {
                    return new Promise((resolve) => {
                        return resolve('Success');
                    })
                }
            }

            if (uploadReconciler.submittingExam) {
                // handle upload reconciler submit
                return new Promise((resolve) => {
                    return resolve('Success');
                })
                
            }

            // Reload the exam details
            await dispatch(editExamRequest(res.examId, ()=>{}));

            // After the regular set to OD to Review and save, for standard RLU we need
            //  to also set the exam to "Ready for OMD" and allow auto-prereview.
            if (user && user.isOD && userIsStandardRLU ) {
                if (getState().examData.exam_status === 'od_review') {
                    dispatch(setExamDataValue('exam_status', 'ready'));
                    dispatch(saveExamData());
                }
            }
        } else if (res.error) {
            const errorIsValidDate = dayjs(res.error).isValid();
            if (errorIsValidDate) {
                // Show the option message in a dialog.
                const recordModifedDatetime = new Date(res.error);
                const recordModifedLocalDateTime = recordModifedDatetime.toLocaleDateString() + " " + recordModifedDatetime.toLocaleTimeString();

                Modal.confirm({
                    className: 'info-modal',
                    title: `This record is out of date. It was last saved by another user at ${recordModifedLocalDateTime}. Do you still want to save your changes?`,
                    content: <div>
                                <div>Click YES to continue saving. This will overwrite the other user's changes.</div>
                                <div>Click NO to reload the record from the database. This will overwrite your changes.</div>
                            </div>,
                    onOk: () => {
                        // Overwrite the exam
                        dispatch(saveExamData(true));
                    },
                    onCancel: () => {
                        // Cancel saving the exam, reload the page
                        window.location.reload();
                    },
                    okText: 'Yes',
                    cancelText: 'No',
                });
            }
            else {
                // Show the failure message in a dialog.
                Modal.error({
                    title: ERROR_MESSAGE_TITLE,
                    content: res.error,
                });
            }
        }


}

const examAlgo3IopWarningModal = (iopValue: number) => (
    <div>
        <p>{`Please inform the patient's Optometrist that, except for special circumstances, all asymptomatic patients with IOP in this range (measured or corrected IOP of ${iopValue} or more) should be asked to come back for a follow-up within the next 1-3 days, for repeat:`}</p>
        <p>- Applanation IOP</p>
        <p>- OCT</p>
        <p>- 24-2 Visual Field.</p>
        <p>It is strongly recommended that baseline testing be completed even for patients with very elevated IOP, instead of starting topical therapy right away, as this may represent our only chance in the patient's lifetime to establish an accurate pre-treatment baseline.</p>
    </div>
)

export const getComorbiditiesText = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData } = getState();
    let comorbiditiesText = 'None';
    const comorbiditiesNone = examData.comorbidities_none;

    if (!comorbiditiesNone) {
        const asthma = examData.asthma;
        const heartBlock = examData.heart_block;
        const uveitisOd = examData.uveitis_od;
        const uveitisOs = examData.uveitis_os;

        comorbiditiesText = `${asthma ? 'Asthma' : ''}${heartBlock ? ', Heart Block' : ''}${uveitisOd ? ', Uveitis OD' : ''}${uveitisOs ? ', Uveitis OS' : ''}`;

        // It's possible for there to be other comorbidities but not the ones above, in that case the text should
        // display "None".
        if (!comorbiditiesText) {
            comorbiditiesText = 'None';
        }
    }

    return comorbiditiesText;
};

export const isOutboundPreReviewedExam = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData: {is_outbound_referral, omd_reviewer}, user: {isADMIN} } = getState();
    return isADMIN && is_outbound_referral && omd_reviewer === OUTBOUND_OMDR_ID;
}

export const submitExam = (navigate: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        // First set the exam status to reviewed.
        dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, 'reviewed'));

        const { examData, user, patientExams, txalgo3 } = getState();

        const isOutboundPreReviewed = dispatch(isOutboundPreReviewedExam());

        const { currentExamRoom, shouldDisplayMultiTabs, rooms } = patientExams;

        // If the user is OMDC, also set the omdc status to reviewed.
        if (user.isOMDC) {
            dispatch(setExamDataValue('omdc_status', 'reviewed'));
        }

        const { exam_count, visit_number, omdc_status, omd_gp, retina_referral, is_referral_letter_upload_pei, rlu_exam_is_premium, is_outbound_referral
        } = examData;

        const { left_iop, left_ciop, right_iop, right_ciop } = txalgo3;

        // if the current exam is the latest exam, check for txalgo3 iop values, if one of the iop
        // values is greater than 33 or 28, display warning message
        if (exam_count === visit_number && omdc_status === 'ready') {
            if (parseInt(left_iop) >= 33 || parseInt(left_ciop) >= 33 ||
                parseInt(right_iop) >= 33 || parseInt(right_ciop) >= 33) {
                Modal.error({
                    className: 'info-modal',
                    content: examAlgo3IopWarningModal(33),
                    title: 'Attention',
                });
            } else if (parseInt(left_iop) >= 28 || parseInt(left_ciop) >= 28 ||
                parseInt(right_iop) >= 28 || parseInt(right_ciop) >= 28) {
                Modal.error({
                    className: 'info-modal',
                    content: examAlgo3IopWarningModal(28),
                    title: 'Attention',
                });
            }
        }

        if (user.isOMDR || isOutboundPreReviewed) {
            // if OMD Notes include XXXXX, display warning message
            if(omd_gp.includes('XXXXX')){
                Modal.error({
                    className: 'info-modal',
                    content: 'Unable to submit, XXXXX was found in your review.',
                });
                return;
            }
            // If the Retina Referral (in OMDR RE F/U options) is Needed, set the ipc value to requested
            if (retina_referral === 'needed') {
                dispatch(setExamDataValue('ipc', 'requested'));
            }

            // For OMDRs, also set the IPC status to Requested by OMD if:
            //  for RLU premium, In-Person OMD Appt is selected
            //  for any RLU, In-Person Retina Referred Needed is selected
            if (is_referral_letter_upload_pei) {
                if (rlu_exam_is_premium) {
                    const OMD_FU_ID = 16;
                    if (examData.selected_omd_note_options.includes(OMD_FU_ID)) {
                        dispatch(setExamDataValue('ipc', 'omd_requested'));
                    }
                }
                
                if (retina_referral === 'needed') {
                    dispatch(setExamDataValue('ipc', 'omd_requested'));
                }
            }

            // update the old billing note so that the old billing note can be used to compare with
            // the updated billing note
            dispatch(setExamDataValue('old_billing_note', examData.billing_note));
        }
        dispatch(setExamDataValue('is_pre_reviewed', false));
        /* TODO For OMDR IC,
           1. save the current CSV viewer
        */

        /* TODO For OMD Power Reviewer,
           1. set omdr of the patient
        */
        try{
            // Set the review timestamp, then save the exam. After that, cancel out of it.
            await dispatch(examApi.endpoints.setExamReviewedTimestamp.initiate(examData.id!)).unwrap();
            (user.isOMDR || isOutboundPreReviewed) && await dispatch(examApi.endpoints.clearSecondOmdrAlertOnPrevExams.initiate(examData.id!))
            dispatch(saveExamData())
            // close the current tab is multi-tab view is active
            if (rooms && rooms.length > 1 && shouldDisplayMultiTabs) {
                dispatch(removeExamFromTab(currentExamRoom, navigate));
            } else {
                if (user.isOMDR) {
                    const examIdsStr = localStorage.getItem('exam_ids');
                    let examIds;
                    try{
                        examIds = JSON.parse(examIdsStr!);
                    } catch(e) {
                        console.log(e);
                    }
                    if (examIds && examIds.length) {
                        // remove the first item in examIds and save it to nextExamInQueue
                        const nextExamInQueue = examIds.shift();
                        // save the updated examIds to local storage
                        localStorage.setItem('exam_ids', JSON.stringify(examIds));
                        const examUrl = `${PATIENT_EXAM_URL}/${nextExamInQueue}`;
                        goToExamInNewTab(examUrl);
                    } else {
                        Modal.info({
                            className: 'info-modal',
                            title: 'Your changes have been saved, you may close this tab. There are no more patients to load.',
                        })
                    }
                } else if (is_outbound_referral && user.isADMIN){
                    // do nothing when exam is outbound exam and is pre-reviewed
                } else {
                        // close current exam page if the last one exam is submitted
                        navigate(ROOT_URL);
                }
            }
        } catch (error) {
            Modal.error({
                className: 'info-modal',
                title: `Errors submitting exam, ${error}`,
            })
        }
}

export const cancelExam = (navigate: NavigateFunction) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
        const { patientExams: { currentExamRoom, rooms } } = getState() as {patientExams: IPatientExamRooms};
        let closeExamPage = true;

        // For cancelling exams, if we are using a tabbed exam page, close the current tab and find the next one.
        if (rooms.length) {
            // Remove the current exam room from the list of rooms and select the next one, if any. If no tabs are left,
            // clear the data.
            const currentExamRoomIndex = rooms.findIndex((entry) => entry.examId.toString() === currentExamRoom);
            if (currentExamRoomIndex >= 0) {
                const nextExamId = rooms.length - 1 > currentExamRoomIndex ? rooms[currentExamRoomIndex + 1].examId :
                    rooms[0].examId;
                let newRooms = [...rooms];
                newRooms.splice(currentExamRoomIndex, 1);

                if (newRooms.length) {
                    closeExamPage = false;
                    dispatch(setPatientExamRoomsData({ rooms: newRooms }));
                    dispatch(editAndGoToExamInInternalTab(nextExamId.toString(), navigate));
                }
                else {
                    dispatch(clearPatientExamRoomsData());
                }
            }
        }

        if (closeExamPage) {
            navigate(ROOT_URL);
        }
}

export const saveExamDataAndClose = (navigate: NavigateFunction) => (dispatch: AppDispatch) => {
    dispatch(saveExamData())
    // On close of an exam, go back to the root URL.
    navigate(ROOT_URL);
}

export const editExamRequest = (examId: number, navigate: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(clearDropzoneData());
        dispatch(clearExamData());

        // Check user status
        const user = getState().user;
        const userIsOMDC = user.isOMDC;
        const userIsOMDR = user.isOMDR;
        const userIsRE = user.isRetinaEnabled;
        const userIsOD = user.isOD;
        const userIsADMIN = user.isADMIN;

        try {
            const response: IExamDataResponse = await dispatch(getExamDataRequest(examId)).unwrap();
            if (response.error) {
                throw new Error(`Errors getting exam data, ${response.error}`)
            } else {

                if (userIsRE && userIsOD) {
                    // set pmh_m value to '' for RE users so that placeholder value is displayed
                    dispatch(setExamHistoryDefault(response.pmh_m));
                }
                if (userIsOMDC) {
                    dispatch(setFuOptionsDefault(response.omdc_note_templates));
                }

                if (userIsOMDR && userIsRE) {
                    dispatch(setNotesToOmdGpDefault());
                }

                // Get TxAlgo3 if user is OMDR or DeepMD OD and patient is glaucoma
                if ((userIsOMDR || userIsOD) && getState().examData.is_glaucoma) {
                    dispatch(resetTxAlgo3());
                    dispatch(getTxAlgo3Request(examId));
                }
                if (userIsOMDR && getState().examData.is_glaucoma && getState().examData.exam_status==="ready"){
                    dispatch(resetTxalgo3Selection());
                }

                // Set exam status to ReadOnly if status is `Ready for OMD` Or `OMD Reviewed`
                if (response.exam_status && (response.exam_status === 'ready' || response.exam_status === 'reviewed')) {
                    dispatch(setExamReadOnly(true));
                }

                // Get random treatment sentences if the user is OMDR.
                if (userIsOMDR) {
                    dispatch(getRandomSentencesRequest(TREATMENT_RANDOM_SENTENCES_LIST));
                }

                // set pre-reviewer initials
                if (userIsADMIN) {
                    const {examData: {initials_select}} = getState();

                    if (!initials_select) {
                        const newInitials = getUserInitials(user);
                        dispatch(setExamDataValue('initials_select', newInitials));
                    }
                }
            }

            // Load the diagnosis data for this exam.
            const diagnosisValues: IDiagnosisValue = dispatch(getDiagnosisValues());
            dispatch(loadDiagnosisData(diagnosisValues));

            // Load the GPT saved and near miss values into the GPT saved values section.
            dispatch(setGptSetValuesData({gpt_saved_values: response.gpt_saved_values,
                gpt_near_miss_fields: response.gpt_near_miss_fields, gpt_response: response.gpt_response}));

            const currentState = getState();

            // Redirect to dashboard if LoggedIn OD group practice doesn't match Exam > Patient > Group practice
            if (navigate && !currentState.user.isADMIN && currentState.user.isOD && currentState.user.odPractice !== currentState.examData.od_group_practice) {
                dispatch(toggleNotAuthorizedForExam(true));
                return navigate(ROOT_URL);
            }

            dispatch(setExamDirty(false));
            dispatch(toggleODNotes(true));
            await dispatch(getPatientDetailsRequest(currentState.examData.patient!));
        } catch(error) {
            console.error('Errors getting exam data', error);
            Modal.confirm({
                className: 'info-modal',
                content: `${error}. Press OK to navigate back to home page, Cancel to stay at current page.`,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => navigate(ROOT_URL),
                onCancel: () => {}
            })
        }
}

export const retrieveExamData = (examId: number) => async (dispatch: AppDispatch) => {
    await dispatch(getExamDataRequest(examId));
    dispatch(toggleODNotes(true));
}

const setNotesToOmdGpDefault = () => (dispatch: AppDispatch, getState: () => RootState) => {
    // if Follow Up with OD is selected, add its text to omd_gp
    const { examData, options } = getState() as {examData: IExamData, options: IOptions};
    const TELE_FU_ID = 15;
    const AGREE_ID = 4;
    const ALERT_ID = 26;
    let initial_omd_gp_text = examData.omd_gp.trim();
    if (examData.selected_omd_note_options && examData.selected_omd_note_options.includes(TELE_FU_ID)){
        const teleFuOption = options && options.omdrFuOptions && options.omdrFuOptions.find(option => option.id === TELE_FU_ID);
        const TELE_FU_TEXT = teleFuOption ? teleFuOption.note_to_od : '';
        // if the Tele F/U text is already in the omd_gp, do nothing, if not, add the Tele F/U text
        if(examData && (typeof examData.omd_gp === 'string') && !examData.omd_gp.includes(TELE_FU_TEXT)){
            initial_omd_gp_text += TELE_FU_TEXT.trim();
        }
    }
    if (examData.selected_omd_note_options && examData.selected_omd_note_options.includes(AGREE_ID)){
        const agreeOption = options && options.omdNoteOptions && options.omdNoteOptions.find(option => option.id === AGREE_ID);
        const AGREE_TEXT = agreeOption ? agreeOption.note_to_od : '';
        // if the Agree text is already in the omd_gp, do nothing, if not, add the Agree text
        if(examData && (typeof examData.omd_gp === 'string') && !examData.omd_gp.includes(AGREE_TEXT)){
            initial_omd_gp_text += AGREE_TEXT.trim();
        }
    }

    if (examData.selected_omd_note_options && examData.selected_omd_note_options.includes(ALERT_ID)){
        const alertOption = options && options.omdNoteOptions && options.omdNoteOptions.find(option => option.id === ALERT_ID);
        const ALERT_TEXT = alertOption ? alertOption.note_to_od : '';
        // if the Alert text is already in the omd_gp, do nothing, if not, add the Alert text
        if(examData && (typeof examData.omd_gp === 'string') && !examData.omd_gp.includes(ALERT_TEXT)){
            initial_omd_gp_text = ALERT_TEXT.trim() + initial_omd_gp_text
        }
    }
    dispatch(setExamDataValue('omd_gp', initial_omd_gp_text));
}

export const goToExam = (examId:number, navigate: NavigateFunction) => {
    navigate(`${PATIENT_EXAM_URL}/${examId}`);
}

export const goToExamInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
}


export const goToExamInNewTabWithTarget = (url: string, target: string) => {
    window.open(url, target)?.focus();
}

export const editAndGoToExamInInternalTab = (examId: string, navigate: NavigateFunction) =>
    async (dispatch: AppDispatch) => {
        dispatch(editExamRequest(Number(examId), navigate));
        dispatch(setPatientExamRoomsData({ currentExamRoom: examId }))
        goToExam(Number(examId), navigate);
}

export const shootsIntoMOA = (notesToMoaCache: string, omdrSelectionChanged: boolean, omdcSelectionChanged: boolean) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
        const omdRequestOpinion = " requests a 2nd opinion with Dr. ";

        const state = getState();
        const user = state.user;
        const omds = state.options.omds;

        const {second_omdr, omdc} = state.examData;

        let currentOmdr = '';

        if (user.isOMDR) {
            currentOmdr = user.displayName;
        }

        const secondOmdr = getOmdName(omds, second_omdr);
        const secondOmdc = getOmdName(omds, omdc );

        const secondOmdrText = omdrSelectionChanged ? currentOmdr + omdRequestOpinion + secondOmdr + "." : "";
        const secondOmdcText = omdcSelectionChanged ? currentOmdr + omdRequestOpinion + secondOmdc + "." : "";

        const moaString = notesToMoaCache + " " + secondOmdrText + secondOmdcText ;
        dispatch(setExamDataValue(NOTES_TO_MOA_FIELD, moaString.trim()));
}

export const shootsIntoMoaOmdc = (notesToMoaCache: string, omdrSelectionChanged: boolean, secondOpinionTeleconsultName: string) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
        const omdRequestOpinion = " requests a 2nd opinion with Dr. ";

        const {user} = getState();

        let currentOmd = '';

        if (user.isOMDC) {
            currentOmd = user.displayName;
        }

        const secondOmdrText = omdrSelectionChanged ? currentOmd + omdRequestOpinion + secondOpinionTeleconsultName + "." : "";
       
        const moaString = notesToMoaCache + " " + secondOmdrText;
        dispatch(setExamDataValue(NOTES_TO_MOA_FIELD, moaString.trim()));
}

export const resetTxalgo3Selection = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {examData: {id, od_vf_override, os_vf_override, right_cd, left_cd,
        od_iop_aim, os_iop_aim}} = getState() as {examData: IExamData};

    // load data from local storage
    const txalgo3Data: ITxalgo3LocalStorageData[] = JSON.parse(localStorage.getItem('txalgo3') ?? '[]') || [];
    // if the data is in the local storage, read values from local storage and set vf_override, cd, iop_aim values
    const txalgo3 = txalgo3Data.find(item => item.id===id);
    if (txalgo3) {
        const {id, ...txalgo3Value} = txalgo3;
        dispatch(setExamDataValues(txalgo3Value));
        dispatch(refreshTxAlgo3Request({exam_id: txalgo3.id, ...txalgo3Value}));
    } else {
        // if the data is not in local storage, that means this load is first load, store vf_override, cd
        // iop_aim values in local storage
        const txalgo3Obj = { id: id!, od_vf_override, os_vf_override, right_cd, left_cd, od_iop_aim,
            os_iop_aim,}
        txalgo3Data.push(txalgo3Obj);
        localStorage.setItem('txalgo3', JSON.stringify(txalgo3Data));
    }
}

export const getExamDataRequest = createAsyncThunk(
    'examData/getExamData',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/24`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const retrievePhotoUrls = createAsyncThunk(
    'examData/retrievePhotoUrls',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/exam_images/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const retrieveModifiedRLUPdfs = createAsyncThunk(
    'examData/retrieveModifiedRLUPdfs',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/rlu_pdfs/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const retrieveModifiedRLUPdfsForPreviousExam = createAsyncThunk(
    'examData/retrieveModifiedRLUPdfsForPreviousExam',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/rlu_pdfs/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const saveExamDataRequest = createAsyncThunk(
    'examData/saveExamDataRequest',
    async (examSubmitData: IExamSubmitData, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        type FormDataArray = [ keyof IExamSubmitData,  string ];

        const formData = new FormData();
        const entries = Object.entries(examSubmitData) as FormDataArray[];
        entries.forEach(([key, value]) => {
            formData.append(key, value);
        });

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/save/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getNewPatientExam = (patientId:number, examDate:string, navigate: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        let examId: number | null = null;
        try{
            const resp = await dispatch(examApi.endpoints.patientHasExam.initiate({patientId, examDate})).unwrap();
            // Check if patient has an existing visit for the current date
            if (resp.result && resp.result === 'false') {
                dispatch(closePatientDetailsModal());
                const response = await dispatch(examApi.endpoints.addNewExam.initiate({patientId, examDate})).unwrap();
                // get the new examId returned from getNewExamRequest
                examId = response.exam!;
                // Get the newly created exam
                // return dispatch(editExamRequest(response.exam, navigate));
                // Use newly created patients' newly created exam
                dispatch(resetNewExamDefaults());
                dispatch(clearPatientExamRoomsData());
                // navigate to the newly created exam
                goToExam(examId, navigate);
            } else if (resp.error) {
                Modal.error({
                    title: ERROR_MESSAGE_TITLE,
                    content: resp.error,
                });
            }
        } catch(error) {
            Modal.error({
                title: ERROR_MESSAGE_TITLE,
                content: PATIENT_HAS_EXAM_ERROR_TEXT,
            });
        }
}

export const getNewODMessagingExam = (patientId:number, examDate:string, odMessage:string) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const {examData: {id}} = getState() as {examData: IExamData};
        let newExamId: number | null = null;
        try{
            const resp = await dispatch(examApi.endpoints.patientHasExam.initiate({patientId, examDate})).unwrap();
            // Check if patient has an existing visit for the current date
            if (resp.result && resp.result === 'false') {
                const response = await dispatch(examApi.endpoints.addNewExam.initiate({patientId, examDate})).unwrap();
                // get the new examId returned from getNewExamRequest
                newExamId = response.exam!;

                // Set exam with OD Messaging values.
                if (newExamId) {
                    const response = await dispatch(setODMessagingExamValuesRequest({examId:newExamId, odMessage})).unwrap();
                    if (response.success) {
                        // Reload the current exam to get the latest data.
                        if (id !== null) {
                            await dispatch(editExamRequest(Number(id), ()=>{}));
                        }
                    }
                }
            } else if (resp.error) {
                Modal.error({
                    title: ERROR_MESSAGE_TITLE,
                    content: resp.error,
                });
            }
        } catch(error) {
            Modal.error({
                title: ERROR_MESSAGE_TITLE,
                content: PATIENT_HAS_EXAM_ERROR_TEXT,
            });
        }
}

export const setODMessagingExamValuesRequest = createAsyncThunk(
    'examData/setODMessagingExamValuesRequest',
    async ({examId, odMessage} : {examId: number, odMessage: string}, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const formData = new FormData();
        formData.append('exam_id', examId.toString());
        formData.append('od_omd', odMessage);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/set_new_od_messaging_exam_values/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const setExamDataValue = (key: keyof IExamData, value: ValueOfExamData ) =>
    (dispatch: AppDispatch) =>{
    dispatch(setSliceExamDataValue({key, value}));
}

export const lengthEqual = <T,>(previous: T[], current: T[]): boolean => (previous.length === current.length);

export const examSlice = createSlice({
    name: 'examSlice',
    initialState,
    reducers: {
        clearExamData: () => initialState,
        setExamDirty: (state, action: PayloadAction<boolean>) => {
            state.dirty = action.payload;
        },
        setSliceExamDataValue: (state, action: PayloadAction<{key: keyof IExamData, value: ValueOfExamData}>) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                dirty: true,
            }
        },
        setExamDataValues: (state, action: PayloadAction<Partial<IExamData>>) => {
            return {
                ...state,
                ...action.payload,
                dirty: true
            }
        },
        setExamReadOnly: (state, action: PayloadAction<boolean>) => {
            state.readOnly = action.payload;
        },
        setPastOMDField: (state, action: PayloadAction<{show: boolean; values: IOmdHistoryItem[]}>) => {
            if (state.omd_history.values && state.omd_history.values.length && Object.prototype.hasOwnProperty.call(state.omd_history.values[0], 'history_omd_name') && state.omd_history.values[0].history_omd_name) {
                state.past_omd = true;
            } else {
                state.past_omd = false;
            }
        },
        setPreReviewData: (state, action: PayloadAction<void>) => {
            const PRE_REVIEW_VALUE_BOOLEAN_LIST = ['od_question', 'od_req_omd', 'billing_addressed',
                 'od_start_drops', 'cu_omd', 'past_omd', 'od_baseline','fhx', 'dhx_od', 'dhx_os',
                 'pxf_od','pxf_os', 'pds_od', 'pds_os' ] as const;
            const preReviewerValue: {[key: number | string]: boolean} = {};

            PRE_REVIEW_VALUE_ID_LIST.forEach(id => {
                preReviewerValue[id] = state.selected_omd_note_options.includes(id);
            });
            PRE_REVIEW_VALUE_BOOLEAN_LIST.forEach(value => {
                preReviewerValue[value] = state[value];
            });

            state.pre_reviewer_values = JSON.stringify(preReviewerValue);
        },
        setODStartDropsField: (state, action: PayloadAction<void>) => {
            // If we have any rows that contain drops, set the OD started drops flag.
            const diffOngoingDrops = isDiffOngoingDrops(state.ongoing);
            if (diffOngoingDrops){
                state.od_start_drops = true;
            } else {
                state.od_start_drops = false;
            }
        },
        toggleUntilYesterdayEntries: (state, action: PayloadAction<void>) => {
            state.until_yesterday.show = !state.until_yesterday.show;
        },
        addUntilYesterdayEntry: (state, action: PayloadAction<void>) => {
            state.until_yesterday.values = state.until_yesterday.values.filter(entry => !isUntilYesterdayEntryEmpty(entry));
            if (state.is_referral_letter_upload_pei) {
                state.until_yesterday.values.push({
                    glc_past_drops_eye_select: '',
                    glc_past_drops_select: '',
                })
            } else {
                state.until_yesterday.values.push({
                    glc_past_drops_eye_select: '',
                    glc_past_drops_select: '',
                    glc_past_drops_compliance_select: '',
                })
            }
        },
        editUntilYesterdayEntry: (state, action: PayloadAction<{editIndex: number, field: keyof IUntilYesterdayItem, value: string}>) => {
            const {editIndex, field, value} = action.payload;
            if (editIndex >=0 && field) {
                state.until_yesterday.values = state.until_yesterday.values.map(
                    (untilYesterdayItem, index) =>
                        index === editIndex ? {
                            ...untilYesterdayItem,
                            [field]: value
                        } : untilYesterdayItem
                )
            }
        },
        deleteUntilYesterdayEntry: (state, action: PayloadAction<number>) => {
            state.until_yesterday.values = state.until_yesterday.values.filter(
                (_, index) => index !== action.payload)
        },
        setUntilYesterdayNoGlc: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.until_yesterday.values = [{ disabled: true }];
            } else if (state.until_yesterday.values.length && state.until_yesterday.values[0].disabled) {
                state.until_yesterday.values = [{
                    glc_past_drops_eye_select: '',
                    glc_past_drops_select: '',
                    glc_past_drops_compliance_select: '',
                }]
            }
            state.until_yesterday.show = false;
        },
        toggleOngoingEntries: (state, action: PayloadAction<void>) => {
            state.ongoing.show = !state.ongoing.show;
        },
        addOngoingEntry: (state, action: PayloadAction<void>) => {
            state.ongoing.values = state.ongoing.values.filter(entry => !isOngoingEntryEmpty(entry));
            state.ongoing.values.push({
                glc_current_drops_eye_select: '',
                glc_current_drops_select: '',
            })
        },
        editOngoingEntry: (state, action: PayloadAction<{editIndex: number, field: keyof IOngoingItem, value: string}>) => {
            const {editIndex, field, value} = action.payload;
            if (editIndex >=0 && field) {
                state.ongoing.values = state.ongoing.values.map(
                    (ongoingItem, index) =>
                        index === editIndex ? {
                            ...ongoingItem,
                            [field]: value
                        } : ongoingItem
                )
            }
        },
        deleteOngoingEntry: (state, action: PayloadAction<number>) => {
            state.ongoing.values = state.ongoing.values.filter(
                (_, index) => index !== action.payload)
        },
        setOngoingSameMeds: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.ongoing.values = [{ disabled: true }];
            } else if (state.ongoing.values.length && state.ongoing.values[0].disabled) {
                state.ongoing.values = [{
                    glc_current_drops_eye_select: '',
                    glc_current_drops_select: '',
                }]
            }
            state.ongoing.show = false;
        },
        toggleOMDHistoryEntries: (state, action: PayloadAction<void>) => {
            state.omd_history.show = !state.omd_history.show;
        },
        addOMDHistoryEntry: (state, action: PayloadAction<void>) => {
            state.omd_history.values = state.omd_history.values.filter(entry => !isOmdHistoryEntryEmpty(entry));
            state.omd_history.values.push({
                history_omd_name: '',
                history_diagnosis: '',
                eye_select: '',
                history_date: '',
                history_end_date: '',
            })
        },
        editOMDHistoryEntry: (state, action: PayloadAction<{editIndex: number, field: keyof IOmdHistoryItem, value: string}>) => {
            const {editIndex, field, value} = action.payload;
            if (editIndex >=0 && field) {
                state.omd_history.values = state.omd_history.values.map(
                    (omdHistoryItem, index) =>
                        index === editIndex ? {
                            ...omdHistoryItem,
                            [field]: value
                        } : omdHistoryItem
                )
            }
        },
        deleteOMDHistoryEntry: (state, action: PayloadAction<number>) => {
            state.omd_history.values = state.omd_history.values.filter(
                (_, index) => index !== action.payload)
        },
        setOMDHistoryNone: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.omd_history.values = [{ disabled: true }];
            } else if (state.omd_history.values.length && state.omd_history.values[0].disabled) {
                state.omd_history.values = [{
                    history_omd_name: '',
                    history_diagnosis: '',
                    eye_select: '',
                    history_date: '',
                    history_end_date: '',
                }]
            }
            state.omd_history.show = false;
        },
        toggleIOPHistoryEntries: (state, action: PayloadAction<void>) => {
            state.iop_history.show = !state.iop_history.show;
        },
        addIOPHistoryEntry: (state, action: PayloadAction<void>) => {
            state.iop_history.values = state.iop_history.values.filter(entry => !isIopHistoryEntryEmpty(entry));
            if (state.is_referral_letter_upload_pei) {
                // procedure and machine select is deleted from RLU PEI
                state.iop_history.values.push({
                    eye_select: '',
                    low_iop: '',
                    high_iop: '',
                    iop_history_drops_select: [],
                })
            } else {
                state.iop_history.values.push({
                    eye_select: '',
                    low_iop: '',
                    high_iop: '',
                    iop_history_drops_select: [],
                    iop_history_procedure_prefix: '',
                    iop_history_procedure_select: [],
                    machine_select: '',
                })
            }
        },
        editIOPHistoryEntry: (state, action: PayloadAction<{editIndex: number, field: keyof IIopHistoryItem, value: string | number | string[]}>) => {
            const {editIndex, field, value} = action.payload;
            if (editIndex >=0 && field) {
                state.iop_history.values = state.iop_history.values.map(
                    (iopHistoryItem, index) =>
                        index === editIndex ? {
                            ...iopHistoryItem,
                            [field]: value
                        } : iopHistoryItem
                )
            }
        },
        deleteIOPHistoryEntry: (state, action: PayloadAction<number>) => {
            state.iop_history.values = state.iop_history.values.filter(
                (_, index) => index !== action.payload)
        },
        setIOPHistoryNone: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.iop_history.values = [{ disabled: true }];
            } else if (state.iop_history.values.length && state.iop_history.values[0].disabled) {
                state.iop_history.values = [{
                    eye_select: '',
                    low_iop: '',
                    high_iop: '',
                    iop_history_drops_select: [],
                    iop_history_procedure_prefix: '',
                    iop_history_procedure_select: [],
                    machine_select: '',
                }]
            }
            state.iop_history.show = false;
        },
        setExamHistoryDefault: (state, action: PayloadAction<string>) => {
            state.pmh_m = action.payload === EXAM_HISTORY_DEFAULT_TEXT ? '' : action.payload;
        },
        setFuOptionsDefault: (state, action: PayloadAction<number[]>) => {
            const fuOptionsArray = action.payload;
            const TeleFuId = 7;
            const OmdFuId = 19;
            // Tele F/U should be selected by default
            if (!fuOptionsArray.includes(TeleFuId) && !fuOptionsArray.includes(OmdFuId)) {
               state.omdc_note_templates = [...action.payload, TeleFuId];
            }
        },
        resetNewExamDefaults: (state, action: PayloadAction<void>) => {
            return {
                ...state,
                e_notes: '',
                pmh_m: '',
                od_bcva: 'ND',
                os_bcva: 'ND',
            }
        },
        setGPReferralAndUrl: (state, action: PayloadAction<string>) => {
            if (action.payload === 'false') {
                state.referral_letter = '';
            }
            state.gp_refed = action.payload === 'true';
        },
        setGPReferralUrl: (state, action: PayloadAction<string>) => {
            state.referral_letter = action.payload;
        },
        setGPReferralProgress: (state, action: PayloadAction<string>) => {
            state.gpReferralUploadLabel = action.payload;
        },
        setDtcValues: (state, action: PayloadAction<{od?: number | null, os?: number | null}[]>) => {
            state.dtc_values = action.payload;
        },
        setPEISpinner: (state, action: PayloadAction<boolean>) => {
            state.operating = action.payload;
        },
        setSelectedOmdNoteOptions: (state, action: PayloadAction<number[]>) => {
            state.selected_omd_note_options = action.payload;
        },
        setGPReferral: (state, action: PayloadAction<string>) => {
            state.gp_refed = action.payload === 'true';
        },
        resetOdSelection: (state) => {
            // Once an exam is submitted, the selections that user made in a different UI should not persist. 
            state.od_wants_omd_report = true;
            state.is_outbound_referral = false;
            state.cat_refer = null;
            state.referral_omd = null;
            state.gp_refed = false;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getExamDataRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getExamDataRequest.fulfilled, (state, action: PayloadAction<IExamDataResponse>) => {
            convertBackendExamData(state, action.payload);
        });
        builder.addCase(getExamDataRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting options data. ${action.payload}`,
            })
        });
        builder.addCase(retrievePhotoUrls.pending, (state) => {
            state.operating = false;
        });
        builder.addCase(retrievePhotoUrls.fulfilled, (state, action: PayloadAction<IExamDataResponse>) => {
            const data = action.payload;
            const mediaUrl = getBackendMediaUrl();

            state.photoUrls.right_fundus_photo = data.right_fundus_photo ? mediaUrl + data.right_fundus_photo : '';
            state.photoUrls.right_stereo_photo = data.right_stereo_photo ? mediaUrl + data.right_stereo_photo : '';
            state.photoUrls.right_oct_photo = data.right_oct_photo ? mediaUrl + data.right_oct_photo : '';
            state.photoUrls.right_oct_rnfl_photo = data.right_oct_rnfl_photo ? mediaUrl + data.right_oct_rnfl_photo : '';
            state.photoUrls.right_vf_photo = data.right_vf_photo ? mediaUrl + data.right_vf_photo : '';
            state.photoUrls.right_vf_10_2_photo = data.right_vf_10_2_photo ? mediaUrl + data.right_vf_10_2_photo : '';
            state.photoUrls.right_oct_angle_photo = data.right_oct_angle_photo ? mediaUrl + data.right_oct_angle_photo : '';
            state.photoUrls.right_gcc_photo = data.right_gcc_photo ? mediaUrl + data.right_gcc_photo : '';

            state.photoUrls.left_fundus_photo = data.left_fundus_photo ? mediaUrl + data.left_fundus_photo : '';
            state.photoUrls.left_stereo_photo = data.left_stereo_photo ? mediaUrl + data.left_stereo_photo : '';
            state.photoUrls.left_oct_photo = data.left_oct_photo ? mediaUrl + data.left_oct_photo : '';
            state.photoUrls.left_oct_rnfl_photo = data.left_oct_rnfl_photo ? mediaUrl + data.left_oct_rnfl_photo : '';
            state.photoUrls.left_vf_photo = data.left_vf_photo ? mediaUrl + data.left_vf_photo : '';
            state.photoUrls.left_vf_10_2_photo = data.left_vf_10_2_photo ? mediaUrl + data.left_vf_10_2_photo : '';
            state.photoUrls.left_oct_angle_photo = data.left_oct_angle_photo ? mediaUrl + data.left_oct_angle_photo : '';
            state.photoUrls.left_gcc_photo = data.left_gcc_photo ? mediaUrl + data.left_gcc_photo : '';

            state.photoUrls.right_ml_large_heme_pigment_overlay_photo = data.right_ml_large_heme_pigment_overlay_photo ? mediaUrl + data.right_ml_large_heme_pigment_overlay_photo : '';
            state.photoUrls.left_ml_large_heme_pigment_overlay_photo = data.left_ml_large_heme_pigment_overlay_photo ? mediaUrl + data.left_ml_large_heme_pigment_overlay_photo : '';

            state.photoUrls.right_ml_micro_aneurysms_overlay_photo = data.right_ml_micro_aneurysms_overlay_photo ? mediaUrl + data.right_ml_micro_aneurysms_overlay_photo : '';
            state.photoUrls.left_ml_micro_aneurysms_overlay_photo = data.left_ml_micro_aneurysms_overlay_photo ? mediaUrl + data.left_ml_micro_aneurysms_overlay_photo : '';

            state.photoUrls.right_ml_hard_exudates_overlay_photo = data.right_ml_hard_exudates_overlay_photo ? mediaUrl + data.right_ml_hard_exudates_overlay_photo : '';
            state.photoUrls.left_ml_hard_exudates_overlay_photo = data.left_ml_hard_exudates_overlay_photo ? mediaUrl + data.left_ml_hard_exudates_overlay_photo : '';

            state.photoUrls.right_ml_disc_hemorrhage_overlay_photo = data.right_ml_disc_hemorrhage_overlay_photo ? mediaUrl + data.right_ml_disc_hemorrhage_overlay_photo : '';
            state.photoUrls.left_ml_disc_hemorrhage_overlay_photo = data.left_ml_disc_hemorrhage_overlay_photo ? mediaUrl + data.left_ml_disc_hemorrhage_overlay_photo : '';

            state.extra_images = data.extra_images;

            state.od_referral_letter = data.od_referral_letter ? mediaUrl + data.od_referral_letter : '';
            state.od_referral_letter_pdf = data.od_referral_letter_pdf ? mediaUrl + data.od_referral_letter_pdf : '';

            state.highlight_omd_pdf = data.highlight_omd_pdf ? mediaUrl + data.highlight_omd_pdf : '';
            state.recipient_removed_pdf = data.recipient_removed_pdf ? mediaUrl + data.recipient_removed_pdf : '';
        });
        builder.addCase(retrievePhotoUrls.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting options data. ${action.payload}`,
            })
        });
        builder.addCase(retrieveModifiedRLUPdfs.pending, (state) => {
            state.operating = false;
        });
        builder.addCase(retrieveModifiedRLUPdfs.fulfilled, (state, action: PayloadAction<IExamDataResponse>) => {
            const data = action.payload;
            const mediaUrl = getBackendMediaUrl();
            state.highlight_omd_pdf = data.highlight_omd_pdf ? mediaUrl + data.highlight_omd_pdf : '';
            state.recipient_removed_pdf = data.recipient_removed_pdf ? mediaUrl + data.recipient_removed_pdf : '';
        });
        builder.addCase(retrieveModifiedRLUPdfs.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting options data. ${action.payload}`,
            })
        });
        builder.addCase(retrieveModifiedRLUPdfsForPreviousExam.pending, (state) => {
            state.operating = false;
        });
        builder.addCase(retrieveModifiedRLUPdfsForPreviousExam.fulfilled, (state, action: PayloadAction<IExamDataResponse>) => {
            const data = action.payload;
            const mediaUrl = getBackendMediaUrl();
            state.past_highlight_omd_pdf = data.highlight_omd_pdf ? mediaUrl + data.highlight_omd_pdf : state.past_highlight_omd_pdf;
            state.past_recipient_removed_pdf = data.recipient_removed_pdf ? mediaUrl + data.recipient_removed_pdf : state.past_recipient_removed_pdf;
        });
        builder.addCase(retrieveModifiedRLUPdfsForPreviousExam.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting modified RLU PDFs. ${action.payload}`,
            })
        });
        builder.addCase(saveExamDataRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(saveExamDataRequest.fulfilled, (state, action: PayloadAction<IExamDataResponse>) => {
            state.operating = false
            state.lastOperationType = action.type;
        });
        builder.addCase(saveExamDataRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting options data. ${action.payload}`,
            })
        });
        builder.addCase(setODMessagingExamValuesRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(setODMessagingExamValuesRequest.fulfilled, (state, action) => {
            state.operating = false
        });
        builder.addCase(setODMessagingExamValuesRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors setting exam values. ${action.payload}`,
            })
        });
        // also update the referral omd to the default OMD
        builder.addMatcher(doctorApi.endpoints.getDefaultOmd.matchFulfilled, (state, action) => {
            if (action.payload) {
                state.referral_omd = action.payload;
            }
           
        });
    },
});

export const { setSliceExamDataValue, setPastOMDField, setODStartDropsField, setPreReviewData, toggleUntilYesterdayEntries, deleteUntilYesterdayEntry, addUntilYesterdayEntry, setUntilYesterdayNoGlc, editUntilYesterdayEntry, toggleOngoingEntries, addOngoingEntry, editOngoingEntry, deleteOngoingEntry, setOngoingSameMeds, toggleOMDHistoryEntries, addOMDHistoryEntry, editOMDHistoryEntry, deleteOMDHistoryEntry, setOMDHistoryNone, toggleIOPHistoryEntries, addIOPHistoryEntry, editIOPHistoryEntry, deleteIOPHistoryEntry, setIOPHistoryNone, clearExamData, setExamHistoryDefault, setFuOptionsDefault, resetNewExamDefaults, setExamDataValues, setExamReadOnly, setExamDirty, setSelectedOmdNoteOptions, setPEISpinner, setGPReferralProgress, setGPReferralUrl, setGPReferralAndUrl, setDtcValues, setGPReferral, resetOdSelection } = examSlice.actions;

export default examSlice.reducer;
