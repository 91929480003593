import { Key, useEffect, useState } from 'react';
import { Table, Select, Button, Tooltip, Row, Col } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { localizedText } from '../../../localizedText';
import { compareStrings } from '../../../helpers/sorting';
import { IPatientManagementGpPatientTableItem } from '../../../services/patient-management-api';
import * as Constants from '../../../constants';
import { goToExamInNewTab } from '../../../reducers/patient-exam-slice';

import '../../../../static/css/components/patient-matching-list-table.scss';
import { RowSelectMethod, SorterResult } from 'antd/es/table/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

type ComponentProps = {
    tableList: IPatientManagementGpPatientTableItem[],
    selectedIds: Set<number>,
    setSelectedIds: (s: Set<number>) => void,
    selectedExamIds: Set<string>,
    setSelectedExamIds: (s: Set<string>) => void,
    setSelectedTableItems: (s: Set<IPatientManagementGpPatientTableItem>) => void,
    refreshTable: () => void,
}

const GpPatientListTable = ({ tableList, selectedIds, setSelectedIds, selectedExamIds, setSelectedExamIds, setSelectedTableItems, refreshTable }: ComponentProps) => {
    const { OF_TEXT, ITEMS_TEXT, ADMIN_REFRESH_TABLES, ADMIN_COLUMN_SORT_TOOLTIP, OPEN_SELECTED_PATIENTS, ADMIN_RESET_SORT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const BASE_PRIORITY_INDEX = 100;
    const [priorityIndex, setPriorityIndex] = useState(BASE_PRIORITY_INDEX);
    const [priorityODName, setPriorityODName] = useState(BASE_PRIORITY_INDEX);
    const [priorityODStatus, setPriorityODStatus] = useState(BASE_PRIORITY_INDEX);
    const [priorityPatientFirstname, setPriorityPatientFirstname] = useState(BASE_PRIORITY_INDEX);
    const [priorityPatientLastname, setPriorityPatientLastname] = useState(BASE_PRIORITY_INDEX);
    const [priorityBirthday, setPriorityBirthday] = useState(BASE_PRIORITY_INDEX);
    const [priorityGpName, setPriorityGpName] = useState(BASE_PRIORITY_INDEX);
    const [priorityGpFax, setPriorityGpFax] = useState(BASE_PRIORITY_INDEX);
    const [priorityOdGrouppractice, setPriorityOdGrouppractice] = useState(BASE_PRIORITY_INDEX);
    const [priorityOdProvince, setPriorityOdProvince] = useState(BASE_PRIORITY_INDEX);


    // Ant Design columns have a sortOrder, which is the direction of the sort: ascend, descend, null (cancelled)
    // This is local state to keep the column sortOrder data so that it can be cancelled when the reset button is clicked.
    const [sortOrderInfo, setSortOrderInfo] = useState<SorterResult<IPatientManagementGpPatientTableItem> | SorterResult<IPatientManagementGpPatientTableItem>[]>()

    const getSelectedRowKeys = () => {
        return Array.from(selectedIds) as Key[];
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // Format the Open Selected Patients tooltip text
    const getOpenSelectedTooltipText = () => {
        return (
            <div className='admin-load-patients-tooltip'>
                In order to open the exam(s) in a separate tab and remain on the current page, please install extension
                <a
                    href='https://chrome.google.com/webstore/detail/force-background-tab/gidlfommnbibbmegmgajdbikelkdcmcl'
                    target="_blank"
                    rel="noreferrer"
                    onClick={(event) => event.stopPropagation()}
                >
                    Force Background Tab
                </a>
            </div>
        )
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const handleRefreshTables = () => {
        refreshTable();
    }

    // Open selected exams in new tabs and stay on the patient list page.
    // This is achieved by using the Force Background Chrome extension
    const handleOpenSelectedPatients = () => {
        if (selectedExamIds && selectedExamIds.size) {
            for (const value of selectedExamIds) {
                const examUrl = `${Constants.PATIENT_EXAM_URL}/${value}`;
                goToExamInNewTab(examUrl);
            }
        }
    }

    const onChange = (selectedRowKeys: Key[], selectedRows: IPatientManagementGpPatientTableItem[], info: {
        type: RowSelectMethod;
    }) => {
        const idSet = new Set(selectedRows.map(obj => obj.id));
        setSelectedIds(new Set(idSet));

        const examIdSet = new Set(selectedRows.map(obj => obj.latest_exam_id));
        setSelectedExamIds(new Set(examIdSet));

        setSelectedTableItems(new Set(selectedRows));
    }

    // Update the column sortOrder local state object
    const handleTableChange = (sorter: SorterResult<IPatientManagementGpPatientTableItem> | SorterResult<IPatientManagementGpPatientTableItem>[]) => {
        setSortOrderInfo(sorter);
    }

    // Each column needs to get the current state of its sortOrder
    const getSortOrder = (key: string) => {
        // If only one column is sorted, sortOrderInfo is an object.
        // If two or more columns are sorted, sortOrderInfo is an array.
        if (Array.isArray(sortOrderInfo)) {
            const sortOrderItem = sortOrderInfo.length > 0 && sortOrderInfo.find(so => so.columnKey === key);
            return sortOrderItem ? sortOrderItem.order : null;

        } else {
            return sortOrderInfo?.columnKey === key ? sortOrderInfo.order : null
        }
    }

    // Reset all priority indexes to the base number.
    const handleClearPriorityIndexes = () => {
        setSortOrderInfo([]);
        setPriorityIndex(BASE_PRIORITY_INDEX);
        setPriorityODName(BASE_PRIORITY_INDEX);
        setPriorityODStatus(BASE_PRIORITY_INDEX);
        setPriorityPatientFirstname(BASE_PRIORITY_INDEX);
        setPriorityPatientLastname(BASE_PRIORITY_INDEX);
        setPriorityBirthday(BASE_PRIORITY_INDEX);
        setPriorityGpName(BASE_PRIORITY_INDEX);
        setPriorityGpFax(BASE_PRIORITY_INDEX);
        setPriorityOdGrouppractice(BASE_PRIORITY_INDEX);
        setPriorityOdProvince(BASE_PRIORITY_INDEX);
    }

    // Ant Design assigns priority to the column with the higher 'mulitple' index number.
    // When a column is first clicked, it should be assigned the next decremented priority index
    const setColumnSortPriority = (dataIndex: string, sorterMultiple: number) => {

        // If the column's priority index is not the base index value that means it has already been indexed.
        // As such, the function expression should be exited since the column's index should remain unchanged.
        if (sorterMultiple !== BASE_PRIORITY_INDEX) {
            return;
        }

        // Decrement the priority index base number.
        // The asynchronous setting of the useState variable will be completed by the time the user selects another column.
        setPriorityIndex(priorityIndex - 1);

        // Set the selected column with the decremented priority index value.
        switch (dataIndex) {
            case 'od_name':
                setPriorityODName(priorityIndex - 1);
                break;
            case 'od_status':
                setPriorityODStatus(priorityIndex - 1);
                break;
            case 'patient_first_name':
                setPriorityPatientFirstname(priorityIndex - 1);
                break;
            case 'patient_last_name':
                setPriorityPatientLastname(priorityIndex - 1);
                break;
            case 'birthday':
                setPriorityBirthday(priorityIndex - 1);
                break;
            case 'gp_name':
                setPriorityGpName(priorityIndex - 1);
                break;
            case 'gp_fax':
                setPriorityGpFax(priorityIndex - 1);
                break;
            case 'od_grouppractice':
                setPriorityOdGrouppractice(priorityIndex - 1);
                break;
            case 'od_province':
                setPriorityOdProvince(priorityIndex - 1);
                break;
            default:
                break;
        }
    }

    const nameColumns: ColumnsType<IPatientManagementGpPatientTableItem> = [
        {
            className: 'od_name-column',
            title: 'OD Name',
            dataIndex: 'od_name',
            key: 'od_name',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('od_name', priorityODName)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.od_name}`, `${b.od_name}`),
                multiple: priorityODName,
            },
            sortOrder: getSortOrder('od_name'),
            render: (text, record) => <div data-testid={`'od_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'od_status-column',
            title: 'OD Status',
            dataIndex: 'od_status',
            key: 'od_status',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('od_status', priorityODStatus)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.od_status}`, `${b.od_status}`),
                multiple: priorityODStatus,
            },
            sortOrder: getSortOrder('od_status'),
            render: (text, record) => <div data-testid={`'od_status'-${record.key}`}>{text}</div>
        },
        {
            className: 'patient_first_name-column',
            title: 'Patient firstname',
            dataIndex: 'patient_first_name',
            key: 'patient_first_name',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('patient_first_name', priorityPatientFirstname)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.patient_first_name}`, `${b.patient_first_name}`),
                multiple: priorityPatientFirstname,
            },
            sortOrder: getSortOrder('patient_first_name'),
            render: (text, record) => <div data-testid={`'patient_first_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'patient_last_name-column',
            title: 'Patient lastname',
            dataIndex: 'patient_last_name',
            key: 'patient_last_name',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('patient_last_name', priorityPatientLastname)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.patient_last_name}`, `${b.patient_last_name}`),
                multiple: priorityPatientLastname,
            },
            sortOrder: getSortOrder('patient_last_name'),
            render: (text, record) => <div data-testid={`'patient_last_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'birthday-column',
            title: 'Birthday',
            dataIndex: 'birthday',
            key: 'birthday',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('birthday', priorityBirthday)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.birthday}`, `${b.birthday}`),
                multiple: priorityBirthday,
            },
            sortOrder: getSortOrder('birthday'),
            render: (text, record) => <div data-testid={`'birthday'-${record.key}`}>{text}</div>
        },
        {
            className: 'gp_name-column',
            title: 'Gp Name',
            dataIndex: 'gp_name',
            key: 'gp_name',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('gp_name', priorityGpName)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.gp_name}`, `${b.gp_name}`),
                multiple: priorityGpName,
            },
            sortOrder: getSortOrder('gp_name'),
            render: (text, record) => <div data-testid={`'gp_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'gp_fax-column',
            title: 'Gp Fax',
            dataIndex: 'gp_fax',
            key: 'gp_fax',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('gp_fax', priorityGpFax)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.gp_fax}`, `${b.gp_fax}`),
                multiple: priorityGpFax,
            },
            sortOrder: getSortOrder('gp_fax'),
            render: (text, record) => <div data-testid={`'gp_fax'-${record.key}`}>{text}</div>
        },
        {
            className: 'od_grouppractice-column',
            title: 'Od Group practice',
            dataIndex: 'od_grouppractice',
            key: 'od_grouppractice',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('od_grouppractice', priorityOdGrouppractice)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.od_grouppractice}`, `${b.od_grouppractice}`),
                multiple: priorityOdGrouppractice,
            },
            sortOrder: getSortOrder('od_grouppractice'),
            render: (text, record) => <div data-testid={`'od_grouppractice'-${record.key}`}>{text}</div>
        },
        {
            className: 'od_province-column',
            title: 'Od Province',
            dataIndex: 'od_province',
            key: 'od_province',
            onHeaderCell: () => {
                return {
                    onClick: () => setColumnSortPriority('od_province', priorityOdProvince)
                }
            },
            sorter: {
                compare: (a, b) => compareStrings(`${a.od_province}`, `${b.od_province}`),
                multiple: priorityOdProvince,
            },
            sortOrder: getSortOrder('od_province'),
            render: (text, record) => <div data-testid={`'od_province'-${record.key}`}>{text}</div>
        }
    ];

    return (
        <div className={'patient-matching-list-table'}>
            <div className='button-actions-div'>
                <Row>
                    <Col span={10}>
                    </Col>
                    <Col span={14}>
                        <Button
                            className='open-selected-button'
                            type='primary'
                            onClick={handleOpenSelectedPatients}
                            disabled={selectedIds && selectedIds.size === 0}
                        >
                            {OPEN_SELECTED_PATIENTS}
                            <Tooltip
                                className="col-sort-order-tooltip"
                                placement='topLeft'
                                title={getOpenSelectedTooltipText}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Button>

                        <Button
                            className='clear-priority-indexes-button'
                            type='primary'
                            onClick={handleClearPriorityIndexes}
                            disabled={priorityIndex === BASE_PRIORITY_INDEX}
                        >
                            {ADMIN_RESET_SORT}
                            <Tooltip
                                className="col-sort-order-tooltip"
                                placement='topLeft'
                                title={ADMIN_COLUMN_SORT_TOOLTIP}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Button>

                        <Button
                            className='refresh-tables-button'
                            type='primary'
                            onClick={handleRefreshTables}
                        >
                            {ADMIN_REFRESH_TABLES}
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className={scrollClass}>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        onChange: onChange,
                        selectedRowKeys: getSelectedRowKeys(),
                    }}
                    columns={nameColumns}
                    sticky={true}
                    dataSource={tableList}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 50,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    onChange={(pagination, filters, sorter) => handleTableChange(sorter)}
                />
            </div>
        </div>
    );
}

export default GpPatientListTable;