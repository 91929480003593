import { Button } from 'antd';

import dayjs from 'dayjs';
import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector, } from '../../../apps/care1-hooks';
import { setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import { setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';

const HoldButton = () => {
    const holdDate = useCare1AppSelector((store) => store.smartUpload.hold_date);
    const adminReconciliationNotes = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciliation_notes
    );

    const adminReconciler = useCare1AppSelector(
        (store) => store.uploadReconciler.admin_reconciler
    );

    const dispatch = useCare1AppDispatch();

    const handleHoldButtonClick = () => {
        const currentDate = dayjs().format('YYYY/MM/DD');
        const holdMessage = `${adminReconciliationNotes} ${currentDate} ${adminReconciler} - Upload`;
        if (holdDate) {
            // Button Text is 'Unhold'
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'hold_date',
                    value: null,
                })
            );

            dispatch(
                setUploadReconcilerSliceDataValue({
                    key: 'admin_reconciliation_notes',
                    value: `${holdMessage} hold removed.`,
                })
            );
        } else {
            // Button Text is 'Hold'
            dispatch(
                setSmartUploadSliceDataValue({
                    key: 'hold_date',
                    value: dayjs(),
                })
            );

            dispatch(
                setUploadReconcilerSliceDataValue({
                    key: 'admin_reconciliation_notes',
                    value: `${holdMessage} on hold.`,
                })
            );
        }
    };

    return (
        <Button type='primary' className='hold-button' onClick={handleHoldButtonClick}>
            {holdDate ? 'Unhold' : 'Hold'}
        </Button>
    );
};

export default HoldButton;
