import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../apps/care1-hooks';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import ComponentTextareaCard from '../component-textarea-card';

const AdminReconciliationNotes = () => {
    const adminReconciliationNotes = useCare1AppSelector(
        (store) => store.examData.upload_reconciler_admin_reconciliation_notes
    );
    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            title={'Admin Reconciliation Notes'}
            testId='admin_reconciliation_notes'
            value={adminReconciliationNotes}
            lightBlueBackground={true}
            handleChange={(e) =>
                dispatch(
                    setExamDataValue(
                        'upload_reconciler_admin_reconciliation_notes',
                        e.target.value
                    )
                )
            }
        />
    );
};

export default AdminReconciliationNotes;
